







import { Vue, Component }   from '$/lib/vueExt';
import env, { Environment } from '$/lib/env';
import PreserveState        from '$/lib/PreserveState';

import ViewportDimensions from '$/screens/components/ViewportDimensions.vue';

@Component({ components : { ViewportDimensions } })
export default class Main extends Vue {

	/**
	 * Whether or not to show the Vue Alerts for errors
	 */
	@PreserveState.LocalStorage('preferences')
	videoModeEnabled = false;

	get isNotProduction() {
		return env.isEnvironmentNot(Environment.PROD);
	}

	mounted() {
		if (this.isNotProduction && !this.videoModeEnabled) {
			Vue.installErrorToasts(this);
		}

		if (this.isNotProduction && this.videoModeEnabled) {
			(window as any).disableVideoMode = () => {
				this.videoModeEnabled = false;
				window.location.reload();
			};
			console.info('use `disableVideoMode()` to disable video mode');
		}

		// add the app name as a class to the root to enable dynamic CSS styles between FL & LCB
		this.$el.classList.add(_.kebabCase(env.app.internalName));
	}

}
