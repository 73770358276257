import Moment from 'moment';

export enum Platform {
	Server = 'server',		// the server-side platform
	Client = 'client',		// the browser-side platform
}

export enum Environment {
	DEV         = 'development',	// local development (dev's computer)
	AUTOTESTING = 'autotesting',	// non=production environment when running automated tests
	TESTING     = 'testing',		// non-production environments used for manual QA
	PROD        = 'production',
}

export const frontlobbyLaunchDate = new Date(2021, 9, 12); // Oct 12, 2021

// SHOULDDO this should be with resources
export const dataContributorDateChanged = Moment.utc('2023-07-18T00:00:00Z').toDate(); // July 18, 2023

interface App {
	internalName: string;
	isFrontLobby: boolean;
	isLCB: boolean;
	name: {
		short: string;
		full: string;
	};
}

/**
 * This class serves up information about the operating environment and configuration.
 */
export abstract class Env {

	private appCache: App;

	/**
	 * @returns the type of application that is currently running
	 */
	get app(): App {
		if (!this.appCache) {
			const isLCB   = this.domain === 'landlordcreditbureau.com';
			this.appCache = {
				internalName : isLCB ? 'lcb' : 'frontLobby',
				isFrontLobby : !isLCB,
				isLCB,
				name         : {
					short : isLCB ? 'LCB' : 'FrontLobby',
					full  : isLCB ? 'Landlord Credit Bureau' : 'FrontLobby',
				},
			};
		}
		return this.appCache;
	}

	get domain(): string {
		return this.config('domain');
	}

	abstract get platform(): Platform;

	/**
	 * Information regarding this particular build.
	 */
	get build(): AppBuild {
		throw new Error('not implemented');
	}

	/**
	 * Version of app from package.json
	 */
	get version(): string {
		throw new Error('not implemented');
	}

	/**
	 * @returns the name of the current environment.
	 * Must be a function (as opposed to a getter) in order for it to fail quickly if it's misspelled.
	 */
	abstract get environment(): Environment;

	/**
	 * A deployment is a specific instance of a environment.
	 * For example, the testing environment may have multiple deployments, such as "testing1", "testing2", etc.
	 * @returns the deployment name
	 */
	abstract get deployment(): string;

	constructor() {
		this.build.date = new Date(this.build.date);
	}

	/**
	 * Tests whether the current environment is one of the ones provided by target.
	 * @throws if the given name does not match any known environment (likely a typo in the name)
	*/
	isEnvironment(targets: PossibleArray<Environment>): boolean {
		if (!Array.isArray(targets)) {
			targets = [ targets ];
		}

		targets.forEach(this.ensureValidEnvironment);
		return targets.includes(this.environment);
	}

	/**
	 * Tests whether the current environment is not all of the given targets.
	 */
	isEnvironmentNot(targets: PossibleArray<Environment>): boolean {
		return !this.isEnvironment(targets);
	}

	get isProd() {
		return this.isEnvironment(Environment.PROD);
	}

	/**
	 * Returns the absolute URL for the current environment given the path.
	 * @param  {String} [path=''] with or without the leading slash
	 * @param  {Object} [queryParams={}] optional query parameters to add to the URL
	 * @return {String} url - if path is undefined or '', the result WILL NOT end in a trailing slash
	 */
	getAbsoluteUrl(path = '', { queryParams = null, domain = this.domain, allowAbsolute = true } = {}): string {
		queryParams = queryParams ? `?${new URLSearchParams(_.compactObject(queryParams))}` : '';

		if (allowAbsolute && path.startsWith('http')) {
			return `${path}${queryParams}`;
		}

		const hostPrefix = {
			[Environment.PROD]        : 'app',
			[Environment.DEV]         : 'local.dev',
			[Environment.TESTING]     : `testing${this.deployment}.dev`,
			[Environment.AUTOTESTING] : 'autotesting.dev',
		}[this.environment];

		if (!hostPrefix) {
			throw new Error(`cannot determine host for environment: ${this.environment}`);
		}

		path = _.ensureStartsWith(path, '/');
		return `https://${hostPrefix}.${domain}${path}${queryParams}`;
	}

	/**
	 * Returns the base domain as a URL, without an environment prefix or path.
	 */
	getDomainUrl(): string {
		return `https://${this.domain}`;
	}

	/**
	 * A short prefix that identifies the environment.
	 * Used mainly during development when there are multiple client windows open.
	 */
	getEnvironmentPrefix(): string {
		return this.isEnvironment(Environment.PROD) ? '' : this.environment[0].toUpperCase() + this.deployment;
	}

	/**
	 * Returns the given text with an environment prefix.
	 * @param {String} text
	 */
	getEnvironmentPrefixedText(text: string): string {
		const envPrefix = this.getEnvironmentPrefix();
		return envPrefix !== '' ? `[${envPrefix}] ${text}` : text;
	}

	/**
	 * Allows for emulation of changing configuration for testing purposes.
	 * @param   {Object}  [overrides] An object containing properties that are to be overridden from the actual config.
	 */
	overrideConfig(path: string, value: any) {
		_.set(this.overrides, path, value);
	}

	resetConfigOverrides() {
		this.overrides = {};
	}

	/**
	 * Returns a value from the configuration
	 * @param  {String}  path - path to value in config file, using object notation (same as for _.get)
	 * @param  {Object}  [overrides] - object to override the config file
	 * @param  {Object}  [options]
	 * @param  {Boolean} [options.required=false] - if true and when the path is not found throws an error (instead of returning undefined)
	 * @return {*}
	 */
	config(path: string, { required = false } = {}): any {
		// look for value in overrides first
		let value = _.get(this.overrides, path);

		// if there isn't an override look for it in the original config
		if (value === undefined) {
			value = _.get(this._config, path);
		}

		if (value === undefined && required) {
			throw new Error(`could not fetch config path: ${path}`);
		}

		return value;
	}

	/**
	 * @returns {EmailAddress} an email address for contacting the company
	 */
	getContactEmail(mailbox = 'support', domain = this.domain): EmailAddress {
		return `${mailbox}@${domain}`;
	}

	getNoReplyEmail(domain = this.domain): EmailAddress {
		return `noreply@${domain}`;
	}

	/**
	 * Overrides that are applied to the environment config variables when fetched through this.config()
	 */
	overrides = {};

	/**
	 * The actual configuration POJO.
	 * Must be implemented by subclasses.
	 */
	protected get _config(): Record<string, any> {
		throw new Error('not implemented');
	}

	protected ensureValidEnvironment(name: string) {
		const envNames = Object.values(Environment) as string[];
		if (!envNames.includes(name)) {
			throw new Error(`invalid environment: ${name}`);
		}
	}

}

// not actually used since Env is subclassed on both client & server but this satisfies Typescript
export default null as Env;

export interface AppBuild {
	branchName: string;
	commitHash: string;
	date:       Date | string; // will be converted to Date in constructor of common/Env
}
