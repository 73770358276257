
/**
 * Overwrite BFormCheckboxGroup to add support for mutating certain props
 */
import { BFormCheckboxGroup } from 'bootstrap-vue';
import { Component, Mixins }  from '$/lib/vueExt';
import VueValidationMixin     from '$/lib/mixins/VueValidationMixin';

@Component
export default class FormCheckboxGroup extends Mixins(BFormCheckboxGroup, VueValidationMixin) {

}
