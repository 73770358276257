import Validate             from '$/lib/Validate';
import { BaseOrgEntity }    from '$/entities/Organization';
import { BaseRole as Role } from '$/entities/roles/BaseRole';
import { BaseEntity, getEntityClass, ManyToOne, Entity, Column } from '$/entities/BaseEntity';

/**
 * Comments made by users in various contexts.
 */
@Entity({ common : true })
export class Comment extends BaseOrgEntity {

	/**
	 * The user that created the comment.
	 */
	@ManyToOne('BaseRole', { onDelete : 'CASCADE' })
	@Validate({ required : true })
	commentor: Role = undefined;

	/**
	 * The text of the comment.
	 */
	@Column({ type : 'varchar', length : 2000 })
	@Validate({ notEmpty : true, maxLength : 2000 })
	comment: string = '';

	/**
	 * The entity that this comment is in reference to.
	 * Format: <entity $class>/<entity id>
	 */
	@Column()
	@Validate({ required : true })		// SHOULDDO: add validation for the reference to ensure that it references something real and that the user has access to the referenced entity
	reference: string = undefined;
	private referenceEntity: BaseEntity = null;

	setReference(newValue: string | BaseEntity) {
		if (newValue instanceof BaseEntity) {
			this.referenceEntity = newValue;
			if (newValue.isNew) {
				return;	// wait until later for new entities
			}

			newValue = newValue.getReferenceString();
		}
		this.reference = newValue as string;
	}

	constructor(commentor?: Role, reference?: string|BaseEntity, comment?: string) {
		super();
		if (commentor) {
			this.commentor = commentor;
		}
		if (reference) {
			this.setReference(reference);
		}
		if (comment) {
			this.comment = comment;
		}
	}

	getReferenceClass(): typeof BaseEntity {
		return this.reference ? getEntityClass(this.reference.split('/', 2)[0]) : undefined;
	}

	getReferenceID(): string {
		return this.reference ? this.reference.split('/', 2)[1] : undefined;
	}

	/**
	 * Returns the entity reference by this comment, or undefined if none referenced.
	 */
	async dereference(): Promise<BaseEntity> {
		if (this.referenceEntity || !this.reference) {
			return this.referenceEntity || undefined;
		}
		return this.getReferenceClass().findOne({ id : this.getReferenceID() });
	}

	/**
	 * Override save to set the reference field just before saving if not already set.
	 * Applies to comments that originally referenced a new entity.
	 */
	save(...args) {
		if (this.referenceEntity) {
			if (this.referenceEntity.isNew) {
				throw new Error('cannot save comment with a new referenced entity.  Save the referenced entity first');
			}
			this.setReference(this.referenceEntity);
		}

		return super.save.apply(this, args);
	}

}
