
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"-3.5 0 27 27"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M0 0h20v27H0z"}}),_c('path',{attrs:{"fill":"#fff","d":"M4.043 4.813h11.488c.352 0 .64.28.64.628s-.288.63-.64.63H4.043a.636.636 0 0 1-.64-.63c0-.347.288-.628.64-.628Zm0 3.14h11.488c.352 0 .64.281.64.629s-.288.629-.64.629H4.043a.636.636 0 0 1-.64-.629c0-.348.288-.629.64-.629Zm0 3.141h11.488c.352 0 .64.281.64.629a.635.635 0 0 1-.64.625H4.043a.635.635 0 0 1-.64-.625c0-.348.288-.63.64-.63Zm0 3.14h11.488c.352 0 .64.282.64.625 0 .348-.288.63-.64.63H4.043a.636.636 0 0 1-.64-.63c0-.343.288-.625.64-.625Zm0 0"}})])
          )
        }
      }
    