
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 -5 20 20"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"m4.762 1.39 2.054 3.348C5.094 5.742 4.023 7.496 3.973 9.402H0c0-3.265 1.8-6.3 4.762-8.011Zm9.914-.3-2.059 3.355a6.352 6.352 0 0 0-5.246.004L5.312 1.094a10.575 10.575 0 0 1 9.364-.004ZM20 9.402h-3.973c-.05-1.91-1.125-3.668-2.851-4.672l2.054-3.343c2.965 1.71 4.774 4.746 4.77 8.015ZM9.535 8.43a.855.855 0 0 0-.41.523.801.801 0 0 0 .113.645c.262.402.82.53 1.25.285l.282-.246 3.289-2.957-4.274 1.652Zm0 0"}})])
          )
        }
      }
    