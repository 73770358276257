
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 -4.325 95.75 95.75"}, attrs),
              ...rest,
            },
            children.concat([_c('defs',[_c('mask',{attrs:{"id":"inner-cut-out"}},[_c('circle',{attrs:{"cx":"29.632","cy":"29.632","r":"29.632","fill":"#fff"}}),_c('circle',{attrs:{"cx":"29.632","cy":"29.632","r":"19.632","fill":"#000"}})])]),_c('circle',{attrs:{"cx":"29.632","cy":"29.632","r":"29.632","mask":"url(#inner-cut-out)"}}),_c('rect',{attrs:{"width":"13.045","height":"45.934","rx":"5","transform":"rotate(-49.98 90.95 -28.358)"}}),_c('path',{attrs:{"d":"M26.64 17.479h5.867v24.305H26.64z"}}),_c('path',{attrs:{"d":"m48.151 49.795 4.512-5.375 11.059 9.283-4.512 5.375zM18.285 25.86h22.629v5.867H18.285z"}})])
          )
        }
      }
    