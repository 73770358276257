import Axios from 'axios';

import { YardiSettings as YardiSettingsCommon, YardiVersion } from '$/common/entities/YardiSettings';
export * from '$/common/entities/YardiSettings';

export class YardiSettings extends YardiSettingsCommon {

	apiUrl(path: string) {
		return `/api/yardi${_.ensureStartsWith(path, '/')}`;
	}

	async testConnection(): Promise<string> {
		return (await Axios.post(this.apiUrl('test'),
			_.pick(this, 'username', 'password', 'serverName', 'databaseName', 'platform', 'url')
		)).data;
	}

	async checkPermissions(): Promise<string[]> {
		return (await Axios.post(this.apiUrl('permissions'),
			_.pick(this, 'username', 'password', 'serverName', 'databaseName', 'platform', 'url')
		)).data;
	}

	async getVersion(): Promise<YardiVersion> {
		return (await Axios.get(this.apiUrl('version'))).data;
	}

	async triggerSync() {
		return (await Axios.post(this.apiUrl('sync'))).data;
	}

	async getSyncStatus() {
		return (await Axios.get(this.apiUrl('status'))).data;
	}

	async getIssues() {
		return (await Axios.get(this.apiUrl('issues'))).data;
	}

}
