
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"fill":"none","stroke":"#fff","stroke-linejoin":"round","stroke-width":".7142850000000001","d":"M.953 9.883h11.43c.328 0 .594.265.594.594v8.57a.597.597 0 0 1-.594.598H.953a.6.6 0 0 1-.598-.598v-8.57c0-.329.27-.594.598-.594Zm0 0"}}),_c('path',{attrs:{"fill":"#fff","d":"M6.668 1.164 1.461 7.855h10.41l-5.203-6.69m0-.864c.14 0 .277.058.375.183l5.691 7.32a.473.473 0 0 1-.375.766H.973a.475.475 0 0 1-.375-.765L6.289.485A.475.475 0 0 1 6.668.3Zm0 0"}}),_c('path',{attrs:{"fill":"none","stroke":"#fff","stroke-linejoin":"round","stroke-width":".7142850000000001","d":"M5.715 15.594h1.902c.332 0 .598.27.598.597v2.856a.598.598 0 0 1-.598.598H5.715a.6.6 0 0 1-.598-.598V16.19a.6.6 0 0 1 .598-.597Zm0 0"}}),_c('path',{attrs:{"fill":"#fff","d":"M20 15.715a4.284 4.284 0 1 1-8.57 0 4.284 4.284 0 1 1 8.57 0Zm0 0"}}),_c('path',{attrs:{"fill":"none","stroke":"#fff","stroke-width":".7142850000000001","d":"M19.645 15.715a3.93 3.93 0 1 1-7.858-.001 3.93 3.93 0 0 1 7.858 0Zm0 0"}}),_c('path',{attrs:{"fill":"none","stroke":"#152951","stroke-width":".95238","d":"m13.767 15.937 1.511 1.512 3.18-3.18"}})])
          )
        }
      }
    