import Moment                         from 'moment';
import { Country }                    from '$/lib/Address';
import { BooleanNullableTransformer } from '$/lib/columnTransformers';
import format                         from '$/lib/formatters';
import { Index }                      from '$/lib/typeormExt';
import Errors                         from '$/lib/Errors';

import { RolePermission }    from '$/entities/roles/RolePermission';
import Permissions           from '$/entities/lib/Permissions';
import { CommonEntity, BaseEntity, Column, getEntityClass, Validate, EntityID, createEntity } from '$/entities/BaseEntity';
import type { BaseRole }     from '$/entities/roles/BaseRole';
import type { Coupon }       from '$/entities/billing/Coupon';
import type { Organization } from '$/entities/Organization';

export enum PackageType {
	SelfServe = 'selfServe',	// user can purchase this package through the UI
	WeServe   = 'weServe',		// only Support can assign this package to an org or role
}

/**
 * List of all package monetization strategies.
 */
export enum ChargeableProp {
	BaseFee          = 'baseFee',
	ChargePerLease   = 'chargePerLease',
	DebtReporting    = 'debtReporting',
	CreditChecks     = 'creditChecks',
	TenantSearch     = 'tenantSearch',
	BackgroundChecks = 'backgroundChecks',
}

/**
 * List of all package features.
 */
export enum PackageFeature {
	AutomaticReminders   = 'automaticReminders',
	RentReporting        = 'rentReporting',
	DebtReporting        = 'debtReporting',
	ReportToCollections  = 'reportToCollections',
	EmailSendCopy        = 'emailSendCopy',
	IntroEmail           = 'introEmail',
	CreditChecks         = 'creditChecks',
	TenantSearch         = 'tenantSearch',
	BackgroundChecks     = 'backgroundChecks',
	OrgImport            = 'orgImport',
	YardiImport          = 'yardiImport',
	YardiRoommatesImport = 'yardiRoommatesImport',
	ReportingThresholds  = 'reportingThresholds',
	ApplicantInvites     = 'applicantInvites',
	PreserveImportData   = 'preserveImportData', // keep the actual data from imports in testing, rather than scrambling it
	ManualPayments	     = 'manualPayments', // send invoices to customer for manual payment, rather than charging automatically
}

export enum Currency {
	CAD = 'CAD',
	USD = 'USD',
}

export interface PackagePrices {
	[strategy: string]: string | Dictionary<string>;
}

export class AllPackages {

	landlord: {
		paid: {
			US: {
				monthly: Package;
				semiYearly60Cents: Package;
				semiYearly: Package;
				yearly60Cents: Package;
				yearly: Package;
			};
			CA: {
				monthly: Package;
				semiYearly: Package;
				yearly: Package;
			};
		};
		basic: {
			US: Package;
			CA: Package;
		};
		billi: Package;
		sparrowLiving: Package;
		letUs: Package;
		letUsImported: Package;
		tricon: Package;
		yorkProperties: Package;
	};

	renter: {
		paid: {
			US: Package;
			CA: Package;
		};
		basic: {
			US: Package;
			CA: Package;
		};
		borrowell: Package;
		myMarble: Package;
		billi: Package;
	};

	internal: Package;

	constructor(packageDefinitions: NestedDictionary<Package>) {
		Object.assign(this, getPackageEntity(packageDefinitions));

		function getPackageEntity(possiblePackage) {
			if (!possiblePackage || possiblePackage instanceof Package) {
				return possiblePackage;
			}

			if (possiblePackage.id) {
				possiblePackage.$class = 'Package';
				return createEntity(possiblePackage);
			}

			return _.mapValues(possiblePackage, value => getPackageEntity(value));
		}
	}

	/**
	 * @returns the Package with the given ID
	 */
	find(packageID: EntityID) {
		return this.filter(pkg => pkg.id === packageID)?.[0];
	}

	/**
	 * @returns a list of packages for which the callback has returned `true`
	 */
	filter(predicate: (pkg: Package) => boolean) {
		return traversePackages(this);

		function traversePackages(collection: AllPackages | NestedDictionary<Package>) {
			const result: Package[] = [];
			for (const value of Object.values(collection)) {
				if (value instanceof Package) {
					if (predicate(value)) {
						result.push(value);
					}
				}
				else {
					result.push(...traversePackages(value));
				}
			}
			return result;
		}
	}

}

/**
 * A package is a collection of features which enables certain functionality within the application.
 */
@CommonEntity()
@Permissions({
	create : Permissions.roleHasPermission(RolePermission.PackageWrite),
	update : Permissions.roleHasPermission(RolePermission.PackageWrite),
	delete : Permissions.roleHasPermission(RolePermission.PackageWrite),
})
export class Package extends BaseEntity {

	/**
	 * The name of the package.
	 */
	@Column()
	@Validate({ required : true })
	name: string = '';

	@Column({ default : PackageType.SelfServe })
	type: PackageType = PackageType.SelfServe;

	/**
	 * If not null, this package is restricted to this set of roles.
	 * The values must be the class names of subclasses of the BaseRole entity.
	 */
	@Column({ type : 'simple-array' })
	@Validate({ required : true, minLength : 1 })
	roles: string[] = [];

	/**
	 * The IDs of the Stripe plans associated with this package.
	 */
	@Column({ type : 'json' })
	stripePrices: PackagePrices = {};

	/**
	 * The currency that this stripe Plan charges in.
	 */
	@Column({ type : 'char', length : 3 })
	@Validate({ required : true })
	currency: Currency = Currency.CAD;

	// #region Package Features

	@Index()
	@Feature("Sends out automatic email reminders to the user's organization's tenants to pay rent.")
	@Column({ type : 'boolean', transformer : BooleanNullableTransformer, nullable : true })
	automaticReminders: boolean;

	@Feature('The organization has access to Rent Reporting features.')
	@Column({ type : 'boolean', transformer : BooleanNullableTransformer, nullable : true })
	rentReporting: boolean;

	@Feature('The organization has access to Debt Reporting features.')
	@Column({ type : 'boolean', transformer : BooleanNullableTransformer, nullable : true })
	@Chargeable({ manual : true, metered : true })
	debtReporting: boolean;

	@Feature('Sends a copy of every email to landlord')
	@Column({ type : 'boolean', transformer : BooleanNullableTransformer, nullable : true })
	emailSendCopy: boolean;

	@Feature('Send out a introduction email to tenants informing them of being registered in FrontLobby, and allowed tenants to be connect to their lease')
	@Column({ type : 'boolean', transformer : BooleanNullableTransformer, nullable : true })
	introEmail: boolean;

	@Feature('The organization has access to collection features.')
	@Column({ type : 'boolean', transformer : BooleanNullableTransformer, nullable : true })
	reportToCollections: boolean;

	@Feature('Landlords can view the credit report ')
	@Chargeable({ manual : true, metered : true })
	@Column({ type : 'boolean', transformer : BooleanNullableTransformer, nullable : true })
	creditChecks: boolean;

	@Feature('Landlord can view background checks')
	@Chargeable({ manual : true, metered : true })
	@Column({ type : 'boolean', transformer : BooleanNullableTransformer, nullable : true })
	backgroundChecks: boolean;

	@Feature('The organization has access to tenant search features and will be charged a fee to view information about a tenant.')
	@Chargeable({ manual : true, metered : true })
	@Column({ type : 'boolean', transformer : BooleanNullableTransformer, nullable : true })
	tenantSearch: boolean;

	@Feature('Able to import data into other organizations')
	@Column({ type : 'boolean', transformer : BooleanNullableTransformer, nullable : true })
	orgImport: boolean;

	@Feature('Able to import data from Yardi')
	@Column({ type : 'boolean', transformer : BooleanNullableTransformer, nullable : true })
	yardiImport: boolean;

	@Feature('Able to import data from Yardi Roommates')
	@Column({ type : 'boolean', transformer : BooleanNullableTransformer, nullable : true })
	yardiRoommatesImport: boolean;

	@Feature('Able to manage Reporting Thresholds')
	@Column({ type : 'boolean', transformer : BooleanNullableTransformer, nullable : true })
	reportingThresholds: boolean;

	@Feature('Able to invite applicants for tenant screening')
	@Column({ type : 'boolean', transformer : BooleanNullableTransformer, nullable : true })
	applicantInvites: boolean;

	// #endregion Package Features

	/**
	 * If true, the organization will be charged a flat fee every month for this package.
	 */
	@Chargeable()
	@Column({ type : 'boolean', transformer : BooleanNullableTransformer, nullable : true })
	baseFee: boolean;

	/**
	 * If true, the organization will be charged a certain amount per 'current lease'
	 */
	@Chargeable({ metered : true })
	@Column({ type : 'boolean', transformer : BooleanNullableTransformer, nullable : true })
	chargePerLease: boolean;

	@Feature('Send invoices for manual payment')
	@Column({ type : 'boolean', transformer : BooleanNullableTransformer, nullable : true })
	manualPayments: boolean;

	/**
	 * @returns true if this Package is a default package for some role.
	 */
	get isBasic() {
		const all = (this.constructor as typeof Package).all;
		return [ ...Object.values(all.renter.basic), ...Object.values(all.landlord.basic) ].some(pkg => pkg?.id === this.id);
	}

	get isMonthly() {
		const all = (this.constructor as typeof Package).all;
		return [ Country.CA, Country.US ].some(country => all.landlord.paid[country].monthly?.id === this.id);
	}

	get isSemiYearly() {
		const all = (this.constructor as typeof Package).all;
		return [
			all.landlord.paid.CA.semiYearly,
			all.landlord.paid.US.semiYearly,
			all.landlord.paid.US.semiYearly60Cents,

		].some(pkg => pkg?.id === this.id);
	}

	get isYearly() {
		const all = (this.constructor as typeof Package).all;
		return [
			all.landlord.paid.CA.yearly,
			all.landlord.paid.US.yearly,
			all.landlord.paid.US.yearly60Cents,

		].some(pkg => pkg?.id === this.id);
	}

	get isPlus() {
		const all = (this.constructor as typeof Package).all;
		return [ Country.CA, Country.US ].some(country => all.renter.paid[country]?.id === this.id);
	}

	get isEnterprise() {
		const all = (this.constructor as typeof Package).all;
		return [ all.landlord.tricon ].some(pkg => pkg?.id === this.id);
	}

	/**
	 * package is a predefined package available in Package.all
	 */
	get isStandard() {
		const all        = (this.constructor as typeof Package).all;
		const isStandard = pkgs => {
			if (pkgs instanceof Package) {
				return this?.id === pkgs.id;
			}
			return Object.values(pkgs).some(pkg => isStandard(pkg));
		};
		return isStandard(all);
	}

	/**
	 * @returns true if this Package has at least one chargeable set to true.
	 */
	get isPaid() {
		return Package.getAllChargeables().some(chargeable => this[chargeable] === true);
	}

	get canUpgrade() {
		return !(this.isPlus || this.isYearly || this.isEnterprise);
	}

	/**
	 * Returns the pricing for this package.
	 */
	getPricing({ includeManual = false, includeMetered = true } = {}): PackagePricing {
		const pricing: PackagePricing = {};
		const chargeables             = Package.getAllChargeables({ includeManual, includeMetered });

		if (this.stripePrices) {
			for (let i = 0; i < chargeables.length; i++) {
				const chargeable = chargeables[i];
				if (!this.stripePrices[chargeable]) {
					continue;
				}

				// SHOULDDO: this isn't fully accurate as it will always use the current date for getPriceByDate
				// the perLease pricing should be based on the subscription start date, but we can't get that synchronously or client side
				pricing[chargeable] = (this.constructor as typeof Package).allPricing[this.getPriceByDate(chargeable as ChargeableProp)];
			}
		}

		return pricing;
	}

	getAutoCouponPricing(chargeables: ChargeableProp[], pricing: PackagePricing) {
		const coupons = (getEntityClass('Coupon') as typeof Coupon).getAutoCoupon(chargeables, this)?.coupons || {};

		let totalPrice = 0;
		for (const chargeable of chargeables) {
			let amount  = pricing[chargeable]?.amount ?? 0;
			amount      = coupons[chargeable] ? coupons[chargeable].applyDiscountTo(amount) : amount;
			totalPrice += amount;
		}
		return _.round(totalPrice, 2);
	}

	getPricingWithAutoCoupons({ includeManual = false, includeMetered = true, chargeables = [] } = {}) {
		const pricing: PackagePricing & { bundle?: number } = this.getPricing({ includeManual, includeMetered });
		pricing.bundle = this.getAutoCouponPricing(chargeables, pricing);
		return pricing;
	}

	/**
	 * Returns the price difference between this package and the specified package for the specified chargeable
	 */
	async getPriceDifference(pkg: Package, chargeable: ChargeableProp, interval?: PricingInterval): Promise<number> {
		const metadata           = Reflect.getMetadata('packageChargeable', this.constructor, chargeable);
		const args               = { includeManual : metadata.manual, includeMetered : metadata.metered };
		const currentPricing     = this.getPricing(args)?.[chargeable];
		const alternativePricing = pkg.getPricing(args)?.[chargeable];

		const currentAmount     = currentPricing?.amount;
		const alternativeAmount = alternativePricing?.amount;

		if (currentAmount === undefined || alternativeAmount === undefined) {
			return 0;
		}

		let currentMultiplier     = 1;
		let alternativeMultiplier = 1;

		if (!interval) {
			interval = currentPricing.interval as PricingInterval;
		}

		if (interval !== PricingInterval.OneTime) {
			// convert the pricing intervals to the specified interval and extract the multiplier of that interval
			// For example if the specified interval is 'year' and price is in months the multiplier will be 12
			currentMultiplier     =  Moment.duration(1, currentPricing.interval as any).as(interval);
			alternativeMultiplier =  Moment.duration(1, alternativePricing.interval as any).as(interval);
		}

		return Math.trunc(currentMultiplier * currentAmount - alternativeMultiplier * alternativeAmount);
	}

	/**
	 * @returns {Boolean} true if this package is applicable to the given country code
	 */
	isForCountry(country: Country): boolean {
		switch (country) {
			case Country.US:
				return this.currency === Currency.USD;

			case Country.CA:
			case '' as Country:
				return this.currency === Currency.CAD;

			default: false;
		}
	}

	/**
	 * @returns true if this package has a truthy value for the given feature, returns false otherwise
	 * @throws if the feature is invalid
	 */
	hasFeature(feature: PackageFeature): boolean {
		return !!this[feature] && _.values(PackageFeature).includes(feature);
	}

	/**
	 * @returns true if this package has a truthy value for the given strategy, returns false otherwise
	 * @throws if the strategy is invalid
	 */
	isChargeable(prop: ChargeableProp): boolean {
		return !!this[prop] && _.values(ChargeableProp).includes(prop);
	}

	/**
	 * @return the Stripe coupon given the coupon's promoCode
	 * @throws if no active coupon exists or coupon does not apply to the package's stripe prices for the given promoCode
	 */
	async getCoupon(promoCode: string, chargeable?: ChargeableProp): Promise<any> { // eslint-disable-line @typescript-eslint/no-unused-vars
		throw new Errors.NotImplemented();
	}

	getPriceByDate(chargeable: ChargeableProp, date: Date = new Date()) {
		return (this.constructor as typeof Package).getPriceByDate(this.stripePrices[chargeable], date);
	}

	/**
	 * Checks if the package has the given price. If the chargeable is specified, it will only check for the price for that chargeable.
	 * @returns true if the given priceID is one of the prices in the package, false otherwise
	 */
	doesIncludePrice(priceID: string, chargeable?: ChargeableProp) {
		const chargeables = chargeable ? [ chargeable ] : Package.getAllChargeables();

		for (const chargeable of chargeables) {
			const prices              = this.stripePrices[chargeable];
			let finalPrices: string[] = [];
			if (typeof prices === 'string') {
				finalPrices = [ prices ];
			}
			else if (typeof prices === 'object') {
				finalPrices = Object.values(prices);
			}
			if (finalPrices.includes(priceID)) {
				return true;
			}
		}

		return false;
	}

	/**
	 * Returns the fields of Package that have the @PackageFeature property decorator.
	 */
	static getAllFeatures(): string[] {
		return _.values(PackageFeature);
	}

	/**
	 * Returns the fields of Package that have the @Chargeable property decorator.
	 * @param {Object}  [options]
	 * @param {Boolean} [options.includeManual]  if true, will also include chargeables that aren't automatically billed when an org upgrades to a package
	 * @param {Boolean} [options.includeMetered] if true, it will also include chargeables that are metered e.g. PerLease, Collect, etc.
	 */
	static getAllChargeables({ includeManual = false, includeMetered = true } = {}): ChargeableProp[] {
		return _.values(ChargeableProp).filter(property => {
			const metadata = Reflect.getMetadata('packageChargeable', this, property);
			return (includeManual || !metadata.manual) && (includeMetered || !metadata.metered);
		});
	}

	static getFeatureDescription(feature: PackageFeature): string {
		return Reflect.getMetadata('packageFeatureDescription', this, feature) || '';
	}

	/**
	 * Returns the default (free) package for the given role or TypeOfBusiness.
	 */
	static getDefaultFor<T extends typeof Package>(this: T, indicator: BaseRole | Organization): InstanceType<T> {
		let isRenter         = false;
		let country: Country = null;

		// avoid circular dependency
		if (indicator.instanceof('Renter') || indicator.instanceof('Applicant')) {
			isRenter   = true;
			const role = indicator as BaseRole;
			country    = role.user.country || role.org?.country;
		}
		else if (indicator.instanceof('Organization')) {
			const org = indicator as Organization;
			isRenter  = org.typeOfBusiness === 'renter';   // SHOULDDO: use TypeOfBusiness.Renter enum but that currently causes a circular dependency
			country   = org.country;
			if (!country && org.package) {
				country = org.package.isForCountry(Country.US) ? Country.US : Country.CA;
			}
		}

		country ||= Country.CA;	// last-resort fallback

		return (isRenter ? this.all.renter.basic[country] : this.all.landlord.basic[country]) as InstanceType<T>;
	}

	/**
	 * Get the price based on the current time
	 * @param prices A dictionary of prices, with the date the price comes into effect as its key (as as string)
	 */
	static getPriceByDate(prices: string | Dictionary<string>, date: Date = new Date()) {
		// If there aren't different prices for different dates, just return the price
		if (typeof prices === 'string')  {
			return prices;
		}

		// Otherwise, find the price that is in effect - i.e. the price with the latest date that is before the given date
		// An empty string is considered to be the default date, so if no other date is found that applies, use that
		let latestDate = null;
		for (const priceDate of Object.keys(prices)) {
			if ((latestDate === null && priceDate === '') || ((latestDate === '' || Moment(priceDate).isAfter(new Date(latestDate))) && Moment(priceDate).isBefore(date))) {
				latestDate = priceDate;
			}
		}

		// Throw an error if no price was found - use defaults when setting up the prices to avoid this
		if (latestDate === null) {
			throw new Error('No price found');
		}

		return prices[latestDate];
	}

	static get all(): AllPackages {
		throw new Errors.NotImplemented();
	}

	/**
	 * @returns the set of all available stripe plans keyed by Stripe's priceIDs
	 */
	static get allPricing(): Dictionary<StripePrice> {
		throw new Errors.NotImplemented();
	}

}

export enum PricingInterval {
	Day     = 'day',
	Month   = 'month',
	Week    = 'week',
	Year    = 'year',
	OneTime = 'one-time',
}

export interface StripePrice {
	id: string;
	name: string;
	currency: string;
	amount: number;
	monthlyAmount?: number;
	interval: string;
	intervalCount: number;
	unit: string;
	metadata?: Record<string, any>;
}

/**
 * Keys are various chargeables.
 */
export type PackagePricing = Dictionary<StripePrice>;

export function formatPricing(pricing: PossibleArray<StripePrice>, options?: { includeUnit?: boolean; includeInterval?: boolean }) {
	options = _.defaults(options, { includeUnit : true, includeInterval : true });
	pricing = _.castArray(pricing).filter(price => !_.isNil(price)) as StripePrice[];

	if (!pricing.length) {
		return;
	}

	// SHOULDDO: Find a way to display this nicely so we can support it
	const units = _.uniq(pricing.map(price => price.unit));
	if (units.length > 1) {
		throw new Error('Multiple unit types not supported in a single transaction.');
	}
	const unit = units[0];

	// SHOULDDO: Find a way to display this nicely so we can support it
	const intervals      = _.uniq(pricing.map(price => price.interval));
	const intervalCounts = _.uniq(pricing.map(price => price.intervalCount));
	if (intervals.length > 1 || intervalCounts.length > 1) {
		throw new Error('Multiple intervals not supported in a single transaction.');
	}
	const interval      = intervals[0];
	const intervalCount = intervalCounts[0];

	let result = format.currency(_.sumBy(pricing, 'amount'));
	if (unit && options.includeUnit) {
		result += ` / ${unit}`;
	}
	if (interval && interval !== PricingInterval.OneTime && options.includeInterval) {
		// if it's a single interval unit just show ' / year', otherwise, show '/ 2 years'
		result += ` / ${format.pluralize(interval, intervalCount || 1, Number(intervalCount) > 1)}`;
	}

	return result;
}

export function formatPricingMonth(pricing: StripePrice) {
	return pricing ? `${format.currency(pricing.monthlyAmount)} / month` : '';
}

/**
 * Decorator that marks the given property as a Package Feature (for easy identification later)
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
function Feature(description?: string) {
	return function(target, propertyKey: string) {
		Reflect.defineMetadata('packageFeature', true, target.constructor, propertyKey);
		if (description) {
			Reflect.defineMetadata('packageFeatureDescription', description, target.constructor, propertyKey);
		}
	};
}

/**
 * Decorator that marks the given property as a Package Charge (for easy identification later)
 * @param {Object} [options]
 * @param {boolean} [options.manual] if set to true, Stripe won't add the respective plans to the orgs subscription when they sign up for the package
 * @param {boolean} [options.metered]
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
function Chargeable({ manual = false, metered = false } = {}) {
	return function(target, propertyKey: string) {
		Reflect.defineMetadata('packageChargeable', { manual, metered }, target.constructor, propertyKey);
	};
}
