


































import { Vue, Component, Prop } from '$/lib/vueExt';

export enum UnavailableVariants {
	ComingSoon = 'comingSoon',
	Unverified = 'unverified',
}

/**
 * A simple widget that display a text message indicating that something is not available.
 */
@Component
export default class Unavailable extends Vue {

	@Prop({ default : undefined })
	title: string;

	@Prop({ default : undefined })
	text: string;

	@Prop({ default : undefined })
	icon: string;

	@Prop({ default : undefined })
	orientation: string;

	/**
	 * Optional variant that sets the title, text, and icon to various pre-sets.
	 */
	@Prop()
	variant: UnavailableVariants;

	get variantOptions() {
		return {
			comingSoon : {
				title : 'Coming Soon',
				icon  : 'wrench',
				text  : 'Currently under construction',
			},
			unverified : {
				title : '',
				icon  : 'lock-fill',
				text  : this.$role.isWaitingApproval
					? 'Profile in Review: This usually takes 1 full business day.'
					: 'Security and privacy are our priority. To unlock this report you must first verify your identity',
			},
		};
	}

	get localTitle() {
		return (this.title ?? this.variantOptions[this.variant]?.title) || '';
	}

	get localIcon() {
		return (this.icon ?? this.variantOptions[this.variant]?.icon) || 'lock-fill';
	}

	get localText() {
		return (this.text ?? this.variantOptions[this.variant]?.text) || '';
	}

	get localOrientation() {
		return this.orientation || (this.$slots.callToAction ? 'horizontal' : 'vertical');
	}

	created() {
		if (this.variant && !this.variantOptions[this.variant]) {
			throw new Error(`unknown Unavailable variant: ${this.variant}`);
		}
	}

}
