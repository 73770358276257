










import { Vue, Component, Prop, Inject } from '$/lib/vueExt';
import type Steps                       from './Steps.vue';

@Component
export default class StepTitle extends Vue {

	@Inject({ from : 'stepsComponentInject', default : undefined })
	readonly stepsComponentInject?: Steps;

	@Prop({ default : null })
	readonly stepsComponent?: Steps;

	@Prop({ default : '' })
	readonly title;

	/**
	 * @returns the parents Steps component, either from the prop or from the injected value.
	 */
	 get steps(): Steps {
		return this.stepsComponent || this.stepsComponentInject;
	}

	get computedTitle() {
		const currentStep = this.steps?.currentStep;
		const title       = currentStep?.stepGroup?.title || currentStep?.title || this.title || '';
		return title.replaceAll('{#}', this.stepNumber + 1);
	}

	get stepNumber() {
		return this.steps ? this.steps.enabledSteps.indexOf(this.steps?.currentStep) : 0;
	}

	get stepCount() {
		return this.steps?.enabledSteps.length;
	}

}
