import Axios        from 'axios';
import initializers from '$/lib/initializers';
import { Entity }   from '$/entities/BaseEntity';

import { Package as PackageCommon, AllPackages, StripePrice, ChargeableProp } from '$/common/entities/Package';
export *                                                                      from '$/common/entities/Package';

let allPackages: AllPackages;
let allPricing: Dictionary<StripePrice>;

@Entity()
export class Package extends PackageCommon {

	async getCoupon(promoCode: string, chargeable?: ChargeableProp) {
		return (await Axios.get(Package.collectionUrl(`${this.id}/coupon/${encodeURIComponent(promoCode)}`), { params : { chargeable } })).data;
	}

	static get all(): AllPackages {
		return allPackages;
	}

	static get allPricing(): Dictionary<StripePrice> {
		return allPricing;
	}

}

// load up all Packages
void initializers.register(async function() {
	allPackages = new AllPackages((await Axios.get(Package.collectionUrl('all'))).data);
});

void initializers.register(async function() {
	allPricing = (await Axios.get(Package.collectionUrl('pricing'))).data;
});
