
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M19.654 18.88A2.33 2.33 0 0 0 20 17.656a2.346 2.346 0 0 0-2.344-2.343v-5.9h1.758a.586.586 0 0 0 .386-1.027l-2.183-1.91v-4.68a.586.586 0 0 0-.586-.586h-2.343a.586.586 0 0 0-.586.586v1.601L10.386.145a.586.586 0 0 0-.772 0L.2 8.387a.586.586 0 0 0 .386 1.027h1.758v5.899A2.347 2.347 0 0 0 0 17.655c0 .448.127.868.346 1.224A.586.586 0 0 0 .586 20h18.828a.586.586 0 0 0 .24-1.12zm-4.38-16.497h1.171V5.45l-1.172-1.026V2.383zM4.101 18.828H2.344a1.173 1.173 0 0 1-1.172-1.172c0-.646.526-1.172 1.172-1.172.496 0 .94.32 1.102.796a.586.586 0 0 0 .706.378.587.587 0 0 1-.05 1.17zm7.656 0H8.242v-4.726h3.516v4.726zm2.483 0h-1.31v-5.312a.586.586 0 0 0-.586-.586H7.656a.586.586 0 0 0-.586.586v5.312H5.76a1.76 1.76 0 0 0-1.38-2.322 2.348 2.348 0 0 0-.863-.876V8.828a.586.586 0 0 0-.586-.586h-.785L10 1.365l7.855 6.877h-.785a.586.586 0 0 0-.586.586v6.802a2.349 2.349 0 0 0-.862.876 1.76 1.76 0 0 0-1.38 2.322zm3.415 0h-1.758a.587.587 0 0 1-.05-1.17.586.586 0 0 0 .706-.378 1.169 1.169 0 0 1 1.102-.796 1.173 1.173 0 0 1 0 2.344z"}})])
          )
        }
      }
    