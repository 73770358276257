import './hubspot.scss';
import env, { Environment } from '$/lib/env';
import { loadScript }       from '$/lib/utils';

const contact = {
	firstName : '',
	lastName  : '',
	email     : '',
	source    : '',
	device    : `${env.device.manufacturer} ${env.device.product}`,
	os        : env.os.toString(),
	browser   : `${env.browser.name} ${env.browser.version}`,
};

const template = `
<div class="ticket-container hidden">
	<div class="ticket-form">
		<div class="header">
			<span class="close-form">—</span>
			Leave us a message
		</div>
		<div class="body">
			<!-- HUBSPOT SUPPORT TICKET FORM WILL BE INJECTED HERE -->
		</div>
	</div>
</div>`;

let loadPromise: Promise<any>;
const knowledgeBaseURL = `https://${env.isEnvironment(Environment.PROD) ? 'help' : 'info.dev'}.${env.domain}`;

export class HubSpotTicket {

	static hideTicketTimeout: any;

	static load(): Promise<void> {
		loadPromise ??= loadHelper();
		return loadPromise;

		async function loadHelper() {
			if (!((window as any).jQuery && (window as any).$)) {
			// load jQuery
				await loadScript('https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js');
			}
			// load HubSpot
			await loadScript('https://js.hsforms.net/forms/v2.js');

			// Append the form to body
			const form     = document.createElement('div');
			form.innerHTML = template.trim();
			document.body.appendChild(form);
			HubSpotTicket.getElement('.header .close-form').on('click', function() {
				void HubSpotTicket.hideTicketForm();
			});
		}
	}

	static openKnowledgeBase() {
		window.open(knowledgeBaseURL, '_blank');
	}

	static openTicketForm = _.debounce(async (message?: string, category?: string) => {
		await this.load();

		// delete the form
		this.getElement('.body').empty();

		(window as any).hbspt.forms.create({
			portalId     : env.config('hubSpot.portalID'),
			formId       : env.config('hubSpot.formID'),
			target       : '.ticket-form .body',
			onFormSubmit : async () => {
				let content = this.getValue('content').trim();
				if (content.length > 20) {
					content = `${content.slice(0, 20)}...`;
				}
				this.setValue('subject', `${this.getValue('hs_ticket_category')}: ${content}`);
				this.getElement('[type=submit]').val('Submitting').prop('disabled', true);
			},
			onFormSubmitted : () => {
				this.hideTicketTimeout = setTimeout(() => {
					void this.hideTicketForm();
				}, 3000);
			},
			onFormReady : async () => {
				const visualViewport = (window as any).visualViewport;
				this.setValue('firstname', contact.firstName);
				this.setValue('lastname', contact.lastName);
				this.setValue('email', contact.email);
				this.setValue('source', contact.source);
				this.setValue('url', `${window.location.origin}${window.location.pathname}`);
				this.setValue('viewport', typeof visualViewport === 'object' ? `${Math.round(visualViewport.width)}x${Math.round(visualViewport.height)}` : '???');
				this.setValue('device', contact.device);
				this.setValue('os', contact.os);
				this.setValue('browser', contact.browser);
				this.setValue('content', message || '');
				this.setValue('hs_ticket_category', category || '');
				this.container.removeClass('hidden');
				$('body').addClass('no-scroll');
			},
		});
	}, 2500, { leading : true, trailing : false });

	static async hideTicketForm() {
		if (this.hideTicketTimeout) {
			clearTimeout(this.hideTicketTimeout);
		}

		$('body').removeClass('no-scroll');
		this.container.addClass('hidden');
		await new Promise(resolve => setTimeout(resolve, 500));
		this.getElement('.body').empty();
	}

	static get container() {
		return $('.ticket-container');
	}

	static getElement(selectorOrNameAttribute: string) {
		const possibleSelectors = [
			`.ticket-form [name='TICKET.${selectorOrNameAttribute}']`,
			`.ticket-form [name='${selectorOrNameAttribute}']`,
			`.ticket-form ${selectorOrNameAttribute}`,
		];
		for (const selector of possibleSelectors) {
			const elements = $(selector);
			if (elements.length > 0) {
				return elements;
			}
		}

		return $([]);
	}

	static setValue(selector: string, value: string) {
		if (value) {
			this.getElement(selector).val(value).change();
		}
	}

	static getValue(selector: string): string {
		return this.getElement(selector).val() as string || '';
	}

	static setContact(firstName: string, lastName: string, email: string, source?: string) {
		contact.firstName = firstName;
		contact.lastName  = lastName;
		contact.email     = email;
		contact.source    = source;
	}

}
