
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M1.293 9.941a.355.355 0 0 0 .36.352h1.894a.351.351 0 0 0 .355-.352V7.43c0-.192.157-.352.352-.352H6.12c.195 0 .352.16.348.352v2.51a.355.355 0 0 0 .36.352h1.898a.351.351 0 0 0 .25-.102.351.351 0 0 0 .101-.25V3.86h1.129a.16.16 0 0 0 .16-.105.158.158 0 0 0-.058-.184c-8.07-5.695 1.597 1.121-5.02-3.543a.216.216 0 0 0-.207 0L.07 3.57c-.058.04-.086.114-.058.184a.16.16 0 0 0 .16.105h1.121Zm9.664 8.669c-4.152.386-8.258-2.614-8.96-7.028h-.345a1.618 1.618 0 0 1-1.007-.34c.554 5.477 5.671 9.403 11.097 8.578a6.01 6.01 0 0 1-.785-1.21Zm9-6.965C20.898 5.098 15.07-.415 8.586.77l1.61 1.14c.042-.008.085-.008.128-.008a8.469 8.469 0 0 1 6.164 2.664 8.323 8.323 0 0 1 2.246 6.29c.43.226.84.492 1.223.789Zm.098 4.691c0-1.984-1.621-3.59-3.621-3.59-1.997 0-3.618 1.606-3.618 3.59 0 1.984 1.621 3.594 3.618 3.594 2 0 3.62-1.61 3.62-3.594Zm0 0"}}),_c('path',{attrs:{"fill":"#fff","d":"m15.602 17.367-1.122-1.105-.378.379 1.503 1.48 3.04-3.035-.38-.379Zm0 0"}})])
          )
        }
      }
    