import { Mixin }           from '$/lib/utils';
import { JSONable, Field } from '$/entities/lib/JSONable';

@Mixin(JSONable)
export class CommunicationDefaults {

	@Field()
	rentReminderEmail: boolean;

	@Field()
	latePaymentWarningEmail: boolean;

	@Field()
	latePaymentWarningDelay?: number;

}
