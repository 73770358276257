/**
 * These classes are an abstraction around the various credit report implementations.
 */
import Moment      from 'moment';
import { Mixin }   from '$/lib/utils';
import { Country } from '$/lib/Address';

import { Address as AddressJson } from '$/entities/Address';
import { JSONable, Field }        from '$/entities/lib/JSONable';

export enum HitCode {
	Hit                 = '1',
	NoHit               = '2',
	ManualFile          = '3',
	ConsumerDeclaration = '4',
	ReferredFile        = '5',
	CreditLock          = '7',
}

@Mixin(JSONable)
export class Address {

	@Field()
	address: AddressJson;

	@Field()
	status: string;

	constructor(street: string, city: string, province: string, country: Country, postalCode: string, status: string) {
		this.address = new AddressJson({ street, city, province, country, postalCode });
		this.status  = status;
	}

	static fromEquifax(address, country) {
		return new this(
			[ address.CivicNumber, address.StreetName ].filter(s => s).join(' '),	// need to filter out undefined values
			address.City['#text'],
			address.Province.code,
			country,
			address.PostalCode,
			address.description
		);
	}

	// mapping based on - https://docs.certn.co/#section/Report-Field-Mapping
	static fromCertn(address, country) {
		return new this(
			`${address.civic_number} ${address.street_name}`,
			address.city,
			address.province,
			country,
			'', // postal code not avail in certn?
			'Unknown' // not avail
		);
	}

}

@Mixin(JSONable)
export class Employment {

	@Field()
	employer: string;

	@Field()
	occupation: string;

	constructor(employer: string, occupation: string) {
		this.employer   = employer;
		this.occupation = occupation;
	}

	static fromEquifax(employment: any) {
		return new this(employment.Employer, employment.Occupation);
	}

	static fromCertn(employment: any) {
		return new this(employment.employer, employment.occupation);
	}

}

@Mixin(JSONable)
export class Tradeline {

	@Field()
	creditor: string;

	@Field()
	paymentStatus: string;

	@Field()
	latePayments: number[];

	@Field()
	rCode: string;

	@Field()
	type: string;

	@Field()
	dateOpened: Date;

	@Field()
	dateReported: Date;

	@Field()
	highCredit: number;

	@Field()
	balance: number;

	@Field()
	pastDue: number;

	@Field()
	narratives: string[];

	constructor(tradeline: Tradeline) {
		Object.assign(this, tradeline);
	}

	static fromEquifax(trade) {
		return new this({
			creditor      : trade.CreditorId?.Name,
			paymentStatus : trade.PaymentRate?.description,
			latePayments  : [ 30, 60, 90 ].map(interval => trade.HistoryDerogatoryCounters?.[`Count${interval}DayPastDue`] || 0),
			rCode         : `${trade.PortfolioType?.code}-${trade.PaymentRate?.code}`,
			type          : trade.PortfolioType?.description,
			dateOpened    : Moment(trade.DateOpened).toDate(),
			dateReported  : Moment(trade.DateReported).toDate(),
			highCredit    : trade.HighCreditAmount,
			balance       : trade.BalanceAmount,
			pastDue       : trade.PastDueAmount,
			narratives    : mapArrayOrObject(trade.Narratives?.Narrative || [], narrative => narrative.description),
		});
	}

	static fromCertn(trade) {
		return new this({
			creditor      : trade.credit_grantor,
			paymentStatus : trade.payment_status,
			latePayments  : [ 30, 60, 90 ].map(interval => trade?.[`past_due_count_${interval}_day`] || 0),
			rCode         : trade.r_code,
			type          : trade.account_type,
			dateOpened    : Moment(trade.date_opened).toDate(),
			dateReported  : Moment(trade.date_reported).toDate(),
			highCredit    : trade.high_credit,
			balance       : trade.balance_amount,
			pastDue       : trade.past_due_amount,
			narratives    : trade.narratives || [],
		});
	}

}

@Mixin(JSONable)
export class Collection {

	@Field()
	status: string;

	@Field()
	creditor: string;

	@Field()
	dateAssigned: Date;

	@Field()
	originalAmount: number;

	@Field()
	balanceAmount: number;

	constructor(collection: Collection) {
		Object.assign(this, collection);
	}

	static fromEquifax(collection) {
		return new this({
			status         : collection.description,
			creditor       : collection.CollectionCreditor?.AccountNumberAndOrName,
			dateAssigned   : Moment(collection.AssignedDate).toDate(),
			originalAmount : collection.OriginalAmount,
			balanceAmount  : collection.BalanceAmount,
		});
	}

	static fromCertn(collection) {
		return new this({
			status         : 'Unknown', // previously no status
			creditor       : collection.creditor || 'Creditor Unnamed',
			dateAssigned   : Moment(collection.assigned_date).toDate(),
			originalAmount : collection.amount_owed,
			balanceAmount  : collection.amount_owed - collection.amount_paid, // correct based off of the old report
			// accountDesignation : collection.account_designation //  do we need?
		});
	}

}

@Mixin(JSONable)
export class Bankruptcy {

	@Field()
	dateFiled: Date;

	@Field()
	type: string;

	@Field()
	customer: string;

	@Field()
	assetAmount: number;

	@Field()
	caseNumberAndTrustee: string;

	@Field()
	filer: string;

	@Field()
	liabilityAmount: number;

	@Field()
	intentOrDisposition: string;

	constructor(bankruptcy: Bankruptcy) {
		Object.assign(this, bankruptcy);
	}

	static fromEquifax(act) {
		return new this({
			dateFiled            : Moment(act.DateFiled).toDate(),
			type                 : act.Type?.description,
			customer             : act.CourtId?.Name,
			assetAmount          : act.AssetAmount,
			caseNumberAndTrustee : act.CaseNumberAndTrustee,
			filer                : act.Filer?.description,
			liabilityAmount      : act.LiabilityAmount,
			intentOrDisposition  : act.IntentOrDisposition?.description,
		}
		);
	}

	static fromCertn(act) {
		return new this({
			dateFiled            : Moment(act.date_reported).toDate(),
			type                 : act.account_type,
			customer             : act.customer,
			assetAmount          : act.asset_amount,
			caseNumberAndTrustee : act.case_number_and_trustee,
			filer                : act.filer,
			liabilityAmount      : act.liability_amount,
			intentOrDisposition  : act.intent_or_disposition,
			// legalNarrative       : act.legal_narrative, // do we need?
		}
		); // no doc in certn api, mapping based on previous implementation
	}

}

@Mixin(JSONable)
export class Inquiry {

	@Field()
	date: Date;

	@Field()
	name: string;

	constructor(date: string | Date, name: string) {
		this.date = new Date(date);
		this.name = name;
	}

	static fromEquifax(inquiry) {
		return new this(Moment(inquiry.date).toDate(), inquiry.CustomerId?.Name);
	}

	static fromCertn(inquiry) {
		return new this(Moment(inquiry.inquiry_date).toDate(), inquiry.customer_name);
	}

}


/**
 * This is the common credit report structure.
 */
@Mixin(JSONable)
export class CreditReportData {

	@Field()
	error?: string;

	@Field()
	score: number;

	@Field(Address)
	addresses: Address[];

	@Field(Employment)
	employers: Employment[];

	@Field(Tradeline)
	tradelines: Tradeline[];

	@Field(Collection)
	collections: Collection[];

	@Field(Bankruptcy)
	bankruptcies: Bankruptcy[];

	@Field(Inquiry)
	inquiries: Inquiry[];

	constructor(error?: string) {
		this.error = error;
	}

	static fromEquifax(equifaxReport: any, country: Country) {
		const report         = new this();
		const consumerReport = equifaxReport.CNConsumerCreditReports.CNConsumerCreditReport;

		if (consumerReport?.CNHeader?.CreditFile?.HitCode?.code === HitCode.CreditLock) {
			report.error = 'Report Unavailable - Consumer Placed A Credit Lock On The File';
		}

		report.score        = consumerReport?.CNScores?.CNScore?.Result?.Value || 0;
		report.addresses    = mapArrayOrObject(consumerReport?.CNAddresses?.CNAddress, address => Address.fromEquifax(address, country));
		report.employers    = mapArrayOrObject(consumerReport?.CNEmployments?.CNEmployment, employment => Employment.fromEquifax(employment));
		report.tradelines   = mapArrayOrObject(consumerReport?.CNTrades?.CNTrade, trade => Tradeline.fromEquifax(trade));
		report.collections  = mapArrayOrObject(consumerReport?.CNCollections?.CNCollection, collection => Collection.fromEquifax(collection));
		report.bankruptcies = mapArrayOrObject(consumerReport?.CNBankruptciesOrActs?.CNBankruptcyOrAct, act => Bankruptcy.fromEquifax(act));
		report.inquiries    = mapArrayOrObject(consumerReport?.CNLocalInquiries?.CNLocalInquiry, inquiry => Inquiry.fromEquifax(inquiry));

		return report;
	}

	static fromCertn(certnReport: any, country?: Country) {
		const report        = new this();
		const equifaxReport = certnReport;

		// checkout for failures
		if (equifaxReport.credit_check_failed) {
			report.error = equifaxReport.credit_check_errors[0];
		}

		report.score        = equifaxReport.credit_score || 0;
		report.addresses    = mapArrayOrObject(equifaxReport.addresses, address => Address.fromCertn(address, country));
		report.employers    = mapArrayOrObject(equifaxReport.employers, employment => Employment.fromCertn(employment));
		report.tradelines   = mapArrayOrObject(equifaxReport.trades, trade => Tradeline.fromCertn(trade));
		report.collections  = mapArrayOrObject(equifaxReport.collections, collection => Collection.fromCertn(collection));
		report.bankruptcies = mapArrayOrObject(equifaxReport.bankruptcies, act => Bankruptcy.fromCertn(act));
		report.inquiries    = mapArrayOrObject(equifaxReport.local_inquiries,
			inquiry => Inquiry.fromCertn(inquiry)).sort((a, b) => Moment(a.date).isBefore(b.date) ? 1 : -1);

		return report;
	}

	static getSample() {
		const report        = new this();
		report.score        = 675;
		report.bankruptcies = [ {
			dateFiled            : new Date('Sep 30 2015'),
			type                 : 'Individual',
			customer             : 'OFF SUP BKRPT',
			assetAmount          : 1360,
			caseNumberAndTrustee : '410300041 APPEL CO INC',
			filer                : 'Subject',
			liabilityAmount      : 7155,
			intentOrDisposition  : 'Discharged',
		} ];
		report.tradelines = [
			{
				creditor      : '602RE09626 FLrent',
				paymentStatus : 'Paid as agreed and up to date',
				type          : 'Open',
				dateOpened    : new Date('Oct 30 2019'),
				dateReported  : new Date('Aug 30 2021'),
				highCredit    : 1250,
				balance       : 0,
				pastDue       : 0,
				rCode         : 'O-1',
				latePayments  : [ 0, 0, 0 ],
				narratives    : [],
			}, {
				creditor      : '', // country specific
				paymentStatus : 'Paid as agreed and up to date',
				type          : 'Revolving',
				dateOpened    : new Date('Nov 30 2016'),
				dateReported  : new Date('Aug 30 2021'),
				highCredit    : 2000,
				balance       : 0,
				pastDue       : 0,
				rCode         : 'R-1',
				latePayments  : [ 0, 0, 0 ],
				narratives    : [ 'Monthly payments', 'Amount in h/c column is credit limit' ],
			}, {
				creditor      : '', // country specific
				paymentStatus : 'Paid as agreed and up to date',
				type          : 'Revolving',
				dateOpened    : new Date('Feb 28 2018'),
				dateReported  : new Date('Aug 30 2021'),
				highCredit    : 10000,
				balance       : 0,
				pastDue       : 0,
				rCode         : 'I-1',
				latePayments  : [ 0, 0, 0 ],
				narratives    : [ 'Monthly payments' ],
			}, {
				creditor      : 'HONDA FINANCE',
				paymentStatus : '120 days past due',
				type          : 'Lease',
				dateOpened    : new Date('Dec 30 2016'),
				dateReported  : new Date('Jun 30 2021'),
				highCredit    : 19428,
				balance       : 11963,
				pastDue       : 1655,
				rCode         : 'R-5',
				latePayments  : [ 1, 1, 1 ],
				narratives    : [ 'Lease Account', 'Weekly payments' ],
			}, {
				creditor      : '', // country specific
				paymentStatus : 'Bad debt, collection account or unable to locate',
				type          : 'Open',
				dateOpened    : new Date('Jun 30 2017'),
				dateReported  : new Date('Fun 30 2021'),
				highCredit    : 2846,
				balance       : 2828,
				pastDue       : 2828,
				rCode         : 'O-9',
				latePayments  : [ 1, 1, 1 ],
				narratives    : [ 'Monthly payments', 'Amount in h/c column is credit limit' ],
			},
		];
		report.collections = [ {
			status         : 'Unpaid Collection',
			creditor       : '', // country specific
			dateAssigned   : new Date('Sep 30 2019'),
			originalAmount : 344,
			balanceAmount  : 547,
		} ];
		report.inquiries = [
			new Inquiry('Aug 31 2021', 'FLrent - ROCK RENTALS'),
			new Inquiry('Aug 24 2021', 'DYNAMIC CAPITAL'),
			new Inquiry('Jul 26 2021', 'RAPPORT CREDIT UNION'),
			new Inquiry('Jul 17 2021', 'CDN TRANE EMPCR UN'),
			new Inquiry('Jun 30 2021', 'NCINO CANADA INC.'),
			new Inquiry('Mar 25 2021', 'SCOTIABANK'),
			new Inquiry('Jan 16 2021', 'HOME TRUST'),
			new Inquiry('Dec 20 2020', 'DYNAMIC CAPITAL'),
			new Inquiry('Nov 11 2020', 'RCAP LEASING INC'),
		];
		return report;
	}

	static getEquifaxSample() {
		const report     = this.getSample();
		report.score     = 675;
		report.addresses = [
			new Address('123 ALLEN STREET', 'TORONTO', 'ON', Country.CA, 'M2B 2K7', 'Current Address'),
			new Address('248 WITHROW AVE', 'TORONTO', 'ON', Country.CA, 'M4K 1E4', 'Past Address'),
			new Address('130 EGLINTON AVE', 'TORONTO', 'ON', Country.CA, 'M4P 2X9', 'Past Address'),
		];
		report.employers = [
			new Employment('TYSON FOODS', 'PRODUCT MANAGER'),
			new Employment('COCA-COLA COMPANY', 'OPERATIONS MANAGER'),
			new Employment('GENERAL MILLS INC.', 'INTERN'),
		];
		report.tradelines[1].creditor  = 'ROYAL BANK VISA';
		report.tradelines[2].creditor  = 'BANK OF MONTREAL M C';
		report.tradelines[4].creditor  = 'BELL MOBILITY';
		report.collections[0].creditor = '003000020 BELL MOBILITY';
		return report;
	}

	static getCertnSample() {
		const report     = this.getSample();
		report.score     = 675;
		report.addresses = [
			new Address('683 W. Helen Street', 'Brooklyn', 'NY', Country.US, '11229', 'Current Address'),
			new Address('77 E. King Drive', 'Troy', 'NY', Country.US, '12180', 'Past Address'),
			new Address('542 Tompson Court', 'Brooklyn', 'NY', Country.US, '11220', 'Past Address'),
		];
		report.employers = [
			new Employment('AMAZON', 'PRODUCT MANAGER'),
			new Employment('COCA-COLA COMPANY', 'OPERATIONS MANAGER'),
			new Employment('GENERAL MILLS INC.', 'INTERN'),
		];

		report.tradelines[1].creditor  = 'CAPITAL ONE MASTERCARD';
		report.tradelines[2].creditor  = 'WELLS FARGO';
		report.tradelines[4].creditor  = 'VERIZON';
		report.collections[0].creditor = '003000020 VERIZON';

		return report;
	}

}

function mapArrayOrObject<T>(array = [], mapFunction: (any) => T) {
	return _.map(_.castArray(array), mapFunction);
}
