import { render, staticRenderFns } from "./FormGroup.vue?vue&type=template&id=64c0e175&"
import script from "./FormGroup.vue?vue&type=script&lang=ts&"
export * from "./FormGroup.vue?vue&type=script&lang=ts&"
import style0 from "./FormGroup.vue?vue&type=style&index=0&id=64c0e175&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../devops/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports