import env, { Environment }                    from '$/lib/env';
import { ALaCarteType, LogEvents, SignUpType } from '$/lib/global';
import { BaseLogger, LoggerLevel, Log }        from '$/lib/logger';
import { loadScript }                          from '$/lib/utils';

import { Package } from '$/entities/Package';

const pixelId = env.config('pixelID');

export async function loadPixel() {
	let fbq = (window as any).fbq;
	if (fbq) {
		return;	// already loaded
	}

	fbq = (window as any).fbq = function() {
		fbq.callMethod ? fbq.callMethod.apply(fbq, arguments) : fbq.queue.push(arguments); // eslint-disable-line prefer-spread, prefer-rest-params
	};

	if (!(window as any)._fbq) {
		(window as any)._fbq = fbq;
	}

	fbq.push    = fbq;
	fbq.loaded  = !0;
	fbq.version = '2.0';
	fbq.queue   = [];

	// actually load the FB script only in production so that we don't pollute FB data with non-prod environments
	if (env.isEnvironment([ Environment.PROD, Environment.TESTING ])) {
		await loadScript('https://connect.facebook.net/en_US/fbevents.js');
	}

	fbq('init', pixelId);
	fbq('track', 'PageView');
}

enum PixelEvent {
	SignUpLandlord         = 'SignUpLandlord',
	TenantScreeningRenters = 'TenantScreeningRenters',
	UpgradePremium         = 'UpgradePremium',
	CreditReport           = 'CreditReport',
	DebtReporting          = 'DebtReporting',
	BackgroundCheck        = 'BackgroundCheck',
	TenantScreening        = 'TenantScreening',
	Collections            = 'Collections',
}

const eventsMap = {
	[LogEvents.SignedUp] : {
		[SignUpType.Landlord] :	PixelEvent.SignUpLandlord,
		[SignUpType.Renter]   : PixelEvent.TenantScreeningRenters,
	},
	[LogEvents.PackageChanged]    : PixelEvent.UpgradePremium,
	[LogEvents.ALaCartePurchased] : {
		[ALaCarteType.CreditReport]          : PixelEvent.CreditReport,
		[ALaCarteType.DebtReporting]         : PixelEvent.DebtReporting,
		[ALaCarteType.BackgroundCheck]       : PixelEvent.BackgroundCheck,
		[ALaCarteType.TenantScreeningBundle] : PixelEvent.TenantScreening,
		[ALaCarteType.Collections]           : PixelEvent.Collections,
	},
};

const defaultPrice = {
	[PixelEvent.CreditReport]    : 14.72,
	[PixelEvent.DebtReporting]   : 145.60,
	[PixelEvent.BackgroundCheck] : 10.53,
	[PixelEvent.TenantScreening] : 23.87,
	[PixelEvent.UpgradePremium]  : 230.74,
};

export class PixelEventLogger extends BaseLogger {

	log(logsOrLevel: Partial<Log> | Partial<Log>[] | Error | LoggerLevel, message?: string | Error, details?: any): Log[] {
		let logs = super.log.apply(this, [ logsOrLevel, message, details ]);

		// commands don't get logged
		logs = logs.filter(log => log.level !== LoggerLevel.Command);

		logs.forEach(log => {
			let eventID = typeof eventsMap[log.message] === 'object'
				? eventsMap[log.message][log.details.type]
				: eventsMap[log.message];

			// log these only for upgrades to non-basic (ie Premium) packages
			if (log.message === LogEvents.PackageChanged) {
				const pkg = log.details.to as Package;
				if (pkg?.isBasic) {
					eventID = null;
				}
			}

			if (eventID) {
				(window as any).fbq('trackCustom', eventID, {
					currency : log.details?.price?.currency || 'CAD',
					value    : log.details?.price?.value    || defaultPrice[eventID],
				});
			}
		});

		return logs;
	}

}
