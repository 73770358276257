






/**
 * This contains a mailto: link to the provided email address
 */
import { Vue, Component, Prop } from '$/lib/vueExt';

@Component
export default class EmailLink extends Vue {

	@Prop({ required : true })
	readonly email: string;

	@Prop({ default : undefined })
	readonly cc: string;

	@Prop({ default : undefined })
	readonly bcc: string;

	@Prop({ default : undefined })
	readonly subject: string;

	@Prop({ default : undefined })
	readonly body: string;

	get href() {
		const params = _.compactObject({
			subject : this.subject,
			cc      : this.cc,
			bcc     : this.bcc,
			body    : this.body,
		});

		const uriParams = _.map(params, (value, key) => `${key}=${encodeURIComponent(value)}`).join('&');

		let href = `mailto:${this.email}`;

		if (uriParams) {
			href += `?${uriParams}`;
		}

		return href;
	}

}
