






import { Vue, Component, Prop } from '$/lib/vueExt';

/**
 * A group of steps that will be displayed as a single step in the navigation.
 */
@Component
export default class StepGroup extends Vue {

	@Prop()
	readonly title: string;

}
