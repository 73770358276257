import { Mixin }              from '$/lib/utils';
import Validate               from '$/lib/Validate';
import type { InvoiceStatus } from '$/lib/stripe';

import { JSONable, Field } from '$/entities/lib/JSONable';
import { ProgressStatus }  from '$/entities/UserExt';
import type { EntityID }   from '$/entities/BaseEntity';

export const largeLandlordUnitsThreshold = 10;

@Mixin(JSONable)
class CreditReportsLimits {

	// the # of credit reports that can be created by the org / day
	@Field()
	daily = 5;

	// the # of credit reports that can be created by the org / month
	@Field()
	monthly = 5;

}

@Mixin(JSONable)
export class OrganizationLimits {

	@Field()
	creditReports: CreditReportsLimits = new CreditReportsLimits();

	@Field()
	@Validate({ min : 0 })
	rentReportingThreshold = 0;

	@Field()
	@Validate({ min : 0 })
	debtReportingThreshold = 0;

	/**
	 * The maximum amount that the organization can be in arrears before being locked out.
	 */
	@Field()
	@Validate({ min : 0 })
	arrearsLimit = 0;

	static intervals = {
		daily   : 24,			// # of hours for the daily interval
		monthly : 24 * 30,		// # of hours for the monthly interval
	};

	// the # of credit reports that can be created by the org before being approved
	static get defaultLimit() {
		return 5;
	}

}

@Mixin(JSONable)
export class OrganizationVerification {

	@Field()
	status: ProgressStatus = ProgressStatus.Incomplete;

	@Field()
	verifiedBy?: EntityID;

	constructor(verifiedBy?: EntityID) {
		this.verifiedBy = verifiedBy;
		if (this.verifiedBy) {
			this.status = ProgressStatus.Approved;
		}
	}

}

/**
 * Data stored in the EquifaxCustomerInfo log, to keep track of changes in status.
 * Needs to be a class so the @Field decorator can reference it.
 */
export class StatusLog {

	status: EquifaxCustomerStatus;
	date: Date;

	constructor(status: EquifaxCustomerStatus, date = new Date()) {
		this.status = status;
		this.date   = date;
	}

}

/**
 * Status for Equifax Credit Report registration for Landlords
 */
export enum EquifaxCustomerStatus {
	Incomplete = 'incomplete',		// registration not started
	Submitted  = 'submitted',		// registration requested by a member of the LL org
	Pending    = 'pending',			// registration actually sent to Equifax
	Approved   = 'approved',		// registration approved by Equifax and customerNumber & security is filled in
	Rejected   = 'rejected',		// registration rejected by Equifax
	Error      = 'error',			// error while processing the registration
}

@Mixin(JSONable)
export class EquifaxCustomerInfo {

	@Field()
	customerNumber: string;

	@Field()
	securityCode: string;

	@Field()
	roleId: string;			// the ID of the role that requested the registration

	@Field()
	private status: EquifaxCustomerStatus;

	@Field()
	private errorMessage: string;

	@Field(StatusLog)
	logs: StatusLog[];

	constructor(initialValues?: Partial<EquifaxCustomerInfo> & { status?: EquifaxCustomerStatus; errorMessage?: string }) {
		this.customerNumber = initialValues?.customerNumber;
		this.securityCode   = initialValues?.securityCode;
		this.roleId         = initialValues?.roleId;
		if (initialValues?.status) {
			this.setStatus(initialValues.status, initialValues?.errorMessage);
		}
	}

	get currStatus() {
		return this.status;
	}

	setStatus(newStatus: EquifaxCustomerStatus, errorMessage?: string) {
		this.status   = newStatus;
		this.logs   ??= [];
		this.logs.push(new StatusLog(newStatus));
		this.errorMessage = this.status === EquifaxCustomerStatus.Error ? errorMessage : '';
	}

}

export interface Invoice {
	id: string;
	created: number;
	total: number;
	periodStart: number;
	periodEnd: number;
	status: InvoiceStatus;
	invoicePDF: string;
	charge: InvoiceCharge;
	isMonthly: boolean;		// true if this is a monthly invoice
}

export interface InvoiceCharge {
	invoice: string;
	failure_message: string; // eslint-disable-line @typescript-eslint/naming-convention
	status: string;
}

export interface PaymentMethod {
	id: string;
	country: string;
	cardBrand: string;
	cardLast4: string;
	cardExpiry: string;
}
