import Moment      from 'moment';
import { Mixin }   from '$/lib/utils';
import { Country } from '$/lib/Address';

import { Field, JSONable } from '$/entities/lib/JSONable';

export enum CriminalCategory {
	Criminal        = 'CRIMINAL',
	Fraud           = 'FRAUD',
	SexOffender     = 'SEX_OFFENDER',
	Affiliations    = 'AFFILIATIONS',
	OFAC            = 'OFAC',
	GlobalClearance = 'GLOBAL_CLEARANCE',
	PublicSafety    = 'PUBLIC_SAFETY',
	Other           = 'OTHER'
}

@Mixin(JSONable)
export class ScanStatus {

	@Field()
	publicCriminalHistory: string;

	@Field()
	fraudWatchlists: string;

	@Field()
	sexOffender: string;

	@Field()
	knownAffiliations: string;

	@Field()
	ofacTerrorist: string;

	@Field()
	politicallyExposed: string;

	@Field()
	publicSafety: string;

	@Field()
	globalClearance: string;

	@Field()
	otherSearches: string;

	@Field()
	publicProfile: string;

	@Field()
	publicCourtRecord: string;

	constructor(scanStatus: ScanStatus) {
		Object.assign(this, scanStatus);
	}

	static fromCertnSoftcheck(scanStatus) {
		return new this({
			publicCriminalHistory : scanStatus.criminal_scan,
			fraudWatchlists       : scanStatus.fraud_scan,
			sexOffender           : scanStatus.sex_offender_scan,
			knownAffiliations     : scanStatus.known_affiliation_scan,
			ofacTerrorist         : scanStatus.ofac_global_terrorist_scan,
			politicallyExposed    : scanStatus.politically_exposed_person_scan,
			publicSafety          : scanStatus.public_safety_scan,
			globalClearance       : scanStatus.global_clearance_scan,
			otherSearches         : scanStatus.other_scan,
			publicProfile         : scanStatus.public_profile_scan,
			publicCourtRecord     : scanStatus.public_court_records,
		});
	}

}

@Mixin(JSONable)
export class Address {

	@Field()
	address?: string;

	@Field()
	city?: string;

	@Field()
	provinceState?: string;

	@Field()
	country?: string;

	@Field()
	postalCode?: string;

	constructor(address: string, city: string, provinceState: string, country: string, postalCode: string);
	constructor(address: Address);
	constructor(...args) {
		let address: Address;

		if (args?.length === 1 && typeof args === 'object') {
			address = args[0];
		}
		else if (args?.length === 5) {
			address = { address : args[0], city : args[1], provinceState : args[2], country : args[3], postalCode : args[4] };
		}
		else if (args?.length > 0) {
			throw new Error(`unrecognized constructor arguments: ${args}`);
		}

		Object.assign(this, address);
	}

	static fromCertnSoftcheck(certnAddress) {
		return new this(certnAddress.address, certnAddress.city, certnAddress.province_state, certnAddress.country, certnAddress.postal_code);
	}

}

@Mixin(JSONable)
export class Employment {

	@Field()
	employer?: string;

	@Field()
	details?: string;

	@Field()
	startDate?: Date;

	@Field()
	endDate?: Date;

	constructor(employment: Employment) {
		Object.assign(this, employment);
	}

	static fromCertnSoftcheck(employment) {
		return new this({
			employer  : employment.company_name,
			details   : employment.position,
			startDate : Moment(employment.start_date).toDate(),
			endDate   : Moment(employment.end_date).toDate(),
		});
	}

}

@Mixin(JSONable)
export class Education {

	@Field()
	institution?: string;

	@Field()
	details?: string;

	@Field()
	startDate?: Date;

	@Field()
	endDate?: Date;

	constructor(education: Education) {
		Object.assign(this, education);
	}

	static fromCertnSoftcheck(education) {
		return new this({
			institution : education.institution,
			details     : education.degree,
			startDate   : Moment(education.start_date).toDate(),
			endDate     : Moment(education.end_date).toDate(),
		});
	}

}

@Mixin(JSONable)
export class CriminalAlert {

	@Field()
	category?: string;

	@Field()
	stage?: string;

	@Field()
	type?: string;

	@Field()
	description?: string;

	@Field()
	date?: Date;

	constructor(criminalAlert: CriminalAlert) {
		Object.assign(this, criminalAlert);
	}

	static fromCertnSoftcheck(criminalAlert) {
		return new this({
			category    : criminalAlert.category,
			stage       : criminalAlert.stage,
			type        : criminalAlert.type,
			description : criminalAlert.description,
			date        : Moment(criminalAlert.date).toDate(),
		});
	}

}

@Mixin(JSONable)
export class Source {

	@Field()
	publicationSource?: string;

	@Field()
	name?: string;

	@Field()
	url?: string;

	@Field()
	date?: Date;

	constructor(source: Source) {
		Object.assign(this, source);
	}

	static fromCertnSoftcheck(source) {
		return new this({
			publicationSource : source.publication_source,
			name              : source.name,
			url               : source.url,
			date              : Moment(source.date).toDate(),
		});
	}

}

@Mixin(JSONable)
export class Name {

	@Field()
	firstName?: string;

	@Field()
	middleName?: string;

	@Field()
	lastName?: string;

	constructor(name: Name) {
		Object.assign(this, name);
	}

	static fromCertnSoftcheck(name) {
		return new this({
			firstName  : name.first_name,
			middleName : name.middle_name,
			lastName   : name.last_name,
		});
	}

}

@Mixin(JSONable)
export class RiskInformation {

	@Field(Address)
	addresses: Address[];

	@Field(Employment)
	employers: Employment[];

	@Field(Education)
	educations: Education[];

	static fromCertnSoftcheck(riskInfoResult: any) {
		const info = new this();

		info.addresses  = mapArrayOrObject(riskInfoResult.addresses,  address => Address.fromCertnSoftcheck(address));
		info.employers  = mapArrayOrObject(riskInfoResult.employers,  employment => Employment.fromCertnSoftcheck(employment));
		info.educations = mapArrayOrObject(riskInfoResult.educations, education => Education.fromCertnSoftcheck(education));

		return info;
	}

}

@Mixin(JSONable)
export class CriminalResult {

	@Field()
	confidence: number;

	@Field()
	confidenceLabel: string;

	@Field(CriminalAlert)
	criminalAlerts: CriminalAlert[];

	@Field(CriminalAlert)
	fraudAlerts: CriminalAlert[];

	@Field(CriminalAlert)
	sexOffenderAlerts: CriminalAlert[];

	@Field(CriminalAlert)
	affiliationsAlerts: CriminalAlert[];

	@Field(CriminalAlert)
	ofacAlerts: CriminalAlert[];

	@Field(CriminalAlert)
	globalClearanceAlerts: CriminalAlert[];

	@Field(CriminalAlert)
	publicSafetyAlerts: CriminalAlert[];

	@Field(CriminalAlert)
	otherAlerts: CriminalAlert[];

	@Field(Source)
	sources: Source[];

	@Field(Address)
	addresses: Address[];

	@Field(String)
	aliases: string[];

	@Field(Date)
	datesOfBirth: Date[];

	@Field(Name)
	names: Name[];

	static fromCertnSoftcheck(criminalResult: any) {
		const identity         = new this();
		const criminalIdentity = criminalResult.criminal_identity;

		identity.confidence      = criminalResult.confidence;
		identity.confidenceLabel = criminalResult.confidence_label;

		const alertsByCategory         = _.groupBy(criminalIdentity.criminal_alerts, 'parent_category');
		identity.criminalAlerts        = getCriminalAlertsForCategory(alertsByCategory, CriminalCategory.Criminal);
		identity.fraudAlerts           = getCriminalAlertsForCategory(alertsByCategory, CriminalCategory.Fraud);
		identity.sexOffenderAlerts     = getCriminalAlertsForCategory(alertsByCategory, CriminalCategory.SexOffender);
		identity.affiliationsAlerts    = getCriminalAlertsForCategory(alertsByCategory, CriminalCategory.Affiliations);
		identity.ofacAlerts            = getCriminalAlertsForCategory(alertsByCategory, CriminalCategory.OFAC);
		identity.globalClearanceAlerts = getCriminalAlertsForCategory(alertsByCategory, CriminalCategory.GlobalClearance);
		identity.publicSafetyAlerts    = getCriminalAlertsForCategory(alertsByCategory, CriminalCategory.PublicSafety);
		identity.otherAlerts           = getCriminalAlertsForCategory(alertsByCategory, CriminalCategory.Other);

		identity.sources      = mapArrayOrObject(criminalIdentity.criminal_sources, source => Source.fromCertnSoftcheck(source));
		identity.addresses    = mapArrayOrObject(criminalIdentity.criminal_addresses, address => Address.fromCertnSoftcheck(address));
		identity.aliases      = extractAdditionalInfo(criminalIdentity.criminal_additional_informations, 'aliases') as string[];
		identity.datesOfBirth = mapArrayOrObject(extractAdditionalInfo(criminalIdentity.criminal_additional_informations, 'dates_of_birth'), date => Moment(date).toDate());
		identity.names        = mapArrayOrObject(extractAdditionalInfo(criminalIdentity.criminal_additional_informations, 'name'), name => Name.fromCertnSoftcheck(name));

		return identity;
	}

}

@Mixin(JSONable)
export class BackgroundCheckData {

	@Field()
	error?: string;

	@Field()
	scanStatus: ScanStatus;

	@Field()
	riskInfo: RiskInformation;

	@Field(CriminalResult)
	criminalResults: CriminalResult[];

	constructor(error?: string) {
		this.error = error;
	}

	static fromCertnSoftcheck(softcheckReport: any) {
		const report = new this();

		report.scanStatus      = ScanStatus.fromCertnSoftcheck(softcheckReport.scan_status);
		report.riskInfo        = RiskInformation.fromCertnSoftcheck(softcheckReport.risk_evaluations[0].risk_information_result);
		report.criminalResults = mapArrayOrObject(softcheckReport.risk_evaluations[0].criminal_results, result => CriminalResult.fromCertnSoftcheck(result));

		return report;
	}

	static getSample(): BackgroundCheckData {
		return {
			riskInfo : {
				addresses : [
					new Address('123 ALLEN STREET', 'TORONTO', 'ON', 'M2B 2K7', Country.CA),
					new Address('130 EGLINTON AVE', 'TORONTO', 'ON', 'M4P 2X9', Country.CA),
				],
				employers : [
					{
						details   : 'INTERN',
						endDate   : new Date('2011-05-01'),
						employer  : 'GENERAL MILLS INC.',
						startDate : new Date('2010-12-01'),
					},
					{
						details   : 'OPERATIONS MANAGER',
						endDate   : new Date('2014-12-01'),
						employer  : 'COCA-COLA COMPANY',
						startDate : new Date('2011-04-01'),
					},
				],
				educations : [
					{
						details     : 'Bachelor of Business Administration (B.B.A.)',
						endDate     : new Date('2011-04-30'),
						startDate   : new Date('2007-09-01'),
						institution : 'University of Toronto',
					},
				],
			},
			scanStatus : {
				sexOffender           : 'CLEARED',
				publicSafety          : 'CLEARED',
				ofacTerrorist         : 'CLEARED',
				otherSearches         : 'POSSIBLE',
				publicProfile         : 'CLEARED',
				fraudWatchlists       : 'CLEARED',
				globalClearance       : 'CLEARED',
				knownAffiliations     : 'CLEARED',
				publicCourtRecord     : 'CLEARED',
				politicallyExposed    : 'CLEARED',
				publicCriminalHistory : 'POSSIBLE',
			},
			criminalResults : [
				{
					names : [
						{
							firstName  : 'John',
							lastName   : 'Doe',
							middleName : null,
						},
					],
					aliases        : [],
					sources        : [],
					addresses      : [ new Address('123 ALLEN STREET', 'TORONTO', 'ON', 'M2B 2K7', Country.CA) ],
					confidence     : 0.99,
					ofacAlerts     : [],
					fraudAlerts    : [],
					otherAlerts    : [],
					datesOfBirth   : [],
					criminalAlerts : [
						{
							date        : new Date('2016-02-20'),
							stage       : 'Wanted',
							category    : 'Assault Battery',
							description : 'John Doe is wanted for assault causing bodily harm, criminal harassment, dangerous operation of a motor vehicle and failure to comply with conditions.',
						},
						{
							date        : new Date('2016-02-02'),
							stage       : 'Wanted',
							category    : 'Escaped Fugitive',
							description : 'Wanted by the judicial authorities of Canada.',
						},
					],
					confidenceLabel       : 'Likely',
					sexOffenderAlerts     : [],
					affiliationsAlerts    : [],
					publicSafetyAlerts    : [],
					globalClearanceAlerts : [],
				},
			],
		};
	}

}

function mapArrayOrObject<T>(array = [], mapFunction: (any) => T) {
	return _.map(_.castArray(array), mapFunction);
}

function getCriminalAlertsForCategory(alertsByCategory, category: CriminalCategory) {
	return mapArrayOrObject(alertsByCategory[category], alert => CriminalAlert.fromCertnSoftcheck(alert));
}

function extractAdditionalInfo(additionalInfo, field: string) {
	return _.flatten(additionalInfo.map(info => info[field]));
}
