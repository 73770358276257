/**
 * Load basic Google Platform Client, we don't need anything beyond what this provides
 * see: https://developers.google.com/identity/gsi/web/reference/js-reference
 */
import jwtDecode      from 'jwt-decode';
import env            from '$/lib/env';
import { loadScript } from '$/lib/utils';

const clientID  = env.config('google.clientID');
let initialized = false;
const onAuthenticateElements: HTMLElement[] = [];

// for full set of options, see https://developers.google.com/identity/gsi/web/reference/js-reference#google.accounts.id.renderButton
interface RenderGoogleOptions {
	type?: 'standard' | 'icon';
	theme?: 'outline' | 'filled_blue' | 'filled_black';
	size?: 'small' | 'medium' | 'large';
}

export async function loadGooglePlatform() {
	if (!clientID || initialized) {
		return;
	}

	await loadScript('https://accounts.google.com/gsi/client');

	(window as any).google.accounts.id.initialize({
		/* eslint-disable @typescript-eslint/naming-convention */
		client_id   : clientID,
		auto_select : true,
		/* eslint-enable @typescript-eslint/naming-convention */
		callback    : function(response) {
			onAuthenticateElements.forEach(element => element.dispatchEvent(
				new CustomEvent('authenticate', {
					bubbles : true,
					detail  : { ...decodeToken(response.credential), token : response.credential },
				})
			));
		},
	});

	initialized = true;
}

export function decodeToken(googleToken: string) {
	const token: any = jwtDecode(googleToken);
	return {
		email     : token.email,
		firstName : token.given_name,
		lastName  : token.family_name,
	};
}

/**
 * Renders the Google sign-in button.
 * Upon a successful sign-in, delivers a 'authenticate' event to the parent element with details of the user
 */
export async function renderGoogleButton(parent: HTMLElement, options?: RenderGoogleOptions) {
	if (!parent) {
		throw new Error('parent element must be provide in order to render signup button');
	}

	await loadGooglePlatform();
	(window as any).google.accounts.id.renderButton(parent, options);

	// SHOULDDO: implement an EventEmitter here
	onAuthenticateElements.push(parent);
}
