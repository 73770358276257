
/**
 * Overwrite BFormInput to add support for mutating certain props
 */
import { BFormInput }        from 'bootstrap-vue';
import { Component, Mixins } from '$/lib/vueExt';
import VueValidationMixin    from '$/lib/mixins/VueValidationMixin';

@Component
export default class FormInput extends Mixins(BFormInput, VueValidationMixin) {
}
