import { Country } from '$/lib/Address';

import { BaseRole }                          from '$/entities/roles/BaseRole';
import { ProgressStatus }                    from '$/entities/tenantScreening/TenantCheck';
import { CommonEntity, ChildEntity, Column } from '$/entities/BaseEntity';
import { CreditReport }                      from '$/entities/tenantScreening/CreditReport';
import { Application }                       from '$/entities/tenantScreening/Application';
import { CreditReportData }                  from '$/entities/tenantScreening/lib/CreditReportData';

/**
 * Records a search by a Landlord for prospective tenant credit report from CERTN.
 */
@CommonEntity() @ChildEntity()
export class CertnCreditReport extends CreditReport {

	@Column()
	certnApplicationId: string;

	static getSample(role: BaseRole) {
		const sample                   = new this();
		sample.id                      = 'sample';
		sample.ver                     = 1;
		sample.org                     = role.org;
		sample.status                  = ProgressStatus.Complete;
		sample.requestedBy             = role.user;
		sample.application             = new Application();
		sample.application.firstName   = 'John';
		sample.application.lastName    = 'Doe';
		sample.application.dateOfBirth = new Date('1992-10-29');
		sample.application.street      = '683 W. Helen Street';
		sample.application.city        = 'Brooklyn';
		sample.application.province    = 'NY';
		sample.application.country     = Country.US;
		sample.report                  = CreditReportData.getCertnSample();

		return sample;
	}

}
