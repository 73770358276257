import Axios              from 'axios';
import { HubSpotTracker } from '$/lib/hubspot';
import log, { LogEvents } from '$/lib/logger';
import { SignUpType }     from '$/lib/global';
import { decodeToken }    from '$/lib/GooglePlatform';
import env                from '$/lib/env';

import { BaseRole }                 from '$/entities/roles/BaseRole';
import { CollectionName, EntityID } from '$/entities/BaseEntity';
import { type Interests }           from '$/entities/roles/RolePreferences';

import { Authentication as AuthenticationCommon } from '$/common/entities/Authentication';
export *                                          from '$/common/entities/Authentication';

/**
 * The secret token if SecretTokenAuth was the method of authentication for this session.
 * This can be used in place of the current password if the user is resetting the password.
 */
const secretToken = new URLSearchParams(window.location.search).get('token') || '';

@CollectionName('auth')
export class Authentication extends AuthenticationCommon {

	static async isAuthenticated(): Promise<boolean> {
		try {
			await Axios.get('/api/user/me');
			return true;
		}
		catch (err) {
			if (err.status === 401 || err.status === 403) {
				return false;
			}
			throw err;
		}
	}

	static async signIn(googleToken: string,                   options?: { roleID?: string; routeTo?: string }): Promise<void>;
	static async signIn(email: EmailAddress, password: string, options?: { roleID?: string; routeTo?: string }): Promise<void>;
	static async signIn(...args) {
		const params: any = {};
		let endPoint      = 'signin';
		let options: any;
		let email: EmailAddress;

		if (!args[1] || typeof args[1] === 'object') {
			endPoint     += '/google';
			params.token  = args[0];
			options       = args[1];
			email         = decodeToken(params.token).email;
		}
		else if (args.length === 2 || args.length === 3) {
			params.username = email = args[0];
			params.password = args[1];
			options         = args[2];
		}

		if (options) {
			params.role    = options.roleID;
			params.routeTo = options.routeTo;
		}

		await Axios.post(this.collectionUrl(endPoint), params, { withCredentials : true });		// "withCredentials" needed to accept cookie
		HubSpotTracker.identify(email);

		window.location.replace(options?.routeTo || '/');
	}

	/**
	 * Navigates the browsers to the signup page for the given type of signup.
	 */
	static async navigateToSignUp(type: 'landlord' | 'tenant', signupData: Partial<SignUpData> = {}) {
		if ((await this.getSession())?.expiresOn) {
			await Axios.post(this.collectionUrl('signout'));
		}
		window.location.replace(new URL(env.getAbsoluteUrl(`/signup/${type}`, { queryParams : signupData })));
	}

	static async signUp(data: SignUpData) {
		await Axios.post(this.collectionUrl('signup'), data);

		HubSpotTracker.identify(data.email);
		log.user(LogEvents.SignedUp, { type : data.roleType });

		// COULDDO: implement async log so we could await it here and not use delays
		// Add a bit of delay so the logger has enough time to send out to HubSpot
		await _.delay(100);
	}

	/**
	 * Signs the user out of the current session.
	 * @params {Object} [options]
	 * @params {String} [options.routeTo] a url to redirect to if signing back in (after signout)
	 */
	static async signOut({ routeTo = '', message = '' } = {}) {
		const queryParams = {
			routeTo : routeTo || undefined,
			message : message || undefined,
		};
		window.location.replace(new URL(env.getAbsoluteUrl(this.collectionUrl('signout'), { queryParams })));
	}

	static async switchRole(role: BaseRole | EntityID) {
		const roleID = role instanceof BaseRole ? role.id : role;
		if (roleID === BaseRole.current.id) {
			return;
		}

		await Axios.post(this.collectionUrl('role/me'),
			{ role : roleID },
			{ withCredentials : true }		// needed to accept cookie
		);

		// reset identity so hubspot doesn't merge session history of different contacts into each other
		HubSpotTracker.resetIdentity();

		// reload since we now likely have new permissions
		window.location.pathname = '/';
	}

	static async forgotPassword(email: EmailAddress) {
		await Axios.post(this.collectionUrl('token'), { email, type : 'forgotPassword' });
	}

	static async verifyEmailAddress(email: EmailAddress) {
		await Axios.post(this.collectionUrl('token'), { email, type : 'verifyEmail' });
	}

	static async createApiKey(roleID: string) {
		await Axios.post(this.collectionUrl('apikey'), { role : roleID }, { withCredentials : true });
	}

	/**
	 * Returns the current secretToken if SecretTokenAuth was used to login to this session.
	 */
	static get secretToken() {
		return secretToken;
	}

	static async getSession() {
		try {
			const data = (await Axios.get(this.collectionUrl('session'))).data;
			return {
				userID    : data.userID as EntityID,
				roleID    : data.roleID as EntityID,
				orgID     : data.orgID  as EntityID,
				issuedOn  : data.issuedOn  ? new Date(data.issuedOn)  : undefined,
				expiresOn : data.expiresOn ? new Date(data.expiresOn) : undefined,
				sessionID : data.sessionID as string,
			};
		}
		catch (error) {
			return null;
		}
	}

}

export interface SignUpData {
	firstName: string;
	middleName: string;
	lastName: string;
	email: EmailAddress;
	phoneNumber?: string;
	roleType: SignUpType;
	suffix: string;
	typeOfBusiness: string;
	rentalUnits: string;
	country: string;
	password: string;
	inviteToken?: string;
	interests?: Interests;
	eula: boolean;
	promoCode: string;
	googleToken: string;
	routeTo?: string;
}
