
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M18.333 15.833h-2.916a.417.417 0 0 0-.417.417v2.917H5.833V.833h12.5V15h.834V.833A.834.834 0 0 0 18.333 0h-12.5A.834.834 0 0 0 5 .833v18.334c0 .46.374.833.833.833h10.834c.11 0 .216-.044.294-.122l2.084-2.083a.416.416 0 0 0 .122-.295V15h-.834v.833Zm0 1.495-1.839 1.839h-.66v-2.5h2.5v.66Z"}}),_c('path',{attrs:{"d":"M12.74 6.093a.288.288 0 0 0-.327-.069.319.319 0 0 0-.185.294v1.697c-1.62.068-2.432.78-2.557.899a4.882 4.882 0 0 0-1.579 2.803 5.227 5.227 0 0 0-.053 1.615v.002l.002.014.064.388a.31.31 0 0 0 .24.259.294.294 0 0 0 .313-.15l.191-.337c.587-1.035 1.319-1.72 2.175-2.038a2.99 2.99 0 0 1 1.204-.187v1.757c0 .129.073.245.186.294.112.05.241.022.327-.07l3.172-3.377a.332.332 0 0 0-.001-.45L12.74 6.093ZM3.794 17.292h-1.97V1.708h1.97V1h-1.97C1.368 1 1 1.318 1 1.708v15.584c0 .39.37.708.823.708 1.314.048 1.97.048 1.97 0v-.708Z"}})])
          )
        }
      }
    