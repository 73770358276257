























import { Vue, Component, Prop } from '$/lib/vueExt';

@Component
export default class Page extends Vue {

	@Prop({ default : false })
	readonly noTitle: boolean;

	@Prop({ default : false })
	readonly busy: boolean;

	get title() {
		return this.$route?.meta?.title;
	}

}
