






/**
 * Validation feedback for fields that don't have a form or a way for the user to directly adjust them
 */
import { Component, Mixins, Prop } from '$/lib/vueExt';
import VueValidationMixin          from '$/lib/mixins/VueValidationMixin';

@Component
export default class ValidationFeedback extends Mixins(VueValidationMixin) {

	@Prop()
	readonly value: any;

}
