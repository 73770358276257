import type { DocumentType } from '$/entities/FileExt';
import type { File }         from '$/entities/File';

export interface LeaseDoc {
	title: string;
	docType: DocumentType;
	file?: File;
	tooltip: string;
}

export enum LeaseStatus {
	Current = 'current',		// tenant(s) are still residing at the address,
	Former  = 'former',
}
