import Validate, { ValidationMixin } from '$/lib/Validate';
import { Mixin }                     from '$/lib/utils';

import { JSONable, Field } from '$/entities/lib/JSONable';

// eslint-disable-next-line
export interface YardiSettings extends ValidationMixin, JSONable {}

export interface YardiVersion {
	yardi: string;
	plugins: Dictionary<string>;
}

@Mixin(JSONable)
export class YardiSettings {

	@Field()
	@Validate({ required : true })
	username: string = '';

	@Field()
	@Validate({ required : true })
	password: string = '';

	@Field()
	@Validate({ required : true })
	serverName: string = '';

	@Field()
	@Validate({ required : true })
	databaseName: string = '';

	@Field()
	@Validate({ required : true })
	platform: string = '';

	@Field()
	@Validate({ required : true, url : true })
	url: string = '';

	@Field()
	propertyListId: string = '';

	@Field()
	tenantExclusionList: string[] = [];

	get isPasswordEncrypted() {
		// SHOULDDO: a more reliable mechanism by which to tell if this.blob is encrypted or not
		return !!this.password && this.password.length > 20;
	}

	constructor(initValues?: Partial<YardiSettings>) {
		if (typeof initValues === 'object') {
			this.username       = initValues.username       || '';
			this.password       = initValues.password       || '';
			this.serverName     = initValues.serverName     || '';
			this.databaseName   = initValues.databaseName   || '';
			this.platform       = initValues.platform       || '';
			this.url            = initValues.url            || '';
			this.propertyListId = initValues.propertyListId || '';
		}
	}

	excludeTenant(tenantId: string) {
		this.tenantExclusionList ??= [];

		if (!this.tenantExclusionList.includes(tenantId)) {
			this.tenantExclusionList.push(tenantId);
		}
	}

	/**
	 * @returns an error description if the connection does not work successfully
	 */
	testConnection(): Promise<string> {
		throw new Error('not implemented');
	}

	/**
	 * @returns a list of permissions that are missing
	 */
	checkPermissions(): Promise<string[]> {
		throw new Error('not implemented');
	}

	/**
	 * @returns the version of the Yardi platform
	 */
	getVersion(): Promise<YardiVersion> {
		throw new Error('not implemented');
	}

	async triggerSync() {
		return new Error('not implemented');
	}

	/**
	 * @returns the status of latest sync as well as last tim sync was successful
	 */
	getSyncStatus(): Promise<YardiVersion> {
		throw new Error('not implemented');
	}

	/**
	 * @returns the version of the Yardi platform
	 */
	getIssues(): Promise<YardiVersion> {
		throw new Error('not implemented');
	}

}
