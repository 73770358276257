
























import { Vue, Component, Prop } from '$/lib/vueExt';
import Testimonial              from '$/lib/widgets/testimonials/Testimonial.vue';
import { getTestimonialsFor, TestimonialLocation } from '$/lib/testimonials';

@Component({ components : { Testimonial } })
export default class Testimonials extends Vue {

	@Prop({ default : false })
	readonly list: boolean;

	@Prop({ required : true })
	readonly location: TestimonialLocation;

	@Prop({ default : '' })
	readonly cardClass: string;

	get testimonials() {
		return  getTestimonialsFor(this.location);
	}

}
