export enum ProgressStatus {
	Incomplete = 'incomplete', // default state
	Submitted  = 'submitted',  // documents submitted
	Rejected   = 'rejected',   // documents rejected
	Approved   = 'approved',   // documents/verification approved
	Fraudulent = 'fraudulent', // fraudulent activity detected, automatically suspends user
}

export enum NameSuffixOptions {
	''     = '',
	Junior = 'Junior',
	Senior = 'Senior',
	Second = 'Second',
	Third  = 'Third',
	Fourth = 'Fourth',
}
