import { Organization }              from '$/entities/Organization';
import { ChildEntity, CommonEntity } from '$/entities/BaseEntity';

@CommonEntity() @ChildEntity()
export class TriconOrganization extends Organization {

	get positiveReportingOnly() {
		return true;
	}

	get isMemberContactInfoRequired() {
		// SHOULDDO: refactor this into a more general "enterprise" setting because we generally trust members of custom enterprise setups
		return false;
	}

}
