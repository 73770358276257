import VueRouter from 'vue-router';
import { Vue }   from '$/lib/vueExt';

import env                from '$/lib/env';
import { HubSpotTracker } from '$/lib/hubspot';
import ProductFruits      from '$/plugins/ProductFruits';

Vue.use(VueRouter);

const router = new VueRouter({
	mode   : 'history',
	routes : [],
	scrollBehavior(to, from, savedPosition) {
		// This is only available from the popstate navigation event
		// triggered by browser forward/backward
		if (savedPosition) {
			return savedPosition;
		}

		if (to.meta.scrollTop !== false) {
			return { x : 0, y : 0, behavior : 'smooth' };
		}
	},
});

router.afterEach(function trackRouteChange(to, from) {
	// Update Document Title based on path
	const suffix   = _(to.matched).map(match => match.meta.title).compact().join(' - ');
	document.title = env.getEnvironmentPrefixedText(suffix ? `${env.app.name.short} - ${suffix}` : env.app.name.full);

	if (from.path !== to.path) {
		// Log Route Change on Hubspot
		HubSpotTracker.recordRouteChange(to.path, to.query as Dictionary<string>);
	}

	ProductFruits.pageChanged();
});

export default router;
