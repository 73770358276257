import { ChildEntity } from '$/lib/typeormExt';
import { Country }     from '$/lib/Address';

import { BaseRole }         from '$/entities/roles/BaseRole';
import { ProgressStatus }   from '$/entities/tenantScreening/TenantCheck';
import { CommonEntity }     from '$/entities/BaseEntity';
import { CreditReport }     from '$/entities/tenantScreening/CreditReport';
import { Application }      from '$/entities/tenantScreening/Application';
import { CreditReportData } from '$/entities/tenantScreening/lib/CreditReportData';

/**
 * Records a search by a Landlord for prospective tenant credit report from Equifax.
 */
@CommonEntity() @ChildEntity()
export class EquifaxCreditReport extends CreditReport {

	static getSample(role: BaseRole) {
		const sample                   = new this();
		sample.id                      = 'sample';
		sample.ver                     = 1;
		sample.org                     = role.org;
		sample.status                  = ProgressStatus.Complete;
		sample.requestedBy             = role.user;
		sample.application             = new Application();
		sample.application.firstName   = 'John';
		sample.application.lastName    = 'Doe';
		sample.application.dateOfBirth = new Date('1992-10-29');
		sample.application.street      = '123 Allen Street';
		sample.application.city        = 'Toronto';
		sample.application.province    = 'ON';
		sample.application.country     = Country.CA;
		sample.report                  = CreditReportData.getEquifaxSample();

		return sample;
	}

}
