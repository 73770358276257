import Axios             from 'axios';
import { doWithRetries } from '$/lib/utils';

import { ChildEntity } from '$/entities/BaseEntity';

import { BackgroundCheck as BackgroundCheckCommon } from '$/common/entities/tenantScreening/BackgroundCheck';
export * from '$/common/entities/tenantScreening/BackgroundCheck';

@ChildEntity()
export class BackgroundCheck extends BackgroundCheckCommon {

	getReport() {
		return doWithRetries(async () => {
			const { status, data } = await Axios.get(BackgroundCheck.collectionUrl(`/${this.id}/report`));
			return status === 202 ? undefined : data;
		}, {
			retries          : 12,
			retryDelay       : 100,
			exponentialDelay : true,
			errorMessage     : 'report not available',
		});
	}

}
