/**
 * A simple logger that is compatible with both the browser and NodeJS environment.
 */
import Moment            from 'moment';
import env, { Platform } from '$/lib/env';
import { stringify }     from '$/lib/utils';

export enum LoggerLevel {
	User    = 'user',		// intended for normal messages generated directly by user interaction
	Debug   = 'debug',		// intended for debugging purposes
	Info    = 'info',		// intended for normal expected events
	Warn    = 'warn',		// intended for unusual behaviour but still anticipated
	Error   = 'error',		// intended for highly unusual and undesired behaviour
	Command = 'command',	// intended to send special messages through out the logging pipeline
}

export interface Log {
	level: LoggerLevel;
	message: string;
	details: any;
	createdOn: Date;
	origin: Platform;
	userID?: string;
	roleID?: string;
	orgID?: string;
	ipAddress?: string;
}

type LogFunction = ((log: Log) => void);
type LogFunctionToBoolean = ((log: Log) => boolean);

/**
 * Base class for all loggers.
 */
export abstract class BaseLogger {

	/**
	 * Logs a message.  Override in subclasses to implement more useful logging.
	 * But it's useful to call this method in the overriding method as this logic
	 * normalizes various input into an array of Log objects.
	 * @param  {String} level     one of the logger.levels
	 * @param  {String} message   the main log message
	 * @param  {Object} [details] additional details for the message
	 * or
	 * @param  {Object} log object with the same fields as the above parameters
	 * or
	 * @param  {Object[]} array of log objects
	 */
	log(log: Partial<Log>)
	log(logs: Log[])
	log(error: Error)
	log(level: LoggerLevel, message: string | Error, details: any)
	log(logsOrLevel: Partial<Log> | Partial<Log>[] | Error | LoggerLevel, message?: string | Error, details?: any): Log[] {
		let logs: Log[];

		if (typeof logsOrLevel  === 'string') {
			if (message instanceof Error) {
				logs = [ _.compactObject({
					level     : logsOrLevel,
					message   : message.message,
					details   : message,
					origin    : (message as any).origin || env.platform,
					createdOn : new Date(),
				}) ];
			}
			else if (typeof message === 'string') {
				logs = [ _.compactObject({
					level     : logsOrLevel,
					message,
					details,
					origin    : details?.origin || env.platform,
					createdOn : new Date(),
				}) ];
			}
			else {
				throw new Error('the second parameter should be either a message or an error');
			}
		}
		else if (logsOrLevel instanceof Error) {
			logs = [ _.compactObject({
				level     : LoggerLevel.Error,
				message   : logsOrLevel.message,
				details   : logsOrLevel,
				origin    : (logsOrLevel as any).origin || env.platform,
				createdOn : new Date(),
			}) ];
		}
		else if (logsOrLevel instanceof Array) {
			logs = logsOrLevel as Log[];
		}
		else if (typeof logsOrLevel === 'object') {
			logs = [ logsOrLevel as Log ];
		}
		else {
			throw new Error('invalid arguments to log');
		}

		return logs.filter(log => {
			// ensure message is always a string
			if (!_.isString(log.message)) {
				console.warn(`WARNING: log message not a string: ${log.message}`);		// eslint-disable-line no-console
				return false;
			}

			// ensure logs have a timestamp
			if (log.createdOn === undefined) {
				log.createdOn = new Date();
			}

			// pull up auth info from details if it doesn't exist on the main level
			[ 'userID', 'roleID', 'orgID' ].forEach(property => {
				if (!log[property] && log.details && log.details.hasOwnProperty(property)) {
					log[property] = log.details[property];
					delete log.details[property];
				}
			});

			return true;
		});
	}

	/**
	 * Shortcut helpers that call this.log with the appropriate level.  All take:
	 * @param {String} message
	 * @param {Mixed}  [details]
	 */

	user(message: string, details?) {
		return this.log(LoggerLevel.User, message, details);
	}
	debug(message: string, details?) {
		return this.log(LoggerLevel.Debug, message, details);
	}
	info(message: string, details?) {
		return this.log(LoggerLevel.Info,  message, details);
	}
	warn(message: string, details?) {
		return this.log(LoggerLevel.Warn,  message, details);
	}
	error(message: string, details?) {
		return this.log(LoggerLevel.Error, message, details);
	}
	// the command level is intended to send messages to the loggers rather than log an event
	command(message: string, details?) {
		return this.log(LoggerLevel.Command, message, details);
	}

}

/**
 * Fix stringify for Error objects to output the stack and message values
 */
Object.defineProperty(Error.prototype, 'toJSON', {
	value : function() {
		const alt = {};

		Object.getOwnPropertyNames(this).forEach(function(key) {
			alt[key] = this[key];
		}, this);

		return alt;
	},
	writable     : true,
	configurable : true,
});

/**
 * Calls the given function for each log.
 */
export class FunctionLogger extends BaseLogger {

	func: LogFunction;

	constructor(func: LogFunction) {
		super();
		this.func = func;
	}

	log(...args) {
		const logs = super.log.apply(this, args);

		logs.forEach((log: Log) => {
			if (log.level !== LoggerLevel.Command) {
				this.func.call(null, log);
			}
		});

		return logs;
	}

}

/**
 * Logger that selectively passes logs to the given loggers depending on whether the filterFunc returns truthy or falsy for that log.
 */
export class Filter extends BaseLogger {

	truthyLogger: BaseLogger;
	falsyLogger: BaseLogger;
	filterFunc: LogFunctionToBoolean;

	/**
	 * @param {LogFunction} filterFunc given a log, returns truthy to pass to truthyLogger; falsy passes to falsyLogger (optional)
	 */
	constructor(filterFunc: LogFunctionToBoolean, truthyLogger: BaseLogger, falsyLogger?: BaseLogger) {
		super();
		if (typeof filterFunc !== 'function') {
			throw new Error('filterFunc must be a function');
		}

		this.truthyLogger = truthyLogger;
		this.falsyLogger  = falsyLogger;
		this.filterFunc   = filterFunc;
	}

	log(...args) {
		return super.log.apply(this, args).forEach((log: Log) => {
			// commands don't need to be filtered
			if (log.level === LoggerLevel.Command) {
				this.truthyLogger.log(log);
				if (this.falsyLogger) {
					this.falsyLogger.log(log);
				}
			}
			else {
				const logger = this.filterFunc(log) ? this.truthyLogger : this.falsyLogger;
				if (logger) {
					logger.log(log);
				}
			}
		});
	}

}

/**
 * Logger that writes logs to the local console.
 */
export class Console extends BaseLogger {

	// if true, adds a timestamp to the output
	addTimestamp = false;

	constructor({ addTimestamp = false } = {}) {
		super();
		if (addTimestamp) {
			this.addTimestamp = !!addTimestamp;
		}
	}

	log(...args) {
		return super.log.apply(this, args).forEach((log: Log) => {
			if (log.level === LoggerLevel.Command) {
				return;	// don't actually output commands
			}

			// make an exception for console.debug because it is an undocumented function that does not write to stdout
			const level = typeof console[log.level] === 'function' && log.level !== 'debug' ? log.level : 'log';		// eslint-disable-line no-console
			let params  = [ log.message ];

			if (this.addTimestamp) {
				params = [ Moment(log.createdOn).format('YYYY-MM-DD HH:mm:ss') ].concat(params);
			}

			if (log.details) {
				params = params.concat(stringify(log.details, null, 0));
			}

			console[level](...params);		// eslint-disable-line no-console
		});
	}

}

/**
 * Buffers up logs and flushes them all at once to the given logger.
 * If the buffer gets full, the logs will start rotating if flushCommand is truthy.
 * The trigger for the flush can be:
 * 1. when the maxTime has passed from the last push to the buffer (set to Infinity to disable this trigger)
 * 2. when the maxLength number of logs have been pushed to the buffer (and flushMessage is falsy)
 * 3. when a command log has been received whose messages matches flushMessage
 *    if a flushMessage is specified, maxLength trigger does not apply
 */
export class BufferLogger extends BaseLogger {

	private buffer: Log[] = [];

	/**
	 * The maximum size of the buffer before logs are flushed.
	 */
	maxLength: 10;	// ms

	/**
	 * The maximum amount of time to wait for more logs to be pushed into the buffer before flushing the buffer.
	 */
	maxTime: 50;	// ms

	/**
	 * The command message that triggers a flush of this buffer.
	 */
	flushMessage = '';

	logger: BaseLogger;

	private timeout;

	/**
	 * @param  {Object} [options] key/value options
	 *            {Number} [maxLength=10] the max number of logs to keep before pushing forward
	 *            {Number} [maxTime=50]   the max time (in ms) to wait for another log call
	 * @param  {BaseLogger} [logger=null] pushes to this logger when maxLength is reached or maxTime is reached
	 */
	constructor(options?, logger: BaseLogger = null) {
		super();

		if (arguments.length == 1) {
			logger  = options;
			options = undefined;
		}

		this.logger = logger;
		options     = _.defaults(options || {}, {
			maxLength : 10,
			maxTime   : 50,
		});

		if (options.maxLength !== undefined) {
			this.maxLength = options.maxLength;
		}
		if (options.maxTime !== undefined) {
			this.maxTime = options.maxTime;
		}
		if (options.flushMessage !== undefined) {
			this.flushMessage = options.flushMessage;
		}
	}

	/**
	 * Returns the current number of log messages buffered up
	 */
	get length() {
		return this.buffer.length;
	}

	log(...args) {
		const newLogs = super.log.apply(this, args);
		this.buffer   = this.buffer.concat(newLogs);

		if (this.flushMessage) {
			let index = 1;
			do {
				index = _.findIndex(this.buffer, { level : LoggerLevel.Command, message : this.flushMessage });
				if (index >= 0) {
					this.flush(index);
					this.buffer.splice(0, 1);	// remove the flush command itself from the buffer since we processed it
				}
			} while (index >= 0);
		}

		// check for buffer overflow
		if (this.buffer.length > this.maxLength) {
			const howMany = this.buffer.length - this.maxLength;
			this.flushMessage ? this.rotate(howMany) : this.flush(howMany);		// rotated logs are lost
		}

		// setup timeout
		if (this.buffer.length > 0) {
			if (this.timeout) {
				clearTimeout(this.timeout);
			}
			if (this.maxTime < Infinity) {
				this.timeout = setTimeout(() => this.flush(), this.maxTime);
			}
		}

		return newLogs;
	}

	/**
	 * Flushes the buffer by pushing buffered logs to this.logger
	 * @param  {Number} [howMany=Infinity] the max number of logs from the buffer to push
	 */
	flush(howMany = Infinity) {
		const logs = this.rotate(howMany);
		if (logs.length > 0 && this.logger) {
			this.logger.log(logs);
		}
	}

	private rotate(howMany: number) {
		return this.buffer.splice(0, howMany);
	}

	/**
	 * @returns the first log in the buffer;
	 */
	peek(): Log {
		return this.buffer[0];
	}

}

/**
 * Passes logs to each of the given loggers.
 */
export class Splitter extends BaseLogger {

	loggers: BaseLogger[] = [];

	/**
	 * @param  {PossibleArray<BaseLogger>} loggers the set of loggers to pass a copy of the log to
	 * @param  {PossibleArray<BaseLogger>} ... any more arguments
	 */
	constructor(...loggers) {
		super();
		this.loggers = _.flatten(loggers);
	}

	log(...args) {
		const logs = super.log.apply(this, args);
		this.loggers.forEach(logger => logger.log(logs));
		return logs;
	}

	addLogger(logger: BaseLogger) {
		if (!(logger instanceof BaseLogger)) {
			throw new Error('logger must be an instanceof BaseLogger');
		}

		if (!this.loggers.includes(logger)) {
			this.loggers.push(logger);
		}
	}

	removeLogger(logger: BaseLogger) {
		if (!(logger instanceof BaseLogger)) {
			throw new Error('logger must be an instanceof BaseLogger');
		}

		const index = this.loggers.indexOf(logger);
		if (index !== -1) {
			this.loggers.splice(index, 1);
		}
	}

}

/**
 * Similar to Javascript's switch statement, evaluates successive conditions for filters and sends
 * each log message to the first filter whose condition evaluates truthy.
 */
export class Switch extends BaseLogger {

	filters: SwitchFilter[] = [];

	/**
	 * @param {SwitchFilter[]} filters - each with the following properties:
	 *           {Function}   if   - called with the log in question, returns truthy to send the log down the 'then' filter
	 *           {BaseLogger} then - the logger to use if the `if` function returns truthy
	 *           {BaseLogger} else - the logger to use if no previous `if` function returned truthy
	 */
	constructor(filters: SwitchFilter[]) {
		super();
		filters.forEach(filter => {
			if (filter.hasOwnProperty('if')) {
				if (!_.isFunction(filter.if)) {
					throw new Error('"if" must be a function');
				}

				if (filter.then && !(filter.then instanceof BaseLogger)) {
					throw new Error('"then" must be an instance of BaseLogger');
				}

				if (filter.hasOwnProperty('else')) {
					throw new Error('cannot have an "else" property on the same filter as "if"');
				}
			}
			else if (filter.hasOwnProperty('else')) {
				if (!(filter.else instanceof BaseLogger)) {
					throw new Error('"else" must be an instance of BaseLogger');
				}

				// for consistency, convert "else" to "if" with a truthy function
				filter.then = filter.else;
				filter.if   = () => true;
				delete filter.else;
			}
			else {
				throw new Error('filter must have either "if" or "else" property');
			}
		});

		this.filters = filters;
	}

	log(...args) {
		const logs = super.log.apply(this, args);

		_.forEach(logs, log => {
			_.some(this.filters, filter => {
				if (filter.if(log)) {
					filter.then.log(log);
					return true;	// stop looping
				}
			});
		});

		return logs;
	}

}

export interface SwitchFilter {
	if?: LogFunctionToBoolean;
	then?: BaseLogger;
	else?: BaseLogger;
}

// to satisfy any imports in common files
export default new Console();
