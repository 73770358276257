
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M14.333 15.833h-2.916a.416.416 0 0 0-.417.417v2.917H1.833V.833h12.5v3.334h.834V.833A.834.834 0 0 0 14.333 0h-12.5A.834.834 0 0 0 1 .833v18.334c0 .46.374.833.833.833h10.834c.11 0 .216-.044.294-.122l2.084-2.083a.415.415 0 0 0 .122-.295V15h-.834v.833Zm0 1.495-1.839 1.839h-.66v-2.5h2.5v.66Z"}}),_c('path',{attrs:{"d":"M12.667 5V3.75a.416.416 0 0 0-.417-.417H3.917a.417.417 0 0 0-.417.417v5c0 .23.187.417.417.417h5.416v-.834h-5V4.167h7.5V5h.834ZM3.5 10.833h4.167v.834H3.5zM8.5 10.833h.833v.834H8.5zM3.5 13.333H6v.834H3.5zM6.833 13.333H11v.834H6.833zM3.5 15.833h4.167v.834H3.5zM8.5 15.833h1.667v.834H8.5z"}}),_c('path',{attrs:{"d":"M14.75 5a4.589 4.589 0 0 0-4.583 4.583 4.589 4.589 0 0 0 4.583 4.584 4.589 4.589 0 0 0 4.583-4.584A4.589 4.589 0 0 0 14.75 5Zm-2.083 7.7v-.2c0-.69.56-1.25 1.25-1.25h1.666c.69 0 1.25.56 1.25 1.25v.2c-.596.4-1.313.633-2.083.633-.77 0-1.487-.234-2.083-.634ZM14.75 10c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25S16 8.06 16 8.75 15.44 10 14.75 10Zm2.852 2.011a2.082 2.082 0 0 0-1.66-1.558c.538-.377.891-.998.891-1.703a2.086 2.086 0 0 0-2.083-2.083 2.086 2.086 0 0 0-2.083 2.083c0 .705.353 1.326.89 1.703a2.082 2.082 0 0 0-1.66 1.558A3.731 3.731 0 0 1 11 9.583a3.754 3.754 0 0 1 3.75-3.75 3.754 3.754 0 0 1 3.75 3.75c0 .926-.34 1.773-.898 2.428Z"}})])
          )
        }
      }
    