import { CommonEntity, ChildEntity } from '$/entities/BaseEntity';
import { BaseRole  }                 from '$/entities/roles/BaseRole';
import { RolePermission }            from '$/entities/roles/RolePermission';
import { OrgID }                     from '$/entities/Organization';
import { DocumentType }              from '$/entities/FileExt';
import { User, ProgressStatus, VerificationDoc } from '$/entities/User';

export interface EquifaxLandlordRegistrationInfo {
	orgId: string;
	name: string;
	address: string;
	phoneNumber: string;
	location: string;
}

export enum LandlordInterest {
	RecoverDebt   = 'recoverDebt',
	BuildCredit   = 'buildCredit',
	PullCredit    = 'pullCredit',
	RecordKeeping = 'recordKeeping',
	EnsureRent    = 'ensureRent',
}

@ChildEntity() @CommonEntity()
export class Landlord extends BaseRole {

	constructor() {
		super();
		if (this.isNew) {
			this.emailPreferences.LandlordMonthlyEmail = true;
		}
	}

	hasPermission(permission: RolePermission): boolean {
		switch (permission) {
			case RolePermission.LeaseWrite:
			case RolePermission.OrgView:
			case RolePermission.OrgManageInvitations:
				return true;
			case RolePermission.RentReportingToggleWrite:
			case RolePermission.EnterpriseThresholdWrite:
			case RolePermission.ConnectionSettingsWrite:
				// TriCon landlords can't change these settings as per request by TriCon
				// SHOULDDO: move this to a Landlord subclass that has fewer permissions
				return (this as any).orgId !== OrgID.Tricon;
		}
		return super.hasPermission(permission);
	}

	getVerificationDocs(): VerificationDoc[] {
		const results = super.getVerificationDocs();

		if (this.verificationStatus !== ProgressStatus.Approved) {
			results.push(
				{
					referenceType : 'role.landlord',
					docType       : DocumentType.BuildingOwnership,
					title         : 'Proof of ownership of at least 1 rental property',
					desc          : '(Must be property tax record, insurance policy, OR title document for the rental property in your name)',
				},
				{
					referenceType : 'role.landlord',
					docType       : DocumentType.OrgCompanyProof,
					title         : 'IF the property is owned by a company, provide documentation showing you are a director or employee of that company',
					optional      : true,
				},
				{
					referenceType : 'role.propertyManager',
					docType       : DocumentType.PMManagementAgreement,
					title         : 'Copy of your property management agreement template',
				},
				{
					referenceType : 'role.propertyManager',
					docType       : DocumentType.PMBusinessCard,
					title         : 'Copy of your business card or proof of employment',
					desc          : '(i.e business card, employment agreement, screenshot of your LinkedIn profile, screenshot of you on the company website)',
				}
			);
		}

		return results;
	}

	async getEquifaxRegistrationInfo(): Promise<EquifaxLandlordRegistrationInfo> {
		await this.loadRelation('org', { required : true });

		return {
			orgId       : this.org.id,
			name        : this.org.name || this.fullName,
			address     : this.user.address.format(),
			phoneNumber : this.user.phoneNumber,
			location    : _.compact([ this.org.province,  this.org.country ]).join(',')
			           || _.compact([ this.user.province, this.user.country ]).join(', '),
		};
	}

	static getSample<T extends typeof Landlord>(this: T): InstanceType<T> {
		const sample = new this() as InstanceType<T>;
		sample.user  = User.getSample();
		return sample;
	}

}
