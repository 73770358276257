import { BaseRole }                        from '$/entities/roles/BaseRole';
import { CommonEntity, ChildEntity }       from '$/entities/BaseEntity';
import { ProgressStatus, VerificationDoc } from '$/entities/User';
import { DocumentType }                    from '$/entities/FileExt';

/**
 * Renters are users whose role is that of renting properties from landlords.
 */
@ChildEntity() @CommonEntity()
export class Renter extends BaseRole {

	getVerificationDocs(): VerificationDoc[] {
		const docs = super.getVerificationDocs();

		if (this.user.verificationStatus !== ProgressStatus.Approved) {
			docs.push({
				referenceType : 'user',
				docType       : DocumentType.BuildingOwnership,
				title         : 'Document showing your name, phone number, and address',
				desc          : '(i.e. phone bill OR utility bill OR lease agreement)',
			});
		}

		return docs;
	}

}
