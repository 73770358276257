
































import { BDropdown, BFormInput } from 'bootstrap-vue';
import { Component, Mixins, Prop, Ref, Watch } from '$/lib/vueExt';
import VueValidationMixin        from '$/lib/mixins/VueValidationMixin';
import env                       from '$/lib/env';

@Component({
	inheritAttrs : false,
	model        : { prop : 'value', event : 'update:value' },
})
export default class FormInputWithSuggestions extends Mixins(VueValidationMixin) {

	@Prop({ default : '' })
	readonly value: string; // current input value

	@Prop()
	readonly suggestions: string[]; // list of suggestions to show

	@Prop({ default : 5 })
	readonly limitTo: number; // maximum number of suggestions to show at a time

	@Ref()
	readonly dropdown: BDropdown;

	@Ref()
	readonly input: BFormInput;

	private uniqueID = `input_suggestions_${Math.random()}`;

	inputFocused = false; // if the input element is currently focused
	dropdownOpening = false; // if the dropdown of suggestions is in the process of opening
	dropdownOpened = false; // if the dropdown of suggestions is currently opened

	get isMobile() {
		return env.isMobile;
	}

	get inputValue() {
		return this.value;
	}
	set inputValue(value) {
		this.$emit('update:value', value);
	}

	/**
	 * If the current input exactly matches a suggestion, don't show it.
	 * Also limit the list to a certain number of values, set by the limitTo prop
	 */
	get displayedSuggestions() {
		return _.slice(_.without(this.suggestions, this.value), 0, this.limitTo);
	}

	/**
	 * When the input is focused, open the dropdown.
	 */
	onInputFocus() {
		if (!this.inputFocused) {
			this.inputFocused = true;
			this.toggleDropdown();
		}
	}

	/**
	 * When the dropdown opens, it pulls focus away from the input. Because of this, sometimes we don't want to treat this as an actual blur.
	 * If we're not in the process of opening the dropdown, then the input is actually being blurred, so mark that the input isn't focused now.
	 * If we are in the process of opening the dropdown, that process is now over, so mark the dropdownOpening variable accordingly.
	 */
	onBlur() {
		if (!this.dropdownOpening) {
			this.inputFocused = false;
		}
		else {
			this.dropdownOpening = false;
		}
	}

	/**
	 * When the dropdown opens, it pulls focus away from the input.
	 * Refocus the input when this happens, and note that the dropdown is open for future reference.
	 */
	onDropdownShown() {
		this.input.focus();
		this.dropdownOpened = true;
	}

	/**
	 * Gets called when the dropdown is about to close. It will try to close whenever another element is focused (most relevantly, the input).
	 * If the input is supposed to still be focused and there are suggestions to show, don't hide the dropdown.
	 */
	keepOpenIfInputFocused(event) {
		if (this.inputFocused && this.displayedSuggestions.length) {
			event.preventDefault();
		}
	}

	/**
	 * Set the dropdown state based on the state of other parts of this component.
	 * If there are suggestions when previously there weren't, then open (and vice versa).
	 * If the value from the input changes, it could also affect the suggestion list due to filtering out the current value from suggestions.
	 * Can't use the input element's input event to handle changes to the value, as it fires at the wrong time.
	 */
	@Watch('suggestions')
	@Watch('value')
	toggleDropdown() {
		if (this.isMobile) {
			return;
		}

		// We want to show the dropdown if it's not already shown, there are suggestions to show, and the input element is currently focused
		if (this.inputFocused && this.displayedSuggestions.length && !this.dropdownOpened) {
			this.dropdownOpening = true; // Track if the dropdown is opening, relevant to the onBlur above
			this.dropdown.show();
		}
		else if (this.dropdownOpened) { // No sense hiding if it's not already open
			this.dropdown.hide();
		}
	}

	/**
	 * Uses the validation of the input element
	 */
	isValid() {
		return this.input?.isValid();
	}

}
