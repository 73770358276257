


























/**
 * This component displays a popup with a prompt to Upgrade to a premium package.
 */
import { Vue, Component, Prop } from '$/lib/vueExt';
import type { PackageFeature }  from '$/entities/Package';

@Component
export default class UpgradePrompt extends Vue {

	/**
	 * Show redirect to upgrade button only on table view
	 */
	@Prop({ default : false })
	readonly showButton: boolean;

	@Prop({ required : true })
	readonly feature: PackageFeature;

	@Prop({ default : false })
	readonly hasUnsavedChanges: boolean;

	async upgrade() {
		let discardChanges = false;
		if (this.hasUnsavedChanges) {
			discardChanges = await this.showConfirmUnsavedChanges();
		}

		if (!this.hasUnsavedChanges || discardChanges) {
			this.$router.push('/pricing');
		}
	}

}
