











import { Debounce }              from '$/lib/utils';
import { Component, Vue, Watch } from '$/lib/vueExt';

/**
 * This component briefly shows the new viewport dimensions as well as the Bootstrap grid size when the window is resized.
 */
@Component
export default class ViewportDimensions extends Vue {

	isVisible = false;

	get dimensions() {
		return `${this.$breakpoint.width} x ${this.$breakpoint.height}`;
	}

	@Watch('dimensions')
	onDimensionChange() {
		this.isVisible = true;
		this.hide();
	}

	@Debounce()
	hide() {
		setTimeout(() => {
			this.isVisible = false;
		}, 3000);
	}

}
