
/**
 * A widget that allows the user to select a particular date.
 */
import { Vue, Component } from '$/lib/vueExt';
import VueDatePicker      from 'vuejs-datepicker';
import Moment             from 'moment';

const datePicker = _.cloneDeep(VueDatePicker);

// delete props that are being overridden with a fixed method
delete datePicker.props.format;
delete datePicker.props.useUtc;

@Component({ extends : datePicker })
export default class DatePicker extends Vue {

	get useUtc() {
		return true;
	}

	mounted() {
		this.$el.querySelector('input').addEventListener('blur', event => {
			(event.target as HTMLInputElement).value = this.format((this as any).value);
		});
	}

	// overrides the format prop in the original vuejs-datepicker to always use the same format
	format(date: Date) {
		return _.isNil(date) ? '' : Moment(date).utcOffset(0).format('ll');
	}

}
