
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M18.446 7.762a1.31 1.31 0 0 0 .333-1.352 1.31 1.31 0 0 0-1.064-.897l-4.377-.636a.264.264 0 0 1-.198-.145L11.183.767A1.31 1.31 0 0 0 10 .032a1.31 1.31 0 0 0-1.183.735L6.86 4.732a.264.264 0 0 1-.198.145l-4.376.636a1.31 1.31 0 0 0-1.065.897 1.31 1.31 0 0 0 .334 1.352L4.72 10.85c.062.06.09.148.076.233l-.748 4.359a1.31 1.31 0 0 0 .525 1.29c.41.297.942.336 1.39.1l3.913-2.057a.264.264 0 0 1 .246 0l3.914 2.057a1.313 1.313 0 0 0 1.389-.1c.41-.298.61-.792.525-1.29l-.748-4.359a.264.264 0 0 1 .076-.233l3.167-3.087Zm-4.283 3.499.748 4.358a.257.257 0 0 1-.105.258.257.257 0 0 1-.278.02l-3.914-2.057a1.319 1.319 0 0 0-1.228 0l-3.914 2.057a.257.257 0 0 1-.278-.02.257.257 0 0 1-.104-.258l.747-4.358a1.319 1.319 0 0 0-.38-1.168L2.292 7.007a.257.257 0 0 1-.067-.27.257.257 0 0 1 .213-.18l4.376-.636c.43-.063.801-.332.994-.722l1.957-3.965A.257.257 0 0 1 10 1.087c.05 0 .171.014.237.147l1.957 3.965c.192.39.563.66.993.722l4.376.636a.257.257 0 0 1 .213.18.257.257 0 0 1-.067.27l-3.166 3.086c-.311.303-.453.74-.38 1.168Zm2-10.494a.528.528 0 0 0-.737.116l-.56.771a.528.528 0 0 0 .854.62l.56-.77a.528.528 0 0 0-.117-.737Zm-11.032.884-.56-.77a.528.528 0 0 0-.853.62l.56.77a.527.527 0 1 0 .853-.62ZM1.936 11.868a.528.528 0 0 0-.665-.338l-.906.294a.528.528 0 1 0 .326 1.004l.906-.295a.528.528 0 0 0 .339-.665ZM10 17.726a.528.528 0 0 0-.527.528v.952a.528.528 0 0 0 1.055 0v-.952a.528.528 0 0 0-.528-.528Zm9.635-5.901-.906-.295a.528.528 0 0 0-.326 1.004l.906.294a.528.528 0 1 0 .326-1.003Z"}})])
          )
        }
      }
    