




import { Component, Vue, Prop } from '$/lib/vueExt';
import styles                   from '$/lib/styles/_exports.module.scss';
import Radial                   from '$/lib/charts/Radial';

@Component({ components : { Radial } })
export default class RadialChart extends Vue {

	@Prop()
	readonly value: number | string;

	@Prop()
	readonly max: number;

	@Prop()
	readonly imageSrc: string;

	@Prop({ default : 0 })
	readonly imagePadding: number;

	@Prop({ default : 'brand' })
	readonly variant: string;

	@Prop()
	readonly rounded: boolean;

	@Prop()
	readonly size: number; // in pixels

	get arcs() {
		if (typeof this.value === 'string' || _.isNil(this.value) || _.isNil(this.max)) {
			return [ 1, 0 ]; // just a full circle
		}
		const clampedValue = _.clamp(this.value, 0, this.max);
		return [ clampedValue, this.max - clampedValue ];
	}

	get chartData() {
		const variantStyle = `color-${this.variant}`;
		const variantColor = Object.keys(styles).includes(variantStyle) ? styles[variantStyle] : styles['color-default'];
		return {
			datasets : [ {
				center : {
					image : {
						src     : this.imageSrc,
						padding : this.imagePadding,
					},
				},
				data            : this.arcs,
				backgroundColor : variantColor,
				borderWidth     : 0,
				rounded         : this.rounded,
			} ],
		};
	}

	get options() {
		return {
			responsive          : true,
			maintainAspectRatio : false,
			animation           : false,
			hover               : {
				mode : null,
			},
			plugins : {
				tooltip : {
					enabled : false,
				},
			},
			size : this.size,
		};
	}

}
