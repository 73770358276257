/**
 * Like native JS Map but takes 2 keys.
 */

export default class Map2<Key1Type, Key2Type, Value> {

	private _map: Map<Key1Type, Map<Key2Type, Value>> = new Map();

	/**
	 * @returns the total number of values in the Map
	 */
	get size() {
		let sum = 0;
		this.forEach(map => {
			sum += map.size;
		});
		return sum;
	}

	get(key1: Key1Type): Map<Key2Type, Value>;
	get(key1: Key1Type, key2: Key2Type): Value;
	get(key1: Key1Type, key2?: Key2Type) {
		const map1 = this._map.get(key1);
		return map1 === undefined || key2 === undefined ? map1 : map1.get(key2);
	}

	has(key1: Key1Type, key2: Key2Type): boolean {
		return this._map.has(key1) && this._map.get(key1).has(key2);
	}

	set(key1: Key1Type, key2: Key2Type, value: Value) {
		let map1 = this._map.get(key1);
		if (!map1) {
			map1 = new Map();
			this._map.set(key1, map1);
		}
		map1.set(key2, value);
	}

	/**
	 * Returns all the keys at the first level.
	 */
	keys1(): IterableIterator<Key1Type> {
		return this._map.keys();
	}

	/**
	 * Returns all the keys at the second level for the given level-one key.
	 */
	keys2(key1: Key1Type): IterableIterator<Key2Type> {
		return this.get(key1)?.keys();
	}

	/**
	 * Iterates over the first level of keys.
	 */
	forEach(callback: (value: Map<Key2Type, Value>, key: Key1Type, map: Map<Key1Type, Map<Key2Type, Value>>) => void) {
		this._map.forEach(callback);
	}

	values() {
		return this._map.values();
	}

}
