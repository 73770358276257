
















/**
 * Overwrite BFormGroup to add support for mutating certain props
 */
import { BFormGroup as BFormGroupOriginal }     from 'bootstrap-vue';
import { Vue, Component, Prop, Watch, Provide } from '$/lib/vueExt';

@Component({ components : { BFormGroupOriginal } })
export default class FormGroup extends Vue {

	/**
	 * if false, don't show the "required" label decorator (currently an asterisk suffix)
	 */
	@Prop({ default : true })
	readonly showRequiredLabel: boolean;

	@Prop({ default : '' })
	readonly invalidFeedback: string;

	@Prop({ default : '' })
	readonly validFeedback: string;

	localInvalidFeedback = null;
	localValidFeedback   = null;
	feedbackOrigin       = undefined;
	localState           = null;
	isFeedbackWarning    = false;

	mounted() {
		// add required labelClass if a descendant input element has the required attribute
		if (this.showRequiredLabel) {
			// HACK: delay a bit to give children a bit of time to compute values if dynamic
			// COULDDO: a better implementation that allows the required attribute to change
			setTimeout(() => {
				const $children = this.$children;
				while ($children.length > 0) {
					const $child = $children.pop();

					if (($child as any).required === true || $child.$attrs.required === 'required' || $child.$attrs.required === 'true') {
						this.$el.classList.add('required');
						break;
					}

					$children.push(...$child.$children);
				}
			}, 100);
		}
	}

	/**
	 * set the value of the form group's feedback
	 * @param {String} feedbackMessage the feedback message
	 * @param {Vue}    origin   the component who called the setFeedback passes itself as the origin
	 * 1) Only the child component that set the feedback can reset its own feedback
	 * 2) If a child component is setting a feedback and there's already another feedback by another child component it will overwrite the other feedback
	 * Note: we only care that if there's a validation error, some feedback from either one of the child components is there
	 * This behavior allows custom components like NationalIDInput to merge its internal validation rules for formatting with the parent's additional validation rules
	 */
	@Provide()
	setFeedback(feedbackMessage: string, origin?: Vue, type = 'invalid') {	// signature is SetFeedback
		type          = type || (feedbackMessage ? 'invalid' : 'valid');
		const isValid = !feedbackMessage || type === 'warning' || type === 'valid';

		if (origin) {
			const isResettingFeedback = !feedbackMessage;
			if (isResettingFeedback && this.feedbackOrigin?._uid !== (origin as any)._uid) {
				return;
			}
			this.feedbackOrigin = origin;
		}

		this.localInvalidFeedback = isValid ? null : feedbackMessage;
		this.localValidFeedback   = isValid ? feedbackMessage : null;
		this.localState           = {
			valid   : null,
			invalid : false,
			warning : true,		// warnings make use of the valid state to show the feedback text
		}[type];

		this.isFeedbackWarning = type === 'warning' && !!feedbackMessage;
	}

	@Watch('invalidFeedback')
	onInvalidFeedbackChange(value) {
		this.setFeedback(value);
	}

	@Watch('validFeedback')
	onValidFeedbackChange(value) {
		this.setFeedback(value, undefined, 'valid');
	}

}
