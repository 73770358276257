export enum TestimonialLocation {
	Upgrade,
	TenantScreening,
	DebtReporting,
	Pricing,
	SignupLandlord,
	SignupTenant,
	Dashboard
}

enum TestimonialSource {
	Facebook = 'Facebook',
	LinkedIn = 'LinkedIn',
	NPS      = 'Survey',
	Email    = 'Email',
	BBB      = 'Better Business Bureau',
}

export const testimonialSourceIcons = {
	[TestimonialSource.Facebook] : '/assets/testimonials/FacebookLogo.svg',
	[TestimonialSource.LinkedIn] : '/assets/testimonials/LinkedInLogo.svg',
	[TestimonialSource.NPS]      : '/assets/testimonials/HubSpotLogo.svg',
	[TestimonialSource.BBB]      : '/assets/testimonials/BBBLogo.svg',
	[TestimonialSource.Email]    : '/assets/testimonials/emailLogo.svg',
};

export interface Testimonial {
	appearsOn: TestimonialLocation[];
	originalText: string;
	date?: Date;
	finalText?: string;
	source?: TestimonialSource;
	testifier: {
		title?: string;
		companyName?: string;
		firstName?: string;
		lastName?: string;
	};
	profileImage?: string;
}

export const allTestimonials: Testimonial[] = [
	{
		appearsOn    : [ TestimonialLocation.Upgrade ],
		originalText : 'Never been easier to collect rent. Everyone has been paying on time and with minimal effort on my end.',
		date         : new Date(2023, 2, 2),
		source       : TestimonialSource.NPS,
		testifier    : {
			firstName : 'Pietro',
			lastName  : 'C',
		},
	},
	{
		appearsOn    : [ TestimonialLocation.Upgrade ],
		originalText : `
			I was delighted that you guys brought such a positive change to this industry to allow landlords and tenants
			to work together to build their great credit and future, while landlords feel much more secure.
		`,
		date      : new Date(2022, 7, 20),
		source    : TestimonialSource.NPS,
		testifier : {
			firstName : 'Tao',
			lastName  : 'Z',
		},
	},
	{
		appearsOn    : [ TestimonialLocation.Upgrade ],
		originalText : 'One stop shop for screening and maintaining tenant financial responsibility.',
		date         : new Date(2022, 8, 9),
		source       : TestimonialSource.NPS,
		testifier    : {
			firstName : 'Douglas',
			lastName  : 'M',
		},
	},
	{
		appearsOn    : [ TestimonialLocation.Upgrade ],
		originalText : `
			This is a fantastic value-added tool that we're excited to be using. As property managers,
			it helps to reduce our risk but the bonus is that our clients and our tenants gain just as much from it as we do
		`,
		date      : new Date(2021, 4, 23),
		source    : TestimonialSource.LinkedIn,
		testifier : {
			firstName : 'Jason',
			lastName  : 'R',
		},
	},
	{
		appearsOn    : [ TestimonialLocation.Upgrade ],
		originalText : `
			FrontLobby has worked wonderfully for us, and our current tenants are excited to have their credit scores improving….
			Former tenants paid their overdue amounts expeditiously….
		`,
		date      : new Date(2021, 4, 11),
		source    : TestimonialSource.Facebook,
		testifier : {
			firstName : 'Dainard',
			lastName  : 'H',
		},
	},
	{
		appearsOn    : [ TestimonialLocation.DebtReporting ],
		originalText : `
			Thanks, it's already starting to work. The tenant reached out after disappearing for months.
		`,
		date      : new Date(2021, 5, 25),
		source    : TestimonialSource.NPS,
		testifier : {
			firstName : 'Alex',
			lastName  : 'C',
		},
	},
	{
		appearsOn    : [ TestimonialLocation.DebtReporting ],
		originalText : `
			My tenant paid the debt that had been outstanding since she stopped paying rent in June 2020.
			She paid us in full ($29,XXX) in December 2022.
		`,
		date      : new Date(2022, 2, 2),
		source    : TestimonialSource.NPS,
		testifier : {
			firstName : 'Emilie',
			lastName  : 'D',
		},
	},
	{
		appearsOn    : [ TestimonialLocation.DebtReporting ],
		originalText : `
			FrontLobby really works. I had an old tenant who moved out a year ago owing $XXXX.
			I decided to try their service. The website is really easy to use. One week
			after FrontLobby sent out the email notification, my old tenant sent me the full amount.
		`,
		date      : new Date(2022, 5, 15),
		source    : TestimonialSource.NPS,
		testifier : {
			firstName : 'Kailin',
			lastName  : 'H',
		},
	},
	{
		appearsOn    : [ TestimonialLocation.DebtReporting ],
		originalText : `
			FrontLobby totally works. I got a previous tenant paid the owing rent even though they have moved out as it
			is reported on their credit bureau.
		`,
		date      : new Date(2022, 2, 2),
		source    : TestimonialSource.Facebook,
		testifier : {
			firstName : 'Jeffrey',
			lastName  : 'L',
		},
	},
	{
		appearsOn    : [ TestimonialLocation.TenantScreening ],
		originalText : `
			One stop shop for screening and maintaining tenant financial responsibility.
		`,
		date      : new Date(2022, 9, 9),
		source    : TestimonialSource.NPS,
		testifier : {
			firstName : 'Douglas',
			lastName  : 'M',
		},
	},
	{
		appearsOn    : [ TestimonialLocation.TenantScreening, TestimonialLocation.Dashboard ],
		originalText : `
			Intuitive platform for reporting lease payments (late as well as on time) plus easy
			to use tenant screening. This one package replaces two other subscriptions.
		`,
		date      : new Date(2022, 1, 31),
		source    : TestimonialSource.NPS,
		testifier : {
			firstName : 'Bonnie',
			lastName  : 'W',
		},
	},
	{
		appearsOn    : [ TestimonialLocation.TenantScreening ],
		originalText : `
			Being a housing provider can be stressful at times but if you've done your due diligence
			and selected the right tenant that whole experience is wonderful. I'm glad I signed up for FrontLobby
			as it made conducting background checks simple and automatic monthly rent reminders minimize rent payment issues.
		`,
		date      : new Date(2022, 12, 7),
		source    : TestimonialSource.NPS,
		testifier : {
			firstName : 'Jagdeep',
			lastName  : 'G',
		},
	},
	{
		appearsOn    : [ TestimonialLocation.Pricing ],
		originalText : `
			Recently implemented the services of FrontLobby to help tenants build their Tenant Record
			and the feedback has been incredible!
		`,
		source    : TestimonialSource.LinkedIn,
		testifier : {
			title       : 'Property Manager',
			companyName : 'East Vista',
		},
	},
	{
		appearsOn    : [ TestimonialLocation.Pricing, TestimonialLocation.SignupLandlord ],
		originalText : 'Since implementing credit reporting default monthly payments decreased by 92%.',
		source       : TestimonialSource.NPS,
		testifier    : {
			title       : 'Property Manager',
			companyName : 'Panacea Property Management',
		},
		profileImage : '/assets/unicorn-icon.svg',
	},
	{
		appearsOn    : [ TestimonialLocation.Pricing ],
		originalText : "I use it and love it. Since I added it to my tenants lease, they haven't been late once.",
		source       : TestimonialSource.Facebook,
		testifier    : {
			title     : 'Housing Provider',
			firstName : 'Sean',
			lastName  : 'M',
		},
	},
	{
		appearsOn    : [ TestimonialLocation.Pricing ],
		originalText : `
			Intuitive platform for reporting payments + easy to use tenant screening.
			This one package replaces two other subscriptions.
		`,
		source    : TestimonialSource.NPS,
		testifier : {
			title     : 'Housing Provider',
			firstName : 'Bonnie',
			lastName  : 'W',
		},
	},
	{
		appearsOn    : [ TestimonialLocation.SignupTenant ],
		originalText : 'I have built up a decent credit file by now that has enabled me to access all the credit I need.',
		testifier    : {
			firstName : 'Nikhil',
			lastName  : 'A',
		},
	},
];

export function getTestimonialsFor(location: TestimonialLocation) {
	return _.shuffle(allTestimonials.filter(testimonial => testimonial.appearsOn.includes(location)));
}
