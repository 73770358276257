
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 280 280"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M280 165.666v-51.333l-31.5-5.25c-2.333-8.75-5.833-16.917-9.917-23.917L257.25 59.5l-36.167-36.75-26.25 18.083c-7.583-4.083-15.75-7.583-23.916-9.917L165.667 0h-51.334l-5.25 31.5c-8.75 2.333-16.333 5.833-23.916 9.916L59.5 23.333 22.75 59.5l18.667 25.666c-4.083 7.584-7.583 15.75-9.917 24.5L0 114.333v51.333l31.5 5.25c2.333 8.75 5.833 16.334 9.917 23.917l-18.667 26.25 36.167 36.167 26.25-18.667c7.583 4.083 15.75 7.583 24.5 9.917l5.25 30.916h51.333l5.25-31.5c8.167-2.333 16.333-5.833 23.917-9.916l26.25 18.666 36.166-36.166-18.666-26.25c4.083-7.584 7.583-15.167 9.916-23.917L280 165.666zm-140 28c-29.75 0-53.667-23.916-53.667-53.666s24.5-53.667 53.667-53.667S193.667 110.25 193.667 140 169.75 193.666 140 193.666z"}})])
          )
        }
      }
    