import { JSONable, Field } from '$/entities/lib/JSONable';

/**
 * Base class for keeping track of various user preferences, eg: email notifications.
 * It is intended to be used as a class for the JSON Column in the DB.
 */
export default class BasePreferences extends JSONable {

	constructor(initialValues?: Dictionary<any>) {
		super();

		_.forEach(initialValues, (value, key) => {
			this.set(key, value);
		});
	}

	get(preference: string): boolean {
		if (!(this.constructor as typeof BasePreferences).getAvailablePreferences().includes(preference)) {
			throw new Error(`invalid preference: ${preference}`);
		}
		return !!this[preference];
	}

	/**
	 * Sets the given preference to the given value.
	 */
	set(preference: string, value: Preference) {
		this.get(preference);	// validate preference: throws if it's an invalid preference
		this[preference] = !!value;
	}

	/**
	 * @returns a list of preferences available
	 */
	static getAvailablePreferences(): string[] {
		return this.getFields();
	}

}

export type Preference = boolean | null;

export enum DraftLandlordEmailPrefsKeys {
	Draft = 'draft',
}

/**
 * Landlord's email preferences.  Keeps track of unsubscribes.
 */
export class DraftLandlordEmailPrefs extends BasePreferences {

	/**
	 * if false, landlord will no longer receive draft related emails
	 */
	@Field()
	[DraftLandlordEmailPrefsKeys.Draft]: Preference = true;

}

export enum TenantEmailPrefsKeys {
	IntroEmail = 'introEmail',
}

/**
 * Tenant's email preferences.  Keeps track of unsubscribes.
 */
export class TenantEmailPrefs extends BasePreferences {

	/**
	 * if false, tenant does not receive any of the Intro email (TenantInvitation and it's subclasses)
	 */
	@Field()
	[TenantEmailPrefsKeys.IntroEmail]: Preference = true;

}

export enum RenterEmailKeys {
	ConsentReminderEmail           = 'ConsentReminderEmail',
	MonthlyRenterConfirmationEmail = 'MonthlyRenterConfirmationEmail',
}

export enum OrganizationEmailPrefsKeys {
	ArrearsReminderEmail = 'ArrearsReminderEmail'
}

export class OrganizationEmailPrefs extends BasePreferences {

	@Field()
	[OrganizationEmailPrefsKeys.ArrearsReminderEmail]: Preference = true;

}
