import env              from '$/lib/env';
import { BaseVerifier } from '$/lib/identityVerification/BaseVerifier';
import { PersonaCheck } from '$/lib/personaClient';

import { VerificationType, VerificationResult } from '$/entities/lib/VerificationResults';

export abstract class PersonaVerifier extends BaseVerifier {

	abstract checkInquiryStatus(inquiryId: string): Promise<{ status: string; sessionToken: string }>;

	get personaEnvironment(): string {
		return env.config('persona.env', { required : true });
	}

	get personaTemplate(): string {
		return env.config('persona.templateID', { required : true });
	}

	/**
	 * Composes humanly-readable failure messages based on the verification result for a particular end user.
	 * @param verificationResult - The verification result to compose failure messages for.
	 * @param forSupport         - if true, composes messages for support staff
	 */
	static getFailureMessages(verificationResult: VerificationResult, { forSupport = false } = {}): string[] {
		return verificationResult.reasons?.map(reason => {
			// first check if it's one of the predefined failure messages
			const piiData = _.compactObject(_.pick(verificationResult.additionalData, 'firstName', 'lastName', 'birthDate'));
			const message = failureMessages(reason as PersonaCheck, piiData);

			if (message) {
				if (forSupport) {
					return message.supportMessage;
				}
				// If no user message defined, return generic message
				return message.userMessage || failureMessages(PersonaCheck.Generic, piiData).userMessage;
			}

			// fallback to the reason itself
			return forSupport ? reason : '';
		}).filter(Boolean);
	}

	static get verificationType(): VerificationType {
		return VerificationType.Persona;
	}

}

/* eslint-disable max-len */
export function failureMessages(check: PersonaCheck, { firstName = '', lastName = '', birthDate = '' } = {}): { userMessage?: string; supportMessage: string } {
	return {
		[PersonaCheck.IDColour] : {
			userMessage    : 'The color of your ID does not match the photo on your ID. Please use a valid ID that matches the photo on your ID.',
			supportMessage : 'ID color does not match photo.',
		},
		[PersonaCheck.Blur] : {
			userMessage    : 'The image of your ID is too blurry. Please retake the photo with a clearer focus.',
			supportMessage : 'ID image is blurry.',
		},
		[PersonaCheck.IDDisallowedCountry] : {
			userMessage    : 'The country of your ID is not supported. Please use a valid ID from a supported country.',
			supportMessage : 'ID from unsupported country.',
		},
		[PersonaCheck.IDDisallowedType] : {
			userMessage    : 'The type of ID you submitted does not match the selected ID type. Please ensure you submit the correct ID type.',
			supportMessage : 'Submitted ID type does not match selected ID type.',
		},
		[PersonaCheck.Expired] : {
			userMessage    : 'The ID you submitted has expired. Please provide a valid, non-expired ID.',
			supportMessage : 'Submitted ID is expired.',
		},
		[PersonaCheck.IDMRZ] : {
			userMessage    : 'The Machine Readable Zone (MRZ) on your ID could not be detected. Please ensure the entire ID is visible and clear.',
			supportMessage : 'Missing MRZ on ID.',
		},
		[PersonaCheck.IDPOBox] : {
			userMessage    : 'A PO Box address was detected on your ID. Please provide an ID with a physical residential address.',
			supportMessage : 'PO Box address detected on ID.',
		},
		[PersonaCheck.IDPortraitClarity] : {
			userMessage    : 'The portrait on your ID is not clear enough. Please retake the photo ensuring the portrait is clearly visible.',
			supportMessage : 'Low clarity of portrait on ID.',
		},
		[PersonaCheck.IDPortrait] : {
			userMessage    : 'No portrait was detected on your ID. Please ensure your ID includes a clearly visible portrait.',
			supportMessage : 'No portrait detected on ID.',
		},
		[PersonaCheck.Generic] : {
			userMessage    : 'There was an issue with your verification. Please try again, ensuring your ID is clear and your face is clearly visible, well-lit, and matches the photo on your ID.',
			supportMessage : 'Verification issue detected.',
		},
		[PersonaCheck.MissingFirstName] : {
			userMessage    : 'The first name on your ID could not be extracted. Please ensure your first name is clearly visible on your ID.',
			supportMessage : 'Missing first name on ID.',
		},
		[PersonaCheck.MissingLastName] : {
			userMessage    : 'The last name on your ID could not be extracted. Please ensure your last name is clearly visible on your ID.',
			supportMessage : 'Missing last name on ID.',
		},
		[PersonaCheck.MissingDateOfBirth] : {
			userMessage    : 'The date of birth on your ID could not be extracted. Please ensure your date of birth is clearly visible on your ID.',
			supportMessage : 'Missing date of birth on ID.',
		},
		[PersonaCheck.MismatchFirstName] : {
			userMessage    : `The first name on your ID ${firstName} does not match the first name you provided. Please ensure your first name matches the name on your ID.`,
			supportMessage : `First name on ID (${firstName}) does not match provided first name.`,
		},
		[PersonaCheck.MismatchLastName] : {
			userMessage    : `The last name on your ID ${lastName} does not match the last name you provided. Please ensure your last name matches the name on your ID.`,
			supportMessage : `Last name on ID (${lastName}) does not match provided last name.`,
		},
		[PersonaCheck.MismatchDateOfBirth] : {
			userMessage    : `The date of birth on your ID ${birthDate} does not match the date of birth you provided. Please ensure your date of birth matches the date on your ID.`,
			supportMessage : `Date of birth on ID (${birthDate}) does not match provided date of birth.`,
		},
		// the following are only shown to support so no user message is needed
		[PersonaCheck.IDElectronicReplicaDetection]    : { supportMessage : 'Digital replica ID detected.' },
		[PersonaCheck.IDRepeat]                        : { supportMessage : 'Duplicate ID across multiple accounts detected.' },
		[PersonaCheck.IDInquiryComparison]             : { supportMessage : 'Inquiry comparison failed.' },
		[PersonaCheck.IDPaperDetection]                : { supportMessage : 'Paper ID detected.' },
		[PersonaCheck.SelfieIDComparison]              : { supportMessage : 'Selfie ID comparison failed.' },
		[PersonaCheck.SelfiePoseDetection]             : { supportMessage : 'Selfie pose detection failed.' },
		[PersonaCheck.SelfieMultipleFacesDetection]    : { supportMessage : 'Selfie multiple faces detection failed.' },
		[PersonaCheck.SelfiePoseRepeatDetection]       : { supportMessage : 'Selfie pose repeat detection failed.' },
		[PersonaCheck.SelfieSuspiciousEntityDetection] : { supportMessage : 'Selfie suspicious entity detection failed.' },
		[PersonaCheck.SelfieLivenessDetection]         : { supportMessage : 'Selfie liveness detection failed.' },
		[PersonaCheck.SelfiePublicFigureDetection]     : { supportMessage : 'Selfie public figure detection failed.' },
		[PersonaCheck.SelfieFaceCoveringDetection]     : { supportMessage : 'Selfie face covering detection failed.' },
	}[check];
}


export default null as PersonaVerifier;
