/**
 * Helper utilities for testing or authoring and maintaining system tests.
 */
import { Vue }        from '$/lib/vueExt';
import { TestEntity } from '$/entities/TestEntity';

export default {
	install,
};

export function install(vueInstance: Vue) {
	// install the data-auto-ids highlighter
	document.body.addEventListener('keydown', event => {
		if (isAutoIDHelperActivated(event)) {
			document.body.classList.add('highlight-auto-ids');
		}
	});
	document.body.addEventListener('keyup', event => {
		if (!isAutoIDHelperActivated(event)) {
			document.body.classList.remove('highlight-auto-ids');
		}
	});

	// install click-listener to extra the pageObject field reference when
	document.body.addEventListener('click', async event => {
		if (!isAutoIDHelperActivated(event) || !(event.target instanceof Element)) {
			return;
		}

		const elem = event.target.closest('[data-auto-id]');
		if (!elem) {
			return;
		}

		const autoID = elem.getAttribute('data-auto-id');
		if (!autoID) {
			console.warn('no autoID element found');
			return;
		}

		const path = (await TestEntity.getAutoIDs())[_.trim(autoID, '&')];
		if (!path) {
			console.warn(`no element found with data-auto-id: ${autoID}`);
			return;
		}

		console.log(`Element path: ${path}`);
		void navigator.clipboard.writeText(path);

		document.body.classList.remove('highlight-auto-ids');
		elem.classList.add('highlight-auto-id');
		setTimeout(() => elem.classList.remove('highlight-auto-id'), 3000);

		event.preventDefault();
		event.stopImmediatePropagation();

		vueInstance.showSuccess(path, { title : 'Code path to element copied to clipboard' });
	}, { capture : true });
}

function isAutoIDHelperActivated(event: KeyboardEvent | MouseEvent) {
	return event.metaKey && event.altKey;
}
