/**
 * Various extensions to Vue
 */
import VueOriginal from 'vue';
import type {
	PropOptions,
	PluginObject, PluginFunction,
	VueConstructor, VNode
} from 'vue';

import { BvMsgBoxOptions } from 'bootstrap-vue';

import { Component, Prop as PropOriginal, PropSync as PropSyncOriginal } from 'vue-property-decorator';
export { Watch, Component, Ref, Provide, Inject, ProvideReactive, InjectReactive, Mixins } from 'vue-property-decorator';

import formatters        from '$/lib/formatters';
import Animations        from '$/lib/widgets/animations/Animations.vue';
import externalLinks     from '$/lib/externalLinks';
import ConfirmationModal from '$/lib/widgets/ConfirmationModal.vue';

import { BaseRole }       from '$/entities/roles/BaseRole';
import { PackageFeature } from '$/entities/Package';
import { RolePermission } from '$/entities/roles/RolePermission';

export const COMPONENT_UID_KEY = '_uid';

Component.registerHooks([ 'beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate' ]);

/**
 * A Vue base class which adds some more capabilities on top of the default Vue class.
 */
export class Vue extends VueOriginal implements VueOriginal {

	/**
	 * Show an alert in a toast popup.
	 */
	showAlert(textToShow: string | Error, toastOptions = {}) {
		const text = textToShow instanceof Error ? textToShow.message : textToShow;
		this.$bvToast.toast(text, { solid : true, variant : 'danger', ...toastOptions });
	}

	showWarning(textToShow: string, toastOptions = {}) {
		this.showAlert(textToShow, { variant : 'warning', ...toastOptions });
	}

	showSuccess(textToShow: string, toastOptions = {}) {
		this.showAlert(textToShow, { variant : 'success', ...toastOptions });
	}

	showAnimation = (Animations as any).showAnimation;

	get $format() {
		return formatters;
	}

	/**
	 * Displays a confirmation box
	 */
	async showConfirm(message: string | VNode[] | Vue, options?: BvMsgBoxOptions | { html?: boolean; okOnly?: boolean }): Promise<boolean> {
		// check if the input param is a confirmation-modal component
		if ((message as any).$vnode?.componentOptions.Ctor.name === 'ConfirmationModal') {
			return (message as unknown as ConfirmationModal).showConfirm();
		}

		return ConfirmationModal.showConfirm(this, message as string, options);
	}

	/**
	 * Displays a confirmation box prompting the user to confirm abandoning unsaved changes.
	 */
	async showConfirmUnsavedChanges(): Promise<boolean> {
		return this.showConfirm('There are unsaved changes.', {
			title         : 'Are you sure you want to leave?',
			okTitle       : 'Discard changes',
			cancelTitle   : 'Continue editing',
			okVariant     : 'outline-default',
			cancelVariant : 'primary',
			footerClass   : 'justify-content-between flex-row-reverse',
		});
	}

	/**
	 * The current role.
	 */
	get $role() {
		return BaseRole.current;
	}

	/**
	 * The current role's user.
	 */
	get $user() {
		return this.$role?.user;
	}

	/**
	 * The current role's organization.
	 */
	get $org() {
		return this.$role?.org;
	}

	get $feature() {
		return PackageFeature;
	}

	get $permission() {
		return RolePermission;
	}

	// Even though lodash is global now, it's not accessible in vue templates, this makes it accessible
	get _() {
		return _;
	}

	get $externalLinks() {
		return externalLinks;
	}

	/**
	 * @return the closest ancestor component that matches the given matcher function.
	 */
	$closestComponent(matcher: (Vue) => boolean) {
		let parent = this.$parent;
		while (parent) {
			if (matcher(parent)) {
				break;
			}
			parent = parent.$parent;
		}
		return parent;
	}

	// This needs to be proxied to original so that Plugins install correctly
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	static use(plugin: PluginObject<any> | PluginFunction<any>, ...options: any[]): VueConstructor {
		// eslint-disable-next-line prefer-spread, prefer-rest-params
		return VueOriginal.use.apply(VueOriginal, arguments);
	}

	/**
	 * Registers error handlers for Vue and Window console errors and displays them as an alert.
	 */
	static installErrorToasts(vue: Vue) {
		// flash any console error messages to signal that something just went wrong
		window.addEventListener('error', error => {
			vue.showAlert(error?.message || error as unknown as string, { title : 'Console Error' });
			console.error(error);
		});

		const oldErrorHandler   = Vue.config.errorHandler;
		Vue.config.errorHandler = (error, vm, info) => {	// too bad Vue doesn't have the standard addEventListener for error handlers
			vue.showAlert(error?.message || error as unknown as string, { title : 'Vue Error' });
			console.error(error); // also dump error to console since by default vue swallows the error
			oldErrorHandler?.(error, vm, info);
		};

		const oldWarnHandler   = Vue.config.warnHandler;
		Vue.config.warnHandler = (message, vm, trace) => {
			vue.showWarning(message, { title : 'Vue Warning' });
			console.warn(message + trace);
			oldWarnHandler?.(message, vm, trace);
		};
	}

}

/**
 * HACK: override the @Prop decorator to add type: Boolean when reflect detected the type of the property being boolean
 * Without this, the presence of the prop when using the component (i.e <component-name prop-name>) wouldn't insinuate prop-name = true
 */
export function Prop(options: PropOptions | Class[] | Class = {}) {
	return function(target, propertyKey: string) {
		adjustPropType(options, target, propertyKey);
		PropOriginal(options).apply(this, arguments); // eslint-disable-line prefer-rest-params
	};
}

export function PropSync(propName: string, options: PropOptions | Class[] | Class = {}) {
	return function(target, propertyKey: string) {
		adjustPropType(options, target, propertyKey);
		PropSyncOriginal(propName, options).apply(this, arguments); // eslint-disable-line prefer-rest-params
	};
}

function adjustPropType(options: PropOptions | Class[] | Class, target, propertyKey: string) {
	if (typeof options === 'function' || Array.isArray(options)) {
		return; // ignore Class | Class[] pattern
	}

	const designType = Reflect.getMetadata('design:type', target, propertyKey);
	if (designType === Boolean && !options.type) {
		options.type = Boolean;
	}
}
