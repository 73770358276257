
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"-.05 -2 40 40"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M33.121 35.977H6.723A6.66 6.66 0 0 1 .918 32.66a6.649 6.649 0 0 1 .004-6.683l13.2-22.602C15.308 1.289 17.522 0 19.921 0s4.613 1.29 5.8 3.375l13.2 22.602a6.649 6.649 0 0 1 .004 6.683 6.66 6.66 0 0 1-5.805 3.317zM17.523 5.375 4.32 27.977a2.675 2.675 0 0 0 .02 2.707 2.67 2.67 0 0 0 2.383 1.293H33.32a2.732 2.732 0 0 0 2.403-4L22.32 5.375a2.729 2.729 0 0 0-4.797 0zm0 0"}}),_c('path',{attrs:{"d":"M17.121 27.375a1.938 1.938 0 0 1 0-2.8l3.402-3.4h-6c-.8-.01-1.52-.5-1.824-1.237a2 2 0 0 1 .422-2.16l7.2-7.204a1.985 1.985 0 0 1 2.738.067c.75.746.777 1.953.062 2.734l-3.8 3.8h6a2.001 2.001 0 0 1 1.402 3.398l-6.801 6.802a1.933 1.933 0 0 1-2.8 0zm0 0"}})])
          )
        }
      }
    