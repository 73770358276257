
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"-9 -0.35 40 40"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"m1.895 8.422-.051.062a6.627 6.627 0 0 0-1.508 3.977 6.535 6.535 0 0 0 1.379 4.426l.062.074a8.417 8.417 0 0 0 3.473 2.445c1.426.535 2.89.957 4.383 1.266.855.168 1.699.379 2.535.633.84.203 1.55.39 2.164.57a8.769 8.769 0 0 1 2.191.98c.446.278.82.649 1.106 1.082a3.44 3.44 0 0 1 .45 1.786v.086c.023.75-.2 1.484-.634 2.097a5.226 5.226 0 0 1-2.37 1.73 10.95 10.95 0 0 1-4.263.716h-.12c-1.583.05-3.157-.27-4.594-.938a5.38 5.38 0 0 1-1.91-1.656l-1.102-1.543-3.094 2.2 1.106 1.542a9.239 9.239 0 0 0 3.324 2.86c1.512.699 3.133 1.128 4.793 1.261V39.2h3.8v-5.187a14.084 14.084 0 0 0 3.41-.832 8.973 8.973 0 0 0 4.067-3.016 7.193 7.193 0 0 0 1.387-4.426 7.296 7.296 0 0 0-.93-3.699 7.455 7.455 0 0 0-2.36-2.39 12.521 12.521 0 0 0-3.167-1.41c-.672-.188-1.434-.395-2.293-.63-.719-.191-1.5-.382-2.715-.632a26.166 26.166 0 0 1-3.805-1.102 4.614 4.614 0 0 1-1.867-1.297 2.768 2.768 0 0 1-.59-1.902v-.192c.035-.593.258-1.16.633-1.62a4.953 4.953 0 0 1 2.18-1.454 13.077 13.077 0 0 1 7.023-.168 6.267 6.267 0 0 1 1.957.93c.399.277.743.629 1.012 1.035l1.055 1.582 3.168-2.098-1.051-1.578a7.612 7.612 0 0 0-2-2.047A10.013 10.013 0 0 0 15 5.586a14.57 14.57 0 0 0-2.004-.406V0H9.207v5.129c-1.172.101-2.328.34-3.45.707a8.77 8.77 0 0 0-3.862 2.586zm0 0"}})])
          )
        }
      }
    