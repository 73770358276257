

























import { BFormInput } from 'bootstrap-vue';

import { Component, Prop } from '$/lib/vueExt';
import VueValidationMixin  from '$/lib/mixins/VueValidationMixin';

@Component({
	inheritAttrs : false,
	// Use Bootstrap Vue's BFormInput to prevent validation duplication
	components   : { BFormInput },
	model        : { event : 'update:value' },
})
export default class FormCurrency extends VueValidationMixin {

	@Prop()
	readonly value: number;

	@Prop({ default : 'CAD' })
	readonly currency: string;

	@Prop({ default : false })
	readonly plaintext: boolean;

	/**
	 * The size of the form is extracted from $attrs to be applied to both the InputGroup and the FormInput
	 */
	@Prop({ default : 'md' })
	readonly size: string;

	get symbol() {
		return Intl.NumberFormat('en-CA', { currency : this.currency, style : 'currency' }).formatToParts(0)[0].value;
	}

	get localValue() {
		return this.value;
	}
	set localValue(v: number | string) {
		this.$emit('update:value', (v === '' || _.isNil(v) || isNaN(Number(v))) ? undefined : Number(v));
	}

	/**
	 * HACK: input[pattern] doesn't autocorrect the value so round the value string to two decimal places and cast back to Number
	 */
	formatter(value: string) {
		const number = Number(value);
		if (value === '' || isNaN(number)) {
			return value;
		}
		return number.toFixed(2);
	}

}
