import Validator from 'validator';
import Moment    from 'moment';
import { parsePhoneNumberFromString, isValidPhoneNumber, isPossiblePhoneNumber } from 'libphonenumber-js';

import { Country }                         from '$/lib/Address';
import { getParentClass, hasMixin, Mixin } from '$/lib/utils';
import { normalizeIssue, ValidationError, ValidationIssue, ValidationType } from '$/lib/validation/ValidationIssue';

// Used to validate firstName, middleName, lastName
// SHOULD DO - just build this into a Validation type
export const nameChars = " ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyzàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð-'æœ";

export interface ValidationRules {
	notEmpty?: PossibleMessage<boolean>;
	allowChars?: PossibleMessage<string>;
	countryCode?: PossibleMessage<boolean>;
	number?: PossibleMessage<boolean>;
	date?: PossibleMessage<boolean>;
	email?: PossibleMessage<boolean>;
	enum?: PossibleMessage<Record<string, any>>;
	regex?: PossibleMessage<RegExp>;
	required?: PossibleMessage<boolean | (() => boolean)>;
	maxLength?: PossibleMessage<number>;
	minLength?: PossibleMessage<number>;
	min?: PossibleMessage<number>;
	max?: PossibleMessage<number>;
	phoneNumber?: PossibleMessage<boolean | { strict?: boolean; country?: Country | (() => PossiblePromise<Country>)}>;
	postalCode?: PossibleMessage<boolean>;
	url?: PossibleMessage<boolean>;
	trimmed?: PossibleMessage<boolean>;

	recursive?: Record<string, ValidationRules> | boolean;
	forEach?: ValidationRules | boolean; // boolean for the special shortcut case of 'forEach : true' -> `forEach : { recursive : true }`
	uniqueBy?: PossibleMessage<string>;

	custom?: PossibleArray<CustomValidation>;
}

const VALIDATION_FUNCTIONS_PROPERTY = '__validators__';

export const Validators = {

	notEmpty({ value: shouldNotBeEmpty, issue }: NormalizedRuleParams) {
		ensureType(shouldNotBeEmpty, 'boolean');
		issue ||= new ValidationError('cannot be empty');

		return ifEnabled(shouldNotBeEmpty, function(value) {
			// let null or undefined pass. Not a concern of this validator.
			return typeof value === 'string' && !!value && value.trim() === '' ? issue : '';
		});
	},

	allowChars({ value: chars, issue }: NormalizedRuleParams) {
		ensureType(chars, 'string');
		return function(value) {
			for (const char of String(value).split('')) {
				if (!chars.includes(char)) {
					return issue || new ValidationError(`'${value}' cannot include '${char}'`);
				}
			}
			return '';
		};
	},

	countryCode({ value: isEnabled, issue }: NormalizedRuleParams = { value : true }) {
		ensureType(isEnabled, 'boolean');
		issue ||= new ValidationError('must be a valid 2-letter country code');
		return ifEnabled(isEnabled, function(value) {
			return isNil(value) || Validator.isISO31661Alpha2(value) ? '' : issue;
		});
	},

	number({ value: isEnabled, issue }: NormalizedRuleParams) {
		ensureType(isEnabled, 'boolean');
		issue ||= new ValidationError('invalid number format');

		return ifEnabled(isEnabled, function(value, property, { autofix = false } = {}) {
			const number = Number(value);

			// just cast to number if value was in string format
			if (autofix && !isNaN(number)) {
				this[property] = number;
				return '';
			}

			return isNaN(number) ? issue : '';
		});
	},

	date({ value: isEnabled, issue }: NormalizedRuleParams) {
		ensureType(isEnabled, 'boolean');
		issue ||= new ValidationError('invalid date format');

		return ifEnabled(isEnabled, function(value, property, { autofix = false } = {}) {
			if (!value || (value instanceof Date && !isNaN(value.valueOf()))) {
				return '';
			}

			const moment = Moment(value, Moment.ISO_8601, true);
			if (autofix && moment.isValid()) {
				this[property] = moment.toDate();
				return '';
			}

			return moment.isValid() ? '' : issue;
		});
	},

	email({ value: isEnabled, issue }: NormalizedRuleParams = { value : true }) {
		ensureType(isEnabled, 'boolean');
		issue ||= new ValidationError('must be a valid email address');
		return ifEnabled(isEnabled, function(value) {
			return isNil(value) || Validator.isEmail(value) ? '' : issue;
		});
	},

	enum({ value: enumType, issue }: NormalizedRuleParams) {
		const enumValues   = Object.values(enumType);
		issue            ||= new ValidationError(`must be one of: "${enumValues.join('", "')}"`);

		return function(value, property, { autofix = false } = {}) {
			if (autofix && typeof value === 'string') {
				value          = enumValues.find(enumValue => _.areEqualCaseless(enumValue as string, value)) ?? value;
				this[property] = value;
			}
			return isNil(value) || enumValues.includes(value) ? '' : issue;
		};
	},

	trimmed({ value: isEnabled, issue }: NormalizedRuleParams) {
		ensureType(isEnabled, 'boolean');
		issue ||= new ValidationError('must not start or end with white spaces');
		return ifEnabled(isEnabled, function(value, property, { autofix = false } = {}) {
			if (autofix && typeof value === 'string') {
				value          = Validator.trim(value);
				this[property] = value;
			}
			value = value || '';
			return value.length === Validator.trim(value).length ? '' : issue;
		});
	},

	regex({ value: regex, issue }: NormalizedRuleParams) {
		if (!(regex instanceof RegExp)) {
			throw new Error(`expected RegExp value, instead got ${regex}`);
		}
		issue ||= new ValidationError(`must match ${regex}`);

		return function(value) {
			return value && !regex.test(String(value)) ? issue : '';
		};
	},

	required({ value: isRequired, issue }: NormalizedRuleParams) {
		ensureType(isRequired, [ 'boolean', 'function' ]);
		issue ||= new ValidationError('this is a required field and must not be empty');

		return function(value) {
			return (
				(typeof isRequired === 'boolean' && isRequired)
				|| (typeof isRequired === 'function' && isRequired.call(this))
			)
			&& isNil(value)
				? issue : '';
		};
	},

	maxLength({ value: maxLength, issue }: NormalizedRuleParams) {
		if (typeof maxLength !== 'number' || maxLength < 1) {
			throw new Error('maxLength value param must be a number greater or equal to 1');
		}

		return function(value) {
			if (value === undefined || value === null) {
				return '';
			}

			if (!value.hasOwnProperty('length')) {
				return new ValidationError('value does not have a length property');
			}
			return value.length <= maxLength ? '' : issue || new ValidationError(`must be at most ${maxLength} ${typeof value === 'string' ? 'characters' : 'items'}`);
		};
	},

	minLength({ value: minLength, issue }: NormalizedRuleParams) {
		if (typeof minLength !== 'number' || minLength < 1) {
			throw new Error('minLength param must be a number greater or equal to 1');
		}

		return function(value: any) {
			if (value === undefined) {
				return '';	// undefined values are okay
			}

			if (value === null || !value.hasOwnProperty('length')) {
				return new ValidationError('value does not have a length property');
			}
			return value.length >= minLength ? '' : issue || new ValidationError(`must be at least ${minLength} ${typeof value === 'string' ? 'characters' : 'items'}`);
		};
	},

	min({ value : minValue, issue }: NormalizedRuleParams) {
		if (typeof minValue !== 'number') {
			throw new Error('minValue value param must be a number');
		}

		return function(value) {
			if (value === undefined || value === null) {
				return '';
			}

			return value >= minValue ? '' : issue || new ValidationError(`${value} must be at least: ${minValue}`);
		};
	},

	max({ value : maxValue, issue }: NormalizedRuleParams) {
		if (typeof maxValue !== 'number') {
			throw new Error('maxValue value param must be a number');
		}

		return function(value) {
			if (value === undefined || value === null) {
				return '';
			}

			return value <= maxValue ? '' : issue || new ValidationError(`${value} must be at most: ${maxValue}`);
		};
	},

	/**
	 * Specify strict to enforce more rigorous validation of phone number (e.g. ensuring area code is an actual code, not 555 or other invalid code)
	 * Using strict is preferred. The main use for non-strict validation is for tenants.
	 * If a tenant has a phone number entered from before this strict validation existed, the LL won't be able to save that tenant's lease
	 * With strict validation being used, libphonenum-js needs regularly updated to keep up to date as new valid phone number ranges are added
	 */
	phoneNumber({ value, issue }: NormalizedRuleParams) {
		if (typeof value === 'boolean') {
			value = { strict : value };
		}
		if (!value.country) {
			value.country = function() {
				return this?.country  || Country.CA;
			};
		}
		const { strict, country } = value as { strict: boolean; country: Country.CA | (() => Country.CA) };

		issue ||= new ValidationError('must be a valid phone number');

		return async function(value, property, { autofix = false } = {}) {
			// blank values are allowed
			if (isNil(value)) {
				return '';
			}

			const actualCountry = typeof country === 'function' ? await country.call(this) || Country.CA : country;

			if (autofix) {
				const parsed = parsePhoneNumberFromString(value, actualCountry)?.number;
				if (parsed) {
					this[property] = value = parsed;
				}
			}

			// should be in E.164 format, so running the value through the parser shouldn't change it
			const isInE164Format = parsePhoneNumberFromString(value, actualCountry)?.number === value;
			return (strict ? isValidPhoneNumber(value) : isPossiblePhoneNumber(value)) && isInE164Format ? '' : issue;
		};
	},

	postalCode({ value: isEnabled, issue }: NormalizedRuleParams = { value : true }) {
		ensureType(isEnabled, 'boolean');
		issue ||= new ValidationError('must be a valid postal code');
		return ifEnabled(isEnabled, function(value, property, { autofix = false } = {}) {
			// blank values are allowed
			if (isNil(value)) {
				return '';
			}

			if (autofix) {
				// remove all whitespace characters
				if (typeof value === 'string') {
					this[property] = value = value.replace(/\s/g, '');
					// but put back the middle single space for Canadian postal codes
					if (Validator.isPostalCode(value, 'CA')) {
						this[property] = value = `${value.substr(0, 3)} ${value.substr(3)}`;
					}
				}
			}

			// SHOULDDO: make this smarter about the locale
			return Validator.isPostalCode(value, 'CA') || Validator.isPostalCode(value, 'US') ? '' : issue;
		});
	},

	url({ value: isEnabled, issue }: NormalizedRuleParams = { value : true }) {
		ensureType(isEnabled, 'boolean');
		issue ||= new ValidationError('must be a valid url');
		return ifEnabled(isEnabled, function(value) {
			return isNil(value) || Validator.isURL(value) ? '' : issue;
		});
	},

	custom(params: NormalizedRuleParams | PossibleArray<CustomValidation>) {
		const validators = _.castArray(typeof params === 'function' || Array.isArray(params) ? params : params.value) as CustomValidation[];
		return async function(value, property: string, options?: ValidationOptions) {
			for (const validator of validators) {
				const results = await validator.call(this, value, property, options);
				if (!_.isNil(results) && results !== '') {
					return results;	// implicitly return the first error from the first validator
				}
			}
			return '';
		};
	},

	recursive(possibleExtraRules: Record<string, ValidationRules> | boolean) {
		return ifEnabled(!!possibleExtraRules, async function(value, property, options) {
			let result = {};
			if (value && hasMixin(value.constructor, ValidationMixin, { includeAncestors : true })) {
				let extraValidators = {};
				if (typeof possibleExtraRules === 'object') {
					extraValidators = _.mapValues(possibleExtraRules, (rules, property) => getValidatorsFromRules(rules, value.constructor, property));
				}
				result = await (value as ValidationMixin).getValidationIssues({ ...options, extraValidators });
			}
			return Object.keys(result).length ? result : '';
		});
	},

	forEach(rules: ValidationRules | boolean, clazz, property) {
		// a shorthand to trigger recursive validation on arrays of objects with the validation mixin
		if (typeof rules === 'boolean') {
			rules = { recursive : true };
		}

		const validators = getValidatorsFromRules(rules, clazz, property);

		return async function(value, property, options) {
			if (isNil(value)) {
				return '';
			}

			if (!Array.isArray(value)) {
				return `expected an array, received ${typeof value}`;
			}

			const result = {};
			for (let index = 0; index < value.length; index++) {
				for (const validator of validators) {
					const output = await captureValidationOutput.call(this, validator, value[index], property, options);
					addToResult(result, index, output, options.type);
				}
			}

			return Object.keys(result).length ? result : '';
		};
	},

	uniqueBy({ value: key, issue }: NormalizedRuleParams) {
		issue ||= new ValidationError('there are duplicate items present');
		return function(value: any[]) {
			return value.map(item => item[key]).find((value, index, array) => array.indexOf(value) !== index) ? issue : '';
		};
	},
};

/**
 * @Decorator that is intended to be used on class fields for classes with the ValidationMixin.
 */
export default function Validate(rules: ValidationRules) {
	return function(clazz, property: string) {
		if (!clazz.constructor.hasOwnProperty(VALIDATION_FUNCTIONS_PROPERTY)) {
			clazz.constructor[VALIDATION_FUNCTIONS_PROPERTY] = {};
		}
		const validationFunctions: Dictionary<CustomValidation[]> = clazz.constructor[VALIDATION_FUNCTIONS_PROPERTY];
		validationFunctions[property] ??= [];
		validationFunctions[property].push(...getValidatorsFromRules(rules, clazz, property));

		// add mixin if not already added
		if (!hasMixin(clazz.constructor, ValidationMixin, { includeAncestors : true })) {
			Mixin(ValidationMixin)(clazz.constructor);
		}
	};
}

/**
 * Mixin class intended to be mixed into any other class to gain validation behaviour.
 */
export class ValidationMixin {

	/**
	 * @returns any validation errors for the given property of this entity.
	 */
	getValidationErrors(property: string, options?: ValidationOptions): Promise<PropertyResult<string>>;

	/**
	 * Validate the properties in this entity and returns any properties that have errors with them.
	 */
	getValidationErrors(): Promise<ValidationResult>;
	getValidationErrors(options: ValidationOptions): Promise<ValidationResult>;

	async getValidationErrors(optionsOrProperty?: string | ValidationOptions, options: ValidationOptions = {}) {
		if (typeof optionsOrProperty === 'object') {
			return this.getValidationIssues({ ...optionsOrProperty, type : ValidationType.Error }) as unknown as Promise<ValidationResult>;
		}
		return this.getValidationIssues(optionsOrProperty, { ...(options ?? {}), type : ValidationType.Error });
	}

	getValidationIssues(): Promise<Dictionary<PropertyResult<string | ValidationIssue>>>;
	getValidationIssues(options: ValidationOptions): Promise<Dictionary<PropertyResult<string | ValidationIssue>>>;
	getValidationIssues(property: string, options?: ValidationOptions): Promise<PropertyResult<string | ValidationIssue>>;
	async getValidationIssues(optionsOrProperty?: string | ValidationOptions, options: ValidationOptions = {}) {
		let properties: string[];
		let originalProperty;

		if (typeof optionsOrProperty === 'object') {
			options = optionsOrProperty;
		}
		else if (typeof optionsOrProperty === 'string') {	// it's a property
			originalProperty = optionsOrProperty;
			properties       = [ optionsOrProperty ];
		}

		options ||= {};

		const { firstErrorOnly } = options;
		const result             = {};

		const rules: Map<string, Validation[]> = new Map();
		for (let clazz = this.constructor as Class; clazz; clazz = getParentClass(clazz)) {
			_.forOwn((clazz as any)[VALIDATION_FUNCTIONS_PROPERTY], (value, key) => rules.set(key, value));
		}

		properties ||= Array.from(rules.keys());

		for (const property of properties) {
			let validators = rules.get(property);
			if (options.extraValidators?.[property]) {
				validators = [ ...validators, ...options.extraValidators[property] ];
			}
			if (!validators || validators.length === 0) {
				continue;
			}

			for (const validator of validators) {
				const output = await captureValidationOutput.call(this, validator, this[property], property, options);
				addToResult(result, property, output, options.type);

				if (firstErrorOnly && hasError(result[property])) {
					break;
				}
			}

			if (firstErrorOnly && hasError(result[property])) {
				break;
			}
		}

		return originalProperty ? (result[originalProperty] ?? []) : result;
	}

	/**
	 * runs the entities through validation to fix any error automatically
	 * @param property used to only fix errors on the specified property
	 */
	async autofixValidationErrors(property?: string) {
		await this.getValidationErrors(property, { autofix : true });
	}

	/**
	 * @returns true if all fields of this entity pass their validation checks
	 */
	async isValid(property?: string): Promise<boolean> {
		if (property) {
			return Object.keys(await this.getValidationErrors(property)).length === 0;
		}
		return Object.keys(await this.getValidationErrors({ firstErrorOnly : true })).length === 0;
	}

	/**
	 * @returns true if just one field of this entity fails it's validation check
	 */
	async isNotValid(property?: string): Promise<boolean> {
		return !(await this.isValid(property));
	}

}

/**
 * Normalize all forms of values passed to a rule
 * With the exception of 'forEach' as it itself is another object of rules for each item in the field array
 */
function normalizeRuleParams(ruleParams: ValidationRules[keyof ValidationRules], ruleName: keyof ValidationRules) {
	// skip certain rules as it is a special case for supporting array fields
	if ([ 'forEach', 'recursive' ].includes(ruleName)) {
		return ruleParams;
	}

	// skip if rule params are already normalized
	if (isPossibleMessage(ruleParams)) {
		const normalizedParams: NormalizedRuleParams = { value : ruleParams.value };
		if (ruleParams.message) {
			normalizedParams.issue = normalizeIssue(ruleParams.message);
		}
		return normalizedParams;
	}

	return { value : ruleParams };
}

/**
 * Generates an array of validators from a POJO of rules
 */
function getValidatorsFromRules(rules: ValidationRules, clazz, property: string): CustomValidation[] {
	return _.map(rules, (ruleParams: ValidationRules[keyof ValidationRules], ruleName: keyof ValidationRules) => {
		ruleParams = normalizeRuleParams(ruleParams, ruleName);

		const validator: CustomValidation = Validators[ruleName].call(clazz.constructor, ruleParams, clazz, property);
		if (typeof validator !== 'function') {
			throw new Error(`validator is not a function: ${ruleName}`);
		}

		return validator;
	});
}

/**
 * Calls the validator function with the given value and returns its output
 */
async function captureValidationOutput(validator: Validation, value: any, property?: string, options?: ValidationOptions): Promise<ValidationResult> {
	try {
		return await validator.call(this, value, property, options);
	}
	catch (error) {
		return error.message || error;
	}
}

/**
 * If the validator had an output add it to result, otherwise keep the result clean
 */
function addToResult(result, key: string | number, output?: ValidationResult, type?: ValidationType) {
	if (!output || (output instanceof ValidationIssue && type && output.type !== type)) {
		return;
	}

	result[key] = _(result[key] ||= [])
		.concat(type && output instanceof ValidationIssue ? output.message : output)
		.sortBy(issue => issue.type === 'warning')  // force warnings to end of array
		.valueOf()
	;
}

function hasError(results) {
	return _.some(results, result => {
		if (result instanceof ValidationIssue) {
			return result.type === ValidationType.Error;
		}

		if (typeof result === 'string') {
			return true;
		}

		return hasError(result);
	});
}

type PrimitiveType = ('string' | 'boolean' | 'function' | 'number' | 'bigint' | 'symbol' | 'undefined' | 'object');

function ensureType(value, types: PrimitiveType | PrimitiveType[]) {
	types = _.castArray(types) as PrimitiveType[];

	if (!types.includes(typeof value)) {
		throw new Error(`expected value to be one of "${types.join(', ')}", instead got: ${typeof value}`);
	}
}

function ifEnabled(isEnabled: boolean, func) {
	return isEnabled ? func : () => '';
}

function isNil(value) {
	return value === undefined || value === null || value === '';
}

interface NormalizedRuleParams {
	value: any;        // the main 'concern' of the validation function factory (e.g. for regex the RegExp to compare against)
	issue?: ValidationIssue;  // a custom error message
}

/**
 * Validation results for a property
 * Could be a combination of the following in the form of an array:
 * T: from validating the field directly
 * Dictionary<T>: from validating a single nested field
 */
type PropertyResult<T> = (T | Dictionary<T>)[];

/**
  * Validation result for objects that have the Validation mixin
  */
export type ValidationResult = Dictionary<PropertyResult<string>>

interface ValidationOptions {
	/**
	 * If true, returns only the first error for the given/first property.
	 */
	type?: ValidationType;

	firstErrorOnly?: boolean;

	autofix?: boolean;

	/**
	 * Extra validators map, keyed by property names.
	 */
	extraValidators?: Record<string, Validation[]>;
}

export type PossibleMessage<T> = T | { value: T; message?: string };
function isPossibleMessage<T>(possibleMessage: PossibleMessage<T>): possibleMessage is { value: T; message?: string } {
	return !!(possibleMessage as {value: T}).value;
}

type Validation = (value: any, key?: string, options?: ValidationOptions) => PossiblePromise<PropertyResult<string | ValidationIssue>>;

export type CustomValidation = (value: any, key?: string, options?: ValidationOptions) => PossiblePromise<string | ValidationIssue>;
