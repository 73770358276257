import Moment                             from 'moment';
import JSON5                              from 'json5';		// relaxed parser that, among other things, parses comments in the JSON text
import { FindOperator, ValueTransformer } from 'typeorm';
import { JSONable }                       from '$/entities/lib/JSONable';

/**
 * Transformer for the @Column's transform property for type : `date` which only holds the date portion of a full timestamp.
 * SHOULDDO: figure out how to automatically add to @Columns with type : 'date'
 */
export const DayTransformer: ValueTransformer = {
	from : (dbValue: string | Date | number): Date => {
		if (!dbValue) {
			return null;
		}
		if (typeof dbValue === 'string') {
			// assume it starts with YYYY-MM-DD
			return new Date(`${dbValue.substr(0, 10)}T12:00:00Z`);
		}

		if (typeof dbValue === 'number') {
			dbValue = new Date(dbValue);
		}

		if (!(dbValue instanceof Date)) {
			throw new Error(`unknown dbValue for date: ${dbValue}`);
		}

		dbValue.setUTCHours(12, 0, 0, 0);	// set the time to 12:00pm so that any timezone adjustments keep the same day
		return dbValue;
	},
	to : value => {
		if (!value) {
			return null;
		}

		if (typeof value === 'string') {
			value = Date.parse(value);
		}

		if (typeof value === 'number') {
			value = new Date(value);
		}

		if (value instanceof Date) {
			value.setUTCHours(12, 0, 0, 0); // set the time to 12:00pm so that any timezone adjustments keep the same day
			return Moment(value).utc().format('YYYY-MM-DD');
		}

		return value;
	},
};

/**
 * Transformer for the @Column's types of datetime.
 */
export const DateTransformer: ValueTransformer = {
	from : (dbValue: any): Date => {
		if (!dbValue) {
			return null;
		}
		if (typeof dbValue === 'string') {
			dbValue = Date.parse(dbValue);
		}

		if (typeof dbValue === 'number') {
			dbValue = new Date(dbValue);
		}

		return dbValue;
	},
	to : value => {
		if (!value) {
			return value;
		}

		if (typeof value === 'string') {
			value = Date.parse(value);
		}

		if (typeof value === 'number') {
			value = new Date(value);
		}

		if (value instanceof Date) {
			return Moment(value).format('YYYY-MM-DDTHH:mm:ss.SSSS');
		}

		return value;
	},
};

/**
 * Transformer for the @Column's types of boolean since MySQL doesn't support it natively.
 */
export const BooleanTransformer: ValueTransformer = {
	from : (dbValue: number): boolean => !!dbValue,
	to   : (value): number => value ? 1 : 0,
};

/**
 * Transformer for the @Column's types of boolean (with nullable = true) since MySQL doesn't support it natively.
 */
export const BooleanNullableTransformer: ValueTransformer = {
	from : (dbValue: number): boolean => dbValue === null ? null : !!dbValue,
	to   : (value): number => {
		if (value === null) {
			return null;
		}
		return value ? 1 : 0;
	},
};

/**
 * JSON parser
 * @param strict    if set to false, it allows relaxed json parsing with comments allowed (default: true)
 * @param jsonable  pass in a class with JSONable mixed in that defines the structure of the JSON
 */
export function JSONTransformer({ strict = true, jsonable = null } = {}): ValueTransformer {
	return {
		from : function(dbValue): any {
			if (jsonable) {
				return (jsonable as typeof JSONable).fromJSON(dbValue, { strict });
			}
			return typeof dbValue === 'string' ? (strict ? JSON : JSON5).parse(dbValue) : dbValue;
		},
		to : value => jsonable && !(value instanceof FindOperator) ? JSONable.toJSON(value) : value,
	};
}

/**
 * Decimal parser
 * typeorm by default transformers columns of type decimal to string
 */
export const DecimalTransformer = {
	from : function(dbValue): any {
		return dbValue === null || dbValue === undefined ? null : parseFloat(dbValue);
	},
	to : function(value) {
		return value;
	},
};
