import { Mixin }                     from '$/lib/utils';
import Validate, { ValidationMixin } from '$/lib/Validate';
import { Country, CountryRegions, getRegionLabel, normalizeStreetSuffix } from '$/lib/Address';

import { Field, JSONable } from '$/entities/lib/JSONable';

export interface StreetParams {
	unitNumber?: string;
	number: string;
	nameSuffixDirection: string;
}

export const levelUnitIdentifiers = [
	'lower level', 'lower unit', 'upper level', 'upper unit', 'upstairs unit', 'basement unit', 'basement', 'bsmt', 'lower', 'upper', 'upstairs',
];
export const unitNumberIdentifiers = [ '#', 'unit', 'apt', 'suite' ];

// eslint-disable-next-line
export interface Address extends ValidationMixin {}

@Mixin(JSONable)
export class Address {

	@Field()
	@Validate({
		trimmed   : true,
		maxLength : 150,
		regex     : { value : /\d+[a-zA-Z]? .+/, message : 'must start with a number followed by a street name' },
		custom    : [ checkForAppendedProvince, checkForInvalidChars, checkForLevelUnit, checkForUnitNumber, streetNormalization ],
	})
	street: string = '';

	@Field()
	@Validate({ trimmed : true, maxLength : 50, custom : checkForAppendedProvince })
	city: string = '';

	@Field()
	@Validate({ custom : checkProvinceCode, trimmed : true, maxLength : 50 })
	province: string = '';

	@Field()
	@Validate({  enum : Country, countryCode : true, trimmed : true })
	country: Country = '' as Country;

	@Field()
	@Validate({ postalCode : true, trimmed : true })
	postalCode: string = '';

	@Field()
	@Validate({ trimmed : true, maxLength : 50 })
	unitNumber: string = '';

	@Field()
	@Validate({ trimmed : true, maxLength : 50 })
	county: string = '';

	constructor(address?: Partial<Address>) {
		Object.assign(this, address);
	}

	/**
	 * Expected output:
	 * 123 Jarvis Street East
	 * 101-123 Jarvis Street East
	 * #101-123 Jarvis Street East
	 * Results are meant to be shown to user for confirmation.
	 */
	get streetSet(): StreetParams {
		let unitNumber = '';
		let number     = '';

		const streetArray = this.street.split(' ');

		const possibleUnit = streetArray.shift().split('-');
		if (possibleUnit[1]) {
			unitNumber = possibleUnit[0].replace('#', '');
			number     = possibleUnit[1];
		}
		else {
			number = possibleUnit[0];
		}

		return {
			unitNumber,
			number,
			nameSuffixDirection : _.compact(streetArray).join(' '),
		};
	}

	/**
	 * @param {Boolean} [street=true] if false, does not include any part of the street address in the result
	 * @param {Boolean} [city=true]   if false, does not include the city name in the result
	 * @param {Boolean} [region=true] if false, does not include the province, country or postal code
	 */
	format({ street = true, city = true, region = true } = {}): string {
		return _.compact([
			street ? _.compact([ this.unitNumber, this.street	]).join(' - ') : undefined,
			city   ? this.city       : undefined,
			region ? this.province   : undefined,
			region ? this.postalCode : undefined,
			region ? this.country    : undefined,
		]).join(', ');
	}

	get isCanadian() {
		return this.country === Country.CA;
	}

	get isAmerican() {
		return this.country === Country.US;
	}

}

function checkProvinceCode(this: Address, value, property: string, { autofix = false } = {}): string {
	const country = this.country;
	const regions = Object.keys(CountryRegions[country] ?? {});
	if (!value || !regions.length) {
		return '';
	}

	if (autofix && typeof value === 'string') {
		this[property] = value = regions.find(region => _.areEqualCaseless(region, value));
	}
	return regions.includes(value) ? '' : `must be a valid 2-letter ${getRegionLabel(country)} code`;
}

function checkForInvalidChars(this: Address, value: string): string {
	if (!value) {
		return;
	}

	const matches = value.match(/[^0-9a-zA-ZÀ-ÿ.,'#:-\s\\/()]/);
	if (matches) {
		return `character not allowed: ${matches[0]}`;
	}
}

/**
 * Checks that the province/state is not appended to the city name (which sometimes tends to happen)
 */
function checkForAppendedProvince(this: Address, value): string {
	if (!value || !value.includes(',')) {
		return '';
	}

	const allRegions = _.flatMap(CountryRegions, regions => ([ ...Object.keys(regions), ...Object.values(regions) ]));
	return allRegions.find(region => new RegExp(`,\\s*${region}\\s*$`).test(value)) ? 'must not include a province/state name or code' : '';
}

function checkForLevelUnit(this: Address, value: string, property: string, { autofix = false } = {}) {
	if (!value) {
		return '';
	}

	const joined   = levelUnitIdentifiers.join('|');
	const patterns = [
		new RegExp(`^(${joined})`,           'i'),
		new RegExp(`^\\((${joined})\\)`,     'i'),
		new RegExp(`\\s+(${joined})$`,       'i'),
		new RegExp(`\\s+\\((${joined})\\)$`, 'i'),
	];

	return matchUnitPattern(this, patterns, value, { autofix }, (matches: RegExpMatchArray, suffix: boolean) => {
		this.unitNumber = matches[1].trim();
		this.street     = suffix ? trimExtraChars(value.slice(0, matches.index), 'end')
		                         : trimExtraChars(value.slice(matches.index + matches[0].length), 'start');
	});
}

function checkForUnitNumber(this: Address, value: string, property: string, { autofix = false } = {}) {
	if (!value) {
		return '';
	}

	const joined   = unitNumberIdentifiers.join('|');
	const patterns = [
		new RegExp(`^(${joined})\\s*(#?\\s*\\w+)`, 'i'),
		new RegExp(`\\s+(${joined})\\s*(#?\\s*\\w+)$`, 'i'),
	];

	return matchUnitPattern(this, patterns, value, { autofix }, (matches: RegExpMatchArray, suffix: boolean) => {
		this.unitNumber = trimExtraChars(matches[2], 'anywhere');
		this.street     = suffix ? trimExtraChars(value.slice(0, matches.index), 'end')
		                         : trimExtraChars(value.slice(matches[0].length), 'start');
	});
}

function matchUnitPattern(address: Address, patterns: RegExp[], street, { autofix = false } = {}, callback: (matches: RegExpMatchArray, suffix: boolean) => void) {
	for (const pattern of patterns) {
		const matches = street.match(pattern);
		if (matches) {
			if (autofix && _.isEmpty(address.unitNumber)) {
				callback(matches, !pattern.toString().startsWith('/^'));
				return;
			}
			return 'must not include a unit indicator in the street address';
		}
	}
}

function trimExtraChars(value: string, position: 'start' | 'end' | 'anywhere') {
	switch (position) {
		case 'start':    return value.trim().replace(/^[.,#'-]+/, '').trim();
		case 'end':      return value.trim().replace(/[.,#'-]+$/, '').trim();
		case 'anywhere': return value.trim().replace(/[.,#'-]+/, '').trim();
		default: return value.trim();
	}
}

/**
 * Removes periods, commas, and double spaces from the street address
 * Along with abbreviating the street suffix and direction
 */
function streetNormalization(this: Address, value: string, property: string, { autofix = false } = {}) {
	if (!value || !autofix || !_.isString(value)) {
		return '';
	}

	this.street = normalizeStreetSuffix(this.street.trim().replace(/\s+/g, ' ').replace(/[,.]$/, ''));
}
