import { Country } from '$/lib/Address';
import random      from '$/lib/Random';

export enum ValidDelimiters {
	None  = '',
	Dash  = '-',
	Dot   = '.',
	Space = ' '
}

/**
 * Factory function for returning NationalID helper instances.
 */
export default _.memoize(function(country: Country): BaseNationalIDHelper {
	if (!_.includes(Country, country?.toUpperCase())) {
		throw new Error(`Not a supported country: ${country}`);
	}

	return {
		CA : new NationalIDHelperCA(),
		US : new NationalIDHelperUS(),
	}[country];
});

abstract class BaseNationalIDHelper {

	abstract get country(): Country;

	abstract get delimiter(): ValidDelimiters;

	abstract isValid(id: string): boolean;

	isNotValid(id: string) {
		return !this.isValid(id);
	}

	/**
	 * @param {Object}  [options]
	 * @param {boolean} [options.fullLabel] full name or the acronym ie Social Insurance Number / SIN
	 */
	abstract getLabel(): string;

	abstract generateRandom(): string;

	abstract format(id: string, delimiter?: ValidDelimiters): string;

	/**
	 * @param {string} char - character to use as encrypted value
	 * @param {ValidDelimiters} delimiter
	 */
	abstract encryptedDisplay(char?: string, delimiter?: ValidDelimiters): string;

	/**
	 * Normalizes the entered value by removing all optional delimiting characters.
	 */
	normalize(id: string): string {
		return id?.replace(new RegExp(`[${Object.values(ValidDelimiters).join('\\')}]`, 'g'), '');
	}

	/**
	 * @returns - properties of given national ID, may describe id holder's region, status, type. Depends on country
	 */
	getNotes(id: string) { // eslint-disable-line @typescript-eslint/no-unused-vars
		return null;
	}

}

class NationalIDHelperCA extends BaseNationalIDHelper {

	notesProperties = {
		IsTemporaryResident : {
			key   : 'isTemporaryResident',
			value : '9',
		},
		IsPossibleBusiness : {
			key   : 'isPossibleBusiness',
			value : [ '7', '8' ],
		},
	};

	get country() {
		return Country.CA;
	}

	get delimiter() {
		return ValidDelimiters.Space;
	}

	isValid(id: string): boolean {
		// https://en.wikipedia.org/wiki/Social_Insurance_Number#Validation
		id = this.normalize(id);

		return /^\d{9}$/.test(id) && this.luhnChecksum(id);
	}

	getLabel({ fullLabel = false } = {}): string {
		return fullLabel ? 'Social Insurance Number' : 'SIN';
	}

	getNotes(id: string) {
		let note = null;

		if (id[0] === this.notesProperties.IsTemporaryResident.value) {
			note = { [this.notesProperties.IsTemporaryResident.key] : true };
		}
		else if (this.notesProperties.IsPossibleBusiness.value.includes(id[0])) {
			note = { [this.notesProperties.IsPossibleBusiness.key] : true };
		}

		return note;
	}

	generateRandom(): string {
		// create a random 9 digits that starts with a 9 , check the remainder on mod 10, and subtract it from the first 9
		const id        = 9 + random.string(8, '0123456789');
		const idArray   = id.split('');
		const sumDigits = this.getSumDigits(id);

		idArray[0] = (9 - (sumDigits % 10)).toString();

		return idArray.join('');
	}

	format(id: string, delimiter?: ValidDelimiters): string {
		delimiter        = delimiter || this.delimiter;
		const originalId = id;
		id               = this.normalize(id);
		if (!/^\d+$/.test(id)) {
			return originalId;
		}

		if (id.length > 3) {
			id = id.substring(0, 3) + delimiter + id.substring(3);
		}
		if (id.length > 7) {
			id = id.substring(0, 7) + delimiter + id.substring(7);
		}
		return id;
	}

	encryptedDisplay(char = '*', delimiter?: ValidDelimiters): string {
		delimiter = delimiter || this.delimiter;
		// XXX XXX XXX
		return `${char}${char}${char}${delimiter}${char}${char}${char}${delimiter}${char}${char}${char}`;
	}

	/**
	 * Luhn's Algorithm (https://en.wikipedia.org/wiki/Luhn_algorithm)
	 * Used for calculating valid Canadian SINs
	 * Takes a string of digits and double every 2nd digit, then sum of all those digits then check if divisible by 10
	 * e.g. 1992 -> 1, 9*2, 9, 2*2 -> 1 + 18 + 9 + 4 -> 32
	 */
	private luhnChecksum(id: string): boolean {
		return this.getSumDigits(id) % 10 === 0;
	}

	/**
	 * used by Luhn's Algorithm and generating Luhn valid numbers
	 */
	private getSumDigits(id: string): number {
		return id.split('').map((digit, index) => {
			const d = parseInt(digit);
			return index % 2 === 1 ? d * 2 : d;
		}).reduce((sum, digit) => sum + (digit >= 10 ? 1 + (digit - 10) : digit), 0);
	}

}

class NationalIDHelperUS extends BaseNationalIDHelper {

	get country() {
		return Country.US;
	}

	get delimiter() {
		return ValidDelimiters.Dash;
	}

	isValid(id: string): boolean {
		// https://en.wikipedia.org/wiki/Social_Security_number#Valid_SSNs
		return /^(?!000|666)[0-8][0-9]{2}(?!00)[0-9]{2}(?!0000)[0-9]{4}$/.test(this.normalize(id));
	}

	getLabel({ fullLabel = false } = {}): string {
		return fullLabel ? 'Social Security Number' : 'SSN';
	}

	generateRandom(): string {
		// any 9 digit number, without 0 or 6, since those are the only digits that may have issues
		return random.string(9, '1234578');
	}

	format(id = '', delimiter?: ValidDelimiters): string {
		delimiter        = delimiter || this.delimiter;
		const originalId = id;
		id               = this.normalize(id);
		if (!/^\d+$/.test(id)) {
			return originalId;
		}
		if (id.length > 3) {
			id = id.substring(0, 3) + delimiter + id.substring(3);
		}
		if (id.length > 6) {
			id = id.substring(0, 6) + delimiter + id.substring(6);
		}
		return id;
	}

	encryptedDisplay(char = '*', delimiter?: ValidDelimiters): string {
		delimiter = delimiter || this.delimiter;
		// XXX XX XXXX
		return `${char}${char}${char}${delimiter}${char}${char}${delimiter}${char}${char}${char}${char}`;
	}

}
