









import Moment                   from 'moment';
import { LocalStorageCache }    from '$/lib/localStorageExt';
import { Vue, Component, Prop } from '$/lib/vueExt';
import { Authentication }       from '$/entities/Authentication';

const notificationCache = new LocalStorageCache('notification');

enum Strategy {
	LocalStorage = 'localStorage',		// dismissal persistance is stored in local storage
	Entity       = 'entity',			// dismissal persistance is stored on a field of an entity
}

@Component
export default class Notification extends Vue {

	/**
	 * Key saved to strategy (local storage or entity)
	 * keyName must be unique across all Notification if the strategy is LocalStorage
	 */
	@Prop({ required : true })
	readonly keyName: string;

	/**
	 * Wrapper component, b-container, b-alert, b-modal, etc
	 */
	@Prop({ default : 'b-card' })
	readonly type: string;

	/**
	 * Days to hide the notification for.
	 * If false notification will no longer appear unless cache or entity field is removed manually.
	 * If 'session', the notification will be hidden for the duration of the current user's authentication session.
	 */
	@Prop({ default : 30 })
	readonly dismissPeriod: number | boolean | 'session';

	@Prop({ default : Strategy.LocalStorage })
	readonly strategy: string;

	isDismissed: boolean = false;

	async mounted() {
		if (this.strategy == Strategy.LocalStorage) {
			const payload = notificationCache.get(this.keyName);
			if (!payload) {
				this.isDismissed = false;
			}
			else if (payload && this.dismissPeriod === 'session') {
				const session    = await Authentication.getSession();
				this.isDismissed = payload.id === session.sessionID;
			}
		}
		// SHOULDDO: add behavior for Entity strategy
	}

	async dismiss() {
		let expires: Date;
		let payload;

		switch (this.strategy) {
			case Strategy.LocalStorage:
				if (_.isFinite(this.dismissPeriod)) {
					expires = Moment().add(this.dismissPeriod as number, 'day').toDate();
					payload = true;
				}
				else if (this.dismissPeriod === 'session') {
					const session = await Authentication.getSession();
					expires       = session.expiresOn;
					payload       = { id : session.sessionID };
				}

				notificationCache.set(this.keyName, payload, { expires });
				this.isDismissed = true;
				break;

			default:
				throw new Error(`invalid notification storage strategy: ${this.strategy}`);
		}
	}

}
