








import stripe               from '$/lib/stripe';
import { Vue, Component, Prop, Inject, Watch } from '$/lib/vueExt';
import { HasValidMethod }   from '$/lib/mixins/VueValidationMixin';
import type { SetFeedback } from '$/lib/mixins/VueValidationMixin';

@Component
export default class FormStripe extends Vue {

	@Prop({ required : true })
	readonly type: 'cardNumber' | 'cardCvc' | 'cardExpiry';

	@Prop()
	readonly state: boolean;

	@Inject({ default : null })
	readonly setFeedback: SetFeedback;

	@Inject({ default : null })
	readonly registerInput: (formInput: HasValidMethod) => void;

	@Inject({ default : null })
	readonly unregisterInput: (formInput: HasValidMethod) => void;

	ready = false;
	element;

	private isCompleted: boolean = false;
	private localState: boolean = null;
	private localError: string = '';

	get error() {
		return this.localError;
	}
	set error(error) {
		this.localState = error ? false : null;
		this.localError = error;
		this.setFeedback?.(error, this);
	}

	async mounted() {
		const elements = await stripe.elements();
		this.element   = elements.create(this.type, {
			style : {
				base : {
					color : '#495057',
				},
				invalid : {
					color : '#495057',
				},
			},
		});
		this.element.mount(this.$refs.input);

		this.element.on('change', event => {
			this.error       = event.error?.message || '';
			this.isCompleted = event.complete;
		});

		this.element.on('ready', this.onReady);

		this.registerInput?.(this as any);
	}

	beforeDestroy() {
		this.unregisterInput?.(this);
		this.element.unmount();
		this.element.destroy();
	}

	@Watch('state')
	onStateChange() {
		this.localState = this.state;
	}

	isValid() {
		const isValid = this.isCompleted && !this.error;
		this.setFeedback?.(isValid ? '' : `${_.startCase(this.type)} is required`, this);
		this.localState = isValid ? null : false;
		return isValid;
	}

	reset() {
		this.element.clear();
		this.error       = '';
		this.isCompleted = false;
	}

	onReady(e) {
		this.ready = true;
		this.$emit('ready', e);
	}

}
