import VueGTag, { event, set }                 from 'vue-gtag';
import env, { Environment }                    from '$/lib/env';
import { ALaCarteType, LogEvents, SignUpType } from '$/lib/global';
import { Country }                             from '$/lib/Address';
import { BaseLogger, LoggerLevel, type Log }   from '$/lib/logger';

import { type Package } from '$/entities/Package';
import { type User }    from '$/entities/User';

const eventsMap = {
	[LogEvents.PackageChanged] : 'AW-611668207/8nq4CNSTt5EDEO-h1aMC',
	[LogEvents.SignedUp]       : {
		[SignUpType.Landlord] :	'AW-611668207/SIpDCIib6ZADEO-h1aMC',
		[SignUpType.Renter]   : 'AW-611668207/9rj_CMmynIQYEO-h1aMC',
	},
	[LogEvents.ALaCartePurchased] : {
		[ALaCarteType.CreditReport]          : 'AW-611668207/Rz_LCICp-MkDEO-h1aMC',
		[ALaCarteType.DebtReporting]         : 'AW-611668207/3-41CPub9MkDEO-h1aMC',
		[ALaCarteType.BackgroundCheck]       : 'AW-611668207/shfFCPzwxOIDEO-h1aMC',
		[ALaCarteType.TenantScreeningBundle] : 'AW-611668207/ax0eCN-5mOQDEO-h1aMC',
		[ALaCarteType.Collections]           : 'AW-611668207/kRbhCM_G840YEO-h1aMC',
	},
};

export default class GTag {

	static install(Vue) { // eslint-disable-line @typescript-eslint/naming-convention
		Vue.use(VueGTag, {
			config : {
				id     : env.config('gtag'),
				params : {
					send_page_views : true, // eslint-disable-line @typescript-eslint/naming-convention
				},
			},
			pageTrackerEnabled : false,
			includes           : env.isEnvironment(Environment.PROD) ? [
				{ id     : 'AW-611668207',
					params : {
						/* eslint-disable @typescript-eslint/naming-convention */
						send_page_views            : false,
						allow_enhanced_conversions : true,
						/* eslint-enable @typescript-eslint/naming-convention */
					} },
			] : [],
		});
	}

	static identify(user: User) {
		/* eslint-disable @typescript-eslint/naming-convention */
		set({ user_data : _.compactObject({
			email        : user.email,
			phone_number : user.phoneNumber,
			address      : user.postalCode ? {
				first_name  : user.firstName,
				last_name   : user.lastName,
				street      : user.street,
				city        : user.city,
				region      : user.province,
				// ensure Canadian postal code has a space in it
				// SHOULDDO: switch to a replaceAll once we upgrade to es2021 instead of the replace / /g
				postal_code : user.country === Country.CA ? user.postalCode.replace(/ /g, '').replace(/(...)/, '$1 ') : user.postalCode,
				country     : user.country,
			} : undefined,
		}) });
		/* eslint-enable @typescript-eslint/naming-convention */
	}

}


export class GTagAdsEventLogger extends BaseLogger {

	log(logsOrLevel: Partial<Log> | Partial<Log>[] | Error | LoggerLevel, message?: string | Error, details?: any): Log[] {
		let logs = super.log.apply(this, [ logsOrLevel, message, details ]);

		// commands don't get logged
		logs = logs.filter(log => log.level !== LoggerLevel.Command);

		logs.forEach(log => {
			const eventID = typeof eventsMap[log.message] === 'object'
				? eventsMap[log.message][log.details.type]
				: eventsMap[log.message];

			if (!eventID) {
				return;
			}

			// log these only for upgrades to non-basic (ie Premium) packages
			if (log.message === LogEvents.PackageChanged) {
				const pkg = log.details.to as Package;
				if (pkg && !pkg.isMonthly && !pkg.isSemiYearly && !pkg.isYearly) {
					return;
				}
			}

			// Only send conversion events in productions
			if (env.isEnvironment(Environment.PROD)) {
				event('conversion', {
					send_to  : eventID, // eslint-disable-line @typescript-eslint/naming-convention
					value    : log.details?.price?.value,
					currency : log.details?.price?.currency,
				});
			}
		});

		return logs;
	}

}

export class GTagAnalyticsEventLogger extends BaseLogger {

	log(logsOrLevel: Partial<Log> | Partial<Log>[] | Error | LoggerLevel, message?: string | Error, details?: any): Log[] {
		let logs = super.log.apply(this, [ logsOrLevel, message, details ]);

		// commands don't get logged
		logs = logs.filter(log => log.level !== LoggerLevel.Command);

		logs.forEach(log => {
			switch (log.message as LogEvents) {
				case LogEvents.PackageChanged:
					const pkg = log.details.to as Package; // eslint-disable-line no-case-declarations
					if (pkg?.isPlus) {
						event('upgrade_plus');
					}
					else if (pkg && !pkg.isBasic && pkg.roles.includes('Landlord')) {
						event('upgrade_premium');
					}
					break;
				case LogEvents.ALaCartePurchased:
					event('purchase', { type : _.snakeCase(log.details.type) });
					break;
				case LogEvents.SignedUp:
					event('sign_up', { type : _.snakeCase(log.details.type) });
					break;
			}
		});

		return logs;
	}

}
