import Axios, { AxiosResponse } from 'axios';

/**
 * monkey-patch Error.prototype.toJSON because some library sets it to non-writable and Axios tries to write that property upon errors
 * SHOULDDO: check once in while to see if this is still needed; if not, delete
 */
function resetErrorToJSON() {
	const oldDescriptor = Object.getOwnPropertyDescriptor(Error.prototype, 'toJSON');
	if (oldDescriptor && !oldDescriptor.writable) {
		oldDescriptor.writable = true;
		Object.defineProperty(Error.prototype, 'toJSON', oldDescriptor);
	}
	else {
		(resetErrorToJSON as any).count = ((resetErrorToJSON as any).count || 0) + 1;

		// limit time so that this doesn't run forever
		if ((resetErrorToJSON as any).count < 20) {
			setTimeout(resetErrorToJSON, 1000);
		}
	}
}
setTimeout(resetErrorToJSON, 1000);


/**
 * Global interceptor that formats custom error messages better.
 */
Axios.interceptors.response.use(
	function onSuccess(response: AxiosResponse) {
		return response;
	},
	function onFailure(error) {
		if (error.response) {
			if (typeof error.response.data === 'string') {
				error = Object.assign(new Error(error.response.data || error.message), error.response);
			}
			else if (_.isPlainObject(error.response.data)) {
				const errorClass: Class = (_.find(Errors, err => err.name === error.response.data.$class) ?? Error) as unknown as Class;
				error                   = Object.assign(new errorClass(), error.response.data);
			}
		}
		return Promise.reject(error);
	}
);

/**
 * Common code for both the CustomError and HTTPError types.
 */
export abstract class BaseError extends Error {

	constructor(message?: string) {
		super(message);

		if (typeof (Error as any).captureStackTrace === 'function') {
			// capture trace, starting at whatever invoked the constructor
			(Error as any).captureStackTrace(this, this.constructor);
		}

		// restore prototype chain
		// see https://github.com/Microsoft/TypeScript/issues/13965#issuecomment-278570200
		const actualProto = new.target.prototype;

		if (Object.setPrototypeOf) {
			Object.setPrototypeOf(this, actualProto);
		}
		else {
			(this as any).__proto__ = actualProto;
		}
	}

}

export abstract class CustomError extends BaseError {

	/**
	 * A possible HTTP status code.
	 * If present, indicates that if this error is sent over HTTP, to use this HTTP status code.
	 */
	status?: number;

	stringify() {
		return JSON.stringify({ $class : this.constructor.name, ...this });
	}

}

export class NotImplemented extends CustomError {

	constructor(message = 'not implemented') {
		super(message);
	}

}

export class AuthInvalidUserPassword extends CustomError {

	status = 400;
	constructor(username) {
		super(`invalid username and/or password${username ? ` for: ${username}` : ''}`);
	}

}

export class EmailNotVerified extends CustomError {

	constructor() {
		super('email not yet verified');
	}

}

export class Timeout extends CustomError {

	constructor(message = 'operation timed out') {
		super(message);
	}

}

export class VerificationMaxAttemptsReached extends CustomError {

	constructor() {
		super('maximum number of verification attempts reached');
	}

}

export class EquifaxCreditReportUnavailable extends CustomError {

	constructor() {
		super('Equifax credit report is unavailable');
	}

}

export class UndeliverableEmailError extends CustomError {

	constructor(message: string) {
		super(message);
	}

}

export class UserSuspended extends UndeliverableEmailError {

	status = 403;
	constructor() {
		super('User is suspended.');
	}

}

export class IgnoredDomain extends UndeliverableEmailError {

	constructor() {
		super('Cannot send to this domain because it is on the ignore list');
	}

}

export class DoNotEngageEmail extends UndeliverableEmailError {

	constructor() {
		super('Recipient is in the "do not engage" list.');
	}

}

export class InvalidRedemptionCode extends CustomError {

	status = 400;
	constructor(redemptionCode?: string) {
		super(`invalid redemption code${redemptionCode ? `: ${redemptionCode}` : ''}`);
	}

}

export class ExpiredRedemptionCode extends CustomError {

	status = 400;
	constructor(redemptionCode?: string) {
		super(`redemption code has expired${redemptionCode ? `: ${redemptionCode}` : ''}`);
	}

}

export class NonApplicableRedemptionCode extends CustomError {

	status = 400;
	constructor(redemptionCode?: string) {
		super(`redemption code is not applicable to this plan${redemptionCode ? `: ${redemptionCode}` : ''}`);
	}

}

export class HubSpotCreateError extends CustomError {

	details: any;
	constructor(details: any) {
		super('HubSpot: error in sync create');
		this.details = details;
	}

}

export class HubSpotUpdateError extends CustomError {

	details: any;
	constructor(details: any) {
		super('HubSpot: error in sync update');
		this.details = details;
	}

}


/**
 * Classes for various HTTP errors.
 * Override in subclass to add the proper HTTP status code.
 * Unlike CustomError, does not allow for arbitrary data to be set, just the status code
 */
export abstract class HTTP extends BaseError {

	constructor(message?: string) {
		super('');
		this.message = message || this.constructor.name;
	}

	/**
	 * Returns HTTP the status code for this error.
	 */
	abstract get status(): number;

	/* eslint-disable @typescript-eslint/naming-convention */
	static BadRequest = class BadRequest extends HTTP {

		get status() {
			return 400;
		}

	};

	static Unauthorized = class Unauthorized extends HTTP {

		get status() {
			return 401;
		}

	};

	static Forbidden = class Forbidden extends HTTP {

		get status() {
			return 403;
		}

	};

	static NotFound = class NotFound extends HTTP {

		get status() {
			return 404;
		}

	};

	static MethodNotAllowed = class MethodNotAllowed extends HTTP {

		get status() {
			return 405;
		}

	};

	static Gone = class Gone extends HTTP {

		get status() {
			return 410;
		}

	};

	static PayloadTooLarge = class PayloadTooLarge extends HTTP {

		get status() {
			return 413;
		}

	};

	static InternalError = class InternalError extends HTTP {

		get status() {
			return 500;
		}

	};
	/* eslint-enable @typescript-eslint/naming-convention */

}

const Errors = {
	BaseError,
	CustomError,
	NotImplemented,
	AuthInvalidUserPassword,
	EmailNotVerified,
	Timeout,
	HTTP,
	VerificationMaxAttemptsReached,
	EquifaxCreditReportUnavailable,
	UserSuspended,
	IgnoredDomain,
	DoNotEngageEmail,
	UndeliverableEmailError,
	InvalidRedemptionCode,
	ExpiredRedemptionCode,
	NonApplicableRedemptionCode,
	HubSpotCreateError,
	HubSpotUpdateError,
};
export default Errors;
