import Axios from 'axios';

import { Entity, createEntity } from '$/entities/BaseEntity';

import { Tenant as TenantCommon } from '$/common/entities/Tenant';
export *                          from '$/common/entities/Tenant';

@Entity()
export class Tenant extends TenantCommon {

	async loadSearchHistory({ force = false } = {}) {
		if (!this.searchHistory || force) {
			try {
				this.searchHistory = ((await Axios.get(Tenant.collectionUrl(`${this.id}/searchHistory`))).data || []).map(createEntity);
			}
			catch (error) {
				this.searchHistory = [];
			}
		}
		return this.searchHistory;
	}

}
