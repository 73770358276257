import { Column }  from '$/lib/typeormExt';
import { Country } from '$/lib/Address';

import { CommonEntity, ChildEntity, EntityID } from '$/entities/BaseEntity';
import Permissions                             from '$/entities/lib/Permissions';
import { PackageFeature }                      from '$/entities/Package';
import { LeaseBalanceStatus }                  from '$/entities/LeaseBalance';
import { BaseRole }                            from '$/entities/roles/BaseRole';
import { ProgressStatus, TenantCheck }         from '$/entities/tenantScreening/TenantCheck';
import { parsePayment, TenantRecordData }      from '$/entities/tenantScreening/lib/TenantRecordData';
import { Application }                         from '$/entities/tenantScreening/Application';

/**
 * A TenantSearch records a search by a Landlord for prospective tenant application(s).
 */
@ChildEntity() @CommonEntity()
@Permissions({
	create : Permissions.roleHasFeature(PackageFeature.TenantSearch),
})
export class TenantSearch extends TenantCheck {

	@Column({ type : 'json', nullable : true })
	report: TenantRecordData = null;

	@Column('simple-array')
	tenantIds: EntityID[] = [];

	get label() {
		return 'Tenant Records';
	}

	get labelOrder() {
		return 2;
	}

	static getSample(role: BaseRole, country: Country) {
		const sample                   = new this();
		sample.id                      = 'sample';
		sample.ver                     = 1;
		sample.org                     = role.org;
		sample.status                  = ProgressStatus.Complete;
		sample.requestedBy             = role.user;
		sample.application             = new Application();
		sample.application.firstName   = 'Peter';
		sample.application.lastName    = 'Pratt';
		sample.application.dateOfBirth = new Date('1992-10-29');
		sample.application.street      = '123 Allen Street';
		sample.application.city        = 'Toronto';
		sample.application.province    = 'ON';
		sample.application.country     = Country.CA;
		sample.report                  = { tenantRecords : [
			{
				lease : {
					monthlyRent : 1250,
					street      : '123 ALLEN ST',
					city        : 'Toronto',
					region      : 'ON',
					moveInDate  : new Date('Oct 01 2021'),
					country     : Country.US,
				},
				landlord : {
					name  : 'First Rate Rentals',
					email : 'jsmith@firstraterentals.com',
				},
				paymentHistory : [
					{ date : 'Aug 01 2021' },
					{ date : 'Jul 01 2021' },
					{ date : 'Jun 01 2021' },
				].map(parsePayment),
				inquiries : [
					{ date : new Date('Jun 03 2022'), name : 'Thomas Anderson' },
				],
			},
			{
				lease : {
					monthlyRent : 1250,
					street      : '248 WITHROW AVE',
					city        : 'Toronto',
					region      : 'ON',
					moveInDate  : new Date('Nov 01 2018'),
					moveOutDate : new Date('Sep 30 2019'),
					country     : Country.US,
				},
				landlord : {
					name  : 'City Rentals',
					email : 'paul@cityrentals.com',
				},
				paymentHistory : [
					{ date : 'Sep 01 2019' },
					{ date : 'Aug 01 2019' },
					{ date : 'Jul 01 2019' },
					{ date : 'Jun 01 2019' },
					{ date : 'May 01 2019', status : LeaseBalanceStatus.PaidLate },
					{ date : 'Apr 01 2019' },
					{ date : 'Mar 01 2019' },
					{ date : 'Feb 01 2019' },
					{ date : 'Jan 01 2019' },
					{ date : 'Dec 01 2018' },
					{ date : 'Nov 01 2018' },
				].map(parsePayment),
				inquiries : [
					{ date : new Date('Sep 21 2022'), name : 'ABC Properties' },
					{ date : new Date('Sep 07 2022'), name : 'Bob Johnson' },
				],
			  },
		] };

		if (country === Country.US) {
			sample.application.street                   = '683 W. Helen Street';
			sample.application.city                     = 'Brooklyn';
			sample.application.province                 = 'NY';
			sample.application.country                  = Country.US;
			sample.report.tenantRecords[0].lease.street = '683 W. Helen Street';
			sample.report.tenantRecords[0].lease.city   = 'Brooklyn';
			sample.report.tenantRecords[0].lease.region = 'NY';
			sample.report.tenantRecords[1].lease.street = '77 E. King Drive';
			sample.report.tenantRecords[1].lease.city   = 'Brooklyn';
			sample.report.tenantRecords[1].lease.region = 'NY';
		}

		return sample;
	}

}
