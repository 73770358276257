
/**
 * Overwrite BFormRadioGroup to add support for mutating certain props
 */
import { BFormRadioGroup }   from 'bootstrap-vue';
import { Component, Mixins } from '$/lib/vueExt';
import VueValidationMixin    from '$/lib/mixins/VueValidationMixin';

@Component
export default class FormRadioGroup extends Mixins(BFormRadioGroup, VueValidationMixin) {
}
