import Axios      from 'axios';
import { Entity } from '$/entities/BaseEntity';

import { Log as LogCommon } from '$/common/entities/Log';
export * from '$/common/entities/Log';

interface IPLocation {
	ipAddress: string;
	city: string;
	region: string;
	regionName: string;
	country: string;
	countryName: string;
	postalCode: string;
}

@Entity()
export class Log extends LogCommon {

	/**
	 * @returns the geographical location corresponding to this log's IP address.
	 */
	getIPLocation() {
		return Log.getIPLocation(this.ipAddress);
	}

	static async getIPLocation(ipAddress: string): Promise<IPLocation> {
		return (await Axios.get(Log.collectionUrl(`ip-location/${ipAddress}`))).data;
	}

}
