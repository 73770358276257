



















































import { Vue, Component, Prop }                     from '$/lib/vueExt';
import { testimonialSourceIcons }                   from '$/lib/testimonials';
import type { Testimonial as TestimonialInterface } from '$/lib/testimonials';

/**
 * Presents a testimonial from a user.
 */
@Component
export default class Testimonial extends Vue {

	@Prop({ required : true })
	readonly testimonial: TestimonialInterface;

	@Prop({ default : 'light' })
	readonly variant: 'light' | 'dark';

	get sourceIcons() {
		return testimonialSourceIcons;
	}

	get name() {
		if (!this.testimonial.testifier) {
			return 'Anonymous';
		}
		return this.testimonial.testifier.companyName
			|| `${_.startCase(this.testimonial.testifier.firstName)} ${this.testimonial.testifier.lastName.slice(0, 1).toLocaleUpperCase()}.`;
	}

}
