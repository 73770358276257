
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 40 40"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M36.484 21.172c.648 0 1.172-.525 1.172-1.172v-3.516h1.172c.647 0 1.172-.524 1.172-1.171v-4.688c0-.647-.525-1.172-1.172-1.172h-3.594V8.281c0-.442-.248-.846-.643-1.046L20.53.126a1.171 1.171 0 0 0-1.058 0L5.41 7.236a1.17 1.17 0 0 0-.643 1.045v1.172H1.172C.525 9.453 0 9.978 0 10.625v4.688c0 .647.525 1.171 1.172 1.171h1.172V20c0 .647.524 1.172 1.172 1.172h1.171V32.89H3.516c-.648 0-1.172.524-1.172 1.172v3.593H1.172a1.172 1.172 0 1 0 0 2.344h37.656a1.172 1.172 0 1 0 0-2.344h-1.172v-3.593c0-.648-.524-1.172-1.172-1.172h-1.172V21.17h1.172zm-1.172-2.344h-7.109v-2.344h7.11v2.344zm-8.28 14.063c-.648 0-1.173.524-1.173 1.172v3.593H14.141v-3.593c0-.648-.525-1.172-1.172-1.172h-1.172V21.17h1.172c.647 0 1.172-.524 1.172-1.171v-3.516h11.718V20c0 .647.525 1.172 1.172 1.172h1.172V32.89h-1.172zM7.108 9.002 20 2.485l12.89 6.517v.451H7.11v-.45zm-4.765 2.795h35.312v2.344H2.344v-2.344zm9.453 4.687v2.344h-7.11v-2.344h7.11zM7.03 21.172h2.422V32.89H7.031V21.17zM4.688 35.234h7.109v2.422h-7.11v-2.422zm23.515 2.422v-2.422h7.11v2.422h-7.11zm4.766-4.765h-2.422V21.17h2.422v11.72z"}}),_c('path',{attrs:{"d":"M20 26.87c-.735 0-1.333-.517-1.333-1.153 0-.635.598-1.152 1.333-1.152.475 0 .917.221 1.156.577.368.55 1.184.74 1.821.42.638-.318.856-1.023.487-1.575-.482-.72-1.246-1.256-2.13-1.528v-1.306c0-.637-.598-1.153-1.334-1.153-.736 0-1.333.516-1.333 1.153v1.305c-1.552.475-2.667 1.757-2.667 3.26 0 1.906 1.794 3.458 4 3.458.735 0 1.333.517 1.333 1.152 0 .636-.598 1.153-1.333 1.153-.475 0-.917-.221-1.156-.577-.368-.55-1.184-.74-1.821-.42-.638.318-.856 1.023-.487 1.574.482.722 1.246 1.257 2.13 1.528v1.261c0 .637.598 1.153 1.334 1.153.736 0 1.333-.516 1.333-1.153V33.59C22.885 33.112 24 31.83 24 30.329c0-1.907-1.794-3.459-4-3.459z"}})])
          )
        }
      }
    