import typeorm               from 'typeorm';
import type { FindOperator } from 'typeorm';
import Moment, { DurationInputArg1, DurationInputArg2 } from 'moment';

export * from 'typeorm';		// exports Types only since typeorm is not an ESM atm
export default typeorm;

// proper ESM exports since currently Typeorm does not properly export them
export const AfterInsert            = typeorm.AfterInsert;
export const AfterUpdate            = typeorm.AfterUpdate;
export const BeforeInsert           = typeorm.BeforeInsert;
export const BeforeUpdate           = typeorm.BeforeUpdate;
export const BeforeRemove           = typeorm.BeforeRemove;
export const ChildEntity            = typeorm.ChildEntity;
export const CreateDateColumn       = typeorm.CreateDateColumn;
export const getMetadataArgsStorage = typeorm.getMetadataArgsStorage;
export const Index                  = typeorm.Index;
export const MoreThan               = typeorm.MoreThan;
export const MoreThanOrEqual        = typeorm.MoreThanOrEqual;
export const PrimaryGeneratedColumn = typeorm.PrimaryGeneratedColumn;
export const Table                  = typeorm.Table;
export const TableColumn            = typeorm.TableColumn;
export const TableIndex             = typeorm.TableIndex;
export const TableInheritance       = typeorm.TableInheritance;
export const UpdateDateColumn       = typeorm.UpdateDateColumn;
export const VersionColumn          = typeorm.VersionColumn;
export const IsNull                 = typeorm.IsNull;
export const Unique                 = typeorm.Unique;
export const Not                    = typeorm.Not;
export const Like                   = typeorm.Like;
export const In                     = typeorm.In;
export const LessThan               = typeorm.LessThan;
export const LessThanOrEqual        = typeorm.LessThanOrEqual;
export const Equal                  = typeorm.Equal;
export const Between                = typeorm.Between;
export const UpdateQueryBuilder     = typeorm.UpdateQueryBuilder;
export const InsertQueryBuilder     = typeorm.InsertQueryBuilder;

const FindOperatorClass = typeorm.FindOperator;

/**
 * FindOperator for JSON fields.
 * Currently only allows searching through JSON fields to match a specific value.
 */
export function JSON<T>(key: string, value: T) {
	const jsonOperator              = new FindOperatorClass('json' as any, value === null ? 'null' : value, true);
	(jsonOperator as any)._json_key = key;

	jsonOperator.toSql = function(connection, aliasPath, parameters) {
		if (this._value instanceof FindOperatorClass) {
			// special case for IsNull which requires special SQL
			if (this._value._type === 'isNull') {
				// SHOULDDO: find a better way to check against a JSON value of NULL (IS NULL doesn't work in the current version of MySQL)
				return `${aliasPath}->>'$.${this._json_key}' = 'null'`;
			}

			return this._value.toSql(connection, `${aliasPath}->>'$.${this._json_key}'`, parameters);
		}
		return `${aliasPath}->>'$.${this._json_key}' = ${parameters[0]}`;
	};

	return jsonOperator;
}

/**
 * Typeorm has a factory function for every FindOperator that sets up other things regarding that operator
 */
export const queryOperatorToFindOperatorFactoryMap = {
	// Following MongoDB's conventions
	$gt  : MoreThan,
	$gte : MoreThanOrEqual,
	$lt  : LessThan,
	$lte : LessThanOrEqual,
	$eq  : Equal,
	$in  : In,

	// Not following MongoDB's conventions
	$between : Between,
	$like    : Like,
	$not     : Not,
	$json    : JSON,
};

/**
 * A QueryOperator is an object with a single key-value pair determining the type of the operator (key), and the operand (value)
 * @example `{ $in : [ 1, 2, 3 ] }`
 */
export type QueryOperator<T> = Partial<Record<keyof typeof queryOperatorToFindOperatorFactoryMap, T>>;

/**
 * Once a Typeorm's FindOperator factory function creates the operator, the type of the operator is one of the following keys
 */
const findOperatorToQueryOperatorMap = _(queryOperatorToFindOperatorFactoryMap).mapValues(factory => _.camelCase(factory.name)).invert().valueOf();

/**
 * Converts a Typeorm FindOperator to a QueryOperator
 * @example `MoreThanOrEqual(2)` => `{ $lte : 2 }`
 */
export function convertFindOperatorToQueryOperator <T>(operator: FindOperator<T>): QueryOperator<T> {
	const findOperatorName  = (operator as any)?._type; // e.g. 'equal', 'lessThan'
	const queryOperatorName = findOperatorToQueryOperatorMap[findOperatorName]; // e.g. '$eq', '$lt'

	// HACK: normalize the 'operator' IsNull and the 'value' null to just 'null'
	if (operator === null || queryOperatorName === 'isNull') {
		return null;
	}

	if (!queryOperatorName) {
		throw new Error(`Operator was not found: ${findOperatorName}`);
	}

	let value = (operator as any)._value;
	value     = isFindOperator(value) ? convertFindOperatorToQueryOperator(value) : value;

	if (findOperatorName === 'json') {
		value = [ (operator as any)._json_key, value ];
	}

	return { [queryOperatorName] : value };
}

/**
 * Converts a QueryOperator to Typeorm's FindOperator
 * @example `{ $lte : 2 }` => `MoreThanOrEqual(2)`
 */
export function convertQueryOperatorToFindOperator<T>(operator: QueryOperator<T>): FindOperator<T> {
	// HACK: `null` is classified as both an 'operator' and a 'value'
	if (operator === null) {
		return IsNull();
	}

	const name                = Object.keys(operator)[0];
	const findOperatorFactory = queryOperatorToFindOperatorFactoryMap[name];

	if (!findOperatorFactory) {
		throw new Error(`Could not find the operator with name: ${name}`);
	}

	let value = operator[name];
	value     = isQueryOperator(value) ? convertQueryOperatorToFindOperator(value) : value;

	// HACK: 'Between' needs the 'from' and 'to' parameters to be spread
	if (findOperatorFactory === Between) {
		return findOperatorFactory(...value);
	}

	// For The JSON operator, same as above - and it may have nested find operators that we need to resolve
	if (findOperatorFactory === JSON) {
		for (let i = 0; i < value.length; i++) {
			value[i] = value !== null && isQueryOperator(value[i]) ? convertQueryOperatorToFindOperator(value[i]) : value[i];
		}
		return findOperatorFactory(...value);
	}

	return findOperatorFactory(value);
}

const validQueryOperatorNames = Object.keys(queryOperatorToFindOperatorFactoryMap);
export function isQueryOperator(potentialOperator): boolean {
	if (potentialOperator === null) {
		return true;
	}

	const keys = Object.keys(potentialOperator);

	// For now all of our operators may only have one key-value pair in them that defines the operator (like `{ $lt : 2 }`)
	if (keys.length > 1) {
		return false;
	}

	return validQueryOperatorNames.includes(keys[0]);
}

export function isFindOperator(potentialOperator): boolean {
	return potentialOperator instanceof FindOperatorClass;
}

/**
 * Escapes a SQL value for quotes, and other characters.
 */
export function escapeSQL(value: string): string {
	// SHOULDDO: expand this to include other characters and more complete escaping logic
	return value.replace(/'/, "\\'");
}

export function WithinLast(amount: DurationInputArg1, unit?: DurationInputArg2) {
	return typeorm.MoreThan(Moment().subtract(amount, unit).toDate());
}
