import Axios      from 'axios';
import { Entity } from '$/entities/BaseEntity';

import { BaseCharge as BaseChargeCommon } from '$/common/entities/billing/BaseCharge';
export                                  * from '$/common/entities/billing/BaseCharge';

@Entity()
export class BaseCharge extends BaseChargeCommon {

	static async getChargeTypes(instanceOf?: string) {
		return (await Axios.get(BaseCharge.collectionUrl('types'), { params : { instanceOf } })).data as { $class: string; label: string}[];
	}

	static async getSummary(params: { periodStart?: Date; periodEnd?: Date }) {
		return (await Axios.get(BaseCharge.collectionUrl('summary'), { params })).data;
	}

}
