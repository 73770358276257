import Axios                from 'axios';
import env, { Environment } from '$/lib/env';

import { Entity }              from '$/entities/BaseEntity';
import type { ProgressStatus } from '$/entities/User';
import type { Package }        from '$/entities/Package';
import type { Organization }   from '$/entities/Organization';

import { TestEntity as TestEntityCommon } from '$/common/entities/TestEntity';
export *                                  from '$/common/entities/TestEntity';

let autoIDs: Record<AutoID, string>;

@Entity()
export class TestEntity extends TestEntityCommon {

	/**
	 * @returns the names of all triggerable task classes
	 */
	static async getTaskTypes(): Promise<string[]> {
		return env.isEnvironment(Environment.PROD)
			? []		// in production, testing APIs are not available
			: (await Axios.get(TestEntity.collectionUrl('/taskTypes'))).data
		;
	}

	/**
	 * Attempt to force trigger tasks
	 * Currently only works on non-production environments.
	 */
	// SHOULDDO: change taskType to type "typeof BaseTask" once BaseTask is in common
	static async triggerTask(taskType: string) {
		await Axios.post(TestEntity.collectionUrl('/taskTypes/trigger'), { task : taskType });
	}

	static async getAutoIDs(): Promise<Record<AutoID, string>> {
		if (!autoIDs) {
			autoIDs = (await Axios.get(TestEntity.collectionUrl('/autoIDs'))).data;
		}
		return autoIDs;
	}

	static async verifyEmail(email: string) {
		await Axios.post(TestEntity.collectionUrl('/email/verify'), { email });
		window.location.href = '/login';
	}

	static async setVerificationStatus(status: ProgressStatus) {
		await Axios.put(TestEntity.collectionUrl('/verification-status'), { status });
	}

	static async setPackage(pkg: Package) {
		await Axios.put(TestEntity.collectionUrl('/package'), { packageId : pkg.id });
	}

	static async registerForEquifax() {
		await Axios.post(TestEntity.collectionUrl('/equifax-registration'));
	}

	static async generateInvoices(org: Organization, count: number) {
		await Axios.post(TestEntity.collectionUrl('/generate-invoices'), { count, orgId : org.id });
	}

}
