











import { Vue, Component } from '$/lib/vueExt';

@Component
export default class Tool extends Vue {
}
