
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 40 40"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M38.713 21.47a4.837 4.837 0 0 0-6.946 0l-5.183 5.307a2.79 2.79 0 0 0-.25-.219 2.89 2.89 0 0 0-1.794-.628h-5.317c-.779-1.833-2.547-3.018-4.499-3.015h-3.926a4.833 4.833 0 0 0-2.945 1.005v-1.005a.994.994 0 0 0-.982-1.005H.981A.994.994 0 0 0 0 22.915v16.08C0 39.55.44 40 .982 40h5.89c.541 0 .98-.45.98-1.005V37.99h15.706a.97.97 0 0 0 .694-.295l14.46-14.803a1.022 1.022 0 0 0 0-1.422zM5.89 37.99H1.963V23.92H5.89v14.07zm17.262-2.01h-15.3v-8.04a2.715 2.715 0 0 1 .09-.716c0-.014 0-.028.008-.042.333-1.33 1.506-2.26 2.847-2.257h3.927c1.341-.003 2.514.926 2.846 2.257a.987.987 0 0 0 .953.758h6.017a.979.979 0 0 1 .932.714c0 .011.01.02.014.032a.993.993 0 0 1-.266.976.93.93 0 0 1-.68.288H13.742v2.01H24.54c1.623-.008 2.937-1.353 2.945-3.015 0-.078-.012-.153-.018-.23l5.693-5.823a2.903 2.903 0 0 1 3.355-.59L23.152 35.98z"}}),_c('path',{attrs:{"d":"M3 26h2v2H3zM20 0C13.925 0 9 4.925 9 11c.007 6.072 4.928 10.993 11 11 6.075 0 11-4.925 11-11S26.075 0 20 0zm0 20a9 9 0 0 1-9-9 9.01 9.01 0 0 1 9-9 9 9 0 0 1 0 18z"}}),_c('path',{attrs:{"d":"M24 9V8a2 2 0 0 0-2-2h-1V4h-2v2h-1a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h4v2h-4v-1h-2v1a2 2 0 0 0 2 2h1v2h2v-2h1a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2h-4V8h4v1h2zm1 1h2v2h-2zm-12 0h2v2h-2z"}})])
          )
        }
      }
    