import Vue from 'vue';

/**
 * Hack fix for v-form-checkboxes not prevent click events even when set to disabled.
 * SHOULDDO: look to see if this gets fixed in future versions of BootstrapVue
 */
export default Vue.directive('lcb-form-checkbox-disabled-fix', {
	bind : (el: Element) => {
		// COULDDO: add other pointer events as well
		el.addEventListener('click', event => {
			if (el.querySelector('input').disabled && (event.target as any).tagName !== 'A') {
				event.preventDefault();
				event.stopImmediatePropagation();
			}
		}, { capture : true });
	},
});
