/**
 * Misc features added to the "global" space.
 */

/**
 * Special log messages that are referenced specifically in the code so this enum ensures that all references align on the same string.
 */
export enum LogEvents {
	SignedUp          = 'signedUp',
	SignUpAborted     = 'signUpAborted',
	ALaCartePurchased = 'aLaCarteItemPurchased',
	PackageChanged    = 'packageChanged',
	RentReporting     = 'rentReporting',
	InitiateCheckout  = 'initiateCheckout',
	EquifaxClicked    = 'equifaxClicked',
}

export enum ALaCarteType {
	CreditReport          = 'Credit Report',
	BackgroundCheck       = 'Background Check',
	DebtReporting         = 'Debt Reporting',
	TenantSearch          = 'Tenant Search',
	TenantScreeningBundle = 'Tenant Screening Bundle',
	Collections           = 'Collections',
}

// Checkout events
export const CheckoutEventPrefix = 'Checkout:';
export enum CheckoutEvent {
	Start  = 'Checkout:start',
	Finish = 'Checkout:finish',
	Error  = 'Checkout:error',
	Cancel = 'Checkout:cancel', // some cancellations can't be detected (e.g. page refresh, browser tab closed)
}

// Checkout types
export enum CheckoutType {
	Upgrade   = 'upgrade',
	Debt      = 'debtReporting',
	Screening = 'tenantScreening',
}

/**
 * Type used to distinguish between signup events
 */
export enum SignUpType {
	Landlord = 'landlord',
	Renter   = 'renter',
}


export enum DaysOfWeek {
	Sunday,
	Monday,
	Tuesday,
	Wednesday,
	Thursday,
	Friday,
	Saturday
}
