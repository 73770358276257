import _Vue from 'vue';

declare module '$/lib/vueExt' {
	interface Vue {
		$breakpoint: Breakpoint;
	}
}

const thresholds = {
	sm : 576,
	md : 768,
	lg : 992,
	xl : 1200,
};

/**
 * This class add the ability to react Bootstrap Breakpoints within JS
 * It follows Bootstrap Grid Logic
 */
class Breakpoint {

	width  = 0;
	height = 0;

	xs = true;
	sm = false;
	md = false;
	lg = false;
	xl = false;

	private resizeTimeout;

	constructor() {
		this.update();

		window.addEventListener('resize', this.onResize.bind(this), { passive : true });
	}

	install(Vue: typeof _Vue) { // eslint-disable-line @typescript-eslint/naming-convention
		Object.defineProperty(Vue.prototype, '$breakpoint', {
			get : () => Vue.observable(this),
		});
	}

	update() {
		this.width  = this.getClientWidth();
		this.height = this.getClientHeight();
		this.sm     = this.width >= thresholds.sm;
		this.md     = this.width >= thresholds.md;
		this.lg     = this.width >= thresholds.lg;
		this.xl     = this.width >= thresholds.xl;
	}

	/**
	 * @return {boolean} true if the current viewport size reflects a "mobile" device (not necessarily actually a mobile device though)
	 */
	get onMobile() {
		return this.width <= thresholds.sm;
	}

	/**
	 * @return {boolean} true if the current viewport size reflects a "desktop" device
	 */
	get onDesktop() {
		return this.width > thresholds.sm;
	}

	private onResize() {
		clearTimeout(this.resizeTimeout);
		this.resizeTimeout = window.setTimeout(this.update.bind(this), 50);
	}

	private getClientWidth() {
		return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
	}

	private getClientHeight() {
		return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
	}

}

export default new Breakpoint();
