














import { Component, Prop, Mixins } from '$/lib/vueExt';
import VueValidationMixin          from '$/lib/mixins/VueValidationMixin';

import { Building } from '$/entities/Building';
import { EntityID } from '$/entities/BaseEntity';

@Component({ model : { event : 'update:value' } })
export default class BuildingSelector extends Mixins(VueValidationMixin) {

	@Prop({ default : null })
	readonly value: Building | string;

	@Prop({ default : () => undefined })
	readonly override: Building;

	@Prop({ type : Boolean, default : false })
	readonly allowNew: boolean;

	buildings: Building[] = [];
	total = 0;

	get localBuildings() {
		return _.compact(_.uniqBy([ this.override, ...this.buildings ], 'id'));
	}

	get options() {
		return [ this.allowNew ? { id : 'new', getName : () => 'New Property' } : '', ...Building.sortBuildings(this.localBuildings) ];
	}

	formatter(building: string | Building) {
		if (building === '') {
			return 'All Properties';
		}
		if (building && typeof building !== 'string') {
			return building.getName();
		}
	}

	mounted() {
		void this.loadBuildings();
	}

	findBuilding(id: EntityID) {
		return this.buildings?.find(building => building.id === id);
	}

	async loadBuildings($search?) {
		[ this.buildings, this.total ] = await Building.findAndCount({ where : { $search } });
	}

}
