import Axios   from 'axios';
import { Vue } from '$/lib/vueExt';

import { User }                 from '$/entities/User';
import { Authentication }       from '$/entities/Authentication';
import { Entity, SyncEntities } from '$/entities/BaseEntity';

import { BaseRole as BaseRoleCommon } from '$/common/entities/roles/BaseRole';
export                              * from '$/common/entities/roles/BaseRole';

let current: BaseRole;
let currentPromise: Promise<void>;

@Entity()
export class BaseRole extends BaseRoleCommon {

	/**
	 * If the client is authenticated, this returns the current Role.
	 */
	@SyncEntities()
	static get current(): BaseRole {
		return current;
	}

	static get currentID() {
		return current?.id ?? '';
	}

	/**
	 * @param {boolean} [options.redirectToLogin=true] if true, redirects to login if credentials no longer valid
	 * @param {boolean} [options.force=false] if true, forces a fresh load (even if current role is already cached)
	 */
	static async loadCurrent({ redirectToLogin = true, force = false } = {}): Promise<BaseRole> {
		if (!currentPromise || force) {
			currentPromise = (async () => {
				if (current && !force) {
					return;
				}

				try {
					// make the instance Vue-reactive since it's being used in many places
					current = Vue.observable(await BaseRole.findOne({ where : { id : 'me' }, relations : [ 'org' ] }));
					if (current.org) {
						await current.org.loadRelation('package');
					}
					// NOTE: it's possible that BaseRole.current.user.id != User.current.id (when assuming another user's Role)
					await User.loadCurrent({ redirectToLogin });
				}
				catch (err) {
					if (err.status === 403 || err.status === 401) {
						if (redirectToLogin) {
							// credentials no longer valid; user must login again
							void Authentication.signOut({ routeTo : document.location.href });
						}
						current = undefined;	// user is not logged in
					}
					else {
						throw err;
					}
				}
			})();
		}

		try {
			await currentPromise;
		}
		finally {
			currentPromise = null;
		}
		return current;
	}

	/**
	 * If renter joined the app earlier than the Landlord they can send an invitation to Landlord to join the app.
	 */
	async sendInvite(to: HasName & HasEmail) {
		await Axios.post(BaseRole.collectionUrl(`${this.id}/invitation`), { landlord : to });
	}

	async createReferral(inviteeInfo: HasName &  HasEmail) {
		await Axios.post(BaseRole.collectionUrl('refer'), { inviteeInfo });
	}

}
