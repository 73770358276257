
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","width":"13.597","height":"13.536","viewBox":"0 0 13.597 13.536"}, attrs),
              ...rest,
            },
            children.concat([_c('g',{attrs:{"id":"Group_24","data-name":"Group 24","transform":"translate(-48.664 -315.732)"}},[_c('path',{attrs:{"id":"Path_31","data-name":"Path 31","d":"M238.962,543.256l.531-1.531,1.47.653,1.123-1.184,1.1,1.184,1.511-.653.49,1.531H246.8l-.143,1.613,1.429.715-.755,1.368,1.041,1.225-1.286,1,.49,1.531-1.613.367-.143,1.613-1.613-.327-.8,1.368-1.327-.939-1.348.939-.837-1.368-1.613.327-.143-1.613-1.572-.367.51-1.531-1.307-1,1.1-1.225-.837-1.368,1.511-.715-.163-1.613Z","transform":"translate(-186.612 -224.962)","fill":"#007e15","stroke":"#007e15","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"1"}}),_c('path',{attrs:{"id":"Path_32","data-name":"Path 32","d":"M586.814,257.7l1.614,1.774,3.961-4.231","transform":"translate(-534.139 65.887)","fill":"none","stroke":"#fff","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"1"}})])])
          )
        }
      }
    