
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M16.648 1.21h-4.101V.587A.586.586 0 0 0 11.96 0H7.273a.586.586 0 0 0-.585.586v.625H2.585A.586.586 0 0 0 2 1.797v17.617c0 .324.262.586.586.586h14.062a.586.586 0 0 0 .586-.586V1.797a.586.586 0 0 0-.586-.586zM7.86 1.173h3.516v1.21H7.859v-1.21zm8.204 17.656H3.171V2.383h3.515v.586c0 .323.263.586.586.586h4.688a.586.586 0 0 0 .586-.586v-.586h3.515v16.445z"}}),_c('path',{attrs:{"d":"M4.93 4.727a.586.586 0 1 0 0 1.171h9.375a.586.586 0 1 0 0-1.171H4.93zm9.375 2.343H4.93a.586.586 0 1 0 0 1.172h9.375a.586.586 0 1 0 0-1.172zm0 2.344H9.617a.586.586 0 1 0 0 1.172h4.688a.586.586 0 1 0 0-1.172zm0 2.344H9.617a.586.586 0 1 0 0 1.172h4.688a.586.586 0 1 0 0-1.172zm0 2.344H9.617a.586.586 0 1 0 0 1.171h4.688a.586.586 0 1 0 0-1.171zm0 2.343H9.617a.586.586 0 1 0 0 1.172h4.688a.586.586 0 1 0 0-1.172zM6.102 12.93a.587.587 0 1 1 .508-.879.586.586 0 1 0 1.014-.587 1.763 1.763 0 0 0-.936-.776v-.665a.586.586 0 1 0-1.172 0v.664a1.76 1.76 0 0 0-1.172 1.657 1.76 1.76 0 0 0 1.758 1.758.587.587 0 1 1-.508.878.586.586 0 1 0-1.015.587c.212.366.548.639.937.777v.64a.586.586 0 1 0 1.171 0v-.64a1.76 1.76 0 0 0 1.172-1.657 1.76 1.76 0 0 0-1.757-1.757z"}})])
          )
        }
      }
    