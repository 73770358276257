














/**
 * Overwrite BFormCheckbox to add support for mutating certain props
 */
import { BFormCheckbox as BFormCheckboxOriginal } from 'bootstrap-vue';
import { Component, Prop }                        from '$/lib/vueExt';
import VueValidationMixin                         from '$/lib/mixins/VueValidationMixin';

@Component({
	components   : { BFormCheckboxOriginal },
	model        : { prop : 'checked' },
	inheritAttrs : false,
})
export default class FormCheckbox extends VueValidationMixin {

	@Prop()
	readonly plaintext: boolean;

	@Prop({ default : 'On' })
	readonly trueLabel: string;

	@Prop({ default : 'Off' })
	readonly falseLabel: string;

	get label() {
		return this.$attrs.checked ? this.trueLabel : this.falseLabel;
	}

}
