import { BaseRole }         from '$/entities/roles/BaseRole';
import { CommonEntity, ChildEntity, OneToMany } from '$/entities/BaseEntity';
import type { Application } from '$/entities/tenantScreening/Application';
import { User }             from '$/entities/User';

/**
 * Applicants are users invited by landlords to apply for a vacancy.
 */
@ChildEntity() @CommonEntity()
export class Applicant extends BaseRole {

	@OneToMany('Application', 'applicant', { persistence : false })
	application: Application[] = undefined;

	static getSample<T extends typeof Applicant>(this: T): InstanceType<T> {
		const sample = new this() as InstanceType<T>;
		sample.user  = User.getSample();
		return sample;
	}

}
