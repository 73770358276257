import Axios  from 'axios';
import Moment from 'moment';

import { User }                  from '$/entities/User';
import { Entity, type EntityID } from '$/entities/BaseEntity';

import { Email as EmailCommon } from '$/common/entities/emails/Email';
export *                        from '$/common/entities/emails/Email';

@Entity()
export class Email extends EmailCommon {

	/**
	 * The body of the HTML.
	 * Not directly transmitted via the standard REST API but used explicitly sometimes.
	 */
	htmlBody: string = '';

	/**
	 * The from of the email.
	 * Not directly transmitted via the standard REST API but used explicitly sometimes.
	 */
	from: string = '';

	static async getEmailTypes(instanceOf?: string) {
		return (await Axios.get(Email.collectionUrl('types'), { params : { instanceOf } })).data as { $class: string; label: string}[];
	}

	static getSampleURL(emailType: string): string {
		return Email.collectionUrl(`types/${emailType}/sample`);
	}

	static async getTypeSample(emailType: string): Promise<Email> {
		const emailData = (await Axios.get(this.getSampleURL(emailType))).data;
		const sample    = this.getSample();
		sample.subject  = emailData.subject;
		sample.htmlBody = emailData.htmlBody;
		sample.from     = emailData.from;
		return sample;
	}

	static getPreviewURL(emailId: EntityID) {
		return this.collectionUrl(`${emailId}/body`);
	}

	static getSample() {
		const user       = User.getSample();
		const sample     = new this({ isSample : true });
		sample.to        = user.email;
		sample.createdOn = Moment().subtract(_.random(2, 14), 'days').toDate();
		return sample;
	}

	static async getDoNotEngageEmails() {
		return (await Axios.get(Email.collectionUrl('/doNotEngage'))).data;
	}

	static async validateAddress(email: EmailAddress) {
		return (await Axios.get(Email.collectionUrl('/validateAddress'), { params : { email } })).data;
	}

}
