
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"-5 -.25 40 40"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"fill-rule":"evenodd","d":"M19.504 17.738c9.683 0 10.187 8.723 10.187 12.512 0 5.176-5.824 9.27-11.082 9.27h-7.527C5.832 39.52 0 35.426 0 30.25c0-3.79.508-12.512 10.188-12.512zm-3.375 14.66h-2.742a4.669 4.669 0 0 1 2.187-4.757c.828-.657 1.973-1.36 1.973-2.52a2.274 2.274 0 0 0-.848-1.59 2.33 2.33 0 0 0-1.75-.496c-1.535-.035-2.836 1.102-2.98 2.61l-2.762-.348c.297-2.77 2.805-4.79 5.617-4.524 2.692 0 5.66 1.465 5.66 4.438 0 1.867-1.636 3.152-2.968 4.238a2.791 2.791 0 0 0-1.387 2.946zm-2.742 3.973v-2.957h2.742v2.957zM14.847.008c4.555 0 8.243 3.64 8.243 8.125 0 4.488-3.692 8.129-8.246 8.129-4.551 0-8.246-3.637-8.246-8.125C6.594 3.645 10.289 0 14.844 0zm0 0"}})])
          )
        }
      }
    