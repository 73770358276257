import random              from '$/lib/Random';
import { Mixin }           from '$/lib/utils';
import { JSONTransformer } from '$/lib/columnTransformers';
import Validate            from '$/lib/Validate';
import Errors              from '$/lib/Errors';
import { Country }         from '$/lib/Address';
import { Not }             from '$/lib/typeormExt';

import { JSONable, Field }                  from '$/entities/lib/JSONable';
import { BaseOrgEntity }                    from '$/entities/Organization';
import { Address }                          from '$/entities/Address';
import { DocumentType, FileUploadProgress } from '$/entities/FileExt';
import type { Lease }                       from '$/entities/Lease';
import type { File }                        from '$/entities/File';
import { Column, OneToMany, CommonEntity, getEntityClass } from '$/entities/BaseEntity';

export enum BuildingStatus {
	Current = 'current',
	Former  = 'former',
}

export enum BuildingUnitType {
	Apartment  = 'apartment',
	Condo      = 'condo',
	Basement   = 'basement',
	Garage     = 'garage',
	Townhouse  = 'townhouse',
	House      = 'house',
	Duplex     = 'duplex',
	Triplex    = 'triplex',
	Fourplex   = 'fourplex',
	Room       = 'room',
	Trailer    = 'trailer',
	Commercial = 'commercial'
}

export const bedroomOptions = [
	{
		value : undefined,
		text  : '--',
	},
	{
		value : 0,
		text  : 'Bachelor',
	},
	..._.range(1, 10).map(i => ({ value : i, text : `${i}` })),
	{
		value : -1,
		text  : 'N/A',
	},
];

export interface BuildingDoc {
	title: string;
	docType: DocumentType;
	file?: File;
	tooltip: string;
}

@Mixin(JSONable)
export class BuildingUnit {

	@Field()
	@Validate({ required : true })
	number: string;

	@Field()
	@Validate({ enum : BuildingUnitType })
	type: BuildingUnitType;

	@Field()
	bedrooms?: number;

	constructor(initialValues?: Partial<BuildingUnit>) {
		_.assign(this, initialValues);
	}

	get label() {
		let label = `${this.number}`;

		if (this.type) {
			label += ` - ${_.startCase(this.type)}`;
		}

		if (!_.isNil(this.bedrooms) && this.bedrooms > -1) {
			label += ` - ${this.bedrooms > 0 ? `${this.bedrooms} Bedrooms` : 'Bachelor'}`;
		}

		return label;
	}

}

let sample: Building;

/**
 * A physical property that the landlords rent out.
 */
@CommonEntity()
export class Building extends BaseOrgEntity {

	/**
	 * Optional nickname given to the building by the building owner.
	 */
	@Column()
	userName: string = '';

	@Column()
	@Validate({ required : true, enum : BuildingStatus })
	status: BuildingStatus = BuildingStatus.Current;

	@Column({ type : 'json', default : () => "('{}')" })
	@Validate({
		required  : true,
		recursive : {
			street     : { required : true },
			city       : { required : true },
			province   : { required : true },
			country    : { required : true },
			postalCode : { required : true },
			county     : { required : function(this: Address) {
				return this.isAmerican;
			} },
		},
		custom : [ checkDuplicateAddress, checkUnitNumber ],
	})
	address: Address = new Address();

	@Column({ asExpression : "address->>'$.street'", nullable : true })
	get street() {
		return this.address.street;
	}
	set street(value: string) {
		this.address.street = value;
	}

	@Column({ asExpression : "address->>'$.city'", nullable : true })
	get city() {
		return this.address.city;
	}
	set city(value: string) {
		this.address.city = value;
	}

	@Column({ asExpression : "address->>'$.province'", nullable : true })
	get province() {
		return this.address.province;
	}
	set province(value: string) {
		this.address.province = value;
	}

	@Column({ asExpression : "address->>'$.country'", nullable : true, length : '2' })
	get country() {
		return this.address.country;
	}
	set country(value: Country) {
		this.address.country = value;
	}

	@Column({ asExpression : "address->>'$.postalCode'", nullable : true })
	get postalCode() {
		return this.address.postalCode;
	}
	set postalCode(value: string) {
		this.address.postalCode = value;
	}

	get isCanadian() {
		return this.address.isCanadian;
	}

	get isAmerican() {
		return this.address.isAmerican;
	}

	/**
	 * Number of units the LL claims to own in the building.
	 * The LL may not add all of them to the units property, but we need to know how many there are to determine the LL's size.
	 */
	@Column({ type : 'int', default : 0 })
	@Validate({ min : 1, max : 1000, required : true })
	unitsClaimedToOwn: number = 0;

	/**
	 * Total number of units in the building, whether they're added to the units array or just claimed by the LL.
	 */
	@Column({ asExpression : 'GREATEST(unitsClaimedToOwn, JSON_LENGTH(units))', nullable : false })
	get totalOwnedUnits() {
		return Math.max(this.unitsClaimedToOwn, this.units.length);
	}
	set totalOwnedUnits(value: number) {
		this.unitsClaimedToOwn = value;
	}

	/**
	 * The set of units within the building that the landlord is renting out.
	 */
	@Column({ type : 'json', transformer : JSONTransformer({ jsonable : BuildingUnit }) })	// JSONTransformer required because of the array
	@Validate({
		minLength : { value : 1,        message : 'there must be at least one unit added' },
		uniqueBy  : { value : 'number', message : 'cannot have multiple units with the same number' },
		forEach   : true,
	})
	units: BuildingUnit[] = [];

	/**
	 * Used to optionally link building record to records in external systems
	 */
	@Column({ type : 'varchar', length : 50  })
	@Validate({ maxLength : 50 })
	externalId: string = '';

	@OneToMany('Lease', 'building', { persistence : false })
	leases: Lease[];

	/**
	 * Gets the building's name (either user specified or derived from the address)
	 * @deprecated use getName() which allows for options
	 */
	get name(): string {
		return this.userName || this.address.format({ region : false });
	}

	/**
	 * Gets the building's name (either user specified and/or derived from the address)
	 */
	getName({ alwaysIncludeAddress = false } = {}) {
		let result = this.userName;
		if (!result || alwaysIncludeAddress) {
			result += (result ? ' - ' : '') + this.address.format({ region : false });
		}
		return result;
	}

	/**
	 * Returns this.units but sorted according to the unit's number
	 */
	get unitsSorted(): BuildingUnit[] {
		// sort by any numeric part of the unit number followed by any non-numeric
		return this.units.slice().sort((unit1: BuildingUnit, unit2: BuildingUnit) => {
			const num1 = Number(unit1.number.replace(/[^\d]/g, ''));
			const num2 = Number(unit2.number.replace(/[^\d]/g, ''));
			if (num1 !== num2) {
				return num1 < num2 ? -1 : 1;
			}

			// values are the same so fallback non-numeric part of the unit number (if any)
			// eg: 2A < 2B but 1B < 2A
			return unit1.number.replace(/\d/g, '') < unit2.number.replace(/\d/g, '') ? -1 : 1;
		});
	}

	/**
	 * @return the the address of the building.
	 */
	getAddress({ street = true, city = true, region = true } = {}): string {
		return this.address.format({ street, city, region });
	}

	setFullAddress(street: string, city: string, province: string, country: Country, postalCode: string) {
		this.address = new Address({ street, city, province, country, postalCode });
	}

	async getBuildingDocs(): Promise<BuildingDoc[]> {
		const FileEntity = getEntityClass<typeof File>('File');
		return [
			{
				docType : DocumentType.BuildingOwnership,
				title   : 'Ownership Proof',
				tooltip : 'A document showing proof of ownership of the building such as a Property Deed, Mortgage Document, Title Insurance, Purchase Agreement, Title Search document or a Property Management Agreement.',
				file    : await FileEntity.findOne({
					where : {
						documentType : DocumentType.BuildingOwnership,
						reference    : this.getReferenceString(),
						orgId        : this.orgId,
					},
					order  : { createdOn : 'DESC' },
					create : true,
				}),
			},
		];
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	async uploadBuildingDocs(docs: File[], progressUpdate?: FileUploadProgress) {
		throw new Errors.NotImplemented();
	}

	/**
	 * HACK: properly deserialize this.units
	 * SHOULDDO: build this functionality generically into BaseEntity.toObject using the Column's type property
	 */
	static toObject(...args) {
		return correctForUnits(super.toObject.apply(this, args));

		function correctForUnits(obj) {
			if (Array.isArray(obj)) {
				obj.forEach(correctForUnits);
			}
			else {
				const value = _.isString(obj.units) ? JSON.parse(obj.units) : obj.units;
				if (value != undefined) {
					obj.units = Array.isArray(value) ? value.map(v => _.pick(v, 'number', 'type', 'bedrooms')) : [];
				}
			}
			return obj;
		}
	}

	get isSample() {
		return this === sample;
	}

	static getSample<B extends typeof Building>(this: B): InstanceType<B> {
		if (!sample) {
			sample     = new this();
			sample.id  = 'sample';
			sample.ver = 1;	// prevents the sample from registering as new (via isNew)
			sample.setFullAddress('871 Treehouse Dr', 'Toronto', 'Ontario', Country.CA, 'A1B 2C3');
			sample.units = [ new BuildingUnit({ number : '734', type : BuildingUnitType.Triplex, bedrooms : 1 }) ];
		}
		return sample as InstanceType<B>;
	}

	static sortBuildings(buildings: Building[]) {
		return _.orderBy(buildings, [ 'street', 'city', 'province', 'country' ]);
	}

	/**
	 * Fills in random but plausible values for the given building entity.
	 */
	static generateAtRandom<B extends typeof Building>(this: B, { building = new this(), country = Country.CA } = {}): InstanceType<B> {
		const adjectives = [
			'Pinehill', 'Maplewood', 'Oakridge', 'Cedarview', 'Willowbrook', 'Riverside', 'Lakeside', 'Sunnyvale', 'Greenfield', 'Hillcrest',
			'Brookside', 'Meadowview', 'Woodland', 'Springfield', 'Clearwater', 'Silverlake', 'Goldenfield', 'Mountainview', 'Foresthill', 'Parkview',
		];

		const nouns = [
			'Homes', 'Estates', 'Residences', 'Apartments', 'Villas', 'Cottages', 'Manors', 'Retreats', 'Lodges', 'Sanctuaries',
			'Oases', 'Hideaways', 'Homesteads', 'Chateaus', 'Cabins', 'Domiciles', 'Abodes', 'Dwellings', 'Havens', 'Bungalows',
		];

		building.userName = `${random.pickOne(adjectives)} ${random.pickOne(nouns)}`;
		building.address  = new Address({
			street     : random.street(),
			city       : random.city(),
			country,
			province   : country === Country.CA ? 'ON' : 'NY',
			postalCode : random.postalCode(country),
			county     : country === Country.CA ? '' : random.city(),
		});
		building.units = _.range(0, 3).map(
			() => new BuildingUnit({
				number   : random.integer(100, 999).toString(),
				type     : random.pickOne(Object.values(BuildingUnitType)),
				bedrooms : random.integer(1, 5),
			})
		);
		building.totalOwnedUnits = building.units.length;
		return building as InstanceType<B>;
	}

	/**
	 * @returns a list of possible county names for the given US address
	 */
	static async findCountyName(address: Address): Promise<string[]> { // eslint-disable-line @typescript-eslint/no-unused-vars
		throw new Errors.NotImplemented();
	}

}

async function checkDuplicateAddress(this: Building) {
	if (await this.entityClass.doesExist({
		where : {
			id       : Not(this.id),
			orgId    : this.org?.id ?? this.orgId,
			street   : this.street,
			city     : this.city,
			province : this.province,
			country  : this.country,
		},
	 })) {
		return 'A property with the same address already exists. Please check the address and try again.';
	}
}

/**
 * Moves the building.address.unitNumber into the building.units array if it doesn't already exist
 */
function checkUnitNumber(this: Building, value: Address, property: string, { autofix = false } = {}) {
	if (!value || _.isEmpty(value.unitNumber) || !autofix) {
		return '';
	}

	if (this.units.length === 1 && this.units[0].number === '1') {
		this.units[0].number = value.unitNumber;
	}
	else if (!_.find(this.units, { number : value.unitNumber })) {
		this.units.push(new BuildingUnit({ number : value.unitNumber, type : BuildingUnitType.Apartment, bedrooms : 0 }));
	}
	value.unitNumber = '';
}
