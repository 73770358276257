import Moment from 'moment';

import env          from '$/lib/env';
import { Index }    from '$/lib/typeormExt';
import Validate     from '$/lib/Validate';
import { Debounce } from '$/lib/utils';
import { AppSection, isRestrictedByLocation }                  from '$/lib/restrictions';
import { BooleanTransformer, DayTransformer, DateTransformer } from '$/lib/columnTransformers';

import { LeaseDoc }                 from '$/entities/LeaseExt';
import Permissions, { Context }     from '$/entities/lib/Permissions';
import { Building }                 from '$/entities/Building';
import { PackageFeature }           from '$/entities/Package';
import { Tenant }                   from '$/entities/Tenant';
import { LeaseBalance }             from '$/entities/LeaseBalance';
import { Renter }                   from '$/entities/roles/Renter';
import { BaseRole }                 from '$/entities/roles/BaseRole';
import { RolePermission }           from '$/entities/roles/RolePermission';
import { Collections }              from '$/entities/roles/Collections';
import { DocumentType, FileStatus } from '$/entities/FileExt';
import { LeaseStatus }              from '$/entities/LeaseExt';
import type { File }                from '$/entities/File';
import type { Email }               from '$/entities/emails/Email';
import type { LeaseDraft }          from '$/entities/LeaseDraft';
import type { Comment }             from '$/entities/Comment';
import type { Discount }            from '$/entities/billing/Discount';
import { BaseOrgEntity, Organization, orgMemberRead } from '$/entities/Organization';
import { CollectionsLandlordDetails, CollectionsStatus, ReportingStatus, ReportingType }              from '$/entities/ReportingStatus';
import { ManyToOne, Column, OneToMany, OneToOne, JoinColumn, CommonEntity, Relation, getEntityClass } from '$/entities/BaseEntity';

export * from '$/entities/LeaseExt';

// The day of the month we submit the payment records to EFX
export const reportSubmissionDay = 15;
export const maxTenantsOnLease   = 10;

// Until this date, all old lease balances are going to be editable
// Useful for correcting mistakes in old LeaseBalances before submitting an Equifax report
const allowEditingOldLeaseBalancesUntil = env.config('allowEditingOldLeaseBalancesUntil');

// The date after which leases must have documents uploaded to report to Equifax as per our contract with Equifax
export const leaseUploadRequiredDate = Moment('2024-11-01');

export enum SampleEmails {
	CurrentTenantInvite = 'currentTenantInvite',
	FormerTenantInvite  = 'formerTenantInvite',
	RentReminder        = 'rentReminder',
	LateWarning         = 'lateWarning',
	DebtReporting       = 'debtReporting',
	CreditAlert         = 'creditAlert',
	Collections         = 'collections'
}

export enum EmotionStatus {
	Default = 'default',
	Angry   = 'angry',
	Happy   = 'happy',
}

export enum CollectionsDocRequired {
	Mandatory   = 'mandatory',
	Recommended = 'stronglyRecommended',
	Available   = 'ifAvailable',
}

export interface CollectionsDoc {
	title: string;		// the user-facing title of the typeof document
	docType: DocumentType;
	file?: File;
	requirementLevel: CollectionsDocRequired;
	typeNote?: string;
	tooltip: string;
}

/**
 * The sample lease is used when the user has no other leases available so as not to show empty datasets.
 */
let sample: Lease;

/**
 * A Lease represents a period of time during which a relatively set number of Tenants
 * rent out a specific Building and unit.
 * These entities are created by landlords.
 */
@CommonEntity()
export abstract class Lease extends BaseOrgEntity {

	@Column() @Index()
	@Validate({ required : true, enum : LeaseStatus })
	status: LeaseStatus = undefined;

	/**
	 * The date after which the this lease is to be excluded from reporting and syncing with external systems.
	 */
	@Column({ type : 'date', nullable : true, transformer : DateTransformer })
	excludedOn: Date = null;

	/**
	 * True if this lease is current and active.
	 */
	get isCurrent() {
		return this.status === LeaseStatus.Current;
	}

	/**
	 * True if the lease has ended and tenant is no longer a resident.
	 */
	get isFormer() {
		return this.status === LeaseStatus.Former;
	}

	/**
	 * True if the lease is a former lease or is archived.
	 */
	get isClosed() {
		return this.isFormer || this.isArchived;
	}

	@Column()
	emoji: EmotionStatus = EmotionStatus.Default;


	@Column({ type : 'date', nullable : true, transformer : DayTransformer })
	@Validate({ date : true,
		// SHOULDDO add back once we are comfortable with the data
		// custom : function checkStartDate(this: Lease, value) {
		// 	if (value && this.endDate && Moment(value).isAfter(Moment(this.endDate))) {
		// 		return 'lease start date cannot be after lease end date';
		// 	}

		// 	return '';
		// },
	})
	startDate: Date = null;

	/**
	 * The last date of the tenancy (status must be former)
	 * Tenant entities validate that the move in date is before the end date.
	 */
	@Column({ type : 'date', nullable : true, transformer : DayTransformer })
	@Validate({
		date     : true,
		required : {
			value : function() {
				return this.isFormer && !this.endDate;
			},
			message : 'the lease end date is missing',
		},
		custom : function checkEndDate(this: Lease, value) {
			// COULDDO: If we want to support future end dates, we'll need to rethink how we handle lease current/former status
			// The lease status will need to be based on end date (and possibly start date as well).  Otherwise, we'll have leases that are
			// marked as former because they end some time in the future, but should still be treated as current until then.
			if (value && Moment().isBefore(value, 'day')) {
				return 'lease end date cannot be in the future';
			}

			return '';
		},
		// SHOULDDO add back once we are comfortable with the data
		// custom : function checkEndDate(this: Lease, value) {
		// 	if (value && this.startDate && Moment(value).isBefore(Moment(this.startDate))) {
		// 		return 'lease end date cannot be before lease start date';
		// 	}

		// 	return '';
		// },
	})
	endDate: Date = null;

	/**
	 * The property at which the tenant is residing.
	 */
	@ManyToOne('Building', { onDelete : 'CASCADE' })
	@Validate({ required : true })
	building: Building = undefined;

	/**
	 * The unit at the property at which the tenant is residing.
	 */
	@Column()
	@Validate({ required : true })
	unit: string = '';

	/**
	 * The monthly rent amount charged for this lease.
	 */
	@Column('decimal', { precision : 10, scale : 2 })
	@Validate({
		required : true,
		number   : true,
		min      : 1,
		max      : async function() {
			await this.loadRelation('org');
			return this.org.limits.monthlyLeaseAmountLimit;
		},
	})
	monthlyAmount: number = 0;

	@OneToMany('Tenant', 'lease', { persistence : false })
	@Permissions({ write : Permissions.serverOnly })
	@Validate({ maxLength : maxTenantsOnLease, custom : checkDuplicateTenants })
	tenants: Tenant[] = undefined; // needs to be explicitly set to undefined in order to be Vue reactive

	@OneToOne('LeaseDraft', { nullable : true })
	@JoinColumn()
	draft: LeaseDraft;

	@OneToMany('LeaseBalance', 'lease', { persistence : false })
	@Permissions({ write : Permissions.serverOnly })
	leaseBalances: LeaseBalance[] = undefined; // needs to be explicitly set to undefined in order to be Vue reactive

	@OneToOne('LeaseBalance', { persistence : false, nullable : true, onDelete : 'SET NULL' })
	@JoinColumn()
	@Permissions({ write : Permissions.serverOnly })
	@Validate({ custom : latestLeaseBalanceValidator })
	latestLeaseBalance: LeaseBalance = undefined;

	/**
	 * SHOULDDO: add a custom OneToMany relationship that uses the Comments 'referenceId,' format
	 * or create child entities for Comments and use the original typeorm OneToMany relationship
	 * NOTE: the OneToMany relation below is for typeorm to register this field as an entity field. DO NOT USE this relation
	*/
	@OneToMany('Comment', 'reference', { persistence : false })
	@Permissions({ write : Permissions.serverOnly })
	comments: Comment[];

	@Column({ type : 'json', default : () => "('{}')" })
	@Permissions({ write : canWriteReportingStatus })
	@Validate({ custom : checkStatus })
	reportingStatus: ReportingStatus = new ReportingStatus();

	get rentReporting() {
		return this.reportingStatus.type === ReportingType.Rent;
	}

	get rentReportingById() {
		return this.rentReporting ? this.reportingStatus.reportedById : null;
	}

	get debtReporting() {
		return this.reportingStatus.type === ReportingType.Debt;
	}

	get pendingDebtReporting() {
		return this.reportingStatus.type === ReportingType.PendingDebt;
	}

	get collections() {
		return this.reportingStatus.type === ReportingType.Collections;
	}

	get isCollectionsActive() {
		return [ CollectionsStatus.Reported, CollectionsStatus.Submitted ].includes(this.reportingStatus.collectionsStatus);
	}

	get isCollectionsDraft() {
		return this.reportingStatus.collectionsStatus === CollectionsStatus.Draft;
	}

	get isCollectionsClosed() {
		return [
			CollectionsStatus.ClosedBankrupt,
			CollectionsStatus.ClosedPaidInFull,
			CollectionsStatus.ClosedSettledInFull,
			CollectionsStatus.ClosedPlacedInError,
		].includes(this.reportingStatus.collectionsStatus);
	}

	/**
	 * When true, sends all active tenants on this Lease an email reminder to pay rent.
	 */
	@Column({ type : 'boolean', transformer : BooleanTransformer })
	@Permissions({
		read  : orgMemberRead,
		write : checkLeaseFeaturePermission('emailMonthlyPaymentReminder'),
	})
	@Validate({ custom : checkLeaseFeature })
	emailMonthlyPaymentReminder: boolean = false;

	/**
	 *  Send a copy of the emails to the landlord
	 */
	@Column({ type : 'boolean', transformer : BooleanTransformer })
	@Permissions({
		read  : orgMemberRead,
		write : checkLeaseFeaturePermission('emailSendCopy'),
	})
	@Validate({ custom : checkLeaseFeature })
	emailSendCopy: boolean = false;

	/**
	 * whether a lease can be connected to a tenant, does not go back to false once true (in general)
	 */
	@Column({ type : 'boolean', transformer : BooleanTransformer })
	@Permissions({
		read  : orgMemberRead,
		write : Permissions.serverOnly,
	})
	shareLeaseWithTenant: boolean = false;

	/**
	 *  last time introduction email was triggered
	 */
	@Column({ type : 'datetime', nullable : true, transformer : DateTransformer })
	@Permissions({
		read  : orgMemberRead,
		write : canWriteIntroEmailTriggeredOn,
	})
	@Validate({ custom : async function(newValue: Date, property) {
		if (!this.isEdited('introEmailTriggeredOn')) {
			return;
		}

		const result = await checkLeaseFeature.call(this, newValue, property);
		if (result && !(this.debtReporting || this.collections)) {
			return result;
		}

		const oldValue = this.getOldValue('introEmailTriggeredOn');
		if (newValue && oldValue && newValue.valueOf() !== oldValue.valueOf() && Moment(oldValue).isAfter(Moment(newValue).subtract(30, 'days'))) {
			return 'Introduction email can only be sent at most once every 30 days.';
		}
	} })
	introEmailTriggeredOn: Date = null;

	/**
	 * When true, sends all active tenants on this Lease an email reminder to pay rent.
	 */
	@Column({ type : 'boolean', transformer : BooleanTransformer })
	@Permissions({
		read  : orgMemberRead,
		write : checkLeaseFeaturePermission('emailSendLatePaymentWarning'),
	})
	@Validate({ custom : checkLeaseFeature })
	emailSendLatePaymentWarning: boolean = false;

	/**
	 * If true, a comment has been flagged by as user as inappropriate
	 */
	@Column({ type : 'boolean', transformer : BooleanTransformer })
	flagged: boolean = false;

	/**
	 * discount (not persisted in DB)
	 */
	@Relation('Discount')
	discount: Discount = null;

	@Column({ nullable : true, length : 6, unique : true })
	collectionsClaimNumber: string = null;

	/**
	 * Used to group leases together for imports
	 */
	@Column({ type : 'varchar', length : 50, default : '' })
	leaseGroupId: string = '';

	/**
	 * @returns true if this lease's status was Current on the given date (looks at this.endDate)
	 */
	wasCurrentOn(date: Date | Moment.Moment): boolean {
		return this.isCurrent || (date && this.endDate && Moment(date).isSameOrBefore(this.endDate, 'day'));
	}

	/**
	 * @returns true if this lease's status was Former on the given date.
	 * This works by looking at this.endDate, which is only set if the status is Former, so this always returns false if lease status is Current,
	 * even if it was former at some point in the past.
	 */
	wasFormerOn(date: Date | Moment.Moment): boolean {
		return !this.wasCurrentOn(date);
	}

	/**
	 * @returns the reporting type for the given date range.
	 */
	getReportingType(dateRangeStart: Date | Moment.Moment, dateRangeEnd: Date | Moment.Moment): ReportingType {
		// basic sanity check
		if (!dateRangeStart || !dateRangeEnd || dateRangeStart > dateRangeEnd) {
			throw new Error('invalid date range');
		}

		// Combine the current status into an array with the history so we can just search through all of it
		const allStatusChanges = [ this.reportingStatus, ...this.reportingStatus.history ];

		const leaseCurrentAtStart = this.wasCurrentOn(dateRangeStart);
		const leaseCurrentAtEnd   = this.wasCurrentOn(dateRangeEnd);

		const date = leaseCurrentAtStart === leaseCurrentAtEnd ? dateRangeEnd : dateRangeStart;
		return (allStatusChanges.find(status => Moment(status.typeChangedOn).isSameOrBefore(date)) ?? _.last(allStatusChanges))?.type;
	}

	/**
	 * Returns the oldest date after which LeaseBalances for this Lease can still be edited.
	 * @param {LeaseBalance[]} leaseBalances - the set of existing lease balances for this lease
	 */
	getOldestEditableMonth(leaseBalances?: LeaseBalance[], { isBeingImported = false } = {}): Date {
		if (isBeingImported) {
			return new Date(0);
		}

		if (this.isCurrent) {
			const canEditOldLeaseBalances     = allowEditingOldLeaseBalancesUntil && Moment().isSameOrBefore(allowEditingOldLeaseBalancesUntil);
			const isBeforeReportSubmissionDay = Moment().date() < reportSubmissionDay;
			return Moment().startOf('month').subtract(isBeforeReportSubmissionDay || canEditOldLeaseBalances ? 1 : 0, 'month').toDate();
		}

		// for former leases, can edit the LeaseBalance for this month, or ever since the most recent lease balance
		leaseBalances = _.filter(leaseBalances, { lease : this });
		if (leaseBalances.length === 1) {
			return new Date(0);	// can always edit leaseBalance if there's only one
		}
		const mostRecentLeaseBalance = _.maxBy(leaseBalances, 'month');
		return mostRecentLeaseBalance ? mostRecentLeaseBalance.month : Moment().startOf('month').toDate();
	}

	@Debounce(100)
	async updateLatestLeaseBalance() {
		const latestLeaseBalance = await LeaseBalance.findOne({ where : { lease : this }, order : { month : 'DESC' } });

		if ((latestLeaseBalance?.id ?? null) === ((this as any).latestLeaseBalanceId ?? null)) {
			return;
		}

		if (this.isNew) {
			this.latestLeaseBalance = latestLeaseBalance;
		}
		else {
			// use `updateOne` instead of `this` because it loads up a fresh copy of the lease
			await (this.constructor as typeof Lease).updateOne(this.id, { latestLeaseBalance });
		}
	}

	/**
	 * Adds the given renter as a Tenant if not already added to this lease.
	 */
	// SHOULD DO : This is only used in tests. It should be removed and the functionality added to Toolbox.
	async ensureTenant(renter: Renter) {
		if (await Tenant.doesExist({ where : { lease : this, renter } })) {
			return;
		}
		const tenant = new Tenant(renter);
		tenant.lease = this as any;
		tenant.org   = this.org;
		await tenant.save();
	}

	async getDuplicateTenants(): Promise<Tenant[]> {
		await this.loadRelation('tenants');
		return this.tenants.filter(tenant => _.some(this.tenants, otherTenant =>
			tenant !== otherTenant
			&& _.areEqualCaseless(otherTenant.firstName, tenant.firstName)
			&& _.areEqualCaseless(otherTenant.lastName, tenant.lastName)
			&& (!tenant.isArchived && !otherTenant.isArchived)
			&& (otherTenant.dateOfBirth && tenant.dateOfBirth && Moment(otherTenant.dateOfBirth).isSame(tenant.dateOfBirth, 'day')
				|| !(otherTenant.dateOfBirth && tenant.dateOfBirth)
			   )
		));
	}

	canToggleRentReporting(role: BaseRole) {
		return !this.isSample
			&& role.hasFeature(PackageFeature.RentReporting)
			&& role.hasPermission(RolePermission.RentReportingToggleWrite)
			&& role.isVerified;
	}

	canToggleDebtReporting(role: BaseRole) {
		return !this.isSample
			&& role?.hasFeature(PackageFeature.DebtReporting)
			&& role?.isVerified
			&& (this.debtReporting || this.latestLeaseBalance?.amount > 0 || _.orderBy(this.leaseBalances, 'month', 'desc')?.[0]?.amount > 0);
	}

	async canEnableBuildingCollections(role: BaseRole) {
		await this.loadRelation('building');
		await role.loadRelation('org');
		return role.org.hasCollectionsFeature
			&& this.building?.address?.country
			&& !isRestrictedByLocation(AppSection.Collections, this.building.address);
	}

	async canToggleCollections(role: BaseRole) {
		return !this.isSample
			&& (await this.canEnableBuildingCollections(role) || role instanceof Collections)
			&& role.isVerified
			&& (this.collections || this.isCollectionsDraft || this.latestLeaseBalance?.amount > 0
				|| _.orderBy(this.leaseBalances, 'month', 'desc')?.[0]?.amount > 0
			);
	}

	setRentReporting(value: boolean, role: BaseRole) {
		if (!this.canToggleRentReporting(role)) {
			return;
		}

		this.reportingStatus = new ReportingStatus(value ? ReportingType.Rent : null, role.id);
	}

	setDebtReporting(value: boolean, role: BaseRole) {
		if (!this.canToggleDebtReporting(role)) {
			return;
		}

		// If collections status is in draft, preserve that
		const collectionsStatus = this.isCollectionsDraft ? CollectionsStatus.Draft : CollectionsStatus.NotStarted;
		this.reportingStatus    = new ReportingStatus(value ? ReportingType.Debt : null, role.id, collectionsStatus);

		if (!value) {
			this.introEmailTriggeredOn = null;
		}
	}

	async setCollectReporting(value: boolean, role: BaseRole, collectionsOptions: { submitting?: boolean; additionalDetails?: string; landlordDetails?: CollectionsLandlordDetails } = {}) {
		if (!await this.canToggleCollections(role)) {
			throw new Error('you cannot enable Collections on this lease');
		}

		if (!value) {
			if (this.collections) {
				this.reportingStatus       = new ReportingStatus(null, role.id);
				this.introEmailTriggeredOn = null;
			}
			else {
				this.reportingStatus.collectionsStatus = CollectionsStatus.NotStarted;
				this.reportingStatus.additionalDetails = '';
				this.reportingStatus.landlordDetails   = null;
			}
			return;
		}

		this.reportingStatus.collectionsStatus = CollectionsStatus.Draft;
		if (collectionsOptions.submitting) {
			this.reportingStatus                   = new ReportingStatus(ReportingType.Collections, role.id);
			this.reportingStatus.collectionsStatus = CollectionsStatus.Submitted;
			// SHOULDDO: This doesn't work if the balance is updated in the lease modal immediately before collections is enabled - needs fixed
			this.reportingStatus.initialAmount = this.outstandingBalance;
		}
		this.reportingStatus.additionalDetails = collectionsOptions.additionalDetails;
		this.reportingStatus.landlordDetails   = collectionsOptions.landlordDetails;
	}

	/**
	 * Generates a sample email for this lease for a few types of emails.
	 * @returns {Email} the sample email object
	 */
	abstract getSampleEmail(type: SampleEmails, tenant: Tenant): Promise<Email>;

	/**
	 * Returns true if this lease is the sample lease (not meant to be saved)
	 */
	get isSample() {
		return this.id === 'sample';
	}

	/**
	 * Returns the sample lease.
	 */
	static getSample<T extends typeof Lease>(this: T): InstanceType<T> {
		if (!sample) {
			sample          = new (this as unknown as Class)();
			sample.id       = 'sample';
			sample.ver      = 1;	// prevents the sample lease from registering as new (via isNew)
			sample.status   = LeaseStatus.Current;
			sample.org      = Organization.getSample();
			sample.building = Building.getSample();
			sample.unit     = sample.building.units[0].number;
			// @ts-ignore TS complains about sample being a common Lease object not being assignable to getSample's param (which is client-side Lease)
			sample.tenants                     = [ Tenant.getSample(sample) ];
			sample.monthlyAmount               = 1300;
			sample.emailMonthlyPaymentReminder = true;
			sample.emailSendLatePaymentWarning = true;
		}

		return sample as InstanceType<T>;
	}

	async save(...args): Promise<this> {
		if (this.isSample) {
			throw new Error('cannot save the sample lease');
		}
		return super.save.apply(this, args);
	}

	adjustFeatures() {
		if (this.isFormer) {
			if (this.rentReporting) {
				this.reportingStatus = new ReportingStatus();
			}
			this.emailSendLatePaymentWarning = false;
			this.emailMonthlyPaymentReminder = false;
		}

		if (this.isCurrent) {
			if (this.debtReporting) {
				this.reportingStatus = new ReportingStatus();
			}
			this.endDate = null;
		}
	}

	/**
	 * Returns the latestLeaseBalance, or the most recent balance from leaseBalances if latestLeaseBalance is not set.
	 */
	get mostRecentLeaseBalance() {
		return this.latestLeaseBalance ?? _.orderBy(this.leaseBalances, 'month', 'desc')?.[0];
	}

	get isLeaseBalanceSettled() {
		return !!(this.outstandingBalance === 0);
	}

	@Validate({
		min    : 0,
		custom : function(this: Lease, value: number) {
			if (this.reportingStatus.initialAmount && value > this.reportingStatus.initialAmount) {
				return 'The outstanding amount cannot be greater than the initial amount';
			}
		},
	})
	get outstandingBalance() {
		return this.mostRecentLeaseBalance?.amount;
	}
	set outstandingBalance(value) {
		if (this.mostRecentLeaseBalance) {
			this.mostRecentLeaseBalance.amount = value;
		}
	}

	async getCollectionsDocs(): Promise<CollectionsDoc[]> {
		const FileEntity             = getEntityClass<typeof File>('File');
		const docs: CollectionsDoc[] = [
			{
				docType          : DocumentType.LeaseAgreement,
				title            : 'Lease',
				tooltip          : 'Upload a copy of the lease agreement that was signed by you and the Tenant(s).',
				requirementLevel : CollectionsDocRequired.Mandatory,
				file             : undefined,
			},
			{
				docType : DocumentType.CollectionsRentalLedger,
				title   : 'Rental Ledger',
				tooltip : `
					Upload a month by month breakdown of the amount paid or owed during the tenancy to show how the
					total outstanding debt is calculated.
				`,
				requirementLevel : CollectionsDocRequired.Mandatory,
				file             : undefined,
			},
			{
				docType          : DocumentType.CollectionsRentalApplication,
				title            : 'Rental Application',
				tooltip          : "Upload the Tenants' full rental application.",
				requirementLevel : CollectionsDocRequired.Recommended,
				file             : undefined,
			},
			{
				docType  : DocumentType.CollectionsOrderJudgement,
				title    : 'Order or Judgement',
				typeNote : ', mandatory in Saskatchewan, Nova Scotia & Quebec.',
				tooltip  : `
					Upload all documents received from the local tenancy board, police, or other institutions regarding the tenancy.
					These should include judgements, monetary orders, tenancy branch decisions, etc. when available.
				`,
				requirementLevel : CollectionsDocRequired.Available,
				file             : undefined,
			},
		];

		const files = await FileEntity.find({ where : { reference : this.getReferenceString() } });
		_.forEach(docs, doc => {
			doc.file = files.find(file => file.documentType === doc.docType)
				?? new FileEntity({ documentType : doc.docType, reference : this.getReferenceString() });
		});

		await this.loadRelation('tenants');
		await _.forEachAsync(this.tenants, async tenant => {
			docs.push({
				docType : DocumentType.CollectionsTenantID,
				title   : this.tenants.length > 1 ? `Tenant's ID - ${tenant.firstName}` : "Tenant's ID",
				tooltip : `
					Upload copies of the Tenant identification documents that you have
					available${this.tenants.length > 1 ? ` for ${tenant.fullName}` : ''},
					like a drivers license, health card, residency card, etc.
				`,
				requirementLevel : CollectionsDocRequired.Available,
				file             : await FileEntity.findOne({
					where  : { reference : tenant.getReferenceString(), documentType : DocumentType.CollectionsTenantID },
					create : true,
				}),
			});
		});

		return docs;
	}

	async getLeaseDocs(): Promise<LeaseDoc[]> {
		await this.loadRelation('building');
		const FileEntity = getEntityClass<typeof File>('File');

		return _.compact([
			{
				docType : DocumentType.LeaseAgreement,
				title   : 'Lease',
				tooltip : 'The lease agreement that was signed by you and the Tenant(s).',
				file    : await FileEntity.findOne({
					where  : { documentType : DocumentType.LeaseAgreement, reference : this.getReferenceString(), orgId : this.orgId },
					order  : { createdOn : 'DESC' },
					create : true,
				}),
			},
			this.building
				? {
					...(await this.building.getBuildingDocs()).find(doc => doc.docType === DocumentType.BuildingOwnership),
					title : 'Building Ownership Proof',
				}
				: undefined,
		]) as LeaseDoc[];
	}

	/**
	 * Marks this lease as excluded from reporting and synchronization with external systems and also archives the lease.
	 */
	async excludeFromReporting() {
		await this.loadRelation('tenants', { reload : true });
		await this.loadRelation('org');
		if (this.tenants.some(tenant => !tenant.externalId)) {
			throw new Error('cannot exclude from reporting if not all tenants have external IDs');
		}

		this.makeEditable();
		this.org.makeEditable();

		this.excludedOn = new Date();
		this.tenants.forEach(tenant => {
			if (tenant.externalId) {
				this.org.yardiSettings.excludeTenant(tenant.externalId);
			}
		});

		await this.org.save();
		await this.save();
		await this.archive();
	}

	async archiveDuplicateTenants() {
		const fieldsToCopy = [ 'email', 'middleName', 'phoneNumber', 'moveIn', 'moveOut', 'firstNameAlt', 'lastNameAlt', 'suffix' ];
		const dupeTenants  = await this.getDuplicateTenants();
		if (dupeTenants.length < 2) {
			return;
		}

		const tenantsReportingCount = dupeTenants.filter(tenant => !_.isEmpty(tenant.consumerAccountNumber)).length;
		let tenantToKeep: Tenant;

		if (tenantsReportingCount === 0) {
			// archive all tenants except for the one with latest updatedOn
			tenantToKeep = _.maxBy(dupeTenants, 'updatedOn');
		}
		else if (tenantsReportingCount === 1) {
			// archive all tenants except for the one with consumerAccountId set
			tenantToKeep = dupeTenants.find(tenant => !_.isEmpty(tenant.consumerAccountNumber));
		}
		else {
			// archive all tenants except for the one with earliest createdOn and consumerAccountId set
			tenantToKeep = _(dupeTenants)
				.filter(tenant => !_.isEmpty(tenant.consumerAccountNumber))
				.minBy('createdOn');
		}

		const tenantsToArchive = dupeTenants.filter(tenant => tenant !== tenantToKeep);

		tenantToKeep.makeEditable();

		for (const tenant of tenantsToArchive) {
			const archivingTenantData = { ...tenant };
			for (const field of fieldsToCopy) {
				// only copy over data from the archiving tenant if the data in the tenant to keep is null or empty
				if (_.isEmpty(tenantToKeep[field]) && !_.isEmpty(archivingTenantData[field])) {
					tenantToKeep[field] = archivingTenantData[field];
				}
			}
		}

		await tenantToKeep.save();
		await _.forEachAsync(tenantsToArchive, tenant => tenant.archive());
	}

	/**
	 * @returns true if this lease still needs to have documents uploaded in order to report
	 * SHOULDDO: move this into the Metro2 logic for EquifaxCA (since these are their requirements)
	 */
	async needsDocumentsToReport() {
		if (!this.rentReporting && !this.debtReporting) {
			return false;
		}

		// not needed for large LLs, and also grandfather in old LL orgs that don't need to have documents uploaded
		await this.loadRelation('org');
		if (await this.org.isLargeLandlord() || Moment(this.org.createdOn).isBefore(leaseUploadRequiredDate)) {
			return false;
		}

		await this.loadRelation('building');
		if (!this.building || !this.building.isCanadian) {
			return false;
		}

		const docs  = await this.getLeaseDocs();
		const files = docs.filter(doc => [ DocumentType.LeaseAgreement, DocumentType.BuildingOwnership ].includes(doc.docType)).map(doc => doc.file);
		return files.length !== 2 || files.some(file => file.status !== FileStatus.Saved);
	}

}

async function canWriteReportingStatus(context: Context, lease: Lease) {
	if (lease.status === LeaseStatus.Current) {
		if (!(await lease.canToggleRentReporting(context.role))) {
			return 'insufficient permissions to toggle reporting';
		}
	}

	// Support can turn off collections, but not make any other changes
	if (context.role.hasPermission(RolePermission.CrossOrgWrite)) {
		if (lease.reportingStatus.type === ReportingType.Collections) {
			context.stopChecks();
			return;
		}

		return 'support can only disable collections';
	}

	// Former leases
	return (await Permissions.roleHasFeature(PackageFeature.DebtReporting)(context)) || Permissions.roleIsVerified(context);
}

async function canWriteIntroEmailTriggeredOn(context: Context, lease: Lease) {
	// Support should be able to turn this off when disabling collections
	if (context.role.hasPermission(RolePermission.CrossOrgWrite) && lease.collections) {
		context.stopChecks();
		return;
	}

	const featurePermissionError = await checkLeaseFeaturePermission('introEmailTriggeredOn')(context, lease);
	if (!featurePermissionError) {
		return;
	}

	return lease.debtReporting || lease.collections ? '' : 'Must have collection enabled to use this feature';
}

// custom validations
function checkStatus(value, prop) {
	if (this.status === LeaseStatus.Current && [ ReportingType.Collections, ReportingType.Debt ].includes(value.type)) {
		return `Cannot set ${prop} to ${ReportingType.Collections} or ${ReportingType.Debt} on a ${LeaseStatus.Current} lease`;
	}

	if (this.status === LeaseStatus.Former && ReportingType.Rent === value.type) {
		return `Cannot set ${prop} to ${ReportingType.Rent} on a ${LeaseStatus.Former} lease`;
	}

	return '';
}

async function checkDuplicateTenants(this: Lease) {
	const duplicateTenants = await this.getDuplicateTenants();
	if (!_.isEmpty(duplicateTenants)) {
		return `Tenants with identical names and date of birth are not allowed on the same lease: ${duplicateTenants[0].firstName} ${duplicateTenants[0].lastName}`;
	}
}

async function latestLeaseBalanceValidator(leaseBalance: LeaseBalance): Promise<string> {
	if (this.isNew || !leaseBalance || !this.isEdited('latestLeaseBalance')) {
		return '';
	}

	const latestLeaseBalance = await LeaseBalance.findOne({
		where : { leaseId : this.id },
		order : { month : 'DESC' },
	});

	if (latestLeaseBalance && latestLeaseBalance.id !== leaseBalance.id) {
		return 'provided LeaseBalance is not the latest LeaseBalance available';
	}
}

// Note: this should only be used in permission checks since it checks that the field has been edited first
function checkLeaseFeaturePermission(propertyKey: keyof Lease) {
	return async function(_context: Context, lease: Lease) {
		// if feature has been edited and enabled
		if (lease.isEdited(propertyKey)) {
			return checkLeaseFeature.call(lease, lease[propertyKey], propertyKey);
		}

		// otherwise, user is allowed to turn it OFF
		return '';
	};
}

async function checkLeaseFeature(this: Lease, value: any, featureName?: keyof Lease) {
	// cannot toggle any features on sample leases
	if (this.isSample) {
		return 'This is a sample lease.';
	}

	if (value) {
		if (!getValidLeaseStatusForFeature(featureName).includes(this.status)) {
			return `This feature is not available for ${this.status} leases.`;
		}

		// additional scenarios for specific toggles when toggle switch is ON
		switch (featureName) {
			case 'debtReporting':
				await this.loadRelation('building');

				// SHOULD DO: DRY out once more countries are added
				if (this.building.isCanadian && this.endDate <= Moment().subtract(6, 'years').toDate()) {
					return 'You cannot enable Debt Reporting for Leases older than 6 years in Canada.';
				}
				if (this.building.isAmerican && this.endDate <= Moment().subtract(7, 'years').toDate()) {
					return 'You cannot enable Debt Reporting for Leases older than 7 years in the US.';
				}

				// @ts-ignore TS complains about `this` not being assignable to the param which is client-specific
				// eslint-disable-next-line no-case-declarations
				const latestLeaseBalance = await LeaseBalance.getFor(this, undefined, { create : true });
				if (!latestLeaseBalance.amount) {
					return 'If tenants have paid their balance, you must disable Debt Reporting.';
				}
				break;
		}

		const feature = {
			introEmailTriggeredOn       : PackageFeature.IntroEmail,
			emailSendCopy               : PackageFeature.EmailSendCopy,
			emailMonthlyPaymentReminder : PackageFeature.AutomaticReminders,
			emailSendLatePaymentWarning : PackageFeature.AutomaticReminders,
		}[featureName];

		// if the toggle doesn't require features
		if (!feature) {
			return;
		}

		// as a last resort if there's a package feature associated with the toggle check for the feature
		await this.loadRelation('org.package');
		if (!this.org.hasFeature(feature)) {
			return 'Your account does not have access to this feature.';
		}

		if (!this.org.isVerified) {
			return 'You must be verified to access this feature.';
		}
	}

	// COULD DO: wrap a decorator around each lease feature and add valid lease status as a metadata
	function getValidLeaseStatusForFeature(toggle: keyof Lease): LeaseStatus[] {
		const BOTH    = [ LeaseStatus.Current, LeaseStatus.Former ];
		const CURRENT = [ LeaseStatus.Current ];
		const FORMER  = [ LeaseStatus.Former ];

		return {
			introEmailTriggeredOn       : BOTH,
			emailMonthlyPaymentReminder : CURRENT,
			emailSendLatePaymentWarning : CURRENT,
			emailSendCopy               : BOTH,
			debtReporting               : FORMER,
		}[toggle];
	}
}
