import { BaseVerifier }                        from '$/lib/identityVerification/BaseVerifier';
import type { Answers, Questionnaire, Result } from '$/lib/equifax/KountClient';

import { VerificationType } from '$/entities/lib/VerificationResults';

/**
 * Implementation of identity verification using Equifax's Kount verification service (knowledge-based test)
 */
export abstract class KountVerifier extends BaseVerifier {

	abstract getQuestions(): Promise<Questionnaire | Result>

	abstract submitAnswers(answers: Answers)

	static get verificationType(): VerificationType {
		return VerificationType.Kount;
	}

}

export default null as KountVerifier;
