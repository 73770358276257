import { CountryLabels }                      from '$/lib/Address';
import { isRestrictedByLocation, AppSection } from '$/lib/restrictions';

import Permissions                           from '$/entities/lib/Permissions';
import { ChargeableProp, PackageFeature }    from '$/entities/Package';
import { CommonEntity, Column, ChildEntity } from '$/entities/BaseEntity';
import { TenantCheck }                       from '$/entities/tenantScreening/TenantCheck';
import { CreditReportData }                  from '$/entities/tenantScreening/lib/CreditReportData';

/**
 * Base class for all credit report entity types.
 */
@CommonEntity() @ChildEntity()
@Permissions({
	create : Permissions.roleHasFeature(PackageFeature.CreditChecks),
})
export abstract class CreditReport extends TenantCheck {

	@Column({ type : 'json', nullable : true })
	report: CreditReportData = null;

	async validateCountry() {
		await this.loadRelation('application');
		if (isRestrictedByLocation(AppSection.CreditReports, this.application.country)) {
			return `We currently do not support pulling Credit Reports for Applicants in ${CountryLabels[this.application.country]}`;
		}
	}

	get error() {
		return this.report?.error;
	}

	get label() {
		return 'Credit Report';
	}

	get labelOrder() {
		return 0;
	}

	get chargeable(): ChargeableProp {
		return ChargeableProp.CreditChecks;
	}

}
