import { TableInheritance } from '$/lib/typeormExt';
import type { User }        from '$/entities/User';
import { BaseEntity, CommonEntity, ChildEntity, ManyToOne, Entity } from '$/entities/BaseEntity';

export const passwordRules = {
	'One lowercase letter required.'                 : /[a-z]+/,
	'One uppercase letter required.'                 : /[A-Z]+/,
	'8 characters minimum.'                          : /.{8,}/,
	'One number required.'                           : /[0-9]+/,
	'Password confirmation does not match password.' : (password, confirmation) => password === confirmation,
};

/**
 * Base class for all Authentication methods.
 */
@CommonEntity()
@Entity()
@TableInheritance({ column : { name : '$class', type : 'varchar' } })
export abstract class Authentication extends BaseEntity {

	@ManyToOne('User', { onDelete : 'CASCADE' })
	user: User = undefined;

	/**
	 * Returns the list of validation errors with the given passwords, or [] if no errors.
	 */
	// SHOULDDO: Switch to entity validation
	static validatePassword(password: string, passwordConfirmation: string): string[] {
		return _.compact(_.map(passwordRules, (rule, message) => {
			if (rule instanceof RegExp && !rule.test(password)) {
				return message;
			}
			if (rule instanceof Function && !rule(password, passwordConfirmation)) {
				return message;
			}
		}));
	}

}

@CommonEntity()
@ChildEntity()
export class PasswordAuth extends Authentication {

}

@CommonEntity()
@ChildEntity()
export class GoogleAuth extends Authentication {

}

@CommonEntity()
@ChildEntity()
export class ApiKeyAuth extends Authentication {

}
