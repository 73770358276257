import { Mixin }       from '$/lib/utils';
import { Country }     from '$/lib/Address';
import { CountryCode } from '$/lib/Metro2';

import { TenantMetro2 }    from '$/entities/reporting/TenantMetro2';
import { JSONable, Field } from '$/entities/lib/JSONable';
import { Tenant }          from '$/entities/Tenant';
import { TenantSearch }    from '$/common/entities/tenantScreening/TenantSearch'; // Importing from common due to circular dependency

@Mixin(JSONable)
export class LeaseRecord {

	@Field()
	monthlyRent: number;

	@Field()
	moveInDate: Date;

	@Field()
	moveOutDate?: Date;

	@Field()
	street: string;

	@Field()
	city: string;

	@Field()
	region: string;

	@Field()
	country: Country;

	constructor(leaseRecord: LeaseRecord) {
		Object.assign(this, leaseRecord);
	}

	static async fromMetro2(tenantRecords: TenantMetro2[]) {
		const tenant  = tenantRecords[0].tenant;
		const address = tenantRecords[0].segment.base.address;
		await tenant.loadRelation('lease');

		return new this({
			monthlyRent : _.last(tenantRecords).segment.base.scheduledPaymentAmount.valueOf(), // Most recent rent value
			moveInDate  : tenantRecords[0].month, // Should already be sorted, first reported date
			moveOutDate : tenant.lease.endDate,
			street      : address.addressLine1,
			city        : address.city,
			region      : address.region,
			country     : address.countryCode === CountryCode.CA ? Country.CA : address.countryCode as Country, // Metro 2 uses CN for Canada
		});
	}

}

@Mixin(JSONable)
export class LandlordRecord {

	@Field()
	name: string;

	@Field()
	email: string;

	constructor(landlordRecord: LandlordRecord) {
		Object.assign(this, landlordRecord);
	}

	static async fromMetro2(tenantRecords: TenantMetro2[]) {
		const lease = tenantRecords[0].tenant.lease;
		await lease.loadRelation('org');
		return new this(await lease.org.fullContactInfo());
	}

}

@Mixin(JSONable)
export class Payment {

	@Field()
	month: Date;

	@Field()
	balance: number;

	@Field()
	status: string;

	constructor(payment: Payment) {
		Object.assign(this, payment);
	}

	static fromMetro2(tenantRecords: TenantMetro2[]) {
		return _.mapAsync(tenantRecords, async tenantRecord => {
			const leaseBalance = await tenantRecord.getAssociatedLeaseBalance();
			return new this({
				month   : tenantRecord.month,
				balance : leaseBalance.amount,
				status  : leaseBalance.status,
			});
		}
		);
	}

}

@Mixin(JSONable)
export class TenantInquiry {

	@Field()
	date: Date;

	@Field()
	name: string;

	constructor(tenantInquiry: TenantInquiry) {
		Object.assign(this, tenantInquiry);
	}

	static async fromMetro2(tenantSearch: TenantSearch) {
		return new this({
			date : tenantSearch.createdOn,
			name : (await tenantSearch.org.fullContactInfo()).name,
		});
	}

}

@Mixin(JSONable)
export class TenantHistory {

	@Field(LeaseRecord)
	lease: LeaseRecord;

	@Field(LandlordRecord)
	landlord: LandlordRecord;

	@Field(Payment)
	paymentHistory: Payment[];

	@Field()
	inquiries: TenantInquiry[];

	static async fromMetro2(tenantRecords: TenantMetro2[]) {
		const tenantHistory = new this();

		const tenant = await Tenant.findOne({ where : { id : tenantRecords[0].tenant.id } });
		await tenant.loadSearchHistory();

		tenantRecords = _.sortBy(tenantRecords, 'month');

		tenantHistory.lease          = await LeaseRecord.fromMetro2(tenantRecords);
		tenantHistory.landlord       = await LandlordRecord.fromMetro2(tenantRecords);
		tenantHistory.paymentHistory = await Payment.fromMetro2(tenantRecords);
		tenantHistory.inquiries      = await _.mapAsync(tenant.searchHistory, search => TenantInquiry.fromMetro2(search));

		return tenantHistory;
	}

}

@Mixin(JSONable)
export class TenantRecordData {

	@Field(TenantHistory)
	tenantRecords?: TenantHistory[];

	@Field()
	error?: string;

	constructor(error?: string) {
		this.error = error;
	}

	static async fromMetro2(tenantMatches: TenantMetro2[]) {
		const report = new this();

		const groupedMatches = _.groupBy(tenantMatches, 'tenantId');
		report.tenantRecords = await _.mapAsync(Object.values(groupedMatches), tenantRecord => TenantHistory.fromMetro2(tenantRecord));
		report.tenantRecords = _.sortBy(report.tenantRecords, 'lease.moveInDate');

		return report;
	}

}

// Helper function for tenant records samples
export function parsePayment(payment: {date: string; amount?: number; status?: string}) {
	return new Payment({
		month   : new Date(payment.date),
		balance : payment.amount ?? 0,
		status  : payment.status ?? 'paidOnTime',
	});
}
