import { Country, CountryLabels }             from '$/lib/Address';
import { AppSection, isRestrictedByLocation } from '$/lib/restrictions';

import Permissions                           from '$/entities/lib/Permissions';
import { ChargeableProp, PackageFeature }    from '$/entities/Package';
import { CommonEntity, Column, ChildEntity } from '$/entities/BaseEntity';
import { BaseRole }                          from '$/entities/roles/BaseRole';
import { ProgressStatus, TenantCheck }       from '$/entities/tenantScreening/TenantCheck';
import { BackgroundCheckData }               from '$/entities/tenantScreening/lib/BackgroundCheckData';
import { Application }                       from '$/entities/tenantScreening/Application';

@CommonEntity() @ChildEntity()
@Permissions({
	create : Permissions.roleHasFeature(PackageFeature.BackgroundChecks),
})
export class BackgroundCheck extends TenantCheck {

	@Column({ type : 'json', nullable : true })
	report: BackgroundCheckData = null;

	@Column()
	certnApplicationId: string;

	getReport(): Promise<BackgroundCheckData> {
		throw new Error('not implemented');
	}

	async validateCountry() {
		await this.loadRelation('application');
		if (isRestrictedByLocation(AppSection.BackgroundChecks, this.application.country)) {
			return `We currently do not support pulling Background Checks for Applicants in ${CountryLabels[this.application.country]}`;
		}
	}

	get error() {
		return this.report?.error;
	}

	get label() {
		return 'Background Check';
	}

	get labelOrder() {
		return 1;
	}

	get chargeable(): ChargeableProp {
		return ChargeableProp.BackgroundChecks;
	}

	static getSample(role: BaseRole) {
		const sample                   = new this();
		sample.id                      = 'sample';
		sample.ver                     = 1;
		sample.org                     = role.org;
		sample.status                  = ProgressStatus.Complete;
		sample.requestedBy             = role.user;
		sample.application             = new Application();
		sample.application.firstName   = 'John';
		sample.application.lastName    = 'Doe';
		sample.application.dateOfBirth = new Date('1992-10-29');
		sample.application.street      = '123 Allen Street';
		sample.application.city        = 'Toronto';
		sample.application.province    = 'ON';
		sample.application.country     = Country.CA;
		sample.report                  = BackgroundCheckData.getSample();

		return sample;
	}

}

