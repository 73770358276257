




import { Vue, Component, Prop } from '$/lib/vueExt';
import { BaseRole }             from '$/entities/roles/BaseRole';

const colors = [ '#008964', '#89007B', '#002989', '#C214AB', '#008900', '#890000', '#00897B' ];

@Component
export default class Avatar extends Vue {

	@Prop()
	readonly role: BaseRole | null;

	@Prop()
	readonly name: string;

	get initials() {
		return this.role?.user.initials || this.name?.split(' ').map(name => name.charAt(0)).join('').toLocaleUpperCase();
	}

	get color() {
		return colors[this.hash(this.initials) % colors.length];
	}

	hash(value: string) {
		let hash = 0;
		if (!value || value.length === 0) {
			return hash;
		}

		for (let i = 0; i < value.length; i++) {
			const chr  = value.charCodeAt(i);
			hash       = (hash << 5) - hash + chr;
			hash      |= 0;
		}
		return hash;
	}

}
