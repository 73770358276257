






































































































import { BTable, BvTableProviderCallback } from 'bootstrap-vue';
import { In }                              from '$/lib/typeormExt';
import { Vue, Component, Prop, Ref, Watch } from '$/lib/vueExt';

import { BaseEntity, defaultFindLimit, EntityID } from '$/entities/BaseEntity';

class RowGroup {

	value: any;		// the grouping value of this group (if grouping by a field)
	entity?: BaseEntity;				// the entity that this group represents (if grouping by an entity)
	rows: Record<string, any>[] = [];
	totalCount: number = undefined;		// the # of total rows for this group in the DB
	expanded           = false;			// whether this group is currently expanded and showing it's rows (or collapsed)
	isLoading          = false;
	error              = null;			// the error that occurred on the last attempted load of items for this group
	mightHaveMore      = true;

	get hasMore() {
		return this.mightHaveMore && (this.totalCount === undefined || this.totalCount > this.rows.length);
	}

	get canLoadMore() {
		return this.expanded && this.hasMore && !this.error;
	}

	constructor(initialValues?: Partial<RowGroup>) {
		this.value      = initialValues?.value;
		this.totalCount = initialValues?.totalCount;
		this.expanded   = initialValues?.expanded ?? false;
	}

}

/**
 * Custom Table widget that extends the VueBootstrap's Table component with customizations for our app.
 *
 * Features:
 * - internal support for fetching entities
 * - handles sorting by re-fetching from the server
 * - handles filtering by re-fetching from the server (though the actual UI for the filter fields must be done externally to this component)
 * - automatically loads more results on scrolling
 * - adds a new "map" key to fields definition that allows for a transformation map function from the result entity into the field's final value
 */
@Component
export default class Table extends Vue {

	@Ref()
	readonly table: BTable;

	@Prop()
	readonly fields: any[];

	@Prop()
	readonly entity: typeof BaseEntity;

	@Prop()
	readonly items: any[] | BvTableProviderCallback;

	/**
	 * An array of relationship fields to also load along with the main items.
	 * Passed to the relations option of the Entity.find() call.
	 */
	@Prop({ default : undefined })
	readonly relations: string[];

	@Prop({ default : false })
	readonly border: boolean;

	/**
	 * If true, fetches raw POJOs from the server.
	 * If false (default) fetches entire entities.
	 */
	@Prop({ default : false })
	readonly useRaw: boolean;

	/**
	 * Specifies when to start rendering rows using a simple column of items and hide the standard column displays.
	 * This purposefully overrides the b-table stacked property of the same name but with a customizable row-render implementation.
	 * <string> - the size at which to switch to rendering rows using the stacked cell template (renders at this size and smaller)
	 */
	@Prop({ default : null })
	readonly stacked: string; // | 'sm' | 'md' | 'lg';

	@Prop({ default : false })
	readonly hideIfEmpty: boolean;

	@Prop({ default : null })
	readonly filter: any;

	@Prop({ default : null })
	readonly sortBy: string;

	@Prop({ default : null })
	readonly sortDesc: boolean;

	/**
	 * If true, load results if filter is not empty otherwise always load all results
	 */
	@Prop({ default : false })
	readonly filteredResultsOnly: boolean;

	/**
	 * An extra filter function to apply after all other item loading
	 * Useful for any filtering that can't be done through the query (e.g. referencing a relation two steps away)
	 */
	@Prop({ default : () => true })
	readonly extraFilterFunction: (item: Record<string, any> | BaseEntity, allRows: Dictionary<any>[] | BaseEntity[]) => boolean | Promise<boolean>;

	/**
	 * If specified, indicates the field whose values are to be used to create collapsible groups of rows,
	 * all of which share the same value for this field.
	 */
	@Prop({ default : null })
	readonly groupBy: string;

	/**
	 * A dictionary of actions to perform when a row is clicked (or a function that returns such a dictionary for each row)
	 * The key is the action name and the value is a function that takes the row item as a parameter.
	 */
	@Prop({ default : null })
	readonly rowActions: Dictionary<(item: BaseEntity) => void> | ((item: BaseEntity) => Dictionary<(item: BaseEntity) => void>);

	@Prop({ default : false })
	readonly withDeletedRelations: boolean;

	/**
	 * The underlying data structure which organizes results into groups, used to construct this.items
	 */
	rowGroups: RowGroup[] = [];

	hasMore   = true;
	isLoading = false;

	localSortBy: string = null;
	localSortDesc       = true;

	hover = false;

	expandAll = false;

	/**
	 * The scrollTop position of the table at the time of the most recent load.
	 * This is used to reset the scroll position after the load and render.
	 * The browser has a tendency to keep the scroll bar locked to the bottom once it reaches the bottom.
	 */
	scrollTop: number = undefined;

	get isTableVisible() {
		return !this.hideIfEmpty || this.items?.length || this.rowGroups[0]?.rows.length;
	}

	/**
	 * Overwrites Bootstrap's definition of being stacked
	 * and checks whether screen width has reached the stacked mode's breakpoint
	 */
	get isStacked() {
		return this.stacked && !this.$breakpoint[this.stacked];
	}

	get wrappedFields() {
		const fields = [];

		if (this.groupBy) {
			// add an extra column for the group headers
			fields.push({
				label   : '',
				key     : '_grouping',
				tdClass : 'grouping',
				thClass : this.stacked ? processFieldClass.call(this, 'grouping') : 'grouping',
				tdAttr  : value => value ? { colspan : '999' } : {},
				thStyle : { width : '0' },
			});
		}

		if (this.stacked) {
			fields.push(
				{
					label   : '',
					key     : 'stacked',
					tdClass : `d-table-cell d-${this.stacked}-none stacked`,
					thClass : 'd-none',
				},
				...this.fields
					.filter(field => field.key !== this.groupBy)
					.map(field => {
						field.tdClass = processFieldClass.call(this, field.tdClass);
						field.thClass = processFieldClass.call(this, field.thClass);
						return field;
					})
			);
		}
		else {
			fields.push(...this.fields.filter(field => field.key !== this.groupBy));
		}

		// add an actions column if there are any actions
		if (this.rowActions?.length) {
			fields.push({ key : '_rowActions', label : '', width : '1%' });
		}

		return fields;

		function processFieldClass(fieldClass: string | Function) {
			if (typeof fieldClass === 'string' || typeof fieldClass === 'undefined') {
				return `${fieldClass || ''} d-none d-${this.stacked}-table-cell`;
			}
			if (typeof fieldClass === 'function') {
				return (...args) => `${fieldClass.call(this, ...args) || ''} d-none d-${this.stacked}-table-cell`;
			}

			throw new Error(`fieldClass type not supported yet: ${typeof fieldClass}`);
		}
	}

	get emptyFilteredText() {
		if (this.hasFilterValues && this.filteredResultsOnly) {
			return 'No results.';
		}
		if (!this.hasFilterValues) {
			return 'Use filters to search for results.';
		}

		return '';
	}

	get whereClause() {
		// null and other values are allowed
		// $search must be 2 or more characters to be considered
		return _.pickBy(this.filter, (value, key) => value !== undefined && value !== '' && key !== 'withDeleted' && (key !== '$search' || value.length > 1));
	}

	get hasFilterValues() {
		return !_.isEmpty(this.whereClause);
	}

	get sortByOptions() {
		return [
			{ text : '', value : null },
			...this.fields.filter(field => field.sortable).map(field => ({ text : field.label, value : field.key })),
		];
	}

	get canLoadMore() {
		return this.groupBy ? this.hasMore : (this.rowGroups[0]?.canLoadMore && (!this.filteredResultsOnly || this.hasFilterValues));
	}

	/**
	 * Changing passed items does not trigger refreshed event
	 */
	@Watch('items')
	onItemsChange(value) {
		if (value) {
			this.table.refresh();
		}
		this.$emit('refreshed', this.table.localItems.filter(item => !item.hasOwnProperty('_grouping')));
	}

	@Watch('sortBy', { immediate : true })
	onSortByChange(newValue) {
		if (newValue !== this.localSortBy) {
			this.localSortBy = newValue;
		}
	}

	@Watch('sortDesc', { immediate : true })
	onSortDescChanged(newValue) {
		if (!_.isNil(newValue) && newValue !== this.localSortDesc) {
			this.localSortDesc = newValue;
		}
	}

	@Watch('localSortBy')
	onLocalSortByChange(newValue) {
		if (newValue !== this.sortBy) {
			this.$emit('update:sortBy', newValue);
			void this.refresh({ hard : true });
		}
	}

	@Watch('localSortDesc')
	onLocalSortDescChange(newValue) {
		if (newValue !== this.sortDesc) {
			this.$emit('update:sortDesc', newValue);
			void this.refresh({ hard : true });
		}
	}

	@Watch('filter', { deep : true })
	onTableFilterChange() {
		void this.refresh({ hard : true });
	}

	mounted() {
		if (!this.groupBy) {
			this.rowGroups = [ new RowGroup({ expanded : true }) ];
		}

		if ((this.$listeners['row-clicked'] || this.$listeners['row-clicked-stacked']) && this.$attrs.hover !== 'false') {
			this.hover = true;
		}
	}

	// returns the final items to show in the table based upon the this.rowGroups data structure
	getLocalItems() {
		if (this.items) {
			return this.items;
		}

		const items = [];

		this.rowGroups.forEach(rowGroup => {
			if (this.groupBy) {
				items.push({ _grouping : rowGroup });
			}

			if (rowGroup.expanded) {
				if (this.$scopedSlots['row-details']) {
					// required in order to support reactivity for row details
					rowGroup.rows.forEach(row => this.$set(row, '_showDetails', false));
				}
				items.push(... rowGroup.rows);

				if (this.groupBy && rowGroup.hasMore) {
					items.push({ _grouping : { loadMoreFor : rowGroup } });
				}
			}
		});

		return items;
	}

	async loadRowGroup(rowGroup?: RowGroup, { refresh = false, loadMore = false } = {}) {
		if ((rowGroup ?? this).isLoading) {
			return;
		}

		if (this.filteredResultsOnly && !this.hasFilterValues) {
			this.rowGroups = this.groupBy ? [] : [ new RowGroup({ expanded : true }) ];
			return;
		}

		try {
			(rowGroup ?? this).isLoading = true;

			// The loading spinner appears underneath the table. By clearing it when we load new data, we guarantee that the spinner will be shown.
			// Don't do this when refreshing existing data (which would cause the user to lose their place in the table).
			// Or when loading more data (where doing this causes an infinite loop of loading the first batch of data over and over).
			if (!refresh && !loadMore && rowGroup) {
				rowGroup.rows = [];
			}

			const limit   = Number(this.table.perPage) || 50;
			const options = {
				where                : this.whereClause,
				relations            : this.relations,
				withDeleted          : this.filter?.withDeleted || false,
				withDeletedRelations : this.withDeletedRelations,
				limit,
				offset               : 0,
				// by default, sort by createdOn DESC
				order                : { [this.localSortBy || 'createdOn'] : this.localSortDesc ? 'DESC' : 'ASC' as 'ASC' | 'DESC' },
				select               : this.useRaw ? [ 'id', ...this.fields.map(field => field.key) ] : undefined,
			};

			// load rowGroups for top-level groups
			if (this.groupBy && !rowGroup) {
				const offset      = loadMore ? this.rowGroups.length : 0;
				const groupCounts = await this.entity.count({ ... _.omit(options, 'order'), countBy : this.groupBy, offset });
				const newGroups   = _.toPairs(groupCounts).sort(nullsAtEnd).map(([ groupValue, count ], index) => {
					// treat "null" as null (since it turns into a string from the API call)
					groupValue = groupValue === 'null' ? null : groupValue;

					// if refreshing, try to re-use existing rowGroups to preserve state
					const rowGroup = (refresh && this.rowGroups.find(group => group.value === groupValue))
						|| new RowGroup({ value : groupValue, expanded : !index && !(loadMore && this.rowGroups.length) });

					rowGroup.totalCount = count;

					// possibly, refresh the rowGroup
					if (rowGroup.expanded || rowGroup.rows.length > 0) {
						this.$nextTick(() => {
							void this.loadRowGroup(rowGroup, { refresh : true });
						});
					}

					return rowGroup;
				});

				if (newGroups.length) {
					// Find the property description for the groupBy property
					const groupByPropertyDescriptor = this.groupBy && this.entity.getPropertyDescriptor(this.groupBy);
					if (groupByPropertyDescriptor?.isRelationship) {
						// If the groupBy property is a relationship, we need to fetch the related entity in order to display the label for the group
						const relatedEntities = await (groupByPropertyDescriptor.type as typeof BaseEntity).find({
							where       : { id : In(newGroups.filter(group => group.value).map(group => group.value)) },
							withDeleted : true,
						});
						relatedEntities.forEach(entity => {
							const group = newGroups.find(group => group.value === entity.id);
							if (group) {
								this.$set(group, 'entity', entity);
							}
						});
					}
				}
				else {
					this.hasMore = false;
				}

				if (loadMore) {
					this.rowGroups = _.uniqBy([ ...this.rowGroups, ...newGroups ], 'value');
				}
				else {
					this.rowGroups = newGroups;
				}

				this.table.refresh();
				return;
			}

			if (!rowGroup) {
				return;
			}

			options.offset = loadMore ? rowGroup.rows.length : 0;

			if (this.groupBy) {
				let groupByField = this.groupBy;
				if (this.entity.getPropertyDescriptor(groupByField).isRelationship) {
					// If the groupBy property is a relationship, we need to use the ID of the related entity in order to filter the results
					groupByField = `${groupByField}Id`;
				}
				options.where = { ...options.where, [groupByField] : rowGroup.value };	// create a copy so as not to overwrite original
			}

			// SHOULDDO PERFORMANCE: support "smart" refreshing even in "useRaw" mode
			if (refresh && rowGroup.rows.length > 0 && !this.useRaw) {
				// fetch the IDs and vers for the current rows of this group
				const newIDVers: { id: string; ver: number }[] = [];

				while (newIDVers.length < rowGroup.rows.length) {
					const nextPageOfIDs = await this.entity.findRaw({
						where                : options.where,
						order                : options.order,
						withDeleted          : options.withDeleted,
						withDeletedRelations : options.withDeletedRelations,

						// HACK: the +1 added to the limit allows for finding one new entity that could have been added by the local user
						// and gives it a chance to appear in the table
						limit  : rowGroup.rows.length + 1,
						offset : newIDVers.length,
						select : [ 'id', 'ver', Object.keys(options.order)[0] ],
					});
					newIDVers.push(...nextPageOfIDs as any);

					if (nextPageOfIDs.length < defaultFindLimit) {
						break;
					}
				}

				const newRows = [];
				for (const idVer of newIDVers) {
					// try to re-use existing entities
					const entity = rowGroup.rows.find(row => row.id === idVer.id) as BaseEntity
						?? await this.entity.findOne({ where : { id : idVer.id }, withDeleted : options.withDeleted, relations : this.relations });

					if (entity.ver < idVer.ver) {
						void entity.reload({ includeRelations : this.relations });
					}

					newRows.push(entity);
				}

				rowGroup.rows = newRows;
			}
			else {		// load rows for one rowGroup
				const [ results, totalCount ] = await (this.useRaw
					? this.entity.findRawAndCount(options)
					: this.entity.findAndCount(options)
				);

				// Perform any additional filtering that couldn't be done through the query
				const allRows         = [ ...rowGroup.rows, ...results ];
				const filteredResults = await _.filterAsync(results, result => this.extraFilterFunction(result, allRows));

				if (loadMore) {
					const oldRowCount      = rowGroup.rows.length;
					rowGroup.rows          = _.uniqBy(rowGroup.rows.concat(filteredResults), 'id');
					rowGroup.mightHaveMore = oldRowCount !== rowGroup.rows.length;
				}
				else {
					rowGroup.rows          = filteredResults;
					rowGroup.mightHaveMore = results.length === options.limit;
				}
				rowGroup.totalCount = totalCount;
			}

			this.scrollTop = this.$el.scrollTop;
			rowGroup.error = null;
			this.table?.refresh();
		}
		catch (error) {
			rowGroup.error = error;
			throw new Error(error);
		}
		finally {
			if (!rowGroup) {
				this.isLoading = false;
				// toggle the hasMoreGroups to force the visibilityLoader to trigger
				if (this.hasMore) {
					this.hasMore = false;
					this.$nextTick(() => {
						this.hasMore = true;
					});
				}
			}
			else {
				rowGroup.isLoading = false;
			}
		}
	}

	async loadMore() {
		if (this.canLoadMore) {
			await this.loadRowGroup(this.groupBy ? undefined : this.rowGroups[0], { loadMore : true });
		}
	}

	/**
	 * Called after every render.
	 */
	updated() {
		Vue.nextTick(() => {
			// reset the scroll position as the browser tends to keep it at the bottom once it's at the bottom
			if (this.scrollTop !== undefined) {
				this.$el.scrollTop = this.scrollTop;
				this.scrollTop     = undefined;
			}
		});
	}

	getRowActions(item: BaseEntity) {
		return typeof this.rowActions === 'function' ? this.rowActions(item) : this.rowActions;
	}

	toggleExpanded() {
		_.assignEach(this.rowGroups, { expanded : !this.expandAll });
		this.expandAll = !this.expandAll;
		this.table.refresh();
	}

	onRowClick(...args) {
		const item = args[0];

		if (item._grouping instanceof RowGroup) {
			const expanded          = !item._grouping.expanded;
			item._grouping.expanded = expanded;
			this.expandAll          = this.rowGroups.every(row => row.expanded === expanded) ? expanded : !expanded;
			this.table.refresh();

			// scroll to top of expanded group
			// COULDDO: improve this for instances when the table needs to load more rows during the scroll
			// Right now, it doesn't actually scroll to the top of the group since it just bottoms out on the page and then starts loading more rows
			if (expanded && this.groupBy) {
				const target       = document.getElementById(`group_${item._grouping.value}`).closest('tr');
				const targetTop    = target.getBoundingClientRect().top;
				const headerHeight = document.getElementsByClassName('sticky-top')[0]?.getBoundingClientRect().height;

				if (headerHeight) {
					window.scrollBy({ top : targetTop - headerHeight, behavior : 'smooth' });
				}
			}
		}
		else if (this.isStacked && this.$listeners['row-clicked-stacked']) {
			this.$emit('row-clicked-stacked', ...args);
		}
		else {
			this.$emit('row-clicked', ...args);
		}
	}

	/**
	 * Shows/hides the details for a row (if any)
	 */
	toggleRowDetails(item, { hideOthers = true } = {}) {
		if (hideOthers) {
			_.assignEach(this.table.localItems, { _showDetails : false });
		}

		if (item) {
			item._showDetails = true;
		}
	}

	/**
	 * Refreshes the table items.
	 * @param {boolean|EntityID} [options.hard=false] if true, forces a hard refresh which reloads all rows from scratch
	 *     use `true` if the table is likely to have had major row changes otherwise `false` is more efficient for small changes
	 *     can also be an ID of an item in the table to force a hard-refresh (with relations) of just that item
	 */
	async refresh({ hard = false }: { hard?: boolean | EntityID } = {}) {
		// SHOULDDO: figure out how to automatically but efficiently refresh all item relations (in this.getLocalItems) and get rid of this parameter
		if (typeof hard === 'string') {
			for (const rowGroup of this.rowGroups) {
				for (const row of rowGroup.rows) {
					if (row.id === hard) {
						await (row as BaseEntity).reload({ includeRelations : this.relations });
					}
				}
			}
			hard = false;
		}
		// If you scroll to the end of the table this will get set to false, which means the table won't load results past the initial page
		// So reset it to true here, which mimics the behaviour of the table when it's first loaded
		this.hasMore = true;
		return this.loadRowGroup(this.groupBy ? undefined : this.rowGroups[0], { refresh : !hard });
	}

}


// Sort nulls to the end
function nullsAtEnd(a, b) {
	if (a[0] === 'null') {
		return 1;
	}
	if (b[0] === 'null') {
		return -1;
	}
	// Otherwise preserve order
	return 0;
}
