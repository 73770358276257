import { Vue } from '$/lib/vueExt';

// Forms
import Form              from './Form.vue';
import FormInput         from './FormInput.vue';
import FormCheckbox      from './FormCheckbox.vue';
import FormCheckboxGroup from './FormCheckboxGroup.vue';
import FormTextarea      from './FormTextarea.vue';
import FormGroup         from './FormGroup.vue';
import FormSelect        from './FormSelect.vue';
import FormSelectSearch  from './FormSelectSearch.vue';
import FormRadioGroup    from './FormRadioGroup.vue';
import FormDropzone      from './FormDropzone.vue';

// Special Forms
import AddressEditor            from './AddressEditor.vue';
import DatePicker               from './DatePicker.vue';
import DobPicker                from './DobPicker.vue';
import FormCurrency             from './FormCurrency.vue';
import FormStripe               from './FormStripe.vue';
import NationalIDInput          from './NationalIDInput.vue';
import MonthPicker              from './MonthPicker.vue';
import FormPassword             from './FormPassword.vue';
import PhoneNumber              from './PhoneNumber.vue';
import FormInputWithSuggestions from './FormInputWithSuggestions.vue';

// Modals
import Modal             from './Modal.vue';
import ProgressModal     from './ProgressModal.vue';
import ConfirmationModal from './ConfirmationModal.vue';
import Steps             from './Steps/Steps.vue';
import Step              from './Steps/Step.vue';
import StepTitle         from './Steps/StepTitle.vue';
import StepNavigation    from './Steps/StepNavigation.vue';
import StepGroup         from './Steps/StepGroup.vue';

// Special Modals
import FileViewer        from './FileViewer.vue';
import FileViewerTrigger from './FileViewerTrigger.vue';
import ResourceViewer    from './ResourceViewer.vue';
import Agreement         from './Agreement.vue';

// Prompts and Tooltips
import InfoTip                    from './InfoTip.vue';
import UpgradePrompt              from './UpgradePrompt.vue';
import IdentityVerificationPrompt from './IdentityVerificationPrompt.vue';
import Unavailable                from './Unavailable.vue';
import ValidationFeedback         from './ValidationFeedback.vue';
import Notification               from './Notification.vue';

// Misc.
import Link            from './Link.vue';
import Table           from './Table.vue';
import Tools           from './Tools.vue';
import Tool            from './Tool.vue';
import Spinner         from './Spinner.vue';
import Avatar          from './Avatar.vue';
import ContactSupport  from './ContactSupport.vue';
import ConfirmButton   from './ConfirmButton.vue';
import SearchInput     from './SearchInput.vue';
import Icon            from './Icon.vue';
import Button          from './Button.vue';
import EmailLink       from './EmailLink.vue';
import Collapsible     from './Collapsible.vue';
import RadialChart     from './RadialChart.vue';
import Testimonial     from './testimonials/Testimonial.vue';
import Testimonials    from './testimonials/Testimonials.vue';
import CollapsibleCard from './CollapsibleCard.vue';

// Layout Components
import Page from './Page.vue';

// register widget components by prefix
_.forOwn({
	'b-' : [
		Form, FormInput, FormCheckbox, FormTextarea, FormCheckbox, FormCheckboxGroup, FormGroup, FormSelect, FormSelectSearch, FormRadioGroup, Modal,
		Icon, Button, Link,
	],
	'fl-' : [
		// Special Forms
		AddressEditor, MonthPicker, DatePicker, DobPicker, FormCurrency, FormStripe, ValidationFeedback, NationalIDInput, FormPassword, PhoneNumber,
		FormInputWithSuggestions,

		// Steps
		Steps, Step, StepTitle, StepNavigation, StepGroup,

		// Modals
		FileViewer, FileViewerTrigger, ResourceViewer, Agreement, ProgressModal, ConfirmationModal,

		// Prompts and Tooltips
		InfoTip, UpgradePrompt, IdentityVerificationPrompt, Unavailable, Notification,

		// Misc.
		Table, Tools, Tool, Spinner, Avatar, ContactSupport, ConfirmButton, SearchInput, FormDropzone, EmailLink, Collapsible, RadialChart,
		Testimonial, Testimonials, CollapsibleCard,

		// Layout Components
		Page,
	],
}, (components, prefix) => {
	components.forEach(component => Vue.component(`${prefix}${_.kebabCase(component.name)}`, component));

	// register fl- widgets with the old lcb- prefix
	// SHOULDDO: remove this once all references to lcb- widgets are changed
	if (prefix === 'fl-') {
		components.forEach(component => Vue.component(`lcb-${_.kebabCase(component.name)}`, component));
	}
});

// exception: InfoTip should really be lcb-info-tip but we're using lcb-infotip in many places
// SHOULDDO: convert lcb-infotip -> lcb-info-tip and get rid of this alias registration
Vue.component('lcb-infotip', InfoTip);

