import env, { Environment }                    from '$/lib/env';
import { ALaCarteType, LogEvents, SignUpType } from '$/lib/global';
import { loadScript }                          from '$/lib/utils';
import { BaseLogger, Log, LoggerLevel }        from '$/common/lib/logger';// references common to avoid circular dependency

import { Package } from '$/entities/Package';

declare global {
	var uetq: any;		// eslint-disable-line no-var
}

export async function loadUET() {
	if (env.isEnvironmentNot(Environment.PROD)) {
		return;
	}

	await loadScript('https://bat.bing.com/bat.js');

	// @ts-ignore UET should exist when this is run
	window.uetq = new UET({ ti : '97060382', enableAutoSpaTracking : true, q : window.uetq });
	window.uetq.push('pageLoad');
}

enum UETEvent {
	SignUpLandlord         = 'basic_landlord',
	TenantScreeningRenters = 'basic_renters',
	UpgradePremium         = 'premium_landlords',
	CreditReport           = 'credit_report',
	DebtReporting          = 'debt_reporting',
	BackgroundCheck        = 'background_check',
	TenantScreening        = 'tenant_screening',
	Collections            = 'collections',
}

const eventsMap = {
	[LogEvents.SignedUp] : {
		[SignUpType.Landlord] :	UETEvent.SignUpLandlord,
		[SignUpType.Renter]   : UETEvent.TenantScreeningRenters,
	},
	[LogEvents.PackageChanged]    : UETEvent.UpgradePremium,
	[LogEvents.ALaCartePurchased] : {
		[ALaCarteType.CreditReport]          : UETEvent.CreditReport,
		[ALaCarteType.DebtReporting]         : UETEvent.DebtReporting,
		[ALaCarteType.BackgroundCheck]       : UETEvent.BackgroundCheck,
		[ALaCarteType.TenantScreeningBundle] : UETEvent.TenantScreening,
		[ALaCarteType.Collections]           : UETEvent.Collections,
	},
};

export class UETEventLogger extends BaseLogger {

	log(logsOrLevel: Partial<Log> | Partial<Log>[] | Error | LoggerLevel, message?: string | Error, details?: any): Log[] {
		let logs = super.log.apply(this, [ logsOrLevel, message, details ]);

		// commands don't get logged
		logs = logs.filter(log => log.level !== LoggerLevel.Command);

		logs.forEach(log => {
			let eventID = typeof eventsMap[log.message] === 'object'
				? eventsMap[log.message][log.details.type]
				: eventsMap[log.message];

			// log these only for upgrades to non-basic (ie Premium) packages
			if (log.message === LogEvents.PackageChanged) {
				const pkg = log.details.to as Package;
				if (pkg?.isBasic) {
					eventID = null;
				}
			}

			if (eventID) {
				window.uetq ||= [];
				window.uetq.push('event', eventID, {});
			}
		});

		return logs;
	}

}
