








































import { Vue, Component, Prop, Ref } from '$/lib/vueExt';
import { BPopover }                  from 'bootstrap-vue';

/**
 * A button with confirmation message.
 */
@Component
export default class ConfirmButton extends Vue {

	/**
	 * The name of the b-icon to use instead of a button.
	 */
	@Prop({ default : 'trash' })
	readonly icon: string;

	/**
	 * Optional confirmation message to show after clicking on the button.
	 */
	@Prop({ default : 'Are you sure?' })
	readonly confirmationMessage: string;

	/**
	 * The placement of the confirmation popover.  Same as the placement property of <b-popover>
	 */
	@Prop({ default : 'bottom' })
	readonly placement: string;

	/**
	 * The variant to use for the button. The confirmation button will use the plain version (i.e. not outline- or text-) of the same variant.
	 */
	@Prop()
	readonly variant: string;

	@Ref()
	readonly popover: BPopover;

	private uniqueID = `delete_button_${Math.random()}`;

	shouldEmitClick = false;
	preventHide = false;

	get buttonVariant() {
		if (this.variant) {
			return this.variant;
		}

		return this.$slots.default ? 'outline-danger' : 'text-danger';
	}

	get confirmationVariant() {
		const splitVariant = this.buttonVariant.split('-');
		return splitVariant[splitVariant.length - 1];
	}

	onConfirmClick() {
		this.shouldEmitClick = true;
		this.popover.$emit('close');
	}

	onHide(event) {
		if (!this.shouldEmitClick && this.preventHide) {
			event.preventDefault();
		}
		this.preventHide = false;
	}

	onFocus() {
		this.preventHide = true;
	}

	onHidden()  {
		if (this.shouldEmitClick) {
			this.$emit('click');
			this.shouldEmitClick = false;
		}
	}

	hideTooltip() {
		this.$root.$emit('bv::hide::tooltip', this.uniqueID);
	}

}
