










































import { Component, Mixins, Prop, Watch } from '$/lib/vueExt';
import { Debounce }                       from '$/lib/utils';
import VueValidationMixin                 from '$/lib/mixins/VueValidationMixin';

/**
 * Special dropdown with search capability
 */
@Component({ model : { event : 'update:value' } })
export default class FormSelectSearch<T> extends Mixins(VueValidationMixin) {

	@Prop()
	readonly value: T;

	@Prop({ default : 'Select an Option' })
	readonly toggleText: string;

	@Prop({ required : true })
	readonly options: T[];

	/**
	 * Let the parent take action when the search text has changed
	 */
	@Prop({ type : Boolean, default : false })
	readonly customFilter: boolean;

	/**
	 * The number of results to show if the searched term had more results
	 */
	@Prop({ default : 10 })
	readonly limit: number;

	@Prop({ default : 0 })
	readonly totalCount: number;
	localTotalCount = 0;

	@Prop({ default : value => String(value) })
	readonly formatter: string | ((value: T) => string);

	private searchText: string = '';

	localFormatter(value: T): string {
		if (typeof this.formatter === 'string') {
			return value?.[this.formatter] || '';
		}
		return this.formatter(value);
	}

	@Watch('totalCount', { immediate : true })
	onTotalCountChange(value: number) {
		this.localTotalCount = value;
	}

	get optionsFiltered(): T[] {
		let results;
		if (this.customFilter) {
			results = this.options; // let the parent handle searching through options
		}
		else {
			results              = this.options.filter(option => this.localFormatter(option).includes(this.searchText));
			this.localTotalCount = results.length;
		}
		return results.slice(0, this.limit);
	}

	get hasMoreResults() {
		return this.localTotalCount > this.limit;
	}

	get hasNoResults() {
		return this.localTotalCount === 0 && this.optionsFiltered.length === 0;
	}

	get localToggleText() {
		return this.localFormatter(this.value) || this.toggleText;
	}

	isSelected(option: T) {
		return this.value === option;
	}

	@Watch('searchText')
	@Debounce()
	onSearch()  {
		this.$emit('search', this.searchText);
	}

}
