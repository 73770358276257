/**
 * HS Conversations API is loaded from the Tracker script.
 *
 * To test locally or testing make sure to enable HS Tracking in globals tools
 */
class ChatBot {

	ready = false;

	install() { // eslint-disable-line @typescript-eslint/naming-convention
		if ((window as any).HubSpotConversations) {
			this.onConversationsAPIReady();
		}
		else {
			(window as any).hsConversationsOnReady = [
				() => {
					this.ready = true;
					this.onConversationsAPIReady();
				},
			];
		}
	}

	get widget() {
		return (window as any).HubSpotConversations?.widget;
	}

	get status() {
		return this.widget?.status();
	}

	onConversationsAPIReady() {
		(window as any).hsConversationsSettings = {
			loadImmediately     : false,
			inlineEmbedSelector : '#chat-bot',
		};
	}

	load() {
		if (this.ready) {
			this.widget?.load();
		}
	}

	remove() {
		this.widget?.remove();
	}


}

export default new ChatBot();
