import Moment from 'moment';

import { Field }    from '$/entities/lib/JSONable';
import { BaseRole } from '$/entities/roles/BaseRole';

export class RepeatedPopup {

	constructor(timesShown = 0, lastShown?: Date) {
		this.lastShown  = lastShown ?? this.lastShown;
		this.timesShown = timesShown;
	}

	@Field()
	timesShown: number;

	@Field()
	lastShown: Date;

	/**
	 * Check if the popup has been shown less than maxTimesShown times and it's been `duration` since the last time it was shown
	 */
	static shouldShowPopup(popup: RepeatedPopup, maxTimesShown: number, duration: Moment.Duration): boolean {
		if (!popup || popup.timesShown >= maxTimesShown) {
			return false;
		}

		return Moment(popup.lastShown).add(duration).isBefore(Moment());
	}

	static async incrementPopupCount(role: BaseRole, name: 'activityTrackerPopup') {
		const popup = role.startupActions[name] ??= new RepeatedPopup();

		role.makeEditable();
		popup.timesShown = (popup.timesShown ?? 0) + 1;
		popup.lastShown  = new Date();
		await role.save();
	}

}

export class StartupActions {

	@Field()
	verify: boolean;

	@Field()
	confirmDraft: string;

	@Field()
	activityTrackerPopup: RepeatedPopup;

	static shouldShowActivityTrackerPopup(role: BaseRole): boolean {
		if (!role.isLandlord || !role.org.package.isPaid) {
			return false;
		}

		// If the popup hasn't ever been shown, show it
		if (!role.startupActions.activityTrackerPopup) {
			return true;
		}

		return RepeatedPopup.shouldShowPopup(role.startupActions.activityTrackerPopup, 12, Moment.duration(1, 'month'));
	}

}
