/**
 * This directive adds a class to it's element whenever the named method in the directive's
 * expression returns a Promise, and removes the class after the Promise fulfills.
 */
import Vue, { ComponentInstance, VNode } from 'vue';

export default Vue.directive('lcb-busy', {

	bind(el: Element, binding, vnode) {
		const { value, expression } = binding;
		if (typeof value !== 'function') {
			return;
		}
		for (const context of [ vnode.context, vnode.context.__proto__ ]) {
			if (!context.hasOwnProperty(expression)) {
				continue;
			}

			const method        = context[expression];
			context[expression] = _.debounce(async function(...args) {
				try {
					setBusy(true, el);
					return await method.apply(this, args);
				}
				catch (error) {
					console.warn(error);
				}
				finally {
					setBusy(false, el);
				}
			}, 100);
			break;
		}
	},

	update(el: Element, binding, vNode: VNode) {
		if (typeof binding.value !== 'function') {
			setBusy(binding.value, el, vNode.componentInstance);
		}
	},

});

function setBusy(newState: boolean, el: Element, component?: ComponentInstance) {
	el.classList.toggle('lcb-busy', newState);
	el.toggleAttribute('disabled', newState);
	if (component && 'disabled' in (component as any)._props) {
		component.$emit('disabled', newState);
	}
}
