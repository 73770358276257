









import { Vue, Component, Prop } from '$/lib/vueExt';
import { File }	                from '$/entities/File';

@Component({ model : { prop : 'file' } })
export default class FileViewerTrigger extends Vue {

	@Prop()
	file: File;

	viewingFile: File = null;

}
