import { ChildEntity, CommonEntity }  from '$/entities/BaseEntity';
import { CreditBureau, TenantMetro2 } from '$/entities/reporting/TenantMetro2';

/**
 * Tenant reporting record specific to Landlord Credit Bureau (US)
 */
@ChildEntity() @CommonEntity()
export class LcbUSTenantMetro2 extends TenantMetro2 {

	static getCreditBureau(): CreditBureau {
		return CreditBureau.LcbUS;
	}

}
