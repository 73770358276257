import { Chart, registerables, defaults, DoughnutController, Color } from 'chart.js';
import type { ArcElement, ArcProps } from 'chart.js';
import { generateChart }             from 'vue-chartjs';

const trackColor = '#f5f6f9';

/**
 *	Extension of Chart.js Doughnut
 *	- Adds data copy to be shown in the center
 *	- Adds label copy, displayed at the bottom half of the center, with the data copy on top
 *	- Optionally adds rounded arcs at the end of the arc
 */
export class RadialController extends DoughnutController {

	static id = 'Radial';

	static overrides = {
		cutout      : '80%',
		aspectRatio : 1,
		plugins     : {
			legend : {
				display : false,
			},
			datalabels : {
				display : false,
			},
		},
	};

	image: HTMLImageElement;

	get centerX() {
		return (this.chart.chartArea.left + this.chart.chartArea.right) / 2;
	}

	get centerY() {
		return (this.chart.chartArea.top + this.chart.chartArea.bottom) / 2;
	}

	get ctx() {
		return this.chart.ctx;
	}

	get dataset() {
		return this.chart.data.datasets[0];
	}

	initialize() {
		super.initialize();
		const imageSrc = this.dataset.center?.image?.src;
		if (imageSrc) {
			this.image        = new Image();
			this.image.src    = imageSrc;
			this.image.onload = () => this.draw();
		}

		// HACK: if the canvas is smaller than its max size when it loads, it will stay at that size even if the page is widened
		// Setting the css height here fixes this somehow
		// Also, when display inline in mobile view, will end up with 0 width, so set the width here too
		const size = (this.chart.config.options as any).size as number;
		if (size) {
			this.ctx.canvas.style.height = `${size}px`;
			this.ctx.canvas.style.width  = `${size}px`;
		}
	}

	draw() {
		const arcs     = this.getMeta().data as unknown as ArcElement[];
		const length   = arcs.length;
		const arcColor = arcs[0].options.backgroundColor;

		// If there's no data to show, make the track use the provided background color
		this.drawTrack(length > 1 ? trackColor : arcColor);

		arcs[0].draw(this.ctx);

		if (this.dataset.rounded && length > 1) {
			this.drawRoundedEnds(arcs);
		}

		this.drawCenter();
	}

	drawRoundedEnds(arcs: ArcElement[]) {
		arcs.forEach((arc, i) => {
			const pArc   = arcs[i === 0 ? arcs.length - 1 : i - 1];
			const pColor = pArc.options.backgroundColor;
			const props  = arc as unknown as ArcProps;

			const radius     = (props.outerRadius + props.innerRadius) / 2;
			const thickness  = (props.outerRadius - props.innerRadius) / 2;
			const startAngle = Math.PI / 2 - props.startAngle;
			const angle      = Math.PI / 2 - props.endAngle;

			this.ctx.save();
			this.ctx.translate(arc.x, arc.y);

			this.ctx.fillStyle = i === 0 ? arc.options.backgroundColor : pColor;
			this.ctx.beginPath();
			this.ctx.arc(radius * Math.sin(startAngle), radius * Math.cos(startAngle), thickness, 0, 2 * Math.PI);

			if (length > 2) {
				this.ctx.fill();
				this.ctx.fillStyle = arc.options.backgroundColor;
				this.ctx.beginPath();
			}

			this.ctx.arc(radius * Math.sin(angle), radius * Math.cos(angle), thickness, 0, 2 * Math.PI);
			this.ctx.closePath();
			this.ctx.fill();

			this.ctx.restore();
		});
	}

	drawTrack(color: string | Color) {
		const radius    = (this.outerRadius + this.innerRadius) / 2;
		const thickness = (this.outerRadius - this.innerRadius) / 2;

		this.ctx.save();
		this.ctx.strokeStyle = color;
		this.ctx.lineWidth   = thickness;
		this.ctx.beginPath();
		this.ctx.ellipse(this.centerX, this.centerY, radius, radius, 0, 0, Math.PI * 2);
		this.ctx.stroke();
		this.ctx.restore();
	}

	drawCenter() {
		const center = this.dataset.center;
		if (!center) {
			return;
		}

		const dataText         = center.data?.value;
		const dataFontOptions  = center.data?.font;
		const labelText        = center.label?.value;
		const labelFontOptions = center.label?.font;
		const imagePadding     = center.image?.padding;

		const { fontSize: labelFontSize, fontFamily: labelFontFamily, fontWeight: labelFontWeight }
			= this.calculateFont(labelText, labelFontOptions);

		const { fontSize: dataFontSize, fontFamily: dataFontFamily, fontWeight: dataFontWeight, elementHeight }
			= this.calculateFont(dataText, dataFontOptions);

		this.ctx.save();
		this.ctx.textAlign    = 'center';
		this.ctx.textBaseline = 'bottom';
		this.ctx.fillStyle    = labelFontOptions?.color || defaults.color.toString();

		// IMAGE
		if (this.image?.complete) {
			const { width, height } = this.calculateImageSize(this.image, imagePadding);
			this.ctx.drawImage(this.image, this.centerX - width / 2, this.centerY - height / 2, width, height);
		}
		else {
			// LABEL
			if (labelText) {
				this.ctx.font = `${labelFontWeight} ${labelFontSize}px ${labelFontFamily}`;
				for (let i = 0; i < labelText.length; i++) {
					this.ctx.fillText(labelText[i], this.centerX + this.offsetX, this.centerY + this.offsetY + (labelFontSize * 1.5) + ((labelFontSize * 1.5) * i));
				}
			}

			// DATA
			this.ctx.fillStyle = dataFontOptions?.color || defaults.color.toString();
			this.ctx.font      = `${dataFontWeight} ${dataFontSize}px ${dataFontFamily}`;
			this.ctx.fillText(dataText, this.centerX + this.offsetX, this.centerY + this.offsetY + dataFontSize / 2 - (elementHeight / 2  * (labelText ? 1 : 0)));
		}

		this.ctx.restore();
	}

	calculateFont(text: string | string [],  { family = defaults.font.family, size = 0, weight = 'normal' } = {}) {
		if (!text) {
			return { fontSize : 0, fontFamily : family, fontWeight : weight };
		}

		text = _.castArray(text);

		const innerDiameter   = this.innerRadius * 2;
		const padding         = innerDiameter / 4;
		const elementWidth    = innerDiameter - padding;
		const elementHeight   = elementWidth / 2;
		const initialFontSize = 30;

		if (size) {
			return { fontSize : size, fontFamily : family, fontWeight : weight,  elementHeight };
		}

		this.ctx.save();
		this.ctx.font = `${weight} ${initialFontSize}px ${family}`;

		let longestWidth = 0;
		for (const i in text) {
			longestWidth = Math.max(longestWidth, this.ctx.measureText(text[i]).width);
		}

		const maxWidthRatio = elementWidth / this.ctx.measureText('100%').width;
		const widthRatio    = elementWidth / longestWidth;
		this.ctx.restore();

		let newFontSize = Math.min(Math.floor(initialFontSize * widthRatio), Math.floor(initialFontSize * maxWidthRatio));
		newFontSize     = Math.min(newFontSize, Math.floor(elementHeight / text.length * 0.75));

		return { fontSize : newFontSize, fontFamily : family, fontWeight : weight,  elementHeight };
	}

	/**
	 * Calculate the height and width needed to exactly fit the image in the available space inside the doughnut graph
	 * @param image Image being used for the center. The aspect ratio of the image is used for the calculation
	 * @param padding Extra padding around the image. Measured in pixels from the corner to the edge of the circle
	 * @returns Width and height to use for the image
	 */
	calculateImageSize(image: HTMLImageElement, padding = 0) {
		const radius = this.innerRadius - padding;
		const ratio  = image.naturalWidth / image.naturalHeight;

		// Formula derived from pythagorean theorem: (width/2)^2 + (height/2)^2 = radius^2, subbing in width = ratio * height
		const height = Math.sqrt(4 * Math.pow(radius, 2) / (Math.pow(ratio, 2) + 1));
		const width  = ratio * height;

		return { width, height };
	}

}

Chart.register(...registerables);
Chart.register(RadialController);
export default generateChart('Radial', 'Radial', RadialController);
