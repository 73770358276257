import Axios from 'axios';

import { name as browserName, version as browserVersion, os, manufacturer, product } from 'platform';
import { AppBuild, Env as EnvCommon, Environment, Platform }                         from '$/common/lib/env';

export class Env extends EnvCommon {

	get platform(): Platform {
		return Platform.Client;
	}

	get build() {
		return config.build as AppBuild;
	}

	get version(): string {
		return '';
	}

	protected get _config(): Dictionary<any> {
		return config;
	}

	get os() {
		return os;
	}

	get browser() {
		return { name : browserName, version : browserVersion };
	}

	get device() {
		return { manufacturer, product };
	}

	get isMobile() {
		// SHOULDDO: find a more reliable way to detect whether the current browser is running on an actual mobile device
		return !!this.device.product;
	}

	get environment(): Environment {
		return config.environment;
	}

	get deployment(): string {
		return config.deployment;
	}

	constructor() {
		super();
		// sanity check
		this.ensureValidEnvironment(this.environment);
	}

	/**
	 * @returns the host IP address of the server that this client is connected to
	 */
	async getHostIP(): Promise<string> {
		return (await Axios.get('/api/status')).data.hostIP;
	}

}

// must be loaded synchronously by including <script src="/config.js"></script>
const config = (window as any)._clientConfig;
if (config === undefined) {
	throw new Error('could not fetch client config');
}
delete (window as any)._clientConfig;

export default new Env();
export * from '$/common/lib/env';
