/**
 * Interface and utilities for a street address.
 */

import Axios from 'axios';
import _     from '$/lib/lodashExt';	// explicit import required

// https://www150.statcan.gc.ca/n1/pub/92-500-g/2020001/tbl/tbl_4.2-eng.htm - all canadian street types
// french not included, includes commonly seen non official abbreviations as well
export const StreetSuffixMapping: { [key: string]: string } = {
	'avenue'              : 'ave',
	'boulevard'           : 'blvd',
	'by-pass'             : 'bypass',
	'cul-de-sac'          : 'cds',
	'circle'              : 'cir',
	'concession'          : 'conc',
	'crescent'            : 'cres',
	'corners'             : 'crnrs',
	'crossing'            : 'cross',
	'crossroads'          : 'crssrd',
	'court'               : 'crt',
	'centre'              : 'ctr',
	'diversion'           : 'divers',
	'drive'               : 'dr',
	'esplanade'           : 'espl',
	'expressway'          : 'expy',
	'extension'           : 'exten',
	'forest service road' : 'fsr',
	'freeway'             : 'fwy',
	'gardens'             : 'gdns',
	'grounds'             : 'grnds',
	'harbour'             : 'harbor',
	'highlands'           : 'hghlds',
	'heights'             : 'hts',
	'highway'             : 'hwy',
	'landing'             : 'landng',
	'lane'                : 'ln',
	'laneway'             : 'lnwy',
	'lookout'             : 'lkout',
	'limits'              : 'lmts',
	'mountain'            : 'mtn',
	'orchard'             : 'orch',
	'passage'             : 'pass',
	'park'                : 'pk',
	'parkway'             : 'pky',
	'place'               : 'pl',
	'plateau'             : 'plat',
	'point'               : 'pt',
	'pathway'             : 'ptwy',
	'private'             : 'pvt',
	'road'                : 'rd',
	'range'               : 'rg',
	'route'               : 'rte',
	'right of way'        : 'rtofwy',
	'section'             : 'sectn',
	'sideroad'            : 'siderd',
	'square'              : 'sq',
	'street'              : 'st',
	'subdivision'         : 'subdiv',
	'terrace'             : 'ter',
	'thicket'             : 'thick',
	'townline'            : 'tline',
	'turnabout'           : 'trnabt',
	'view'                : 'vw',
};

// SHOULD DO - validate if this is still necessary since street matching has changed
export interface AddressStreetSet {
	unitNumber?: string;
	street: string;
}

export enum Country {
	CA = 'CA',
	US = 'US',
}

export enum CountryLabels {
	CA = 'Canada',
	US = 'United States of America',
}

export function getRegionLabel(country) {
	switch (country) {
		case Country.US: return 'State';
		case Country.CA: return 'Province';
		default        : return 'Region';
	}
}

export const CountryRegions = {
	CA : {
		AB : 'Alberta',
		BC : 'British Columbia',
		MB : 'Manitoba',
		NB : 'New Brunswick',
		NL : 'Newfoundland and Labrador',
		NS : 'Nova Scotia',
		ON : 'Ontario',
		PE : 'Prince Edward Island',
		QC : 'Quebec',
		SK : 'Saskatchewan',
		NT : 'Northwest Territories',
		NU : 'Nunavut',
		YT : 'Yukon',
	},
	US : {
		AL : 'Alabama',
		AK : 'Alaska',
		AS : 'American Samoa',
		AZ : 'Arizona',
		AR : 'Arkansas',
		CA : 'California',
		CO : 'Colorado',
		CT : 'Connecticut',
		DE : 'Delaware',
		DC : 'District Of Columbia',
		FM : 'Federated States Of Micronesia',
		FL : 'Florida',
		GA : 'Georgia',
		GU : 'Guam',
		HI : 'Hawaii',
		ID : 'Idaho',
		IL : 'Illinois',
		IN : 'Indiana',
		IA : 'Iowa',
		KS : 'Kansas',
		KY : 'Kentucky',
		LA : 'Louisiana',
		ME : 'Maine',
		MH : 'Marshall Islands',
		MD : 'Maryland',
		MA : 'Massachusetts',
		MI : 'Michigan',
		MN : 'Minnesota',
		MS : 'Mississippi',
		MO : 'Missouri',
		MT : 'Montana',
		NE : 'Nebraska',
		NV : 'Nevada',
		NH : 'New Hampshire',
		NJ : 'New Jersey',
		NM : 'New Mexico',
		NY : 'New York',
		NC : 'North Carolina',
		ND : 'North Dakota',
		MP : 'Northern Mariana Islands',
		OH : 'Ohio',
		OK : 'Oklahoma',
		OR : 'Oregon',
		PW : 'Palau',
		PA : 'Pennsylvania',
		PR : 'Puerto Rico',
		RI : 'Rhode Island',
		SC : 'South Carolina',
		SD : 'South Dakota',
		TN : 'Tennessee',
		TX : 'Texas',
		UT : 'Utah',
		VT : 'Vermont',
		VI : 'Virgin Islands',
		VA : 'Virginia',
		WA : 'Washington',
		WV : 'West Virginia',
		WI : 'Wisconsin',
		WY : 'Wyoming',
	},
};

/**
 * Validates city and province combinations.
 * Currently only works for Canadian municipalities.
 */
export async function validateCityProvince(city: string, province: string, { throwOnHTTPError = false } = {}) {
	let cities: string[];
	try {
		cities = await getCityNames(city, province, { throwOnHTTPError });
	}
	catch (error) {
		if (error.message?.startsWith('invalid province code:')) {
			return error.message;
		}
		throw error;
	}

	return cities.find(cityOption => _.areEqualCaseless(normalizeName(city), normalizeName(cityOption)))
		? ''
		: 'City not found in the given province. Please verify and continue.'
	;

	function normalizeName(name: string) {
		return name.replace(/[.-]/g, ' ').replace(/\s{2,}/g, ' ').trim();
	}
}

/**
 * Retrieves the list of valid city names matching the input city pattern and the given province.
 * @param {object}  [options]
 * @param {boolean} [options.suggestions] if true, returns cities that start with the given city string
 */
export const getCityNames = _.memoize(
	async function(city: string, province: string, { autocomplete = false, throwOnHTTPError = false } = {}): Promise<string[]> {
		const provinceCode = {
			NL : 10,
			PE : 11,
			NS : 12,
			NB : 13,
			QC : 24,
			ON : 35,
			MB : 46,
			SK : 47,
			AB : 48,
			BC : 59,
			YT : 60,
			NT : 61,
			NU : 62,
		}[province];

		if (!provinceCode) {
			throw new Error(`invalid province code: ${province}`);
		}

		if (autocomplete) {
			city = `${city}*`;
		}
		const params = `q=${encodeURIComponent(city)}&province=${provinceCode}&theme=985&category=O`;
		try {
			const results = (await Axios.get(`https://geogratis.gc.ca/services/geoname/en/geonames.json?${params}`)).data;
			return _(results.items).map('name').uniq().value();
		}
		catch (error) {
			if (throwOnHTTPError) {
				throw new Error(`could not reach city search API: ${error.message || error}`);
			}
			return [];
		}
	},
	// memoization key function
	(city: string, province: string, { autocomplete = false } = {}) => `${city}${autocomplete ? '*' : ''}:${province}`
);

// intentional space to check if it is last word
const streetSuffixRegex = new RegExp(` ${Object.keys(StreetSuffixMapping).join('$| ')}$`, 'gi');

export function normalizeStreetSuffix(street: string): string {
	return street.replace(streetSuffixRegex, matched => ` ${_.capitalize(StreetSuffixMapping[_.trim(_.toLower(matched))])}`);
}
