import { Vue }            from '$/lib/vueExt';
import { mergeData }      from 'vue-functional-data-merge';
import { VueConstructor } from 'vue';

const BaseAttrs = {
	'width'      : '1em',
	'height'     : '1em',
	'focusable'  : 'false',
	'role'       : 'img',
	'aria-label' : 'icon',
};

// This is creating a webpack context for any svg in the $/assets/icons directory
// https://webpack.js.org/guides/dependency-management/#requirecontext
const req = (require as any).context('$/assets/icons/?inline', false, /\.svg$/);


/**
 * Creates a Component name and load the Component (using vue-svg-loader)
 * for each file in the above context and register it with Vue globally.
 */
req.keys().forEach(path => {
	const filename = _.last<string>(path.split('/')).replace(/\.svg$/, '');
	const name     = `FIcon${_.pascalCase(filename)}`;
	const svg      = req(path);
	registerIcon(name, svg);
});

function registerIcon(name: string, svg: VueConstructor) {
	const component = Vue.extend({
		functional : true,
		props      : {
			variant : {
				type    : String,
				default : '',
			},
			fontScale : {
				type    : [ Number, String ],
				default : 1,
			},
		},
		render(h, { data, props }) {
			const variant   = props.variant;
			const fontScale = Math.max(parseFloat(props.fontScale as string) || 1, 1);
			const attrs     = { ...BaseAttrs,
				style : fontScale === 1 ? null : `font-size : ${fontScale * 100}%;`,
			};
			return h(
				svg,
				mergeData({
					staticClass : 'b-icon bi', // Add the default BIcon classes
					class       : {
						[`text-${variant}`] : variant, // This support setting the variant prop
					},
					attrs,
				},
				data,
				{
					attrs : {
						fill : 'currentColor', // This overwrites the fill defined on SVG element, allowing css colors
					},
				})
			);
		},
	});
	Vue.component(name, component);
}
