








































































import type { BModal }          from 'bootstrap-vue';
import { Vue, Component, Prop } from '$/lib/vueExt';

@Component({ model : { prop : 'file' } })
export default class FileViewer extends Vue {

	@Prop()
	readonly file: File;

	/**
	 * if true, adds approve/reject buttons to the modal and emits a review event
	 */
	@Prop({ default : false })
	readonly review: boolean;

	loading  = true;
	rotation = 0;

	get isImage() {
		return this.file ? this.file.mimeType.match('image/*') : false;
	}

	get style() {
		return `transform : rotate(${this.rotation * 90}deg)`;
	}

	reviewFile(approved: boolean) {
		this.$emit('review', approved);
		(this.$refs['file-viewer'] as BModal).hide();
	}

	rotateClockwise() {
		this.rotation++;
	}

	rotateCounterClockwise() {
		this.rotation--;
	}

	onHidden() {
		this.rotation = 0;
		this.loading  = true;
		this.$emit('update:file', null);
		this.$emit('hidden');
	}

}

export interface File {
	name: string;
	mimeType: string;
	contentURL: string;
}
