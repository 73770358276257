
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 -3.149 121.596 121.596"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M0 41.763 60.908 0l21.3 14.846v-12.3h18.927v23.8l20.464 15.413-9.178 14.051-51.51-35.3-51.334 35.3Z"}}),_c('path',{attrs:{"d":"m14.232 52.369-.2 62.929h93.617l.1-62.871-6.733-4.506-.129 61.365H19.918l.193-60.824"}}),_c('path',{attrs:{"d":"M56.891 39.72h7.676v6.885l4.733.992 4.838 2.531 2.457 3.939.582 5.1h-10.93l-.235-2.151-2.13-1.632-3.631-1.312-3.36 1.312-1.312 2.731.521 3.055 2.9 1.417 7.015.579 5.913 1.422 3.052 1.784 2.886 3.892.792 4.885-.792 6.988-2.886 3.479-5.677 3.466-4.733 1.417v6.471h-7.676v-7.05l-5.568-.838-4.474-2.887-2.731-4.058-1.052-2.826v-3h11.77l.744 2.424 1.312 1.831 4.626 1.573 4.73-1.573 1.254-1.831v-1.739l-1.254-1.845-2.365-1.255-13.612-3.156-3.421-2.32-2.731-3.829-.578-7.571 3.31-5.83 4.945-3.584 5.1-1.512Z"}})])
          )
        }
      }
    