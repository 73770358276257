/**
 * Stores objects in browser's localStorage, and prunes old ones as needed when reaching the storage limit imposed by the browser.
 */

// legacy key prefixes -- used to identify purge candidates
const LEGACY_PREFIXES = [];

// a cache of storageDates for each full key
const metadataCache: Dictionary<LocalStorageMetadata> = {};

// COULDDO: add support for using sessionStorage as additional space
export class LocalStorageCache {

	// prefix all of the keys controlled by this class
	// some items for the site are not controlled by this class (ie mongoMachineId used by ObjectID)
	namespace: string = '';

	constructor(namespace: string) {
		this.namespace = namespace;
	}

	private getPrefixedKey(key: string) {
		return this.namespace ? `${this.namespace}.${key}` : key;
	}

	private getRecord(key: string): LocalStorageRecord {
		let record: any = localStorage.getItem(this.getPrefixedKey(key));

		record = tryParseRecord(record);

		if (typeof record === 'string') {
			throw new Error('could not parse record JSON');
		}

		return record;
	}

	getMetadata(key: string): LocalStorageMetadata {
		const fullKey = this.getPrefixedKey(key);
		let metadata  = metadataCache[fullKey] = metadataCache[fullKey] || this.getRecord(key);

		if (metadata) {
			// COULD DO: use JSONable
			_.forEach([ 'timestamp', 'expires' ], field => {
				if (metadata[field]) {
					metadata[field] = new Date(metadata[field]);
				}
			});
		}

		const hasExpired      = metadata && metadata.expires && metadata.expires <= new Date();
		const existsInStorage = localStorage.hasOwnProperty(fullKey);

		if (hasExpired || !existsInStorage) {
			this.delete(key);
			metadata = undefined;
		}

		return metadata;
	}

	/**
	 * Retrieves an item from browser's local storage
	 * @param  key  Globally unique identifier for object
	 * @return      value stored at key, or undefined if not found
	 */
	get(key: string) {
		let record: any = localStorage.getItem(this.getPrefixedKey(key));

		if (record === null) {
			return undefined;
		}

		record = tryParseRecord(record);

		if (typeof record !== 'object' || record === null) {
			return record;
		}

		// check for expiry
		if (typeof record.expires === 'number' && record.expires <= new Date()) {
			this.delete(key);
			return undefined;
		}

		return record.value;
	}

	/**
	 * Stores an item in the browser's local storage.
	 * Prunes items if required to stay under storage limit imposed by browser.
	 * Oldest item by storage date gets pruned first.
	 *
	 * @param  key    Globally unique identifier for object
	 * @param  value  Item to store.  Must be serializable by JSON.stringify (primitives are serializable).
	 */
	set(key: string, value: any, { expires = undefined } = {}) {
		if (expires) {
			expires = new Date(expires);
		}

		const timestamp = new Date();
		const record    = JSON.stringify({
			value,
			timestamp,
			expires,
		});

		// don't attempt to store anything larger than 5MB since we'd be needlessly purging the cache
		if (record.length > 5 * 1024 * 1024) {
			throw new Error('cannot be larger than 5MB');
		}

		let purgeCandidates;
		let fullKey;
		while (true) {		// eslint-disable-line no-constant-condition
			try {
				fullKey = this.getPrefixedKey(key);
				localStorage.setItem(fullKey, record);
				metadataCache[fullKey] = {
					timestamp,
					expires,
				};
				return;
			}
			catch (err) {
				if (err.name !== 'QuotaExceededError' && err.name !== 'NS_ERROR_DOM_QUOTA_REACHED') {
					throw err;
				}

				// if we haven't yet built the list of candidates for purging, build that list now
				if (!purgeCandidates) {
					const purgeKeyPrefixes = LEGACY_PREFIXES.concat(this.getPrefixedKey(''));
					purgeCandidates        = this.getKeysWithPrefixes(purgeKeyPrefixes, true).map(purgeItemKey => {
						const purgeItem = tryParseRecord(localStorage.getItem(purgeItemKey));

						// if it doesn't have a timestamp, then purge it first
						return {
							key       : purgeItemKey,
							timestamp : typeof purgeItem.timestamp == 'number' ? purgeItem.timestamp : Number.MIN_VALUE,
						};
					});

					// sort in descending timestamp order (so that last item in array is oldest and therefore the first to be purged)
					purgeCandidates = _.sortBy(purgeCandidates, 'timestamp');
				}

				// if the list of purge candidates is still empty, then there's nothing we can do to make extra space
				if (purgeCandidates.length === 0) {
					throw err;
				}

				fullKey = purgeCandidates.pop().key;
				localStorage.removeItem(fullKey);
				delete metadataCache[fullKey];
			}
		}
	}

	/**
	 * Removes an item from the browser's local storage, if it exists.
	 */
	delete(key: string) {
		const fullKey = this.getPrefixedKey(key);
		const value   = this.get(key);
		localStorage.removeItem(fullKey);
		delete metadataCache[fullKey];

		return value;
	}

	clear() {
		this.getKeysWithPrefixes([ this.getPrefixedKey('') ], true).forEach(key => {
			localStorage.removeItem(key);
			delete metadataCache[key];
		});
	}

	has(key: string): boolean {
		return !!this.getMetadata(key);
	}

	/**
	 * Returns all keys in the browser's local storage that are managed by this class.
	 */
	keys(): string[] {
		return this.getKeysWithPrefixes([ this.getPrefixedKey('') ]).filter(key =>
			this.has(key)	// checks for expiry
		);
	}

	/**
	 * Returns all keys in localStorage that have one of the given prefixes.
	 * @param   prefixes
	 * @param   withPrefix if true, returns the keys with the prefix; if false, strips the prefix from the result keys
	 */
	private getKeysWithPrefixes(prefixes: string[], withPrefix?: boolean) {
		const results = [];
		for (let a = 0; a < localStorage.length; a++) {
			const key = localStorage.key(a);

			for (let b = 0; b < prefixes.length; b++) {
				if (key.indexOf(prefixes[b]) === 0) {
					results.push(withPrefix ? key : key.substr(prefixes[b].length));
				}
			}
		}
		return results;
	}

	/**
	 * Calls the given callback once for each key/value in this storage.
	 * @param  {Function} callback
	 *             {*}      value
	 *             {String} key
	 */
	forEach(callback: (value: any, key: string) => void) {
		this.keys().forEach(key => {
			callback(this.get(key), key);
		});
	}

	storageDate(key: string): Date {
		return (this.getMetadata(key) || {}).timestamp;
	}

}

function tryParseRecord(value: string): LocalStorageRecord {
	try {
		return JSON.parse(value);
	}
	catch (e) {
		return value as any;
	}
}

interface LocalStorageRecord extends LocalStorageMetadata {
	value: any;
}

interface LocalStorageMetadata {
	timestamp: Date;
	expires: Date;
}
