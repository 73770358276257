
/**
 * Overwrite BModal to add support for fullscreen modals on.
 */

import { BModal }          from 'bootstrap-vue';
import { Component, Prop } from '$/lib/vueExt';

@Component
export default class Modal extends BModal {

	@Prop()
	fullscreen: boolean;

	get dialogClasses() {
		return [
			...(BModal as any).options.computed.dialogClasses.call(this),
			{
				fullscreen : this.fullscreen,
			},
		];
	}

}
