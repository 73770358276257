




































































import { Vue, Component, Prop, Watch } from '$/lib/vueExt';

/**
 * A component that can show/hide a body section.
 * Also supports read-only/editing mode on the body.
 */
@Component({ model : { prop : 'expanded', event : 'toggle' } })
export default class CollapsibleCard extends Vue {

	@Prop({ default : '' })
	readonly title: string;

	@Prop({ default : '' })
	readonly bodyClass: string | any[] | Record<string, unknown>;

	@Prop({ default : '' })
	readonly titleClass: string | any[] | Record<string, unknown>;

	@Prop({ default : false })
	readonly editable: boolean;

	/**
	 * Used to control whether the card is collapsed (false) or expanded (true)
	 */
	@Prop({ default : true })
	readonly expanded: boolean;

	/**
	 * If true, cannot be collapsed/expanded via UI (only programmatically)
	 */
	@Prop({ default : false })
	readonly noUserCollapse: boolean;

	/**
	 * If true, the card will not show the collapse/expand icon
	 */
	@Prop({ default : false })
	readonly noCollapse: boolean;

	isExpanded: boolean = false;
	isEditing: boolean = false;

	@Watch('expanded', { immediate : true })
	onExpandedChange(value) {
		if (value !== this.isExpanded) {
			this.isExpanded = value;
		}
	}

	@Watch('isExpanded')
	onExpansionChange(value, oldValue) {
		if (value !== oldValue) {
			this.$emit('toggle', value);
		}
	}

	@Watch('isEditing')
	onEditingChance() {
		if (this.isEditing) {
			this.isExpanded = true;
		}
	}

	onStartEditing() {
		this.isEditing = true;
		this.$emit('edit');
	}

	onCancel() {
		this.isEditing = false;
		this.$emit('cancel');
	}

	async onSave() {
		// emit save event with an onDone callback to finish the save operation (if called)
		this.$emit('save', () => {
			this.isEditing = false;
		});
	}

	toggleExpansion() {
		if ((!this.noUserCollapse || !this.isExpanded) && !this.isEditing && !this.noCollapse) {
			this.isExpanded = !this.isExpanded;
		}
	}

}
