import Errors from '$/lib/Errors';

import { VerificationStatus, VerificationType } from '$/entities/lib/VerificationResults';
import type { User }                            from '$/common/entities/User'; // intentionally common to avoid semantic error

/**
 * Base class for all identity verification implementations.
 */
export abstract class BaseVerifier {

	/**
	 * The max number of attempts allowed for this verification type.
	 */
	get maxAttempts() {
		return 2;
	}

	getAttempts(user: User) {
		return user.getVerificationResults((this.constructor as typeof BaseVerifier).verificationType)
			.filter(result => result.status !== VerificationStatus.Started)
			.length;
	}

	/**
	 * @returns true if this user has reached or exceeded the maximum number of attempts for this verification type
	 */
	maxAttemptsReached(user: User) {
		return this.getAttempts(user) >= this.maxAttempts;
	}

	static getURL(suffix = ''): string {
		return `/api/identity-verification/${_.lowerCase(this.verificationType)}${_.ensureStartsWith(suffix, '/')}`;
	}

	/**
	 * @returns the type of verification this verifier performs
	 */
	static get verificationType(): VerificationType {
		throw new Errors.NotImplemented();
	}

}
