import Axios from 'axios';

import { PersonaVerifier as PersonaVerifierCommon } from '$/common/lib/identityVerification/personaVerifier';
export * from '$/common/lib/identityVerification/personaVerifier';

export class PersonaVerifier extends PersonaVerifierCommon {

	async checkInquiryStatus(inquiryId: string): Promise<{ status: string; sessionToken: string }> {
		return (await Axios.get(PersonaVerifier.getURL(inquiryId))).data;
	}

}

export default new PersonaVerifier();
