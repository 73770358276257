import Axios            from 'axios';
import { formatSeries } from '$/lib/ChartJSHelper';

import { Entity } from '$/entities/BaseEntity';
import { ReportingGraph as ReportingGraphCommon, GraphTypes } from '$/common/entities/ReportingGraph';
export *                                                      from '$/common/entities/ReportingGraph';

export type GraphParams = Dictionary<any>;

interface QueryResult {
	[columnName: string]: any[];
}


@Entity()
export class ReportingGraph extends ReportingGraphCommon {

	sqlError: string = '';

	/**
	 * Whether the SQL is a valid query or not.  Null if unknown.
	 */
	sqlValidationState: boolean | null = null;

	/**
	 * An efficient way to determine if the SQL is valid or not.
	 */
	async validateSql(sqlParams?: GraphParams) {
		try {
			await Axios.get(ReportingGraph.collectionUrl('/sql'), { params : { sql : `EXPLAIN ${this.replaceSqlParams(sqlParams)}` } });
			this.sqlValidationState = true;
			this.sqlError           = '';
		}
		catch (err) {
			this.sqlValidationState = false;
			this.sqlError           = err.message || err;
		}

		return this.sqlValidationState;
	}

	/**
	 * @return the result of the query in the DB in column-format (keys are the column names, values are an array of row values for that column)
	 */
	protected async getDataRaw(sqlParams?: GraphParams, { sendSql = false } = {}): Promise<QueryResult> {
		try {
			this.sqlError           = '';
			this.sqlValidationState = true;

			if (sendSql) {
				return (await Axios.get(ReportingGraph.collectionUrl('/sql'), { params : { sql : this.replaceSqlParams(sqlParams) } })).data;
			}

			return (await Axios.get(ReportingGraph.collectionUrl(`/${this.id}/data`), { params : { sqlParams : JSON.stringify(sqlParams) } })).data;
		}
		catch (err) {
			this.sqlValidationState = false;
			this.sqlError           = err.message || err;
			return { };
		}
	}

	/**
	 * Displays the data depending on the GraphType
	 */
	async getData(sqlParams: GraphParams, { forceTabularData = false, sendSql = false } = {}) {
		const data = await this.getDataRaw(sqlParams, { sendSql });

		switch (this.library) {
			case GraphTypes.Report:
			case GraphTypes.Table:
				return data;

			case GraphTypes.PivotTable: {
				const options = this.chart?.options;
				if (!options?.cols || !options?.rows || !options?.data) {
					this.chartParseError = 'must define "options.rows", "options.cols", and "options.data" keys.';
					return {};
				}

				const results = { [options.rows] : _.uniq(data[options.rows]) };

				for (const column of _.uniq(data[options.cols]) as string[]) {
					results[column] = new Array(results[options.rows].length);
					if (options.emptyValue !== undefined) {
						_.fill(results[column], options.emptyValue);
					}
				}

				let prevValue;
				let index = -1;
				for (const dataIndex in data[options.rows]) {
					const rowValue = data[options.rows][dataIndex];
					const column   = data[options.cols][dataIndex];
					if (prevValue !== rowValue) {
						index++;
					}
					results[column][index] = data[options.data][dataIndex];
					prevValue              = rowValue;
				}

				return results;
			}

			case GraphTypes.KPI:
				return formatSeries(data)[0].data[0];

			case GraphTypes.ChartJS:
				if (forceTabularData) {
					return data;
				}
				return {
					labels   : data.labels,
					datasets : formatSeries(data, this.chart?.graphType),
				};
		}
	}

	/**
	 * Returns the data for this graph in CSV format
	 */
	async getDataAsCSV(sqlParams?: GraphParams): Promise<string> {
		let data = await this.getData(sqlParams, { forceTabularData : true });
		data     = [
			_.keys(data),
			..._.zip(..._.values(data)),
		];
		return data.map(line => line.map(s => `"${s}"`)).join('\n');
	}

	static async getTables(): Promise<Dictionary<string[]>> {
		return (await Axios.get(ReportingGraph.collectionUrl('/tables'))).data;
	}

}
