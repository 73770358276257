import { Mixin }                     from '$/lib/utils';
import Validate, { ValidationMixin } from '$/lib/Validate';

import { JSONable, Field } from '$/entities/lib/JSONable';
import { Address }         from '$/entities/Address';

export enum ReportingType {
	Rent        = 'rent',
	Debt        = 'debt',
	Collections = 'collections',
	PendingDebt = 'pendingDebt', // for imports, gets changed to Debt the next time the EnterpriseDebtReportingTask runs
}

/**
 * Status for Reporting
 */
export enum CollectionsStatus {
	NotStarted           = 'notStarted',		// reporting not started
	Draft                = 'draft',				// started collections draft
	Submitted            = 'submitted',			// reporting requested by a member of the LL org
	Reported             = 'reported',			// reporting sent to collections
	Error                = 'error',				// error while processing the registration
	ClosedPlacedInError  = 'placedInError',		// closed because the submission was a mistake
	ClosedBankrupt       = 'bankrupt',			// closed because debtor has declared bankruptcy
	ClosedSettledInFull  = 'settledInFull',		// closed with a settlement for a partial repayment
	ClosedPaidInFull     = 'paidInFull',		// closed with the debt fully repaid
}

// eslint-disable-next-line
export interface CollectionsLandlordDetails extends ValidationMixin {}

@Mixin(JSONable)
export class CollectionsLandlordDetails {

	@Field()
	@Validate({ required : true })
	nameOnLease: string = '';

	@Field()
	@Validate({ required : true })
	contactName: string = '';

	@Field()
	@Validate({ required : true, phoneNumber : true })
	contactPhone: string = '';

	@Field()
	@Validate({ required : true, email : true })
	contactEmail: string = '';

	@Field()
	@Validate({
		required  : true,
		recursive : {
			street     : { required : true },
			city       : { required : true },
			province   : { required : true },
			country    : { required : true },
			postalCode : { required : true },
		},
	})
	paymentAddress: Address = null;

	constructor(details?: Partial<CollectionsLandlordDetails>) {
		Object.assign(this, details);
	}

}

@Mixin(JSONable)
export class ReportingStatus {

	@Field()
	type: ReportingType;

	@Field()
	reportedById: string;

	@Field()
	collectionsStatus: CollectionsStatus;

	@Field()
	additionalDetails: string;

	@Field()
	recovered: number;

	@Field()
	initialAmount: number;

	@Field()
	closedDate: Date;

	@Field()
	landlordDetails: CollectionsLandlordDetails;

	@Field()
	typeChangedOn: Date;

	@Field(ReportingStatus)
	history: Omit<ReportingStatus, 'history'>[] = [];

	get submitted() {
		return [ CollectionsStatus.Submitted, CollectionsStatus.Reported ].includes(this.collectionsStatus);
	}

	constructor(type?: ReportingType, reportedById?: string, collectionsStatus?: CollectionsStatus) {
		this.type              = type ?? null;
		this.typeChangedOn     = new Date();
		this.reportedById      = reportedById ?? null;
		this.collectionsStatus = collectionsStatus;
	}

}
