import { HubSpotTracker } from '$/lib/hubspot';
import type { Log }       from '$/lib/logger';

import { HubSpotEventLogger as HubSpotEventLoggerCommon } from '$/common/lib/hubspot/HubSpotEventLogger';
export *                                                  from '$/common/lib/hubspot/HubSpotEventLogger';

export class HubSpotEventLogger extends HubSpotEventLoggerCommon {

	sendToHubspot(eventNameOrID: string, log: Log, legacy: boolean) {
		if (legacy) {
			// SHOULD DO: remove once custom behavioral events replace legacy events entirely
			HubSpotTracker.trackBehavioralEvent(eventNameOrID);
		}
		else {
			HubSpotTracker.trackCustomBehavioralEvent(eventNameOrID, log.details);
		}
	}

}
