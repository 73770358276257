import Axios      from 'axios';
import { Entity } from '$/entities/BaseEntity';

import { File as FileCommon } from '$/common/entities/File';
export                      * from '$/common/entities/File';

@Entity()
export class File extends FileCommon {

	nativeFile = null; // native File object

	isEdited(propertyNames?: PossibleArray<keyof this>, options?): boolean {
		// a file is considered edited if it has a native file (since the only way for that to happen is if the user has selected a new file)
		if (this.isNew && !this.nativeFile) {
			return false;
		}

		let isEdited = false;
		if (!propertyNames || _.castArray(propertyNames).includes('nativeFile')) {
			isEdited = !!this.nativeFile;
		}
		return isEdited || super.isEdited(propertyNames, options);
	}

	async getContents(): Promise<Buffer> {
		if (this.nativeFile) {
			return this.nativeFile.arrayBuffer();
		}

		return (await Axios.get(File.collectionUrl(`${this.id}/content`))).data;
	}

}
