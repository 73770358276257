























import { Vue, Component, Prop } from '$/lib/vueExt';

export enum SpinnerVariant {
	BSpinner = 'b-spinner',
	Dots     = 'dots'
}

/**
 * This component displays an animated visual meant to indicate that work is in progress.
 */
@Component
export default class Spinner extends Vue {

	@Prop({ default : SpinnerVariant.BSpinner })
	readonly variant: SpinnerVariant;

	@Prop()
	readonly color: string;

	@Prop({ default : 2 })
	readonly size: number;

}
