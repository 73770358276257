/**
 * Entity used to write logs into the DB for persistance.
 */
import JSON5                  from 'json5';
import { SelectQueryBuilder } from 'typeorm';
import { JSONTransformer, BooleanTransformer } from '$/lib/columnTransformers';

import Permissions, { Context }       from '$/entities/lib/Permissions';
import { RolePermission }             from '$/entities/roles/RolePermission';
import { BaseEntity, Entity, Column } from '$/entities/BaseEntity';

export enum GraphTypes {
	KPI        = 'kpi',
	Table      = 'table',
	ChartJS    = 'ChartJS',
	Report     = 'report',
	PivotTable = 'pivotTable',
}

@Entity('reportingGraph', { common : true })
@Permissions({
	create : Permissions.roleHasPermission(RolePermission.ReportingWrite),
	read   : [ Permissions.roleHasPermission(RolePermission.ReportingRead), reportingGraphRead ],
	update : Permissions.roleHasPermission(RolePermission.ReportingWrite),
	delete : Permissions.roleHasPermission(RolePermission.ReportingWrite),
})
export class ReportingGraph extends BaseEntity {

	@Column()
	title: string = '';

	@Column('text')
	sql: string = '';

	@Column({ type : 'varchar', length : 1000 })
	description: string = '';

	@Column()
	library: GraphTypes = GraphTypes.Report;

	@Column({ type : 'simple-array' })
	categories: string[] = [];

	@Column({ type : 'float', nullable : true })
	order: number = null;

	@Column({ type : 'json', transformer : JSONTransformer({ strict : false  }), nullable : true })
	chart: DashboardChart = { graphType : '', options : {} };

	/**
	 * Additional required permissions needed to view this graph
	 */
	@Column({ type : 'simple-array' })
	@Permissions({ write : Permissions.serverOnly })
	requiredPermissions: RolePermission[] = [];

	/**
	 * If true,  automatically load the graph once it becomes visible on the dashboard
	 * Useful for heavy graphs that aren't of type 'Report'
	 */
	@Column({ type : 'boolean', transformer : BooleanTransformer })
	autoLoad: boolean = true;

	chartParseError: string = '';

	setChart(newValue?: string) {
		try {
			this.chartParseError = '';
			if (newValue) {
				this.chart = JSON5.parse(newValue);
			}

			return JSON5.stringify(this.chart, undefined, 2);
		}
		catch (err) {
			this.chartParseError = err.message || err;
			return newValue;
		}
	}

	get includesDateRangeParams() {
		return this.sql.includes('${from}') || this.sql.includes('${to}');
	}

	get includesGranularityParams() {
		return this.sql.includes('${granularity}');
	}

	/**
	 * Replaces any params in the query with matching keys from sqlParams
 	 */
	replaceSqlParams(sqlParams: Dictionary<any> = {}) {
		return this.sql?.replace(/\$\{.+?\}/g, paramName => {
			paramName = paramName.substring(2, paramName.length - 1).trim();
			let value = sqlParams[paramName];

			// map value through param map in options
			const paramMap = this.chart.params;
			if (paramMap?.hasOwnProperty(paramName)) {
				value = paramMap[paramName][value];
			}

			if (value === undefined || value === null) {
				return '';
			}

			if (value instanceof Date) {
				return value.toISOString();
			}

			return value;
		});
	}

}

export interface DashboardChart {
	/**
	 * Type of graph: line, line-area, bars, pie etc.
	 */
	graphType: string;

	/**
	 * Dataset options passed to each dataset
	 */
	datasetOptions?: Record<string, unknown>[];

	/**
	 * Specific options for graph depending on library.
	 */
	options?: any;

	/**
	 * Maps SQL parameters to other values.
	 */
	params?: Record<string, any>;
}

export function reportingGraphRead(context: Context, graph: ReportingGraph, query: SelectQueryBuilder<ReportingGraph>) {
	if (query) {
		const alias           = query.expressionMap.mainAlias.name;
		const rolePermissions = context.role.additionalPermissions;

		// SHOULDDO: Allow multiple required permissions. Currently no graph uses more than one, so this logic works for now
		query.andWhere(`
			(${alias}.requiredPermissions = ''
			OR (${alias}.requiredPermissions != '' AND FIND_IN_SET(${alias}.requiredPermissions, '${rolePermissions}')))
		`);
	}
	else {
		// if graph requires role to have additional add-on permissions, check if role has all of them
		if (graph.requiredPermissions?.some(permission => !context.role.hasPermission(permission))) { // eslint-disable-line no-lonely-if
			return 'you do not have permission to view this graph';
		}
	}
}
