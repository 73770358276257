/**
 * Handles permissions for entities.
 */
import typeorm, { SelectQueryBuilder } from 'typeorm';

import { Platform }                   from '$/lib/env';
import { getParentClass, isSubclass } from '$/lib/utils';

import type { BaseEntity }     from '$/entities/BaseEntity';
import type { BaseRole }       from '$/entities/roles/BaseRole';
import type { RolePermission } from '$/entities/roles/RolePermission';
import type { PackageFeature } from '$/entities/Package';
import type { User }           from '$/entities/User';
import type { Organization }   from '$/entities/Organization';

export interface ClassPermissions {
	read?:   PossibleArray<ClassPermsGuardRead>;
	create?: PossibleArray<ClassPermsGuardWrite>;
	update?: PossibleArray<ClassPermsGuardWrite>;
	delete?: PossibleArray<ClassPermsGuardWrite>;
}

export interface FieldPermissions {
	read?:  PossibleArray<PermsGuard>;
	write?: PossibleArray<PermsGuard>;
}

export class Context {

	/**
	 * The user making the request to read or write to the field.
	 */
	user: User;

	/**
	 * The role of the user making the request to read or write to the field.
	 */
	role: BaseRole;

	/**
	 * The organization that the role might belong to (or null if none)
	 */
	org: Organization;

	/**
	 * Whether the request to write to the field originated on the server or client.
	 */
	platform: Platform;

	constructor({ role, platform }: { role: BaseRole; platform: Platform });
	constructor({ role, user, org, platform }: { role: BaseRole; user: User; org: Organization; platform: Platform });
	constructor({ role, user = role.user, org = role.org, platform }: { role?: BaseRole; user?: User; org?: Organization; platform?: Platform } = {}) {
		this.user     = user;
		this.role     = role;
		this.org      = org;
		this.platform = platform;
	}

	/**
	 * Signals that no more permission checks should be performed for this context.
	 */
	stopChecks() {
		this._stopChecks = true;
	}

	resetStopChecks() {
		this._stopChecks = false;
	}

	get areChecksStopped() {
		return this._stopChecks;
	}

	private _stopChecks: boolean = false;

}

type PermsGuard  = (context: Context, entity?: BaseEntity) => PossiblePromise<string>;

/**
 * Signature of Entity permission guard functions.
 * Guard functions can modify the entity or query.
 * Guard functions must return undefined or an empty string if validation passes or a truthy string error message on permission error.
 */
type ClassPermsGuardRead  = (context: Context,  entity?: BaseEntity, query?: SelectQueryBuilder<BaseEntity>) => PossiblePromise<string>;
type ClassPermsGuardWrite = PermsGuard;

/**
 * Signature of property permission guard functions.
 * Guard functions can modify the entity.
 * Guard functions must return undefined or an empty string if validation passes or a truthy string error message on permission error.
 */
// export type FieldPermsGuard = PermsGuard;

/**
 * Decorator function that sets up the permissions for a given Entity or a field of an Entity.
 */
export default function Permissions(perms: ClassPermissions | FieldPermissions) {
	return function(target, propertyKey?) {
		if (propertyKey) {
			// also copy permissions onto any aliases property
			_.compact([ propertyKey, Reflect.getOwnMetadata('propertyAlias', target.constructor, propertyKey) ]).forEach(property => {
				Reflect.defineMetadata('property-permissions', perms, target.constructor, property);
			});
		}
		else {
			Reflect.defineMetadata('class-permissions', perms, target);
		}
	};
}

/**
 * Tests and adjusts the given select query given the context.
 */
Permissions.getEntityReadingError = function(context: Context, queryOrEntity: BaseEntity | SelectQueryBuilder<BaseEntity>, options?: TestClassGuardOptions): Promise<string> {
	let entity: BaseEntity;
	let query: SelectQueryBuilder<BaseEntity>;
	let entityClass: Class;

	if (queryOrEntity instanceof typeorm.BaseEntity) {
		entity      = queryOrEntity;
		entityClass = entity.constructor as Class;
	}
	else {
		query       = queryOrEntity;
		entityClass = queryOrEntity.expressionMap.mainAlias.target as Class;
	}

	return testClassGuards('read', context, entityClass, entity, query, options);
};

/**
 * Tests whether the given entity can be created or updated given the context.
 */
Permissions.getEntityWritingError = function(context: Context, entity: BaseEntity, options?: TestClassGuardOptions): Promise<string> {
	return testClassGuards(entity.isNew ? 'create' : 'update', context, entity.constructor as Class, entity, undefined, options);
};

/**
 * Tests whether the given entity can be deleted given the context.
 */
Permissions.getEntityDeleteError = function(context: Context, entity: BaseEntity, options?: TestClassGuardOptions): Promise<string> {
	return testClassGuards('delete', context, entity.constructor as Class, entity, undefined, options);
};

/**
 * Helper function to help call entity class permission guard functions.
 */
async function testClassGuards(operation: Operation, context: Context, entityClass: Class, entity: BaseEntity, query?: SelectQueryBuilder<BaseEntity>, options: TestClassGuardOptions = {}): Promise<string> {
	let clazz        = entityClass;
	const prevGuards = [];

	while (clazz && isSubclass(typeorm.BaseEntity, clazz)) {
		const perms: ClassPermissions = Reflect.getMetadata('class-permissions', clazz);
		const guards                  = _.without(_.castArray(_.get(perms, operation, [])), ...prevGuards);

		// keep track of guards already checked so that they don't get checked again (happens because guards are inherited to subclasses)
		prevGuards.push(...guards);

		for (const guard of guards) {
			let error;
			try {
				if (operation === 'read') {
					error = await (guard as ClassPermsGuardRead)(context, entity, query);
				}
				else {
					error = await (guard as ClassPermsGuardWrite)(context, entity);
				}
			}
			catch (err) {
				error = err.message || String(err);
			}

			if (error) { // truthy result means error
				if (options.throwError) {
					throw new options.throwError(error);
				}
				return error;
			}

			if (context.areChecksStopped) {
				clazz = null;
				break;
			}
		}

		clazz = getParentClass(clazz);		// check parent classes for any more Permission guards
	}

	return '';
}

/**
 * @returns the set of all properties of the given entity that allow the given permission type
 *          if this is [], then no properties of this entity can have the given operation applied
 */
Permissions.getAllowedProperties = async function(operation: 'read'|'write', context: Context, entity: BaseEntity): Promise<string[]> {
	const guardCache: Map<Function, any> = new Map();

	// run any class entity permission guards which have to also be satisfied in addition to field guards
	let classError: string;
	try {
		classError = await (operation === 'write' ? Permissions.getEntityWritingError : Permissions.getEntityReadingError)(context, entity);
	}
	catch (error) {
		classError = error;
	}

	// filter down to set of props that are allowed to be modified from the client
	const propsDesc       = entity.entityClass.getPropertyDescriptors();
	const props: string[] = [];
	for (const propertyDesc of propsDesc) {
		if (context.platform === Platform.Client && !propertyDesc.isCommon) {
			continue;
		}

		const perms: FieldPermissions = Reflect.getMetadata('property-permissions', entity.entityClass, propertyDesc.property);

		let error            = '';
		let areChecksStopped = false;
		context.resetStopChecks();

		if (perms) {
			for (const guard of _.castArray(perms[operation] ?? [])) {
				if (guardCache.has(guard)) {
					({ error, areChecksStopped } = guardCache.get(guard));
				}
				else {
					error            = await guard(context, entity);
					areChecksStopped = context.areChecksStopped;
					guardCache.set(guard, { error, areChecksStopped });
				}

				if (error || areChecksStopped) {
					break;
				}
			}
		}

		if (!error && !areChecksStopped) {
			error = classError;
		}

		if (!error) {
			props.push(propertyDesc.property);
			if (propertyDesc.isRelationship) {
				props.push(`${propertyDesc.property}Id`);
			}
		}
	}

	return props;
};


// Guard Functions

/**
 * Class and Field guard function that indicates the field or entity may only be written on the server-side (never on the client).
 */
Permissions.serverOnly = function(context: Context) {
	return context.platform === Platform.Server ? '' : 'cannot perform this operation from the client';
} as PermsGuard;

/**
 * Returns a class & field guard function that enforces the given rolePermission.
 */
Permissions.roleHasPermission = function(rolePermission: RolePermission) {
	return function(context: Context): string {
		return context.role?.hasPermission(rolePermission) ? '' : `role does not have sufficient permissions, requires: ${rolePermission}`;
	} as PermsGuard;
};

/**
 * Returns a class & field guard function that enforces any one of the given guards.
 */
function AnyOf(...guards: ClassPermsGuardWrite[]): ClassPermsGuardWrite;	// eslint-disable-line @typescript-eslint/naming-convention
function AnyOf(...guards: PermsGuard[]): PermsGuard;				// eslint-disable-line @typescript-eslint/naming-convention
function AnyOf(...guards) {													// eslint-disable-line @typescript-eslint/naming-convention
	return async function(...args) {
		let result;
		for (const guard of guards) {
			try {
				result = await guard.call(null, ...args);
			}
			catch (error) {
				result = error;
			}

			if (!result) {
				return;
			}
		}
		return result;
	};
}
Permissions.anyOf = AnyOf;

/**
 * Returns a permission function that enforces the given package feature.
 */
Permissions.roleHasFeature = function(feature: PackageFeature) {
	return async function(context: Context): Promise<string> {
		// ensure org.package field is loaded
		await context.role.org.loadRelation('package');
		return context.role.hasFeature(feature) ? '' : `role does not currently have access to feature: ${feature}`;
	};
};

/**
 * Returns a permission function that ensures that role is verified
 */
Permissions.roleIsVerified = function(context: Context): string {
	return context.role.isVerified ? '' : 'you must verify your identity';
};

/**
 * Stops any further permission checks in the given context.
 * Useful for overriding a class-level guard at the field level.
 */
Permissions.stopChecks = function(context: Context): Promise<string> {
	context.stopChecks();
	return Promise.resolve('');
};

type Operation = 'create' | 'read' | 'update' | 'delete';

interface TestClassGuardOptions {

	/**
	 * If specified, will throw an new instance of this class instead of returning errors.
	 */
	throwError?: any; // typeof Error;
}
