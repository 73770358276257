import { Country } from '$/lib/Address';

const FL     = 'https://frontlobby.com';
const FLHelp = 'https://help.frontlobby.com';

export default {
	frontlobby : {
		homeDepot : 'https://frontlobby.com/homedepot',
	},
	help : {
		center          : `${FLHelp}/`,
		reportingStatus : `${FLHelp}/what-do-the-rent-reporting-status-mean-and-how-can-i-fix-them`,
		reportingTiming : `${FLHelp}/when-is-information-sent-to-credit-bureaus`,
		whenInfoSent    : `${FLHelp}/when-is-information-sent-to-credit-bureaus`,
		recoveringDebts : `${FLHelp}/how-does-frontlobbys-collect-help-me-recover-debts-from-former-tenants`,
		totalBalance    : `${FLHelp}/what-can-be-recorded-under-total-balance`,
		disputes        : `${FLHelp}/how-do-i-dispute-inaccurate-information-reported-to-credit-bureaus-through-frontlobby`,
		introEmail      : `${FLHelp}/i-received-an-introduction-email-from-my-landlord-saying-they-are-using-frontlobby.-what-should-i-do`,
		bookMeeting     : `${FLHelp}/meetings/ginap/frontlobby-support`,
	},
	legal : {
		termsOfUse : {
			[Country.CA] : `${FL}/terms-of-use-canada`,
			[Country.US] : `${FL}/terms-of-use`,
		},
		termsOfService : {
			[Country.CA] : `${FL}/terms-of-service-canada`,
			[Country.US] : `${FL}/terms-of-service`,
		},
		legalFramework : {
			[Country.CA] : `${FL}/legal-framework-canada`,
			[Country.US] : `${FL}/legal-framework`,
		},
		privacyPolicy : {
			[Country.CA] : `${FL}/privacy-policy-canada`,
			[Country.US] : `${FL}/privacy-policy`,
		},
		securityPolicy : {
			[Country.CA] : `${FL}/security-canada`,
			[Country.US] : `${FL}/security`,
		},
		clauses : {
			[Country.CA] : `${FL}/application-and-lease-clauses-canada/`,
			[Country.US] : `${FL}/application-and-lease-clauses/`,
		},
	},
	lcb : {
		viewTenantRecord : 'https://landlordcreditbureau.com/view-your-tenant-record/',
	},
	partners : {
		renterCreditReports : {
			[Country.CA] : 'https://www.consumer.equifax.ca/personal/products/credit-score-report/',
			[Country.US] : 'https://www.equifax.com/personal/credit-report-services/free-credit-reports/',
		},
		apollo : {
			renter   : 'https://info.apollocover.com/frontlobby',
			landlord : 'https://info.apollocover.com/frontlobby-home',
		},
		canadianTenantInspection : 'http://www.ctiservices.ca/',
		fourPillars              : 'https://www.4pillars.ca/getstarted',
		habitatForHumanity       : {
			[Country.CA] : 'https://habitat.ca/en',
			[Country.US] : 'https://www.habitat.org/',
		},
		homeDepot : {
			[Country.CA] : 'https://www.homedepot.ca/en/home/pro/proxtra.html',
			[Country.US] : 'https://www.homedepot.com/c/Pro_Xtra',
		},
		iWin : {
			book    : 'https://www.infinitywealth.ca/book-fl',
			consult : 'https://www.infinitywealth.ca/FrontLobby',
		},
		lawDepot : {
			[Country.CA] : 'https://www.lawdepot.ca/contracts/groups/lease/?pid=pg-TRJ15DK3R9-RealEstate_125x125Light.jpg#.Y7cuxXbMKUl',
			[Country.US] : 'https://www.lawdepot.com/contracts/groups/lease/?pid=pg-TRJ15DK3R9-RealEstate_120x240Light.jpg#.Y7cu6nbMKUl',
		},
		legalLine                       : 'https://www.legalline.ca/',
		neldaSchulte                    : 'https://www.neldaschulte.com',
		ontarioLandlordWatch            : 'https://ontariolandlordswatch.com/',
		realEstateInvestmentNetwork     : 'https://reincanada.com/',
		rentFaster                      : 'https://www.rentfaster.ca/',
		sfgLegal                        : 'https://sfg.legal/EN/',
		smallMatters                    : 'http://www.smallmatterspa.ca/',
		saskatchewanLandlordAssociation : 'https://saskatchewanlandlordassociation.ca/',
		smallOwnershipLandlordOfOntario : 'https://soloontario.ca/register-to-join-solo-landlords/',
		xyzStorage                      : 'https://www.xyzstorage.com/',
		listItCalgary                   : 'https://www.listitcalgary.ca/contact.php',
	},
	collections : {
		secci        : 'https://www.secci.ca',
		secciPayment : 'https://www.secci.ca/payment-page',
	},
	articles : {
		tradelineStudy            : 'https://www.globenewswire.com/news-release/2022/09/13/2514754/0/en/Equifax-Canada-and-FrontLobby-Complete-First-Rental-Tradeline-Study.html',
		canadaFinancialNewsletter : 'https://www.canada.ca/en/financial-consumer-agency/news/newsletters/november-2022.html',
	},
	pricing : {
		[Country.CA] : `${FL}/landlord-pricing-canada/`,
		[Country.US] : `${FL}/en/landlord-pricing/`,
	},
};
