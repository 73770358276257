import { BaseOrgEntity }             from '$/entities/Organization';
import Permissions                   from '$/entities/lib/Permissions';
import { ChargeableProp }            from '$/entities/Package';
import { ManyToOne, Entity, Column } from '$/entities/BaseEntity';
import { BaseCharge }                from '$/entities/billing/BaseCharge';
import type { Coupon }               from '$/entities/billing/Coupon';

@Entity({ common : true })
@Permissions({
	create : Permissions.serverOnly,
	update : Permissions.serverOnly,
	delete : Permissions.serverOnly,
})
export class Discount extends BaseOrgEntity {

	@ManyToOne('Coupon', { onDelete : 'CASCADE' })
	@Permissions({ write : Permissions.serverOnly })
	coupon: Coupon;

	@Column({ type : 'varchar', length : 255, nullable : false, default : '' })
	invoiceItemId: string = '';

	async getRedemptionError(chargeable: ChargeableProp) {
		await this.loadRelation('coupon', { required : true });
		if (!this.coupon.chargeables.includes(chargeable)) {
			return 'Redemption code does not apply to this item';
		}

		if (this.coupon.limitPerOrg && this.coupon.limitPerOrg <= await BaseCharge.count({ where : { discount : this } })) {
			return 'Redemption code limit reached';
		}

		return '';
	}

	async canRedeem(chargeable: ChargeableProp) {
		return !(await this.getRedemptionError(chargeable));
	}

}
