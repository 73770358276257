


























import { Vue, Component, Prop } from '$/lib/vueExt';
import Validate                 from '$/lib/Validate';

import { Agreement as AgreementJSONable } from '$/entities/Agreement';
import { User }                           from '$/entities/User';

enum AgreementType {
	CheckBox = 'checkbox',
	Modal    = 'modal',
}

@Component({
	model : {
		prop  : 'agreement',
		event : 'changed',
	},
	inheritAttrs : false,
})
export default class Agreement extends Vue {

	@Prop()
	readonly agreement: AgreementJSONable;

	@Prop({ default : AgreementType.Modal })
	readonly type: AgreementType;

	@Prop({ default : false })
	readonly agreeOnly: boolean;

	@Prop({ default : false })
	readonly required: boolean;

	hasChanged = false;

	@Validate({ custom : function(value) {
		return !this.required || value ? '' : 'You must agree to the above terms';
	} })
	get agreed() {
		return !!this.agreement?.agreed;
	}
	set agreed(value: boolean) {
		this.hasChanged = true;
		this.agreement.update(this.$role, User.current, value);
		this.$emit('changed', this.agreement);
	}

}
