/**
 * The intent of this library is to allow registration of Promises that must be fulfilled before "main activity" can start.
 * On the server, this means before API requests are server.
 * On the client, this means before the user is presented with the UI.
 */
const promises: Set<Promise<any>> = new Set();

/**
 * Registers a new promise to be resolved before main activity is started.
 * @returns a Promise for when the register function resolves.
 */
export function register(promise: Promise<any> | (() => Promise<any>)) {
	if (typeof promise === 'function') {
		promise = Promise.resolve(promise());
	}
	promises.add(promise);
	return promise;
}

/**
 * Waits for all registered promises to fulfill.
 */
export function waitForAll(): Promise<any> {
	return Promise.all(Array.from(promises.values()));
}

export default {
	register,
	waitForAll,
};
