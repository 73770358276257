















































import { Vue, Component, Ref, Prop, Watch, Inject } from '$/lib/vueExt';
import type { HasValidMethod, SetFeedback }         from '$/lib/mixins/VueValidationMixin';
import { File as FileEntity }                       from '$/entities/File';

@Component({
	inheritAttrs : false,
	model        : {
		prop  : 'file',
		event : 'update:file',
	},
})
export default class FormDropzone extends Vue {

	@Prop()
	readonly file: File | FileEntity;		// accepts both native DOM File objects and FL's File Entity objects

	@Prop({ default : '*' })
	readonly accept: string;

	@Prop({ default : false })
	readonly disabled: boolean;

	@Prop({ default : false })
	readonly required: boolean;

	@Ref()
	readonly input: HTMLInputElement;

	// reports validation feedback up to a possible ancestor
	@Inject({ default : null, from : 'setFeedback' })
	readonly setFeedbackCallback: SetFeedback;

	// registers this form input with a possible ancestor
	@Inject({ default : null })
	readonly registerInput: (formInput: HasValidMethod) => void;

	@Inject({ default : null })
	readonly unregisterInput: (formInput: HasValidMethod) => void;

	// Can't just toggle the dragging class on dragenter/dragleave
	// The drag styling would get removed when dragging over a child element in the dropzone
	// See: https://stackoverflow.com/a/21002544
	dragCounter = 0;

	localError = '';

	get localNativeFile(): File {
		return this.file instanceof FileEntity ? this.file.nativeFile : this.file as File;
	}

	get errorMessage() {
		return this.file instanceof FileEntity && this.file.errorMessage || this.localError;
	}

	get fileIcon() {
		const mimeType = this.file instanceof FileEntity && this.file.mimeType ||  this.localNativeFile?.type || '';
		if (!mimeType) {
			return 'cloud-upload';
		}
		if (mimeType.startsWith('image/')) {
			return 'file-earmark-image';
		}
		if (mimeType.startsWith('application/pdf')) {
			return 'file-earmark-pdf';
		}

		return 'file-earmark';
	}

	get isDragging() {
		return !this.disabled && this.dragCounter > 0;
	}

	get acceptRegex() {
		// Turn the accept string into an array of regex expressions
		// The wildcard * is replaced by regex that matches 1 or more alphanumeric characters
		return this.accept.split(',').map(type => RegExp(`^${type.trim().replace('*', '.+')}$`));
	}

	get filename() {
		return this.$format.middleEllipsis(this.localNativeFile?.name);
	}

	get fileSize() {
		if (this.file instanceof FileEntity) {
			return this.file.nativeFile?.size ?? this.file.size.valueOf();
		}
		return this.file.size;
	}

	get dropText() {
		return this.localNativeFile ? `Replace ${this.filename}` : 'Drop your file here';
	}

	mounted() {
		// register with a possible ancestor form
		this.registerInput?.(this);
	}

	beforeDestroy() {
		this.unregisterInput?.(this);
	}

	drop(event: DragEvent) {
		this.dragCounter--;

		if (this.disabled) {
			return;
		}

		const files = event.dataTransfer.files;
		if (this.areFilesValid(files)) {
			this.input.files = files;
			this.input.dispatchEvent(new Event('change'));
		}
	}

	async isValid() {
		return this.areFilesValid(this.input.files);
	}

	areFilesValid(files: FileList) {
		if ((!files || files.length === 0) && this.fileSize === 0) {
			this.localError = this.required ? 'This field is required.' : '';
		}
		else if (files?.length > 1) {
			// COULDDO: support for multiple files
			this.localError = 'Only select a single file.';
		}
		else if (files?.[0] && !this.acceptRegex.some(rx => rx.test(files[0].type))) {
			this.clearFile();
			this.localError = 'This file type is not acceptable.';
		}
		else {
			this.localError = '';
		}

		this.setFeedbackCallback?.(this.localError);
		return !this.localError;
	}

	@Watch('file')
	onFilePropChange() {
		if (!this.localNativeFile) {
			// When the file prop is set to a falsy value, clear the file input.
			// This prevents an issue when the file is cleared outside of the component, it doesn't actually clear the file.
			this.clearFile();
		}

		if (this.file instanceof FileEntity && !this.file.nativeFile) {
			this.localError = this.file.errorMessage;
			this.setFeedbackCallback?.(this.file.errorMessage);
		}
	}

	@Watch('file.nativeFile')
	test() {
		if (this.file instanceof FileEntity && this.file.nativeFile) {
			this.file.errorMessage = this.localError;
		}
	}

	clearFile() {
		this.input.value = '';
		this.input.dispatchEvent(new Event('change'));
	}

	onFilesInputChange() {
		const newFiles = this.input.files;
		if (newFiles && newFiles.length && !this.areFilesValid(newFiles)) {
			return;
		}

		if (!this.file || this.file instanceof File) {
			this.$emit('update:file', newFiles[0] ?? null);
		}
		else if (this.file instanceof FileEntity) {
			this.file.nativeFile = newFiles[0] ?? null;
			this.$emit('file-changed', this.file.nativeFile);
		}
	}

	openFileBrowser() {
		this.input.click();
	}

}

