import Validate               from '$/lib/Validate';
import { Country }            from '$/lib/Address';
import { Index }              from '$/lib/typeormExt';
import NationalIDHelper       from '$/lib/NationalIDHelper';
import { BooleanTransformer } from '$/lib/columnTransformers';
import Permissions            from '$/entities/lib/Permissions';

import { BaseEntity, Column, CommonEntity, OneToOne, JoinColumn, CollectionName } from '$/entities/BaseEntity';
import type { User }        from '$/entities/User';
import type { Tenant }      from '$/entities/Tenant';
import type { Application } from '$/entities/tenantScreening/Application';

/**
 * National Identification Number, such as SIN (Canada), SSN (US)
 */
@CommonEntity()
@CollectionName('nationalID')
export class NationalID extends BaseEntity {

	/**
	 * The actual national ID value.
	 * This is stored encrypted as a base64 string so initially will show encrypted.
	 */
	@Column({ type : 'varchar', length : '1000' })
	@Validate({
		required : true,
		custom   : function(value) {
			if (value === undefined || !this.country) {
				return;
			}
			return NationalIDHelper(this.country).isValid(value) ? '' : 'invalid format';
		},
	})
	value: string = '';

	get isValueEncrypted() {
		// COULDDO: a more reliable mechanism by which to tell if this.blob is encrypted or not
		return !!this.value && this.value.length > 20;
	}

	/**
	 * If this nationalID belongs to a user, this references that user.
	 */
	@OneToOne('User', { nullable : true })
	@JoinColumn()
	@Index({ unique : true })
	@Validate({
		custom : function() {
			if (this.user && this.tenant) {
				return 'a nationalID record can only be associated with a user or tenant, not both';
			}
			if (!this.user && !this.tenant) {
				return 'a nationalID record must be associated with either a user or tenant';
			}
		},
	})
	user: User = undefined;

	/**
	 * If this nationalID belongs to a tenant, this references that tenant.
	 */
	@OneToOne('Tenant', { nullable : true, onDelete : 'CASCADE' })
	@JoinColumn()
	@Index({ unique : true })
	tenant: Tenant = undefined;

	@OneToOne('Application', { nullable : true, onDelete : 'CASCADE' })
	@JoinColumn()
	@Index({ unique : true })
	application: Application = undefined;

	@Column({ type : 'varchar', length : 2 })
	@Validate({ required : true, enum : Country })
	country: Country = '' as Country;

	/**
	 * true when the value was sent by the Renter and thus decrypt should not be available to a LL
	 */
	@Column({ type : 'boolean', transformer : BooleanTransformer })
	@Permissions({ write : Permissions.serverOnly })
	setByRenter: boolean = false;

	/**
	 * @returns the decrypted nationalID value
	 */
	decrypt(): Promise<string> {
		throw new Error('not implemented');
	}

	static getValue(target: Tenant | User | Application) {
		if (!target.nationalID) {
			return '';
		}
		return target.nationalID.isValueEncrypted ? NationalIDHelper(Country.CA).encryptedDisplay() : target.nationalID.value;
	}

	/**
	 * Handy utility method for setting the nationalID value on a target entity.
	 * This creates a new NationalID entity (if needed) and sets it's value and country
	 */
	static async setValue(target: Tenant | User | Application, newValue: string, country: Country) {
		await target.loadRelation('nationalID');
		if (target.nationalID) {
			target.nationalID.makeEditable();
		}
		else {
			target.nationalID = new this() as any;
		}

		const nationalID   = target.nationalID;
		nationalID.value   = NationalIDHelper(country).normalize(newValue);
		nationalID.country = country;

		if (target.instanceof('User')) {
			nationalID.user = target as unknown as User;
		}
		else if (target.instanceof('Tenant')) {
			nationalID.tenant = target as unknown as Tenant;
		}
		else if (target.instanceof('Application')) {
			nationalID.application = target as unknown as Application;
		}
		else {
			throw new Error(`target type not supported: ${(target as any).$class}`);
		}
	}

}
