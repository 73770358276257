























import { Vue, Component, Prop, Watch } from '$/lib/vueExt';

export type ProgressCallback = (progress: number) => void;

@Component
export default class ProgressModal extends Vue {

	/**
	 * The progress of the progress bar.  Must be between 0.0 and 1.0, or negative numbers to hide the progress bar.
	 */
	@Prop({ default : -1 })
	readonly progress: number;

	/**
	 * A message to show (can also use the #message slot)
	 */
	@Prop({ default : '' })
	readonly message: string;

	/**
	 * If true, adds a time estimate to the progress bar.
	 */
	@Prop({ default : false })
	readonly timeEstimate: boolean;

	/**
	 * If false, tries to prevent the user from closing the tab while the progress modal is visible.
	 * Note that the user can still choose to close the tab, but they will be prompted with a warning.
	 */
	 @Prop({ default : false })
	readonly allowUnload: boolean;

	startTime: Date = null;

	get normalizeProgress() {
		return Math.min(1, this.progress);
	}

	get secondsUntilCompletion() {
		if (!this.startTime || this.normalizeProgress === 0) {
			return undefined;
		}
		const elapsedSeconds = (Date.now() - this.startTime.valueOf()) / 1000;
		return Math.round(elapsedSeconds / this.normalizeProgress - elapsedSeconds);
	}

	@Watch('progress')
	onProgressChange() {
		if (this.progress >= 0 && this.startTime === null) {
			this.startTime = new Date();
			if (!this.allowUnload) {
				window.addEventListener('beforeunload', handleBeforeUnload);
			}
		}
		else if ((this.progress < 0 || _.isNil(this.progress)) && this.startTime !== null) {
			this.startTime = null;
			window.removeEventListener('beforeunload', handleBeforeUnload);
		}
	}

}

/**
 * Shows the progress modal (without needed to instantiate an explicit ProgressModal component) and uses the provided callback to update the progress.
 */
export async function showProgress(callback: (progressCallback: ProgressCallback) => Promise<any>, options?: ProgressModalOptions): Promise<string> {
	const propsData = {
		progress     : -1,
		message      : '',
		timeEstimate : true,
		...options,
	};

	const progressModal = new ProgressModal({ propsData }).$mount(document.createElement('div'));

	try {
		await callback(progress => {
			progressModal.$props.progress = progress;
		});

		return '';	// no error
	}
	catch (error) {
		return error.message || error;
	}
	finally {
		progressModal.$props.progress = -1;
	}
}

export interface ProgressModalOptions {
	message?: string;
	timeEstimate?: boolean;
	allowUnload?: boolean;
}

function handleBeforeUnload(event: BeforeUnloadEvent) {
	event.preventDefault();
	event.returnValue = '';
}

