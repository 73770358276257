
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","width":"14","height":"14","viewBox":"0 0 14 14"}, attrs),
              ...rest,
            },
            children.concat([_c('g',{attrs:{"id":"Group_3041","data-name":"Group 3041","transform":"translate(-37 -484)"}},[_c('g',{attrs:{"id":"Path_7611","data-name":"Path 7611","transform":"translate(37 484)","fill":"#fff"}},[_c('path',{attrs:{"d":"M 7 13.5 C 3.415889978408813 13.5 0.5 10.58411026000977 0.5 7 C 0.5 3.415889978408813 3.415889978408813 0.5 7 0.5 C 10.58411026000977 0.5 13.5 3.415889978408813 13.5 7 C 13.5 10.58411026000977 10.58411026000977 13.5 7 13.5 Z","stroke":"none"}}),_c('path',{attrs:{"d":"M 7 1 C 3.691590309143066 1 1 3.691590309143066 1 7 C 1 10.30840969085693 3.691590309143066 13 7 13 C 10.30840969085693 13 13 10.30840969085693 13 7 C 13 3.691590309143066 10.30840969085693 1 7 1 M 7 0 C 10.86598968505859 0 14 3.134010314941406 14 7 C 14 10.86598968505859 10.86598968505859 14 7 14 C 3.134010314941406 14 0 10.86598968505859 0 7 C 0 3.134010314941406 3.134010314941406 0 7 0 Z","stroke":"none","fill":"#152951"}})]),_c('path',{attrs:{"id":"Path_7612","data-name":"Path 7612","d":"M586.814,257.7l1.614,1.774,3.961-4.231","transform":"translate(-545.602 233.641)","fill":"none","stroke":"#152951","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"1.5"}})])])
          )
        }
      }
    