
/**
 * Overwrite BIcon to add support for our custom in-house icons
 *
 * NOTE: This is a copy of the original BIcon component with a few modifications
 * MUST use BootstrapVue's pascalCase to convert icon name to PascalCase
 * as lodash converts some cases incorrectly, example: 'badge3d' => 'Badge3D'
 * while BootstrapVue's icon is registered as 'BIconBadge3d'
 */
import { BIcon, BIconBlank } from 'bootstrap-vue';
import { pascalCase }        from 'bootstrap-vue/esm/utils/string';
import { mergeData }         from 'vue-functional-data-merge';
import { Component }         from '$/lib/vueExt';

function findIconComponent(ctx, iconName) {
	if (!ctx) {
		return null;
	}
	return ctx.$options?.components?.[iconName] || findIconComponent(ctx.$parent, iconName);
}

const IconPrefix  = /^(f-icon-|FIcon)/;
const BIconPrefix = /^BIcon/;
const name        = 'FIcon';

@Component({ name })
export default class Icon extends BIcon {

	render(h, { data, props, parent }) {
		const isLCBIcon = IconPrefix.test(props.icon);
		const icon      = pascalCase(props.icon || '').trim().replace(IconPrefix, '').replace(BIconPrefix, '');

		return h(
			icon // eslint-disable-line no-nested-ternary
				? isLCBIcon
					? findIconComponent(parent, `FIcon${icon}`)
					: findIconComponent(parent, `BIcon${icon}`) || BIconBlank
				: BIconBlank,
			mergeData(data, { props })
		);
	}

}
