


















import Vue, { VNode }              from 'vue';
import { BModal, BvMsgBoxOptions } from 'bootstrap-vue';
import { Component, Ref }          from 'vue-property-decorator';

@Component
export default class ConfirmationModal extends Vue {

	@Ref()
	modal: BModal;

	private resolveFunc: (value: boolean) => void;

	/**
	 * Shows the confirmation modal and returns a Promise that resolves with `true` if the ok button was clicked,
	 * or `false` if the cancel button was clicked (or the modal was closed in any other way)
	 */
	showConfirm(): Promise<boolean> {
		this.resolveFunc?.(false);
		return new Promise(resolve => {
			this.modal.show();
			this.resolveFunc = resolve;
		});
	}

	onOk() {
		this.resolveFunc?.(true);
		this.resolveFunc = null;
	}

	onCancel() {
		this.resolveFunc?.(false);
		this.resolveFunc = null;
	}

	/**
	 * Displays a confirmation box
	 */
	static async showConfirm(vue: Vue, message: string | VNode[], options?: BvMsgBoxOptions | { html?: boolean; okOnly?: boolean }): Promise<boolean> {
		// check if the input param is a confirmation-modal component
		if ((message as any).$vnode?.componentOptions.Ctor.name === 'ConfirmationModal') {
			return (message as unknown as ConfirmationModal).showConfirm();
		}

		// Convert to a node to allow HTML tags in the message string
		message = typeof message === 'string' && options?.html ? [ vue.$createElement('div', { domProps : { innerHTML : message } }) ] : message;

		return !!(await vue.$bvModal[options?.okOnly ? 'msgBoxOk' : 'msgBoxConfirm'](message, {
			hideHeaderClose : true,
			centered        : true,
			okVariant       : 'warning',
			cancelVariant   : 'outline-default',
			footerClass     : 'justify-content-between',
			contentClass    : 'confirmation-modal',
			..._.omit(options, [ 'html', 'okOnly' ]),
		}));
	}

}
