import { ChildEntity, CommonEntity }                  from '$/entities/BaseEntity';
import { CreditBureau, ReportingGroup, TenantMetro2 } from '$/entities/reporting/TenantMetro2';

export const tradelineProgramID  = '1101320';
export const collectionProgramID = '1500318';

/**
 * Tenant reporting record specific to Equifax Canada
 */
@ChildEntity() @CommonEntity()
export class EquifaxCATenantMetro2 extends TenantMetro2 {

	static getCreditBureau(): CreditBureau {
		return CreditBureau.EquifaxCA;
	}

	static getReportingGroup(programID: string) {
		switch (programID) {
			case tradelineProgramID:  return ReportingGroup.Tradeline;
			case collectionProgramID: return ReportingGroup.Collection;
		}
	}

}
