






import { Vue, Component, Prop } from '$/lib/vueExt';
import env                      from '$/lib/env';
import { HubSpotTicket }        from '$/lib/hubspot';

/**
 * Renders a link to contact Support.
 */
@Component
export default class ContactSupport extends Vue {

	/**
	 * Optional subject of the message to support.
	 */
	@Prop({ default : '' })
	readonly subject: string;

	@Prop({ default : 'form' })
	readonly type: 'form' | 'email';

	@Prop({ default : '' })
	readonly linkText: string;

	@Prop({ default : '' })
	readonly category: string;

	openTicketForm() {
		ContactSupport.start(this.subject, this.category, { type : this.type });
	}

	get fullEmailAddress() {
		return env.getContactEmail();
	}

	/**
	 * Starts a new conversation with Support (with an optional default subject and body provided)
	 * Intended to also be called from outside code.
	 */
	static start(subject = 'Help Please', category = '', { body = '', type = 'form' }: { body?: string; type?: 'form' | 'email' }  = {}) {
		switch (type) {
			case 'email':
				window.open(`mailto:${env.getContactEmail()}?subject=${subject}&body=${body}`);
				return;
			case 'form':
				return HubSpotTicket.openTicketForm(subject, category);
			default:
				throw new Error(`invalid type: ${type}`);
		}
	}

}
