import Moment    from 'moment';
import Validate  from '$/lib/Validate';
import { Field } from '$/entities/lib/JSONable';
import BasePreferences, { type Preference, RenterEmailKeys } from '$/entities/lib/BasePreferences';

// SHOULD DO: expose all email types as classes on the common side and use their constructor.name as keys
export class EmailPreferences extends BasePreferences {

	@Field()
	LandlordNoLogin?: Preference; // eslint-disable-line @typescript-eslint/naming-convention

	@Field()
	LandlordMonthlyEmail?: Preference; // eslint-disable-line @typescript-eslint/naming-convention

	@Field()
	[RenterEmailKeys.ConsentReminderEmail]?: Preference; // eslint-disable-line @typescript-eslint/naming-convention

	@Field()
	[RenterEmailKeys.MonthlyRenterConfirmationEmail]?: Preference; // eslint-disable-line @typescript-eslint/naming-convention

	@Field()
	DataContributorSecondEmail?: Preference; // eslint-disable-line @typescript-eslint/naming-convention

}

export class Interests extends BasePreferences {

	// #region landlord preferences
	@Field()
	tenantScreening?: Preference;

	@Field()
	rentReporting?: Preference;

	@Field()
	debtReporting?: Preference;

	@Field()
	invitedByTenant?: Preference;

	@Field()
	pullCredit?: Preference;

	@Field()
	buildCredit?: Preference;

	@Field()
	ensureRent?: Preference;

	@Field()
	recoverDebt?: Preference;

	@Field()
	recordKeeping?: Preference;
	// #endregion landlord preferences

	// #region renter preferences
	@Field()
	improvingMyCredit?: Preference;

	@Field()
	establishingRentalHistory?: Preference;

	@Field()
	viewLeaseRecord?: Preference;

	@Field()
	invitedByLandlord?: Preference;
	// #endregion renter preferences

	// #region common preferences
	@Field()
	learnMore?: Preference;
	// #endregion common preferences

	// #region renter misc fields
	@Field()
	@Validate({ date : true })
	insuranceReminderAt?: Date | null;

	static get insuranceReminderMin(): Date {
		return Moment().add(1, 'day').toDate();
	}

	static get insuranceReminderMax(): Date {
		return Moment().add(2, 'years').toDate();
	}
	// #endregion renter misc fields

}
