import Axios             from 'axios';
import { doWithRetries } from '$/lib/utils';

import { ChildEntity } from '$/entities/BaseEntity';

import { EquifaxCreditReport as EquifaxCreditReportCommon } from '$/common/entities/tenantScreening/EquifaxCreditReport';
export * from '$/common/entities/tenantScreening/EquifaxCreditReport';

@ChildEntity()
export class EquifaxCreditReport extends EquifaxCreditReportCommon {

	getReport() {
		return doWithRetries(async () => {
			const { status, data } = await Axios.get(EquifaxCreditReport.collectionUrl(`/${this.id}/report`));
			return status === 202 ? undefined : data;
		}, {
			retries          : 12,
			retryDelay       : 100,
			exponentialDelay : true,
			errorMessage     : 'report not available',
		});
	}

}
