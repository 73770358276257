import Axios        from 'axios';
import initializers from '$/lib/initializers';

import { Entity, createEntity }    from '$/entities/BaseEntity';
import { Discount }                from '$/entities/billing/Discount';
import { ChargeableProp, Package } from '$/entities/Package';

import { AvailableAutomaticCoupons, AutomaticDiscounts, Coupon as CouponCommon } from '$/common/entities/billing/Coupon';
export * from '$/common/entities/billing/Coupon';

let availableAutomaticCoupons: AvailableAutomaticCoupons[];

@Entity()
export class Coupon extends CouponCommon {

	static async getApplyError(promoCode: string, chargeable?: ChargeableProp): Promise<string> {
		return (await Axios.get(Coupon.collectionUrl(`apply/${promoCode}`), { params : { chargeable } })).data;
	}

	static async apply(promoCode: string, chargeable?: ChargeableProp): Promise<Discount> {
		return createEntity((await Axios.post(Coupon.collectionUrl(`apply/${promoCode}`), { params : { chargeable } })).data);
	}

	static async applyAutomaticCoupons(chargeables: ChargeableProp[], targetPackage?: Package): Promise<AutomaticDiscounts> {
		const automaticDiscounts: AutomaticDiscounts = (await Axios.post(Coupon.collectionUrl('automaticCoupons'), { chargeables, targetPackageId : targetPackage?.id })).data;
		automaticDiscounts.discounts                 = _.mapValues(automaticDiscounts.discounts, discount => createEntity(discount));
		return automaticDiscounts;
	}

	static get automaticCoupons(): AvailableAutomaticCoupons[] {
		return availableAutomaticCoupons;
	}

	static async referralInfo(): Promise<{ canApply: boolean; description: string }> {
		return (await Axios.get(Coupon.collectionUrl('referralInfo'))).data;
	}

}

function getCouponEntity(automaticCoupons: AvailableAutomaticCoupons[]) {
	return _.castArray(automaticCoupons).map(autoCoupon => {
		autoCoupon.coupons = _.mapValues(autoCoupon.coupons, coupon => createEntity({ ...coupon, $class : 'Coupon' }));
		return autoCoupon;
	});
}

void initializers.register(async function() {
	availableAutomaticCoupons = getCouponEntity((await Axios.get(Coupon.collectionUrl('automaticCoupons'))).data);
});
