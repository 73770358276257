export enum ValidationType {
	Error   = 'error',
	Warning = 'warning'
}

export class ValidationIssue {

	message: string;
	type: ValidationType;

	constructor(type: ValidationType, message: string) {
		this.type    = type;
		this.message = message;
	}

}

export class ValidationError extends ValidationIssue {

	constructor(message: string) {
		super(ValidationType.Error, message);
	}

}

export class ValidationWarning extends ValidationIssue {

	constructor(message: string) {
		super(ValidationType.Warning, message);
	}

}

export function normalizeIssue(issue: string | ValidationIssue): ValidationIssue {
	return issue instanceof ValidationIssue ? issue : new ValidationError(issue);
}
