import Moment   from 'moment';
import Validate from '$/lib/Validate';
import Errors   from '$/lib/Errors';
import { In, MoreThanOrEqual, Not, SelectQueryBuilder } from '$/lib/typeormExt';

import { BaseOrgEntity }                  from '$/entities/Organization';
import Permissions, { Context }           from '$/entities/lib/Permissions';
import { User }                           from '$/entities/User';
import { ChargeableProp }                 from '$/entities/Package';
import { Application, ApplicationStatus } from '$/entities/tenantScreening/Application';
import type { Discount }                  from '$/entities/billing/Discount';
import type { Address }                   from '$/entities/Address';
import type { Building  }                 from '$/entities/Building';
import type { BaseCharge  }               from '$/entities/billing/BaseCharge';
import type { EquifaxCreditReport }       from '$/entities/tenantScreening/EquifaxCreditReport';
import { OrganizationLimits, EquifaxCustomerStatus } from '$/entities/OrganizationExt';
import type { Organization as OrganizationCommon }   from '$/common/entities/Organization';
import { CommonEntity, CollectionName, Column, ManyToOne, EntityID, getEntityClass } from '$/entities/BaseEntity';

export enum ProgressStatus {
	Incomplete = 'incomplete',       // initial state
	InProgress = 'inprogress',
	Complete   = 'complete',
	Failed     = 'failed',           // expected error
	Error      = 'error'             // unexpected error
}

export interface SearchParams {
	firstName: string;
	middleName: string;
	lastName: string;
	dateOfBirth: Date;
	nationalID?: string;
	address: Address;
	building: Building;
}

/**
 * Base class for all types of Tenant screening checks.
 */
@CommonEntity()
@CollectionName('tenantCheck')
@Permissions({
	read   : ownApplicantRead,
	update : Permissions.serverOnly,
	delete : Permissions.serverOnly,
})
export class TenantCheck extends BaseOrgEntity {

	report: any;

	@Column()
	@Permissions({ write : Permissions.serverOnly })
	status: ProgressStatus = ProgressStatus.Incomplete;

	/**
	 * The user that created this TenantCheck.
	 * Included mostly for auditing and legal purposes.
	 */
	@ManyToOne('User', { eager : true })
	@Permissions({ write : Permissions.serverOnly })
	requestedBy: User;

	/**
	 * The Discount to apply to any resultant charge for this tenantCheck.
	 */
	@ManyToOne('Discount', { onDelete : 'SET NULL' })
	@Permissions({ write : ownApplicantWrite })
	discount: Discount = undefined;

	@ManyToOne('Application', { onDelete : 'CASCADE', eager : true })
	@Validate({ custom : function() {
		return this.validateCountry();
	} })
	application: Application = null;

	get hasFailed() {
		return [ ProgressStatus.Failed, ProgressStatus.Error ].includes(this.status);
	}

	get isComplete() {
		return this.status === ProgressStatus.Complete;
	}

	get inProgress() {
		return this.status === ProgressStatus.InProgress;
	}

	get fullAddress() {
		return this.application.address.format();
	}

	get isSample() {
		return this.id === 'sample';
	}

	/**
	 * If true, this tenantCheck is expired and no longer valid.
	 * It's report cannot be fetched again.
	 */
	get isExpired() {
		return Moment() > Moment(this.expiresOn);
	}

	get expiresOn() {
		const expiresIn = this.status === ProgressStatus.Incomplete ? TenantCheck.expiresInDays.Incomplete : TenantCheck.expiresInDays.Complete;
		return Moment(this.createdOn).add(expiresIn, 'days').toDate();
	}

	get error() {
		return '';
	}

	get label() {
		return 'Tenant Check';
	}

	/**
	 * When displaying the names of multiple check types together, displaying them in ascending order according to this number
	 */
	get labelOrder() {
		return 999;
	}

	/**
	 * The ChargeableProp associated with this tenant check.
	 * Expected to be overridden by subclasses
	 */
	get chargeable(): ChargeableProp {
		return null;
	}

	/**
	 * The BaseCharge associated with this tenant check.
	 * Expected to be overridden by subclasses
	 */
	get chargeClass(): typeof BaseCharge {
		return null;
	}

	/**
	 * Validation for the country field.
	 * Expected to be overridden in subclasses.
	 */
	validateCountry(): PossiblePromise<string> {
		return '';
	}

	/**
	 * @returns the data specific to this type of tenantCheck.  Expected to be overridden in subclasses.
	 */
	getReport(): Promise<any> {
		throw new Errors.NotImplemented();
	}

	static expiresInDays = {
		Complete   : 45,		// completed tenant checks expire in this many days
		Incomplete : 7,			// incomplete tenant checks expire in this many days
	};

	/**
	 * @returns For a given org, get the number of remaining tenant checks allowed (for the various periods).
	 */
	static async getRemainingAllowed(org: OrganizationCommon, { applicationToIgnore }: { applicationToIgnore?: EntityID } = {}): Promise<{ instant?: number; daily?: number; monthly?: number }> {
		const EquifaxCreditReportClass = getEntityClass<typeof EquifaxCreditReport>('EquifaxCreditReport');
		const nonErrorStatuses         = [ ProgressStatus.Complete, ProgressStatus.InProgress, ProgressStatus.Incomplete ];

		// for EquifaxCA reports, before LL registration is completed, use the default instant limit
		if (org.equifaxCustomerInfo?.currStatus !== EquifaxCustomerStatus.Approved) {
			const creditReports: EquifaxCreditReport[] = await EquifaxCreditReportClass.find({
				where : { org, status : In(nonErrorStatuses) }, withDeleted : true,
			});
			const creditReportCount = _.sumBy(creditReports, report => report.isExpired ? 0 : 1);
			return { instant : OrganizationLimits.defaultLimit - creditReportCount };
		}

		const remaining = { daily : undefined, monthly : undefined };
		for (const interval in OrganizationLimits.intervals) {
			const tenantChecks: TenantCheck[] = await this.find({
				withDeleted : true,
				where       : _.compactObject({
					org,
					status        : In(nonErrorStatuses),
					createdOn     : MoreThanOrEqual(Moment().subtract(OrganizationLimits.intervals[interval], 'hours').toDate()),
					applicationId : applicationToIgnore ? Not(applicationToIgnore) : undefined,
				}),
			});
			const tenantCheckCount = _(tenantChecks).uniqBy('applicationId').sumBy(tc => tc.isExpired ? 0 : 1);
			remaining[interval]    = org.limits.creditReports[interval] - tenantCheckCount;
		}

		return remaining;
	}

}

async function ownApplicantRead(context: Context, entity: TenantCheck, query: SelectQueryBuilder<Application>): Promise<string> {
	await entity?.loadRelation('application');

	// Allow applicants to read their own application
	if (query) {
		const alias = query.expressionMap.mainAlias.name;
		query.innerJoin(Application, 'application', `${alias}.applicationId = application.id`);
		query.andWhere(`
			((application.applicantId = :applicantId AND application.status = '${ApplicationStatus.PendingApplicant}')
			OR ${alias}.orgId = :orgId)
		`, { applicantId : context.role.id, orgId : context.org.id });
		context.stopChecks();
	}
	else if (entity.application.isWaitingOnApplicant && (entity.application as any).applicantId === context.role.id) {
		context.stopChecks();
	}

	return '';
}

async function ownApplicantWrite(context: Context, entity: TenantCheck): Promise<string> {
	await entity.loadRelation('application');

	// Allow applicants to modify their own application
	if (entity.application?.isWaitingOnApplicant && (entity.application as any)?.applicantId === context.role.id) {
		context.stopChecks();
		return '';
	}
}
