import Axios      from 'axios';
import { Entity } from '$/entities/BaseEntity';

import { NationalID as NationalIDCommon } from '$/common/entities/NationalID';
export *                                  from '$/common/entities/NationalID';

@Entity()
export class NationalID extends NationalIDCommon {

	async decrypt(): Promise<string> {
		if (this.isValueEncrypted) {
			this.value = `${(await Axios.get(NationalID.collectionUrl(`${this.id}/value`))).data}`;
		}
		this.makeEditable({ force : true });
		return this.value;
	}

	/**
	 * Specialized save logic that does not bother saving new & empty values and deletes existing empty values
	 */
	async save() {
		if (!this.value) {
			if (!this.isNew) {
				// actually delete empty existing values
				await Axios.delete(NationalID.collectionUrl(`${this.id}`));
			}

			return this; 		// don't bother saving empty values
		}

		return super.save();
	}

}
