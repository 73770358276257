// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="./lodashExt.d.ts" />

/**
 * Lodash mixins.
 * This file adds mixins for lodash, but also exports lodash so it can be used instead of lodash
 * otherwise if it is required before the use of normal lodash it will add these mixins to the global lodash.
 */
import _       from 'lodash';
import typeorm from 'typeorm';

export default _;

// since on the browser, lodash by default creates a global variable, mimic the same behaviour on Node
if (typeof global !== 'undefined') {
	(global as any)._ = _;
}

/**
 * Object flatten exceptions
 * These are object types that _.flattenObject will not flatten if it reaches them in a nested POJO object
 */
export const exceptions = [
	Error,
	Array,
	String,
	Number,
	Boolean,
	Function,
	Date,
	typeorm.BaseEntity,
];

const oldDelay = _.delay;

// chainable
_.mixin({
	/**
	 * Like _.assign but iterates over an array and makes the same assignment to all objects in the array.
	 * @param {*[]} array
	 * @param {...Object} sources - properties of source to assign to each object of array
	 */
	assignEach : function(array: [], sources) {
		const length = array.length;
		let a        = 0;
		while (a < length) {	// using while loop for performance
			_.assign(array[a++], sources);
		}
		return array;
	},

	flattenObject : function(object: Record<string, any>): Record<string, any> {
		const result: any = {};
		for (const property in object) {
			if (!Object.keys(object).includes(property)) {
				continue;
			}

			const value = object[property];
			if (typeof value === 'object' && value !== null && !exceptions.some(clazz => value instanceof clazz)) {
				const flatObject = _.flattenObject(value);
				for (const flatObjectProperty in flatObject) {
					if (!Object.keys(flatObject).includes(flatObjectProperty)) {
						continue;
					}
					result[`${property}.${flatObjectProperty}`] = flatObject[flatObjectProperty];
				}
			}
			else {
				result[property] = value;
			}
		}
		return result;
	},

	unflattenObject : function(object: Record<string, any>): Record<string, any> {
		const result = {};
		for (const property in object) {
			const keys = property.split('.');
			// eslint-disable-next-line no-return-assign, no-nested-ternary
			keys.reduce((total, current, index) => total[current] || (total[current] = isNaN(Number(keys[index + 1])) ? (keys.length - 1 == index ? object[property] : {}) : []), result);
		}
		return result;
	},

	compactObject : function(object) {
		return _.pickBy(object, value => !_.isUndefined(value));
	},

	sortByKeys : function(object: Record<string, any>, compareFunction?: (a: any, b: any) => number): Record<string, any> {
		if (!object || typeof object !== 'object') {
			return object;
		}

		// Object keys in JS are ordered by creation order, so we can sort the keys and then copy the values to a new object in order
		return Object.keys(object).sort(compareFunction).reduce((sortedObject, key) => {
			sortedObject[key] = object[key];
			return sortedObject;
		}, {});
	},

}, { chain : true });

// non-chainable
_.mixin({
	/**
	 * Compares two strings for equality case insensitively.
	 */
	areEqualCaseless : function(string1: string, string2: string): boolean {
		if (string1 === '' || _.isNil(string1) && (string2 === '' || _.isNil(string2))) {
			return true;
		}

		const isString1 = _.isString(string1);
		const isString2 = _.isString(string2);
		if (!isString1 && !isString2) {
			throw new Error('invalid arguments: at least one must be a string');
		}

		if (!isString1) {
			string1 = String(string1);
		}
		if (!string2) {
			string2 = String(string2);
		}

		return string1.localeCompare(string2, undefined, { sensitivity : 'base' }) === 0;
	},

	/**
	 * Efficiently convert a Map's values to an array
	 * @param {Map<*, V>} map
	 * @returns {V[]}
	 */
	arrayFromValues : function(map) {
		return this.arrayFromIterator(map.values(), map.size);
	},

	/**
	 * Efficiently convert a Map's keys to an array
	 * @param {Map<K, *>} map
	 * @returns {K[]}
	 */
	arrayFromKeys : function(map) {
		return this.arrayFromIterator(map.keys(), map.size);
	},

	/**
	 * Efficiently convert a Map's entries to an array
	 * @param {Map<K, V>} map
	 * @returns {[ K, V ][]}
	 */
	arrayFromEntries : function(map) {
		return this.arrayFromIterator(map.entries(), map.size);
	},

	/**
	 * Efficiently convert an iterator to an array
	 * @param {Iterator<T>} iterator
	 * @param {Number}      length
	 * @returns {T[]}
	 */
	arrayFromIterator : function<T>(iterator: Iterator<T>, length): T[] {
		const arr          = new Array(length);
		let iteratorResult = iterator.next();
		let i              = 0;
		while (!iteratorResult.done) {
			arr[i]         = iteratorResult.value;
			iteratorResult = iterator.next();
			++i;
		}
		return arr;
	},

	/**
	 * A modification of _.isFunction that returns true for async functions and generators
	 * A combination of 3.10.1 and 4.17.4 functionality
	 * @param {*} value - The value to check.
	 * @returns {Boolean} Returns true if value is correctly classified, else false.
	 */
	isFunction : function(value: any) {
		if (!_.isObject(value)) {
			return false;
		}
		const tag = Object.prototype.toString.call(value);
		return tag === '[object Function]'
			|| tag === '[object AsyncFunction]'
			|| tag === '[object GeneratorFunction]';
	},

	ensureStartsWith : function(str: string, startingString: string): string {
		return str && !str.startsWith(startingString) ? startingString + str : str;
	},

	ensureEndsWith : function(str: string, endingString: string): string {
		return str && !str.endsWith(endingString) ? str + endingString : str;
	},

	commonPrefix : function(...strings: string[]) {
		strings           = strings.concat().sort();
		const firstString = strings[0];
		const lastString  = strings[strings.length - 1];
		const length      = firstString.length;
		let i             = 0;
		while (i < length && firstString.charAt(i) === lastString.charAt(i)) {
			i++;
		}
		return firstString.substring(0, i);
	},

	mapValuesAsync : function(object: any, iterator: (value: any, key: string, obj: any) => any) {
		const keys     = Object.keys(object);
		const promises = keys.map(k => iterator(object[k], k, object).then(newValue => ({ key : k, value : newValue })));
		return Promise.all(promises).then(values => {
			const newObject = {};
			values.forEach(v => {
				newObject[v.key] = v.value;
			});
			return newObject;
		});
	},

	mapAsync : function<T, TResult>(array: T|T[], mapFunction: (element: T) => Promise<TResult>) {
		return Promise.all(_.castArray(array).map(mapFunction));
	},

	filterAsync : async <T>(array: T[], predicate: (value: T, index: number, arr: T[]) => PossiblePromise<boolean>) => {
		if (!Array.isArray(array)) {
			return array;
		}

		const results = await Promise.all(array.map(predicate));
		return array.filter((value, index) => results[index]);
	},

	forEachAsync : async <T>(array: T[], predicate: (value: T, index: number, arr: T[]) => PossiblePromise<any>, { series = false } = {}) => {
		if (!Array.isArray(array)) {
			return;
		}
		if (series) {
			for (let index = 0; index < array.length; index++) {
				if (await predicate(array[index], index, array) === false) {
					return;
				}
			}
		}
		else {
			return Promise.all(array.map(predicate));
		}
	},


	timesAsync : async (n: number, iterator: (i: number) => PossiblePromise<any>, { series = true } = {}) => {
		if (series) {
			const result = [];
			for (let i = 0; i < n; i++) {
				result.push(await iterator(i));
			}
			return result;
		}
		return Promise.all(_.times(n, iterator));
	},

	someAsync : async <T>(array: T[], predicate: (value: T, index: number, arr: T[]) => PossiblePromise<boolean>): Promise<boolean> => {
		if (!Array.isArray(array)) {
			return false;
		}

		for (let index = 0; index < array.length; index++) {
			if (await predicate(array[index], index, array)) {
				return true;
			}
		}
		return false;
	},

	everyAsync : async <T>(array: T[], predicate: (value: T, index: number, arr: T[]) => PossiblePromise<boolean>): Promise<boolean> => {
		if (!Array.isArray(array)) {
			return true;
		}

		for (let i = 0; i < array.length; i++) {
			if (!await predicate(array[i], i, array)) {
				return false;
			}
		}
		return true;
	},

	delay(funcOrWait: number | ((...args: any[]) => any), possibleWait?: number, ...args: any[]) {
		if (typeof funcOrWait === 'function') {
			return oldDelay(funcOrWait, possibleWait, ...args);
		}
		if (typeof funcOrWait === 'number') {
			return new Promise(resolve => setTimeout(resolve, funcOrWait));
		}
		throw new Error('invalid parameter');
	},

	pascalCase : function(value: string) {
		return _.startCase(value).split(' ').join('');
	},

	convertKeys : function(obj: any, caseFn: (key: string) => string, recursive?: boolean) {
		if (!obj || typeof obj !== 'object') {
			return obj;
		}

		if (Array.isArray(obj)) {
			return recursive ? obj.map(item => _.convertKeys(item, caseFn, recursive)) : obj;
		}

		return _.reduce(obj, (newObj, value, key) => {
			newObj[caseFn(key)] = recursive ? _.convertKeys(value, caseFn, recursive) : value;
			return newObj;
		}, {});
	},

	snakeCaseKeys(obj: any, recursive?: boolean): any {
		return _.convertKeys(obj, _.snakeCase, recursive);
	},

	isNotEmpty(...args) {
		return !_.isEmpty(...args);
	},

}, { chain : false });
