









import parsePhoneNumber from 'libphonenumber-js';

import { Country }         from '$/lib/Address';
import { Component, Prop } from '$/lib/vueExt';
import VueValidationMixin  from '$/lib/mixins/VueValidationMixin';

@Component({ model : { event : 'update:value' } })
export default class PhoneNumber extends VueValidationMixin {

	/**
	 * The phone number, likely formatted in E.164 format (https://en.wikipedia.org/wiki/E.164).
	 * If it's not already in this format, it will be parsed and changed into this format the first time the input blurs
	 */
	@Prop({ required : true })
	readonly value: string;

	/**
	 * Country code for the phone number parsing/display.
	 * Canada and US numbers are displayed the same way, but might have different restrictions for strict parsing so it's good to specify.
	 */
	@Prop({ default : Country.CA, type : String as () => Country })
	readonly country: Country;

	/**
	 * Updates the phone number in the parent component through this event
	 */
	onBlur(newValue: string) {
		// SHOULDDO: set validation feedback if parse fails
		this.$emit('update:value', parsePhoneNumber(newValue, this.country)?.number ?? newValue);
	}

}
