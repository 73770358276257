
/**
 * Overwrite BFormTextarea to add support for mutating certain props
 */
import { BFormTextarea }     from 'bootstrap-vue';
import { Component, Mixins } from '$/lib/vueExt';
import VueValidationMixin    from '$/lib/mixins/VueValidationMixin';

@Component
export default class FormTextarea extends Mixins(BFormTextarea, VueValidationMixin) {
}
