import type { Readable } from 'stream';
import validator         from 'validator';
import env               from '$/lib/env';
import { Index }         from '$/lib/typeormExt';

import { BaseEntity, CommonEntity, Column, Alias } from '$/entities/BaseEntity';

export enum EmailStatus {
	Unsent      = 'unsent',
	Sent        = 'sent',        // the email was successfully delivered to an upstream mail server
	Delivered   = 'delivered',   // the email was successfully delivered to the recipient's mail server
	Undelivered = 'undelivered', // the email was determined to not be deliverable (for example, the recipient's email address is invalid)
	Opened      = 'opened',      // the email was opened by an email client
	Clicked     = 'clicked',     // a link in the email was clicked on
	Error       = 'error',
}

/**
 * A representation of an Email message to possibly send.
 * Note that even though this is a common Entity, it does not have an API and is meant merely as an interface
 * on the client/common files.
 */
@CommonEntity()
export class Email extends BaseEntity {

	constructor(options?: { isSample?: boolean }) {
		super();
		if (options?.isSample) {
			this.id = 'sample';
		}
	}

	@Column()
	status: EmailStatus = EmailStatus.Unsent;

	/**
	 * The email address to send this email to.
	 */
	@Index()
	@Column({ name : 'to', type : 'varchar' }) @Alias('to')
	private toValue: EmailAddress = '';

	get to(): EmailAddress {
		return this.toValue;
	}
	set to(newValue: EmailAddress) {
		this.toValue = (newValue as unknown as HasEmail).email || newValue as EmailAddress;
	}

	@Column()
	subject: string = env.app.name.full;

	@Column({ nullable : true })
	cc: EmailAddress = '';

	@Column({ nullable : true })
	bcc: EmailAddress = '';

	@Column()
	attempt: number = 0;

	@Column({ nullable : true })
	errorMessage: string = '';

	attachment: { data: Readable; filename: string };

	replyTo: string;

	/**
	 * Returns true if this email is a sample (not meant to be saved)
	 */
	 get isSample() {
		return this.id === 'sample';
	}

	/**
	 * @returns true if the email is a valid email address.  Does not check if the email address is deliverable.
	 */
	static isEmailAddress(value: EmailAddress): boolean {
		return typeof value === 'string' && validator.isEmail(value);
	}

	/**
	 * Validates an email address thoroughly.
	 * @returns validation error if the email address is not valid; '' if it is valid
	 */
	static async validateAddress(email: EmailAddress): Promise<string> { // eslint-disable-line @typescript-eslint/no-unused-vars
		throw new Error('not implemented');
	}

}
