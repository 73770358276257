import env, { Environment }         from '$/lib/env';
import { LogEvents }                from '$/lib/global';
import { BaseLogger, type Log }     from '$/common/lib/logger';	// references common to avoid circular dependency
import { getPackageNameForHubSpot } from '$/lib/hubspot/HubSpotClient';

const portalId      = env.config('hubSpot.portalID');
const eventIdPrefix = env.isEnvironment(Environment.PROD) ? 'pe8513259' : 'pe8934772_t_';

const eventsMap = {
	[LogEvents.SignedUp] : {
		legacyEventName    : 'SignUp',
		behaviouralEventId : `${eventIdPrefix}_signed_up`,
	},
	[LogEvents.ALaCartePurchased] : {
		legacyEventName    : (log: Log) => log.details.type.replace(' ', ''),
		behaviouralEventId : `${eventIdPrefix}_a_la_carte_item_purchased`,
	},
	[LogEvents.RentReporting] : {
		legacyEventName    : 'RentReporting',
		behaviouralEventId : `${eventIdPrefix}_rent_reporting`,
	},
	[LogEvents.PackageChanged] : {
		legacyEventName    : (log: Log) => `Package Changed from ${log.details.from} to ${log.details.to}`,
		behaviouralEventId : `${eventIdPrefix}_package_changed`,
		mapDetails         : ({ to, from, ...others }) => ({
			to : getPackageNameForHubSpot(to), from : getPackageNameForHubSpot(from), ...others,
		}),
	},
	[LogEvents.SignUpAborted] : {
		legacyEventName : 'SignUpAborted',
	},
	[LogEvents.EquifaxClicked] : {
		legacyEventName : 'EquifaxClicked',
	},
};

export class HubSpotEventLogger extends BaseLogger {

	log(...args) {
		if (!portalId || env.isEnvironment(Environment.AUTOTESTING)) {
			return;
		}

		const logs: Log[] = super.log.apply(this, args);
		logs.forEach(l => {
			try {
				const eventMap = eventsMap[l.message];

				// map the details if function provided, otherwise grab the initial details
				const details = eventMap?.mapDetails?.(l.details) || l.details;

				// create a copy of the log as to not mutate the details for other events loggers
				const log = { ...l, details };

				if (eventMap?.legacyEventName) {
					const eventName = typeof eventMap.legacyEventName === 'function'
						? eventMap.legacyEventName(log)
						: eventMap.legacyEventName
					;
					this.sendToHubspot(eventName, log, true);
				}

				if (eventMap?.behaviouralEventId) {
					this.sendToHubspot(eventMap.behaviouralEventId, log);
				}
			}
			catch (error) {
				console.error(`Failed to send Hubspot event : ${l.message}`);
			}
		});
	}

	/**
	 * Actually sends the log event to HubSpot (must be implemented by subclasses)
	 */
	sendToHubspot(eventNameOrID: string, log: Log, legacyEvent = false) { // eslint-disable-line @typescript-eslint/no-unused-vars
		throw new Error('not implemented');
	}

}
