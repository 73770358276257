import { BaseRole }                  from '$/entities/roles/BaseRole';
import { RolePermission }            from '$/entities/roles/RolePermission';
import { ChildEntity, CommonEntity } from '$/entities/BaseEntity';

@ChildEntity() @CommonEntity()
/**
 * Collections are users whose need access to files uploaded for Collection purposes.
 */
export class Collections extends BaseRole {

	hasPermission(permission: RolePermission): boolean {
		switch (permission) {
			case RolePermission.CollectFileCrossOrgAccess:
				return true;
		}
		return super.hasPermission(permission);
	}

}
