import { ChildEntity } from '$/lib/typeormExt';

import { CommonEntity } from '$/entities/BaseEntity';
import { CreditBureau, ReportingGroup, TenantMetro2 } from '$/entities/reporting/TenantMetro2';

export const tradelineProgramID = 'BSXO';

/**
 * Tenant reporting record specific to Experian US
 */
@ChildEntity() @CommonEntity()
export class ExperianUSTenantMetro2 extends TenantMetro2 {

	static getCreditBureau(): CreditBureau {
		return CreditBureau.ExperianUS;
	}

	static getReportingGroup(programID: string) {
		return programID === tradelineProgramID ? ReportingGroup.Tradeline : undefined;
	}

}
