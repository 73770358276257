export function transpose(data: any[]) {
	const result = {};
	if (data.length === 0) {
		return result;
	}
	const columns = Object.keys(data[0]);
	columns.forEach(key => {
		result[key] = [];
	});
	data.forEach(row => {
		columns.forEach(key => {
			result[key].push(row[key]);
		});
	});
	return result;
}

export function formatSeries(data, type?: string) {
	if (type === 'bubble') {
		const result = [];
		for (const key in data) {
			for (const index in data[key]) {
				result[index]      = result[index] || {};
				result[index][key] = data[key][index];
			}
		}
		return _(result)
			.groupBy('labels')
			.mapValues((data, label) => ({ label, data }))
			.values()
			.valueOf();
	}

	return _(data).omit('labels')
		.transform((result, ...[ data, label ]: any) => {
			result.push({ data, label });
			return result;
		}, [])
		.valueOf();
}
