
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M12.36 12.865h-.585V7.602a.585.585 0 0 0-.585-.585H7.68a.585.585 0 0 0-.585.585v2.339c0 .323.262.585.585.585h.585v2.339H7.68a.585.585 0 0 0-.585.584v2.34c0 .322.262.584.585.584h4.678a.585.585 0 0 0 .585-.585V13.45a.585.585 0 0 0-.585-.585zm-.585 2.339H8.266v-1.17h.585a.585.585 0 0 0 .585-.584V9.94a.585.585 0 0 0-.585-.585h-.585v-1.17h2.339v5.264c0 .322.262.584.585.584h.585v1.17zM10.02 2.34c-.967 0-1.754.786-1.754 1.754 0 .967.787 1.754 1.754 1.754.968 0 1.755-.787 1.755-1.754 0-.968-.787-1.755-1.755-1.755zm0 2.338a.585.585 0 1 1 .002-1.17.585.585 0 0 1-.002 1.17z"}}),_c('path',{attrs:{"d":"M10.02 0C4.567 0 .002 4.406.002 9.941c0 1.924.552 3.836 1.56 5.421L.043 19.158a.585.585 0 0 0 .76.76L4.6 18.4a10.182 10.182 0 0 0 5.421 1.56c5.541 0 9.941-4.571 9.941-10.019a9.935 9.935 0 0 0-9.94-9.94zm0 18.798a8.978 8.978 0 0 1-5.024-1.537.585.585 0 0 0-.547-.06l-2.813 1.124 1.125-2.812a.585.585 0 0 0-.06-.548 8.978 8.978 0 0 1-1.538-5.024c0-4.836 3.978-8.779 8.857-8.779 4.837 0 8.78 3.95 8.78 8.787 0 4.88-3.943 8.85-8.78 8.85z"}})])
          )
        }
      }
    