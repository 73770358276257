import  Moment               from 'moment';
import { Route }             from 'vue-router';
import { LocalStorageCache } from '$/lib/localStorageExt';
import { Vue, Watch }        from '$/lib/vueExt';
import { BaseEntity }        from '$/entities/BaseEntity';

abstract class PreservationStrategy {

	/**
	 * Loads the field's value from persistent storage into the decorated field.
	 */
	abstract load(state: State);

	/**
	 * Saves the field's value into persistent storage.
	 */
	abstract save(state: State): boolean;

	/**
	 * Factory function that creates a decorator for this type of PreservationStrategy
	 */
	static create(transformer?: PreservationTransformer): PropertyDecorator {
		const strategy = new (this as unknown as Class)();
		return function(clazz: Vue, propertyKey: string) {
			addToVueClass(clazz, propertyKey, strategy, transformer);
		};
	}

}

/**
 * Preserves a string field's value in a URL query parameter.
 */
class QuerySingleParam extends PreservationStrategy {

	/**
	 * The name of the url parameter into which to save the field's value.
	 * Defaults to the name of the decorated class field.
	 */
	paramName: string;

	static create(transformer?: PreservationTransformer): PropertyDecorator;
	static create(queryParamName?: string, transformer?: PreservationTransformer): PropertyDecorator;
	static create(...args): PropertyDecorator {
		let [ queryParamName, transformer ] = args;
		if (typeof queryParamName === 'object') {
			transformer    = queryParamName;
			queryParamName = undefined;
		}

		return function(clazz: Vue, propertyKey: string) {
			const strategy     = new QuerySingleParam();
			strategy.paramName = queryParamName || propertyKey;
			addToVueClass(clazz, propertyKey, strategy, transformer);
		};
	}

	load(state: State): string {
		const { route } = state;
		const result    = route.current.query[this.paramName];
		return Array.isArray(result) ? result[0] : result;
	}

	save(state: State): boolean {
		const { route, value: fieldValue } = state;
		const query                        = { ...route.current.query }; // mutate query to prevent NavigationDuplicated
		route.current.query                = query;

		if (query[this.paramName] === fieldValue) {
			return false;
		}

		if (_.isNil(fieldValue) || fieldValue === '') {
			delete query[this.paramName];
		}
		else {
			query[this.paramName] = fieldValue;
		}

		return true;
	}

}

/**
 * Preserves an object field value in multiple URL query parameters.
 */
class QueryMultipleParams extends PreservationStrategy {

	paramSpecs: Dictionary<ParamSpec> = {};

	static create(transformer?: PreservationTransformer): PropertyDecorator;
	static create(queryParamSpecs: Dictionary<ParamSpec | PreservationTransformer | string | boolean>, transformer?: PreservationTransformer): PropertyDecorator;
	static create(...args): PropertyDecorator {
		let [ queryParamSpecs, transformer ] = args;
		queryParamSpecs                      = queryParamSpecs || {};

		// WARNING: avoid naming the child params `get` and `set`
		if (isPreservationTransformer(queryParamSpecs)) {
			transformer     = queryParamSpecs;
			queryParamSpecs = {};
		}

		// Normalize ParamSpecs
		queryParamSpecs = _.mapValues(queryParamSpecs, paramSpec => {
			if (typeof paramSpec === 'string') {
				return { alias : paramSpec };
			}
			if (typeof paramSpec === 'boolean') {
				return { enabled : paramSpec };
			}
			if (paramSpec.get && paramSpec.set) {
				return { transformer : paramSpec };
			}
			return paramSpec;
		});


		transformer = transformer || ObjectTransformer(
			_.compactObject(
				_.mapValues(queryParamSpecs, queryParamSpec => queryParamSpec.transformer)
			)
		);

		return function(clazz: Vue, propertyKey: string) {
			const strategy      = new QueryMultipleParams();
			strategy.paramSpecs = queryParamSpecs;
			addToVueClass(clazz, propertyKey, strategy, transformer);
		};
	}

	load(state: State): string {
		const { route, target, propertyKey } = state;
		if (route.previous && route.current.path !== route.previous.path) {
			return;
		}

		const fieldValue = target[propertyKey];

		let shouldUpdate  = false;
		const result: any = {};

		for (const key in fieldValue) {
			if (this.paramSpecs[key]?.enabled === false) {
				result[key] = target[propertyKey]?.[key];
				continue; // skip this param since it was explicitly set to ignore
			}
			const queryParamName = this.paramSpecs[key]?.alias || key;
			if (route.current.query.hasOwnProperty(queryParamName)) {
				result[key]  = route.current.query[queryParamName];
				shouldUpdate = true;
			}
			else {
				result[key] = fieldValue[key];
			}
		}

		return shouldUpdate ? result : undefined;
	}

	save(state: State): boolean {
		const { route, value: fieldValue } = state;
		const query                        = { ...route.current.query }; // mutate query to prevent NavigationDuplicated
		route.current.query                = query;

		let shouldUpdate = false;

		for (const key in fieldValue) {
			if (this.paramSpecs[key]?.enabled === false) {
				continue; // skip this param since it was explicitly set to ignore
			}

			const alias = this.paramSpecs[key]?.alias || key;
			const value = fieldValue[key];

			if ((query[alias] || value) && query[alias] !== value) {
				shouldUpdate = true;
				query[alias] = value;
			}
		}

		return shouldUpdate;
	}

}

interface ParamSpec {
	alias?: string;
	enabled?: boolean;
	transformer?: PreservationTransformer;
}

/**
 * Preserves the field's value in the URL hash
 */
class HashPreservationStrategy extends PreservationStrategy {

	load(state: State): string {
		return state.route.current.hash.substring(1);
	}

	save(state: State): boolean {
		const hash = state.route.current.hash.substring(1); // remove #
		if (hash === state.value) {
			return false;
		}
		state.route.current.hash = state.value;
		return true;
	}

}

/**
 * Preserve's the field's value in the last part of the path.
 */
class PathPreservationStrategy extends PreservationStrategy {

	/**
	 * The name of the router parameter into which to save the field's value.
	 * Defaults to the name of the decorated class field.
	 */
	paramName?: string;

	static create(transformer?: PreservationTransformer): PropertyDecorator;
	static create(paramName?: string, transformer?: PreservationTransformer): PropertyDecorator;
	static create(...args): PropertyDecorator {
		let [ paramName, transformer ] = args;
		if (typeof paramName === 'object') {
			transformer = paramName;
			paramName   = undefined;
		}

		return function(clazz: Vue, propertyKey: string) {
			const strategy     = new PathPreservationStrategy();
			strategy.paramName = paramName || propertyKey;
			addToVueClass(clazz, propertyKey, strategy, transformer);
		};
	}

	load(state: State) {
		return state.route.current.params[this.paramName];
	}

	save(state: State): boolean {
		const currentRoute = state.route.current;

		if (!currentRoute.params.hasOwnProperty(this.paramName)) {
			throw new Error(`Route does not have a router parameter named "${this.paramName}" failed to save state`);
		}

		// if value is already the suffix of the current path then don't need to do anything
		if (currentRoute.params[this.paramName] === state.value) {
			return false;
		}

		currentRoute.params[this.paramName] = state.value;
		let newPath                         = _.last(currentRoute.matched).path;
		_.forEach(currentRoute.params, (value, key) => {
			newPath = newPath.replace(new RegExp(`:${key}\\??`), value ? value : '');
		});
		state.route.current.path = _.trimEnd(newPath, '/');
		return true;
	}

}

const localStorageMap: Dictionary<LocalStorageCache> = {};

/**
 * Preserve's the field's value in the local storage
 */
class LocalStoragePreservationStrategy extends PreservationStrategy {

	cache: LocalStorageCache;
	expires: Date | (() => Date);

	load(state: State) {
		return this.cache.get(state.propertyKey);
	}

	save(state: State) {
		const expires = typeof this.expires === 'function' ? this.expires() : this.expires;
		this.cache.set(state.propertyKey, state.value, { expires });
		return false; // no need to refresh the current route
	}

	static create(transformer?: PreservationTransformer): PropertyDecorator
	static create(namespace?: string, options?: LocalStoragePreservationOptions): PropertyDecorator
	static create(options?: LocalStoragePreservationOptions): PropertyDecorator
	static create(nameOrTransformer?: string | PreservationTransformer | LocalStoragePreservationOptions, options: LocalStoragePreservationOptions = {}): PropertyDecorator {
		if (isPreservationTransformer(nameOrTransformer)) {
			options.transformer = nameOrTransformer as PreservationTransformer;
		}
		else if (typeof nameOrTransformer === 'string') {
			options.namespace = nameOrTransformer;
		}
		else {
			options = nameOrTransformer as LocalStoragePreservationOptions;
		}

		const strategy                 = new (this as unknown as Class)();
		let { namespace }              = options;
		const { transformer, expires } = options;

		return function(clazz: Vue, propertyKey: string) {
			namespace        = namespace || _.camelCase(clazz.constructor.name);
			strategy.cache   = localStorageMap[namespace] = localStorageMap[namespace] || new LocalStorageCache(namespace);
			strategy.expires = expires;
			addToVueClass(clazz, propertyKey, strategy, transformer);
		};
	}

}

interface LocalStoragePreservationOptions {
	namespace?: string;
	expires?: Date | (() => Date);
	transformer?: PreservationTransformer;
}

export default {
	QuerySingleParam    : QuerySingleParam.create,
	QueryMultipleParams : QueryMultipleParams.create,
	Hash                : HashPreservationStrategy.create.bind(HashPreservationStrategy),
	Path                : PathPreservationStrategy.create,
	LocalStorage        : LocalStoragePreservationStrategy.create.bind(LocalStoragePreservationStrategy),
};


function addToVueClass(clazz: Vue, propertyKey: string, strategy: PreservationStrategy, transformer: PreservationTransformer) {
	// if explicit transformer not provided, guess at one based upon the field's data type
	transformer = transformer || getTransformer(clazz as unknown as Class, propertyKey);

	const setStateKey  = `${propertyKey}SetState`;
	clazz[setStateKey] = async function(value) {
		const state: State = {
			target : this,
			propertyKey,
			value  : await transformer.set.call(this, value, propertyKey),
			route  : {
				current : _.clone(this.$route),
			},
		};

		if (strategy.save(state)) {
			this.$router.push(state.route.current);
		}
	};
	Watch(propertyKey, { deep : true })(clazz, setStateKey);

	const getStateKey  = `${propertyKey}GetState`;
	clazz[getStateKey] = async function(currentRoute, previousRoute) {
		const state: State = {
			target : this,
			propertyKey,
			route  : {
				current  : _.clone(currentRoute),
				previous : previousRoute,
			},
		};

		const value = strategy.load(state);
		if (value === undefined) {
			return;
		}

		try {
			this[propertyKey] = await transformer.get.call(this, value, propertyKey);
		}
		catch (error) {
			// Ignore
		}
	};
	Watch('$route', { immediate : true, deep : true })(clazz, getStateKey);
}

interface State {
	target: any;
	propertyKey: string;
	value?: any;
	route: {
		current: Route;
		previous?: Route;
	};
}

/**
 * Transformers are custom de/serialization between value storage and in-app use.
 */
export interface PreservationTransformer {
	get(value: string, propertyKey: string): any;
	set(value: any, propertyKey: string): string;
}

/**
 * Returns an appropriate PreservationTransformer based upon the class's field data type.
 */
function getTransformer(clazz: Function, propertyKey: string): PreservationTransformer {
	const designType = Reflect.getMetadata('design:type', clazz, propertyKey);

	if (typeof designType === 'function' && BaseEntity.isPrototypeOf(designType)) {
		return EntityTransformer(designType);
	}

	if (designType === Date) {
		return DateTransformer;
	}

	if (designType === Boolean) {
		return BooleanTransformer;
	}

	return BaseTransformer;
}

const BaseTransformer: PreservationTransformer = {
	get(value: string): any {
		return value;
	},
	set(value: any): string {
		return value;
	},
};

const DateTransformer: PreservationTransformer = {
	get(value: string): Date {
		return Moment(value).toDate();
	},
	set(value: Date): string {
		return value.toISOString();
	},
};

export const BooleanTransformer: PreservationTransformer = {
	get(value: string): boolean {
		return [ 'true', '1' ].includes(value);
	},
	set(value: boolean): string {
		return String(value);
	},
};

export function ObjectTransformer(transformers: Dictionary<PreservationTransformer>) {
	return {
		get(obj: any): any {
			return _.mapValues(obj, (value, key) => transformers[key] ? transformers[key].get(value, key) : value);
		},
		set(obj: any) {
			return _.mapValues(obj, (value, key) => transformers[key] ? transformers[key].set(value, key) : value);
		},
	};
}

export const JSONTransformer: PreservationTransformer = {
	get(value: string) {
		return _.isNil(value) ? value : JSON.parse(value);
	},
	set(value) {
		return _.isNil(value) ? value : JSON.stringify(value);
	},
};

type Case = 'kebabCase' | 'snakeCase' | 'camelCase' | 'startCase' | 'upperCase' | 'lowerCase' | 'pascalCase';
export function CaseTransformer(getCase: Case, setCase: Case) {
	return {
		get(value: string): string {
			return _[getCase](value);
		},
		set(value: string): string {
			return _[setCase](value);
		},
	};
}

const ENTITY_TRANSFORMER_CACHE_PROPERTY = Symbol('__entityTransformerCache__');

/**
 * Preserves an BaseEntity field by storing it's id.
 * New entities are stored using the word "new" and restored by creating a new instance of the entity type.
 * @param entity                        type of the entity to transform to
 * @param options.passIdForNewEntities  if true, pass the id of entity for new entities instead of the string 'new'
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function EntityTransformer(entity: typeof BaseEntity, { passIdForNewEntities = false, findOptions = {} } = {}): PreservationTransformer {
	return {
		get : async function(value: string, propertyKey: string): Promise<BaseEntity> {
			if (!value) {
				return null;
			}

			const cache = this[ENTITY_TRANSFORMER_CACHE_PROPERTY]?.[propertyKey]?.[value];

			if (cache) {
				return cache;
			}

			if (value === 'new') {
				return new entity();
			}

			if (typeof value !== 'string') {
				throw new Error(`value is not a string: ${value}`);
			}

			return entity.findOne(_.merge({ where : { id : value } }, findOptions));
		},
		set : function(value: BaseEntity, propertyKey: string): string {
			// ensure the private cache property is created
			this[ENTITY_TRANSFORMER_CACHE_PROPERTY] = this[ENTITY_TRANSFORMER_CACHE_PROPERTY] || {};

			if (!value) {
				return '';
			}

			// Cache new entities under the correct id for later retrieval
			const cacheId = value.isNew && !passIdForNewEntities ? 'new' : value.id;

			_.set(this[ENTITY_TRANSFORMER_CACHE_PROPERTY], `${propertyKey}.${cacheId}`, value);

			return cacheId;
		},
	};
}

/**
 * Transforms the stored value between a numeric index and a string in an array corresponding to that index.
 */
export function MapByIndexTransformer(array: any[]): PreservationTransformer {
	return {
		get(value: string): number {
			return array.indexOf(value);
		},
		set(value: number): string {
			return array[value];
		},
	};
}

// Helper function to determine whether or not an object is a transformer
// Since transformers are POJOs we need to sniff out if get/set exist
function isPreservationTransformer(obj: any): boolean {
	return obj.get && obj.set;
}
