











































import Moment                          from 'moment';
import { Vue, Component, Prop, Watch } from '$/lib/vueExt';
import VueValidationMixin              from '$/lib/mixins/VueValidationMixin';

@Component({ model : { event : 'update:value' } })
export default class DobPicker extends VueValidationMixin {

	@Prop()
	readonly value: Date | string;

	@Prop({ default : false })
	readonly required: boolean;

	@Prop({ default : false })
	readonly disabled: boolean;

	@Prop({ default : false })
	readonly plaintext: boolean;

	@Prop({ default : 'md' })
	readonly size: string;

	/**
	 * The largest possible date selectable via this control.
	 */
	@Prop()
	readonly maxDate: Date;

	@Prop()
	readonly minDate: Date;

	@Prop({ default : null })
	readonly state: boolean;

	month: number = null;
	day: number   = null;
	year: number  = null;

	get DobPicker() {
		return DobPicker;
	}

	get date() {
		return new Date(this.year, this.month, this.day, 12, 0, 0);
	}

	get yearsArray(): number[] {
		// The maxDate year, or the year 6 months from now to support future lease
		const maxYear = (this.maxDate ?? new Date()).getFullYear();
		const minYear = this.minDate?.getFullYear();

		return _.range(maxYear, minYear ? minYear - 1 : maxYear - 100, -1);
	}

	async isValid(): Promise<boolean> {
		if (_.isNil(this.month) || _.isNil(this.day) || _.isNil(this.year)) {
			this.setFeedback(this.required ? 'Date cannot be empty' : '');
			return !this.required;
		}

		let result = !this.maxDate || Moment(this.date).isSameOrBefore(this.maxDate, 'day');

		if (!result) {
			this.setFeedback(`Date must be equal or earlier than: ${Moment(this.maxDate).format('ll')}`);
			return false;
		}

		result = !this.minDate || Moment(this.date).isSameOrAfter(this.minDate, 'day');

		if (!result) {
			this.setFeedback(`Date must be equal or later than: ${Moment(this.minDate).format('ll')}`);
			return false;
		}

		return VueValidationMixin.isValid(this as any);
	}

	created() {
		this.decomposeValue(this.value);
	}

	@Watch('value')
	decomposeValue(newValue: Date | string | number) {
		if (_.isNil(newValue)) {
			this.year = this.month = this.day = null;
			return;
		}

		if (!(newValue instanceof Date)) {
			newValue = new Date(newValue);
		}
		newValue.setHours(12, 0, 0);

		// set parts again from new value since certain day/month/year combos are not valid (eg: Feb 31)
		this.year  = (newValue as Date).getUTCFullYear();
		this.month = (newValue as Date).getUTCMonth();
		this.day   = (newValue as Date).getUTCDate();

		// set invalidation feedback
		void this.isValid();
	}

	@Watch('month')
	@Watch('day')
	@Watch('year')
	private onDateChanged() {
		if (_.isNil(this.year) || _.isNil(this.month) || _.isNil(this.day)) {
			return;
		}

		const newValue = this.date;
		this.$emit('update:value', newValue);

		Vue.nextTick(() => {		// nextTick is used to prevent a race condition in the UI where sometimes the new value would not be set
			this.decomposeValue(newValue);
		});
	}

	static monthsArray = Moment.monthsShort().map((text, value) => ({ value, text }));
	static daysArray: number[] = _.range(1, 32);

}
