import ClassTransformer from 'class-transformer';
import JSON5            from 'json5';		// relaxed parser that, among other things, parses comments in the JSON text
import { stringify }    from '$/lib/utils';

const { plainToClass, plainToClassFromExist, Type, Expose } = ClassTransformer;

/**
 * This class intended as a mixin provides the ability to convert the final class to/from JSON objects
 */
export class JSONable {

	/**
	 * Modifies `this` with values passed by the the `json` param
	 * @param json  JSON to use to populate the instance
	 * @returns     modified instance
	 */
	fromJSON(json: JSONType, { strict = true } = {}) {
		return plainToClassFromExist(this, typeof json === 'string' ? (strict ? JSON : JSON5).parse(json) : json /* , { excludeExtraneousValues : true }*/);
	}

	/**
	 * Returns a new instance of this class populated with the passed json with adjusted types
	 * @param json  JSON to use to populate the instance
	 * @returns     new instance
	 */
	static fromJSON(json: JSONType, { strict = true } = {}) {
		return plainToClass(this, typeof json === 'string' ? (strict ? JSON : JSON5).parse(json) : json /* , { excludeExtraneousValues : true } */);
	}

	static toJSON(object): JSONType {
		return object === undefined || object === null ? object : JSON.parse(stringify(object));
		// SHOULDDO: put this back but need to solve weird issues with the not getting the correct type of JSONable
		// return classToPlain(object, { strategy : 'excludeAll' });
	}

	static getFields(): string[] {
		return Reflect.getMetadata('fields', this);
	}

}

/**
 * Replace the original @Type decorator by class-transformer to automatically pick up the type of field by Reflect
 */
export function Field(designType?: Class) {
	return function(target, propertyKey: string) {
		designType = designType || Reflect.getMetadata('design:type', target, propertyKey);
		if (!designType) {
			return;
		}

		// COULDDO: add property descriptors (adds more complexity)
		let fields = Reflect.getMetadata('fields', target.constructor);
		if (!fields) {
			fields = [];
			Reflect.defineMetadata('fields', fields, target.constructor);
		}
		fields.push(propertyKey);

		// apply the Expose decorator to the field
		Expose.call(this).apply(this, arguments); // eslint-disable-line prefer-rest-params

		// apply the Type decorator to the field
		return Type.call(this, () => designType).apply(this, arguments); // eslint-disable-line prefer-rest-params
	};
}
