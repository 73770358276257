




























import { Vue, Component, Prop, Watch } from '$/lib/vueExt';

@Component({ model : { prop : 'visible', event : 'input' } })
export default class Collapsible extends Vue {

	@Prop({ default : '' })
	readonly title: string;

	@Prop({ default : '' })
	readonly titleClass: string | any[] | Record<string, unknown>;

	@Prop({ default : false })
	readonly hideTitle: boolean;

	@Prop({ default : false })
	readonly visible: boolean;

	isExpanded: boolean = false;

	@Watch('visible', { immediate : true })
	onVisibilityChange(value) {
		if (value !== this.isExpanded) {
			this.isExpanded = value;
		}
	}

	@Watch('isExpanded')
	onExpansionChange(value, oldValue) {
		if (value !== oldValue) {
			this.$emit('input', value);
		}
	}

	toggle() {
		this.isExpanded = !this.isExpanded;
	}

}
