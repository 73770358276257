/**
 * Sets up the client-side logging.
 */
import Axios from 'axios';

import env                    from '$/lib/env';
import { stringify }          from '$/lib/utils';
import { PixelEventLogger }   from '$/lib/MetaPixel';
import { UETEventLogger }     from '$/lib/MicrosoftUET';
import { HubSpotEventLogger } from '$/lib/hubspot';
import { GTagAdsEventLogger, GTagAnalyticsEventLogger }                          from '$/plugins/vueGtag';
import { BaseLogger, Log, Splitter, Console, Filter, BufferLogger, LoggerLevel } from '$/common/lib/logger';

export { LogEvents, ALaCarteType } from '$/common/lib/global';
export * from '$/common/lib/logger';

/**
 * A logger that POSTs logs to an HTTP endpoint.
 */
class HttpPost extends BaseLogger {

	/**
	 * The URL of the logging API endpoint.
	 */
	url = '';

	constructor(url) {
		super();
		this.url = url;
	}

	log(log: Partial<Log>)
	log(logs: Log[])
	log(error: Error)
	log(level: LoggerLevel, message: string | Error, details: any)
	log(logsOrLevel: Partial<Log> | Partial<Log>[] | Error | LoggerLevel, message?: string | Error, details?: any): Log[] {
		let logs = super.log.apply(this, [ logsOrLevel, message, details ]);

		// commands don't get logged
		logs = logs.filter(log => log.level != LoggerLevel.Command);

		if (typeof navigator.sendBeacon === 'function') {
			let data = '';
			try {
				data = stringify(logs);
			}
			catch (error) {
				data = stringify(error);
			}
			navigator.sendBeacon(this.url, new Blob([ data ], { type : 'application/json' }));	// sendBeacon doesn't block the browser and will send even during unload
		}
		else {
			void Axios.post(this.url, logs);		// fallback to HTTP post; don't wait for it to finish sending
		}

		return logs;
	}

}

const clientLogger = new Filter(
	// pass-through logger that adds extra details to error logs
	(log: Log) => {
		if (log.level === LoggerLevel.Error || log.level === LoggerLevel.Warn) {
			if (!log.details) {
				log.details = {};
			}
			log.details.url     = window.location.toString();
			log.details.browser = env.browser;
			log.details.os      = env.os;
			log.details.build   = env.build;
		}

		return true;
	},
	new Splitter([
		new Console({ addTimestamp : false }),	// browser console already has timestamps
		new HubSpotEventLogger(),
		new GTagAdsEventLogger(),
		new GTagAnalyticsEventLogger(),
		new PixelEventLogger(),
		new UETEventLogger(),
		new BufferLogger({ maxLength : 50, maxTime : 500, flushMessage : 'FLUSH_BUFFER' },
			new HttpPost('/api/log')
		),
	])
);

export default clientLogger as BaseLogger;

/**
 * Allows attaching of other loggers from anywhere within the app via the splitter's addLogger() method
 */
export function addLogger(logger: BaseLogger) {
	return (clientLogger.truthyLogger as Splitter).addLogger(logger);
}
