import Axios   from 'axios';
import { Vue } from '$/lib/vueExt';

import { Authentication }                 from '$/entities/Authentication';
import { Entity, EntityID, SyncEntities } from '$/entities/BaseEntity';

import { User as UserCommon } from '$/common/entities/User';
export *                      from '$/common/entities/User';

let current: User;


@Entity()
export class User extends UserCommon {

	async changePassword(currentPassword: string, newPassword: string) {
		await Axios.put(User.collectionUrl(`${this.id}/password`), { current : currentPassword, new : newPassword });
	}

	async changeEmail(email: string) {
		await Axios.put(User.collectionUrl(`${this.id}/email`), { email });
	}

	/**
	 * @returns the ID of the current logged in user (if any)
	 */
	static get currentID(): EntityID {
		return current?.id;
	}

	/**
	 * @param {boolean} [options.redirectToLogin=true] if true, redirects to login if credentials no longer valid
	 */
	static async loadCurrent({ redirectToLogin = true } = {}): Promise<User> {
		try {
			// make the instance Vue-reactive since it's being used in many places
			current = Vue.observable(await User.findOne({ where : { id : 'me' } }));
		}
		catch (err) {
			if (err.status === 403 || err.status === 401) {
				if (redirectToLogin) {
					// credentials no longer valid; user must login again
					void Authentication.signOut({ routeTo : document.location.href });
				}
				current = undefined;	// user is not logged in
			}
			else {
				throw err;
			}
		}
		return current;
	}

	/**
	 * If the client is authenticated, this returns the current User.
	 */
	@SyncEntities()
	static get current(): User {
		return current;
	}

}
