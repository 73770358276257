






































import { Vue, Component } from 'vue-property-decorator';
import SuccessAnimation   from './SuccessAnimation.vue';
import BluSkyAnimation    from './BluSkyAnimation.vue';

export enum AnimationType {
	Success = 'success-animation',
	BluSky  = 'blu-sky-animation'
}

interface AnimationOptions {
	title?: string;
	subtitle?: string;
	contentClass?: string;
	hideAfter?: number; // if set to zero, it will not hide
	html?: boolean;
	opacity?: number;
	actions?: {
		[key: string]: {
			title?: string;
			variant?: string;
			class?: string;
			handler?: () => any;
		};
	};
}

@Component({
	components : { SuccessAnimation, BluSkyAnimation },
})
export default class Animations extends Vue {

	type: AnimationType = null;

	title        = '';
	subtitle     = '';
	contentClass = '';
	opacity      = 0.8;
	visible      = false;
	actions      = {};
	html         = false;

	async showAnimation(type: AnimationType, {
		title        = '',
		subtitle     = '',
		contentClass = '',
		hideAfter    = 3000,
		actions      = {},
		opacity      = 0.8,
		html         = false,
	}: AnimationOptions = {}) {
		this.html         = html;
		this.title        = title;
		this.subtitle     = subtitle;
		this.contentClass = contentClass;
		this.type         = type;
		this.actions      = actions;
		this.opacity      = opacity;

		this.$nextTick(async () => {
			this.visible = true;
			if (hideAfter) {
				await _.delay(hideAfter as number);
				this.visible = false;
			}
		});
	}

	onOverlayClick() {
		if (Object.keys(this.actions).length) {
			return;
		}

		this.visible = false;
	}

	onActionClick({ handler = () => ({}) } = {}) {
		this.visible = false;
		handler();
	}

	private static instance: Animations;

	static async showAnimation(type: AnimationType, options: AnimationOptions) {
		if (!this.instance) {
			this.instance = new Animations();
			const div     = document.createElement('div');
			document.body.appendChild(div);
			this.instance.$mount(div);
		}

		await this.instance.showAnimation(type, options);
	}


}
