




























/**
 * Resource viewer is a variant of FileViewer but uses bootstrap modal instead of the third party lightbox
 * It's mainly used for showing agreements and other documents from the resource section
 */
import { Vue, Component, Prop, Watch, Ref } from '$/lib/vueExt';
import type { Resource }                    from '$/lib/resources';

@Component({
	model : {
		prop  : 'resource',
		event : 'input',
	},
})
export default class ResourceViewer extends Vue {

	@Prop({ type : Object })
	readonly resource: Resource;

	@Ref()
	readonly iframe;

	@Ref()
	readonly video: HTMLVideoElement;

	/**
	 * The maximum duration the user has watched (so if they go back and forth we still count the max as 'viewed' content)
	 */
	videoMaxProgress = 0;

	/**
	 * Checks the extension of the file to deduce whether or not the file is a video
	 * COULD DO: send an explicit flag from the resource
	 */
	get isVideo() {
		return /(mp4|mkv|avi)$/g.test(this.resource?.url);
	}

	@Watch('resource', { immediate : true })
	async onResourceChange(resource: Resource) {
		if (!resource) {
			return;
		}

		if (resource.download) {
			window.open(resource.url, '_blank', 'noopener noreferrer');
			this.$emit('input', null);
		}
		else if (resource.openInNewTab) {
			window.open(resource.url, '_blank');
			this.$emit('input', null);
		}
		else if (resource.html) {
			await _.delay(500); // add a bit of delay for the modal to render then use the $ref within the modal
			this.iframe.contentWindow.document.write(resource.html);
		}
	}

	hide(event?) {
		let additionalData;
		if (this.isVideo) {
			// Before hiding send some data about how far along the video the user got
			additionalData = {
				videoStats : {
					maxProgress : this.videoMaxProgress.toFixed(0),
					duration    : this.video?.duration.toFixed(0) || 0,
				},
			};
			this.videoMaxProgress = 0;
		}
		this.$emit('hide', event, additionalData); // When this event is received from the parent the resource field is still populated
		this.$emit('input', null);
	}

	onVideoProgressChanged() {
		if (!this.video) {
			return;
		}

		this.videoMaxProgress = Math.max(this.videoMaxProgress, this.video.currentTime);
	}

	/**
	 * Show the given resource in a global ResourceViewer instance
	 */
	static showResource(resource: Resource) {
		// doesn't matter what element it mounts to since b-modal moves itself to the $root element anyway
		new this({ propsData : { resource } }).$mount(document.createElement('div'));
	}

}
