




















import { Vue, Component, Prop } from '$/lib/vueExt';

@Component({
	inheritAttrs : false,
	model        : { prop : 'password', event : 'change' },
})
export default class FormPassword extends Vue {

	@Prop({ default : '' })
	password: string;

	get inputPassword() {
		return this.password;
	}
	set inputPassword(value) {
		this.$emit('change', value);
	}

	// SHOULDDO: Make this not be a prop that we have to sync. Search for other password fields in the same form to toggle their visibility
	@Prop({ default : false })
	visible: boolean;

	@Prop({ default : '' })
	size: string;

}
