import { Country } from '$/lib/Address';
import { Address } from '$/entities/Address';

/**
 * Utilities for determining whether a user can have access to a section of the app based on location, etc.
 */
export enum AppSection {
	TenantSearch     = 'tenantSearch',
	CreditReports    = 'creditReports',
	Building         = 'building',
	CreditAlert      = 'creditAlert',
	BackgroundChecks = 'backgroundChecks',
	Collections      = 'collections',
}

const RESTRICTED          = true;
const restrictedLocations = {
	[AppSection.TenantSearch] : { // Landlords from these areas should not be able to use Tenant Search
		[Country.US] : RESTRICTED,
		[Country.CA] : {
			SK : RESTRICTED,
			NL : RESTRICTED,
			QC : RESTRICTED,
			NB : RESTRICTED,
			NS : RESTRICTED,
			PE : RESTRICTED,
		},
	},
	[AppSection.CreditAlert] : {
		[Country.CA] : {
			NL : RESTRICTED,
			QC : RESTRICTED,
			NB : RESTRICTED,
			NS : RESTRICTED,
			PE : RESTRICTED,
		},
	},
	[AppSection.BackgroundChecks] : {
		[Country.US] : RESTRICTED,		// applies to both the Application being checked and the LL doing the check
	},
	[AppSection.Collections] : {
		[Country.US] : RESTRICTED,		// we don't currently have a Collections process for US
	},
};

export function isRestrictedByLocation(section: AppSection, address: Address);
export function isRestrictedByLocation(section: AppSection, country: Country, province?: string, city?: string);
export function isRestrictedByLocation(section: AppSection, country: Country | Address, province?: string, city?: string) {
	if (country instanceof Address) {
		({ country, province, city } = country);
	}

	let restricted = restrictedLocations[section] as boolean | Dictionary<any>;

	for (const area of _.compact([ country, province, city ])) {
		restricted = restricted?.[area];

		if ((restricted as unknown as boolean) === RESTRICTED) {
			break;
		}
	}

	// if the specified location isn't explicit enough to be considered restricted
	if (typeof restricted === 'object') {
		return false;
	}

	return !!restricted;
}

/**
 * Get an array of restricted locations for a section of the app
 * Useful for SQL queries to check if the concatenation of country, province, or city of a table row is in the restricted locations array
 */
export function getRestrictedLocationsAsArray(section: AppSection, delimiter = '') {
	return Object.keys(_.flattenObject(restrictedLocations[section])).map(location => location.split('.').join(delimiter));
}
