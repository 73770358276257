






















import { Vue, Component } from '$/lib/vueExt';

@Component
export default class Page extends Vue {

	get title() {
		return this.$route?.meta?.title;
	}

}
