


























import { Vue, Component, Prop }                     from '$/lib/vueExt';
import { type Testimonial, testimonialSourceIcons } from '$/lib/testimonials';

@Component
export default class TestimonialNode extends Vue {

	@Prop({ required : true })
	readonly testimonial: Testimonial;

	get sourceIcons() {
		return testimonialSourceIcons;
	}

	get name() {
		if (!this.testimonial.testifier) {
			return 'Anonymous';
		}
		return this.testimonial.testifier.company_name
			|| `${_.startCase(this.testimonial.testifier.first_name)} ${this.testimonial.testifier.last_name.slice(0, 1).toLocaleUpperCase()}.`;
	}

}
