









/**
 * Overwrite BForm to add support for triggering validation on descendant FormInput elements.
 */
import { BForm as BFormOriginal } from 'bootstrap-vue';

import { Vue, Component, Provide } from '$/lib/vueExt';
import type { HasValidMethod }     from '$/lib/mixins/VueValidationMixin';

@Component({ components : { BFormOriginal } })
export default class Form extends Vue {

	// a list of all form input descendants
	private formInputs: HasValidMethod[] = [];

	@Provide()
	registerInput(formInput: HasValidMethod) {
		this.formInputs.push(formInput);
	}

	@Provide()
	unregisterInput(formInput: HasValidMethod) {
		this.formInputs = this.formInputs?.filter(input => input !== formInput);
	}

	/**
	 * Validates all form input elements under this form.
	 */
	async isValid() {
		let isValid                 = true;
		let invalidElement: Element = null;

		for (const formInput of this.formInputs) {
			if (!(await formInput.isValid())) {
				isValid = false;
				if ((formInput as any)._isVue) {
					invalidElement = (formInput as unknown as Vue).$el;
				}
			}
		}

		if (isValid) {
			// also check native form element validation
			isValid = (this.$el as HTMLFormElement).reportValidity();
		}

		if (!isValid && invalidElement) {
			invalidElement.scrollIntoView({ block : 'center' });
		}

		return isValid;
	}

	reset() {
		this.formInputs.forEach(formInput => (formInput as any)?.setFeedbackCallback?.(''));
	}

	async isNotValid() {
		return !(await this.isValid());
	}

	beforeDestroy() {
		this.formInputs = null;
	}

}
