export enum Unit {
	Byte     = 'B',
	KiloByte = 'KB',
	KibiByte = 'KiB',
	MegaByte = 'MB',
	MebiByte = 'MiB',
	GigaByte = 'GB',
	GibiByte = 'GiB',
	TeraByte = 'TB',
	TebiByte = 'TiB',
}

// COULDDO: dynamically generate this
const UnitMultipliers = {
	[Unit.Byte]     : 1,
	[Unit.KiloByte] : 1000,
	[Unit.KibiByte] : 1024,
	[Unit.MegaByte] : Math.pow(1000, 2),
	[Unit.MebiByte] : Math.pow(1024, 2),
	[Unit.GigaByte] : Math.pow(1000, 3),
	[Unit.GibiByte] : Math.pow(1024, 3),
	[Unit.TeraByte] : Math.pow(1000, 4),
	[Unit.TebiByte] : Math.pow(1024, 4),
};

/**
 * Represents a numerical Byte value, with convenience methods to convert between different units
 */
export default class Bytes {

	byteValue: number = 0;

	/**
	 * Constructs a new Bytes object from the given value.
	 * If value is already a Bytes object, its byteValue is copied to this new Byte.
	 */
	constructor(value: Bytes | number = 0, unit: Unit = Unit.Byte) {
		if (value instanceof Bytes) {
			this.byteValue = value.byteValue;
			return;
		}

		this.set(value || 0, unit);
	}

	// Convenience setters & getters
	get kilobyteValue() {
		return this.get(Unit.KiloByte);
	}
	set kilobyteValue(newValue) {
		this.set(newValue, Unit.KiloByte);
	}

	/* kibibyteValue : makeGetterSetter('KiB'),
	megabyteValue : makeGetterSetter('MB'),
	mebibyteValue : makeGetterSetter('MiB'),
	gigabyteValue : makeGetterSetter('GB'),
	gibibyteValue : makeGetterSetter('GiB'),
	terabyteValue : makeGetterSetter('TB'),
	tebibyteValue : makeGetterSetter('TiB');
	*/

	/**
	 * Gets the value of this Bytes object using the specified unit
	 */
	get(unit: Unit = Unit.Byte): number {
		if (!unit || !UnitMultipliers.hasOwnProperty(unit)) {
			throw new Error(`Unknown Bytes unit: ${unit}`);
		}

		const result = this.byteValue / UnitMultipliers[unit];
		// have a single decimal of precision is the number is relatively small
		return result <= 2.0 ? Math.round(result * 10) / 10 : Math.round(result);
	}

	/**
	 * Sets the value of this Bytes object with specified value and unit
	 */
	set(value, unit: Unit = Unit.Byte) {
		if (!unit || !UnitMultipliers.hasOwnProperty(unit)) {
			throw new Error(`Unknown Bytes unit: ${unit}`);
		}

		this.byteValue = Math.round(value * UnitMultipliers[unit]);
	}

	/**
	 * Makes a new Bytes object whose value is the sum of this Bytes and a given value
	 */
	add(value, unit: Unit = Unit.Byte): Bytes {
		return new Bytes(this.byteValue + Bytes.ensureBytes(value, unit).byteValue);
	}

	/**
	 * Makes a new Bytes object whose value is the difference of this Bytes and a given value
	 */
	subtract(value, unit: Unit = Unit.Byte): Bytes {
		return new Bytes(this.byteValue - Bytes.ensureBytes(value, unit).byteValue);
	}

	/**
	 * Determines if this Bytes object is equivalent to a given value
	 */
	equals(value, unit: Unit = Unit.Byte): boolean {
		return this.byteValue == Bytes.ensureBytes(value, unit).byteValue;
	}

	/**
	 * Determines if this Bytes object is greater than a given value
	 */
	isGreater(value, unit: Unit = Unit.Byte): boolean {
		return this.byteValue > Bytes.ensureBytes(value, unit).byteValue;
	}

	/**
	 * Determines if this Bytes object is less than a given value
	 */
	isLess(value, unit: Unit = Unit.Byte): boolean {
		return Bytes.ensureBytes(value, unit).isGreater(this);
	}

	/**
	 * Gets a string representation of this Bytes, rounding to the closest binary unit
	 */
	toString(): string {
		const units  = [ Unit.Byte, Unit.KibiByte, Unit.MebiByte, Unit.GibiByte, Unit.TebiByte ];
		let prevUnit = Unit.Byte;
		for (const unit of units) {
			if (this.byteValue < UnitMultipliers[unit]) {
				return `${this.get(prevUnit)} ${prevUnit}`;
			}
			prevUnit = unit;
		}

		return `${this.get(prevUnit)} ${prevUnit}`;
	}

	/**
	 * Custom implementation for coercing to a primitive value for comparison checks using `<`, `>`, and `==` operators
	 */
	valueOf() {
		return this.byteValue;
	}

	/**
	 * Ensures a given value is a Bytes. If the given value is a Number, a new Bytes is made.
	 * If the given value is already a Bytes, then the same Bytes object is returned.
	 */
	static ensureBytes(value: Bytes | number, unit: Unit = Unit.Byte): Bytes {
		return value instanceof Bytes ? value : new Bytes(value, unit);
	}

	/**
	 * De-serializes an object representation from the database into a new Bytes model
	 */
	static fromObject(value: number | string | Bytes): Bytes {
		if (value instanceof Bytes) {
			return value;
		}

		if (typeof value === 'string') {
			value = parseInt(value, 10);
		}

		// value is a Number, and not NaN or ±Infinity
		if (typeof value === 'number' && isFinite(value)) {
			value = new Bytes(value);
		}

		return value as Bytes;
	}

}

/**
 * TypeORM transformer for Bytes.
 * Use this transformer with any bytes Column.
 *
 */
export const BytesTransformer = {
	from : (dbValue: number): Bytes => Bytes.fromObject(dbValue),
	to   : (value: Bytes): number => value ? value.byteValue : 0,
};
