










import { Vue, Component, Prop, Mixins }      from '$/lib/vueExt';
import Validate                              from '$/lib/Validate';
import { Country }                           from '$/lib/Address';
import NationalIDHelper, { ValidDelimiters } from '$/lib/NationalIDHelper';
import VueValidationMixin                    from '$/lib/mixins/VueValidationMixin';

@Component({ model : { event : 'update:value' } })
export default class NationalIDInput extends Mixins(VueValidationMixin) {

	@Prop({ required : true })
	readonly value: string;

	@Prop({ default : null, type : String as () => Country })
	readonly country: Country;

	@Prop({ default : null })
	readonly label: string;

	@Prop({ default	: false })
	readonly hideLabel: boolean;

	@Prop({ type : String as () => ValidDelimiters })
	readonly delimiter: ValidDelimiters;

	get isRequired() {
		return (this as unknown as Vue).$attrs.hasOwnProperty('required') && (this as unknown as Vue).$attrs.required;
	}

	get helper() {
		return NationalIDHelper(this.country);
	}

	// available but not currently used
	get placeholder() {
		return this.helper.encryptedDisplay('X', this.delimiter);
	}

	// HACK: @Validate replaces the VueStateMixin isValid with its own isValid so replace it back
	isValid() {
		return VueValidationMixin.isValid(this as any);
	}

	@Validate({
		custom : function(value) {
			if (!value && this.isRequired && !this.disabled) {
				return 'must have a value';
			}
			if (!value || this.disabled || value === this.helper.encryptedDisplay('*', this.delimiter)) {
				return '';
			}

			return this.helper.isValid(value) ? '' : `Invalid ${this.helper.getLabel(true)}`;
		},
	})
	get nationalID() {
		return this.helper.format(this.value, this.delimiter);
	}
	set nationalID(value) {
		(this as unknown as Vue).$emit('update:value', this.helper.normalize(value));
	}

}
