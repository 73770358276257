
/**
 * Overrides for BLink.
 */
import { BLink as BLinkOriginal } from 'bootstrap-vue';
import { Component, Prop }        from '$/lib/vueExt';

@Component
export default class Link extends BLinkOriginal {

	// override the default target to _blank so that by default links open up on new tabs
	@Prop({ default : '_blank' })
	readonly target: string;

}
