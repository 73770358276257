/**
 * Provides access to the Stripe client-side library.
 */
import { loadStripe } from '@stripe/stripe-js/pure';
import env            from '$/lib/env';

export * from '$/common/lib/stripe';

let stripe: Promise<any>;
let elements: Promise<any>;

export default async function Stripe() {
	if (!stripe) {
		stripe = loadStripe(env.config('stripe.publishableKey', { required : true }));
	}

	return stripe;
}

Stripe.elements = function() {
	if (!elements) {
		elements = Stripe().then(stripe => stripe.elements());
	}

	return elements;
};

Stripe.createToken = async function(...args) {
	return (await Stripe()).createToken(...args);
};
