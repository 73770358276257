import Moment    from 'moment';
import { Mixin } from '$/lib/utils';

import { JSONable, Field } from '$/entities/lib/JSONable';
import type { BaseRole }   from '$/entities/roles/BaseRole';
import type { User }       from '$/entities/User';

/**
 * Represent any form of agreement or consent made by an individual within an organization
 * Organization is accessible via role
 */
@Mixin(JSONable)
export class Agreement {

	@Field()
	userId: string = undefined;

	@Field()
	roleId: string = undefined;

	@Field()
	agreed: boolean = undefined;

	@Field()
	date: Date;

	constructor(role?: BaseRole, user?: User, agreed?: boolean, date?: string | Date | Moment.Moment) {
		if (role || !_.isNil(agreed)) {
			this.update(role, user, agreed, Moment(date).toDate());
		}
	}

	update(role?: BaseRole, user?: User, agreed?: boolean, date?: Date) {
		this.roleId = role?.id;
		this.userId = user?.id;
		this.agreed = agreed;
		this.date   = date ?? new Date();
	}

}

@Mixin(JSONable)
export class RoleAgreements {

	@Field()
	eula: Agreement = new Agreement();

}

// SHOULD DO: add OrganizationAgreements here
