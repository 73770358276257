import Axios from 'axios';

import { Entity } from '$/entities/BaseEntity';

import { Application as ApplicationCommon } from '$/common/entities/tenantScreening/Application';
export *                                    from '$/common/entities/tenantScreening/Application';

@Entity()
export class Application extends ApplicationCommon {

	/**
	 * Intended to be used by an Applicant to pay for the Application.
	 */
	async payForApplication() {
		await Axios.post(Application.collectionUrl(`/${this.id}/pay`));
		await this.reload();
	}

	/**
	 * Request the reports from Equifax/Certn for each of the tenant checks in this application
	 */
	async getReports() {
		await Axios.post(Application.collectionUrl(`/${this.id}/request-reports`));
		await this.reload();
	}

	async sendInvite() {
		await Axios.post(Application.collectionUrl(`/${this.id}/send-invite`));
	}

	async unarchive() {
		await Axios.post(Application.collectionUrl(`/${this.id}/unarchive`));
		await this.reload();
	}

	static async getSampleEmail() {
		return (await Axios.get(Application.collectionUrl('/sample-email'))).data;
	}

}
