
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M10.288 3.27a.577.577 0 0 0 .577-.578V.577a.577.577 0 1 0-1.154 0v2.115c0 .319.258.577.577.577zm2.715.215 1.154-1.154a.577.577 0 1 0-.816-.816L12.188 2.67a.577.577 0 1 0 .815.816zm-5.431 0a.577.577 0 0 0 .816-.816L7.234 1.515a.577.577 0 1 0-.816.816l1.154 1.154zM18.515 8.973l-1.604-3.209a1.721 1.721 0 0 0-1.548-.956H5.213c-.66 0-1.254.366-1.549.956L2.06 8.973a.583.583 0 0 0 .019.55l5.648 9.622c.31.528.882.855 1.493.855h2.136c.61 0 1.183-.327 1.492-.855l5.649-9.622a.583.583 0 0 0 .018-.55zm-3.704-3.011L13.024 8.26l-1.609-2.298h3.396zm-4.523.456.019-.027 1.584 2.263H8.684L10.27 6.39l.019.027zM9.16 5.962 7.552 8.26 5.764 5.962H9.16zm-4.52.433 1.757 2.259H3.51l1.13-2.26zm4.58 12.451a.579.579 0 0 1-.498-.285L3.584 9.808h3.548l2.387 9.038h-.3zm-.895-9.038h3.925l-1.962 7.429-1.963-7.43zm3.528 8.753a.585.585 0 0 1-.5.285h-.297l2.388-9.038h3.548l-5.139 8.753zm2.326-9.907 1.757-2.26 1.13 2.26h-2.887z"}})])
          )
        }
      }
    