/**
 * Entity used to write logs into the DB for persistance.
 */
import { BaseEntity, ManyToOne, Entity, Column } from '$/entities/BaseEntity';

import type { Platform }     from '$/lib/env';
import type { LoggerLevel }  from '$/lib/logger';
import type { User }         from '$/entities/User';
import type { BaseRole }     from '$/entities/roles/BaseRole';
import type { Organization } from '$/entities/Organization';

@Entity({ common : true })
export class Log extends BaseEntity {

	@Column()
	level: LoggerLevel = null;

	@Column()
	origin: Platform = null;

	@ManyToOne('User', { onDelete : 'SET NULL' })
	user: User;

	@ManyToOne('BaseRole')
	role: BaseRole;

	@ManyToOne('Organization')
	org: Organization;

	@Column({ nullable : true })
	ipAddress: string = null;

	@Column()
	message: string = '';

	@Column({ type : 'json', nullable : true })
	details: any = null;

}
