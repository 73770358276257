import _Vue from 'vue';

import ChatBot    from '$/plugins/ChatBot';
import Breakpoint from '$/plugins/Breakpoint';
import Gtag       from '$/plugins/vueGtag';

export default {
	install(Vue: typeof _Vue) { // eslint-disable-line @typescript-eslint/naming-convention
		Vue.use(ChatBot);
		Vue.use(Breakpoint);
		Vue.use(Gtag);
	},
};
