








































import Moment     from 'moment';
import Datepicker from 'vuejs-datepicker';
// HACK: need datepicker in a popover as a workaround for: https://github.com/charliekassel/vuejs-datepicker/issues/385
import { Component, Prop, Mixins, COMPONENT_UID_KEY } from '$/lib/vueExt';
import VueValidationMixin                             from '$/lib/mixins/VueValidationMixin';

/**
 * A widget that allows the user to select a particular month of a year.
 */
@Component({
	components   : { Datepicker },
	inheritAttrs : false,
})
export default class MonthPicker extends Mixins(VueValidationMixin) {

	@Prop()
	readonly value: Date;

	@Prop({ default : false })
	readonly disabled: boolean;

	@Prop({ default : false })
	readonly plaintext: boolean;

	@Prop({ default : false })
	readonly required: boolean;

	@Prop({ default : 'bottom' })
	readonly placement: string;

	/**
	 * Same as the disabled-dates prop on vuejs-datepicker
	 * @see https://github.com/charliekassel/vuejs-datepicker#disabled-dates
	 */
	@Prop()
	readonly disabledDates;

	get id() {
		return `datepicker-${this[COMPONENT_UID_KEY]}`;
	}

	get format() {
		return 'yyyy MMMM';
	}

	textValue(value) {
		return value ? Moment(value).format(this.format) : value;
	}

}
