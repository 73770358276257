
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 -5.61 120.804 120.804"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"m18.912 71.259 17.754-13.644v51.97H18.912Z"}}),_c('path',{attrs:{"d":"m54.941 55.429 18.255 5.655v48.5H54.941Z"}}),_c('path',{attrs:{"d":"m91.221 56.726 18.138-17.117v69.975H91.221Z"}}),_c('path',{attrs:{"d":"m0 59.301 3.994 5.289 39.1-31.128 37.053 12.081 34.485-34.853v15.689h6.175V0H94.679v6.813h16.094l-32.94 32.3L41.05 26.376Z"}})])
          )
        }
      }
    