
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 39.433 39.433"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M19.717 0 0 17.75h3.943v21.694H35.49V17.75h3.943Zm0 35.49a9.864 9.864 0 0 1-1.977-19.527v-2.135h-1.977V9.885h7.887v3.943h-1.967v2.145a9.51 9.51 0 0 1 2.156.747l2.334-2.334 2.787 2.787-1.9 1.914a9.829 9.829 0 0 1-7.34 16.4Z"}}),_c('path',{attrs:{"d":"M25.637 25.637a5.93 5.93 0 0 0-4.732-5.794l-1.167-.242-.032.011v6.046l4.175 4.185a5.974 5.974 0 0 0 1.756-4.206Z"}})])
          )
        }
      }
    